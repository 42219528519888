import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { ButtonBase, Typography, makeStyles } from "@material-ui/core";
import { SPACE_CADET, WHITE } from "constants/colors";

import { ReactComponent as DocumentIcon } from "assets/document-icon.svg";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import { CheckBoxControl } from "components/Checkbox";
import { attachmentType } from "pages/BrickDashboard/shapes";

const useStyles = makeStyles({
  wrapper: {
    color: WHITE,
    width: 120,
    overflow: "hidden",
  },
  itemPreview: {
    width: 120,
    height: 120,
    marginBottom: 10,
    cursor: "pointer",
    display: "block",
  },
  media: {
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  symbol: {
    backgroundColor: SPACE_CADET,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  name: {
    marginBottom: 3,
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  date: {
    fontSize: 10,
    lineHeight: 1,
  },
  checkboxWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
});

const AttachmentItem = ({
  name,
  date,
  deleteMode,
  onClick,
  RHFKey,
  idx,
  type,
  thumb,
}) => {
  const classes = useStyles();
  const { control } = useFormContext();

  return (
    <div className={classes.wrapper} onClick={onClick}>
      {type === attachmentType.DOCUMENT && (
        <ButtonBase className={clsx(classes.itemPreview, classes.symbol)}>
          <DocumentIcon width={28} height={38} />
        </ButtonBase>
      )}
      {(type === attachmentType.IMAGE || type === attachmentType.VIDEO) && (
        <div
          className={clsx(classes.itemPreview, classes.media)}
          style={{ backgroundImage: `url(${thumb})` }}
        />
      )}
      <Typography className={classes.name} title={name}>{name}</Typography>
      <Typography className={classes.date}>
        {dayjs(date).format("DD/MM/YYYY")}
      </Typography>
      {deleteMode && (
        <div
          className={classes.checkboxWrapper}
          onClick={e => e.stopPropagation()}
        >
          <CheckBoxControl
            control={control}
            name={`${RHFKey}.${idx}.delete`}
            defaultValue={false}
            pxSize={20}
          />
        </div>
      )}
    </div>
  );
};

AttachmentItem.propTypes = {
  name: PropTypes.string,
  date: PropTypes.string,
  deleteMode: PropTypes.bool,
  RHFKey: PropTypes.string.isRequired,
  idx: PropTypes.number,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(Object.values(attachmentType)).isRequired,
  thumb: PropTypes.string,
};

export default AttachmentItem;
