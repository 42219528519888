/* eslint-disable max-len */
import React from "react";
import { PropTypes } from "prop-types";

const SentimentNeutral = ({ fill = "#FF8800", width, height, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 50}
      height={height || 50}
      viewBox="0 0 63 63"
      fill="none"
      style={style}
    >
      <path
        d="M31.44 1.59137e-08C25.2229 -0.000197767 19.1454 1.84321 13.976 5.2971C8.80652 8.75099 4.7774 13.6602 2.39808 19.404C0.0187585 25.1478 -0.603882 31.4682 0.608893 37.5659C1.82167 43.6635 4.81539 49.2646 9.21147 53.6608C13.6076 58.0571 19.2086 61.051 25.3062 62.2639C31.4038 63.4769 37.7242 62.8545 43.4681 60.4753C49.2119 58.0962 54.1213 54.0672 57.5754 48.8979C61.0294 43.7286 62.873 37.6511 62.873 31.434C62.8635 23.1003 59.5488 15.1106 53.656 9.21768C47.7633 3.32476 39.7737 0.00979272 31.44 1.59137e-08ZM31.44 60.134C25.7637 60.134 20.2148 58.4508 15.4952 55.2972C10.7755 52.1436 7.09691 47.6613 4.92468 42.417C2.75244 37.1728 2.18409 31.4022 3.29148 25.8349C4.39888 20.2677 7.13229 15.1538 11.1461 11.14C15.1598 7.12627 20.2737 4.39286 25.8409 3.28546C31.4082 2.17807 37.1788 2.74642 42.423 4.91866C47.6673 7.09089 52.1496 10.7694 55.3032 15.4891C58.4568 20.2088 60.14 25.7577 60.14 31.434C60.1315 39.0431 57.1051 46.3382 51.7246 51.7186C46.3442 57.0991 39.0491 60.1255 31.44 60.134Z"
        fill={fill}
      />
      <path
        d="M20.506 30.0672C21.3169 30.0672 22.1096 29.8267 22.7838 29.3762C23.4581 28.9257 23.9836 28.2854 24.2939 27.5362C24.6042 26.787 24.6854 25.9626 24.5272 25.1673C24.369 24.372 23.9785 23.6415 23.4051 23.0681C22.8317 22.4947 22.1012 22.1042 21.3059 21.946C20.5106 21.7878 19.6862 21.869 18.937 22.1793C18.1878 22.4896 17.5475 23.0151 17.097 23.6893C16.6465 24.3636 16.406 25.1563 16.406 25.9672C16.4073 27.0542 16.8397 28.0963 17.6083 28.8649C18.3769 29.6335 19.419 30.0659 20.506 30.0672ZM20.506 24.6002C20.7764 24.6002 21.0407 24.6804 21.2655 24.8306C21.4903 24.9808 21.6655 25.1943 21.769 25.4441C21.8724 25.6939 21.8995 25.9687 21.8467 26.2339C21.794 26.499 21.6638 26.7426 21.4726 26.9338C21.2814 27.125 21.0379 27.2552 20.7727 27.3079C20.5075 27.3607 20.2327 27.3336 19.9829 27.2301C19.7331 27.1267 19.5196 26.9515 19.3694 26.7267C19.2192 26.5019 19.139 26.2376 19.139 25.9672C19.1395 25.6048 19.2837 25.2574 19.54 25.0012C19.7962 24.7449 20.1436 24.6007 20.506 24.6002Z"
        fill={fill}
      />
      <path
        d="M42.373 21.8672C41.5621 21.8672 40.7694 22.1077 40.0952 22.5582C39.4209 23.0087 38.8954 23.649 38.5851 24.3982C38.2748 25.1474 38.1936 25.9717 38.3518 26.7671C38.51 27.5624 38.9005 28.2929 39.4739 28.8663C40.0473 29.4397 40.7778 29.8302 41.5731 29.9884C42.3685 30.1466 43.1928 30.0654 43.942 29.7551C44.6912 29.4448 45.3315 28.9193 45.782 28.245C46.2325 27.5708 46.473 26.7781 46.473 25.9672C46.4717 24.8802 46.0393 23.8381 45.2707 23.0695C44.5021 22.3009 43.46 21.8685 42.373 21.8672ZM42.373 27.3342C42.1027 27.3342 41.8383 27.254 41.6135 27.1038C41.3887 26.9536 41.2135 26.7401 41.1101 26.4903C41.0066 26.2405 40.9795 25.9657 41.0323 25.7005C41.085 25.4353 41.2152 25.1918 41.4064 25.0006C41.5976 24.8094 41.8412 24.6792 42.1063 24.6264C42.3715 24.5737 42.6463 24.6008 42.8961 24.7042C43.1459 24.8077 43.3594 24.9829 43.5096 25.2077C43.6598 25.4325 43.74 25.6968 43.74 25.9672C43.7395 26.3296 43.5953 26.677 43.339 26.9332C43.0828 27.1895 42.7354 27.3337 42.373 27.3342Z"
        fill={fill}
      />
      <path
        d="M45.106 38.2671H17.773C17.5904 38.2622 17.4087 38.2939 17.2386 38.3603C17.0685 38.4268 16.9134 38.5266 16.7826 38.654C16.6517 38.7814 16.5477 38.9337 16.4766 39.1019C16.4056 39.2702 16.369 39.451 16.369 39.6336C16.369 39.8162 16.4056 39.997 16.4766 40.1653C16.5477 40.3335 16.6517 40.4858 16.7826 40.6132C16.9134 40.7406 17.0685 40.8405 17.2386 40.9069C17.4087 40.9734 17.5904 41.005 17.773 41.0001H45.106C45.462 40.9905 45.8002 40.8423 46.0486 40.5871C46.297 40.3318 46.436 39.9898 46.436 39.6336C46.436 39.2775 46.297 38.9354 46.0486 38.6801C45.8002 38.4249 45.462 38.2767 45.106 38.2671Z"
        fill={fill}
      />
    </svg>
  );
};

SentimentNeutral.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
};

export { SentimentNeutral };
