import React, { useEffect } from "react";
import PropTypes from "prop-types";
import theme from "themeV4";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";

// Material UI
import { Grid, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Components
import TextField from "components/TextField";
import Loading from "components/Loading";
import Button from "components/Button";
import SvgIcon from "components/SvgIcon";
// Assets
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
import { WILD_BLUE_YONDER } from "constants/colors";

// Styles
const useStyles = makeStyles(() => ({
  formWrapper: {
    width: "100%",
  },
  switchWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  dragIcon: {
    marginRight: 10,
  },
  loading: {
    height: "40vh",
  },
  title: {
    fontWeight: "bold",
    fontSize: 13,
  },
  containerGrid: {
    width: "100%",
    marginTop: 10,
  },
  formRow: {
    marginBottom: 20,
  },
  formRowSpaced: {
    marginBottom: 30,
  },
  referenceGroup: {
    marginTop: 20,
  },
}));

const THEME_TYPE = "light";
const DEFAULT_REFERENCE = { name: "", email: "", number: "" };

const CustomerForm = ({ customerData, loading }) => {
  // Hooks
  const classes = useStyles();

  const {
    formState: { errors },
    control,
    register,
    reset,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "references",
    keyName: "_id",
  });

  // Effects
  useEffect(() => {
    if (customerData) {
      const { name, references } = customerData;
      reset({
        name,
        references,
      });
    } else {
      reset({ name: "" });
    }
  }, [customerData]);

  // Helpers
  const addReference = () => append(DEFAULT_REFERENCE);

  // Renders
  const renderReference = (field, idx) => {
    const isDisabled = field?.deletable === false;
    return (
      <Grid key={idx} container item xs={12} className={classes.referenceGroup}>
        <Grid item xs={idx > 0 ? 11 : 12} className={classes.formRow}>
          <TextField
            disabled={isDisabled}
            type="text"
            themeType={THEME_TYPE}
            label={`Referente ${idx + 1}`}
            {...register(`references.${idx}.name`)}
            defaultValue={field?.name || DEFAULT_REFERENCE.name}
            error={!!errors.references?.[idx]?.name}
          />
        </Grid>

        {/* Reference Delete Button */}
        {idx > 0 && (
          <Grid
            item
            xs={1}
            container
            className={classes.formRow}
            style={{ display: "flex" }}
          >
            <Button
              className="iconButton"
              variant="outlined"
              color="secondary"
              themeType="light"
              onClick={() => remove(idx)}
              style={{ marginLeft: "auto", marginTop: -1 }}
              disabled={field?.deletable === false}
            >
              <SvgIcon
                color={
                  field?.deletable === false
                    ? WILD_BLUE_YONDER
                    : theme.palette.secondary.main
                }
                width={40}
                height={40}
                icon={<CancelIcon />}
              />
            </Button>
          </Grid>
        )}

        <Grid item xs={12} className={classes.formRow}>
          <TextField
            disabled={isDisabled}
            type="email"
            themeType={THEME_TYPE}
            label={`Mail referente ${idx + 1}`}
            {...register(`references.${idx}.email`)}
            defaultValue={field?.email || DEFAULT_REFERENCE.email}
            error={!!errors.references?.[idx]?.email}
          />
        </Grid>

        <Grid item xs={12} className={classes.formRow}>
          <TextField
            disabled={isDisabled}
            type="text"
            themeType={THEME_TYPE}
            label={`Telefono referente ${idx + 1}`}
            {...register(`references.${idx}.number`)}
            defaultValue={field?.number || DEFAULT_REFERENCE.number}
            error={!!errors.references?.[idx]?.number}
          />
        </Grid>

        <TextField
          disabled={isDisabled}
          type="text"
          themeType={THEME_TYPE}
          {...register(`references.${idx}.id`)}
          defaultValue={field?.id}
          style={{ display: "none" }}
        />
      </Grid>
    );
  };

  if (loading)
    return (
      <div className={classes.loading}>
        <Loading showWrapper={false} />
      </div>
    );

  return (
    <Fade in timeout={400}>
      <form>
        <Grid container item className={classes.containerGrid}>
          <Grid item xs={12} className={classes.formRowSpaced}>
            <Controller
              render={({ field }) => (
                <TextField
                  label="Nome"
                  error={!!errors.name}
                  required
                  themeType={THEME_TYPE}
                  shrink={!!field.value}
                  {...field}
                />
              )}
              name="name"
              control={control}
              rules={{ required: true }}
            />
          </Grid>

          {/* Render first reference field group */}
          {renderReference(fields[0], 0)}

          <Grid item xs={12} className={classes.formRow}>
            <Button
              fullWidth
              themeType="light"
              variant="outlined"
              color="primary"
              onClick={addReference}
            >
              + REFERENTE
            </Button>
          </Grid>

          {/* ...Render remaining references field groups */}
          {fields
            ?.slice(1)
            ?.map((field, idx) => renderReference(field, idx + 1))}
        </Grid>
      </form>
    </Fade>
  );
};

CustomerForm.propTypes = {
  customerData: PropTypes.object,
  loading: PropTypes.bool,
};

export default CustomerForm;
