import React from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
// Components
import HorizonSeparator from "../HorizonSeparator";
// Skyline Config
import { HORIZON_RADIUS, HORIZON_WIDTH } from "../config";

const useStyles = makeStyles({
  horizon: {
    position: "absolute",
    height: HORIZON_WIDTH,
    backgroundColor: "#FFFFFF",
    borderRadius: HORIZON_RADIUS,
    transition: "all 0.3s ease-out",
    zIndex: 5,
  },
});

const HorizonLine = ({
  leftAnnexWidth,
  offsetLeft,
  offsetTop,
  rightAnnexWidth,
  start,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.horizon}
      style={{
        left: `-${leftAnnexWidth}px`,
        marginLeft: `${offsetLeft}px`,
        width: `calc(100% + ${leftAnnexWidth}px  + ${rightAnnexWidth}px)`,
        top: offsetTop,
      }}
    >
      {start && <HorizonSeparator />}
    </div>
  );
};

HorizonLine.defaultProps = {
  start: false,
  leftAnnexWidth: 0,
  offsetLeft: 0,
  offsetTop: 0,
  rightAnnexWidth: 0,
};

HorizonLine.propTypes = {
  leftAnnexWidth: PropTypes.number,
  offsetLeft: PropTypes.number,
  offsetTop: PropTypes.number,
  rightAnnexWidth: PropTypes.number,
  start: PropTypes.bool,
};

export default HorizonLine;
