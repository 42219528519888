import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, styled } from "@mui/material";
import CustomizedProgressBar from "components/ProgressBar";
import { WILD_BLUE_YONDER } from "constants/colors";
import { seniorityTypes } from "utils/enums/be";
import CountUp from "react-countup";
import { useCountUp } from "use-count-up";

// styles
const SkillRow = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "& .skill-name": {
    fontWeight: "bold",
    fontSize: 13,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingRight: 16,
  },

  "& .skill-percentage": {
    fontWeight: 700,
    fontSize: 13,
  },

  "& .skill-level": {
    width: 120,
    textTransform: "uppercase",
    color: WILD_BLUE_YONDER,
    fontSize: 13,
    fontWeight: 700,
  },

  "& .skill-progress": {
    flexGrow: 1,
  },
});

const skillLevelMap = {
  [seniorityTypes.JUNIOR]: "Junior",
  [seniorityTypes.INTERMEDIATE]: "Intermediate",
  [seniorityTypes.SENIOR]: "Senior",
};

const Skill = ({ data }) => {
  const {
    value,
    skill: { name, seniority },
  } = data;

  const { value: animatedValue } = useCountUp({
    isCounting: true,
    end: value,
    start: 0,
    duration: 1,
  });

  return (
    <Box mb="25px">
      <SkillRow>
        <Typography className="skill-name">{name}</Typography>
        <Typography className="skill-percentage">
          <CountUp
            start={0}
            end={value}
            delay={0.2}
            useEasing
            decimals={0}
            duration={0.4}
            suffix="%"
          />
        </Typography>
      </SkillRow>
      <SkillRow>
        <Typography className="skill-level">
          {skillLevelMap[seniority]}
        </Typography>
        <div className="skill-progress">
          <CustomizedProgressBar
            height={10}
            value={animatedValue}
            width="100%"
            backgroundColor="rgba(165, 169, 199, 0.4)"
          />
        </div>
      </SkillRow>
    </Box>
  );
};

Skill.propTypes = {
  data: PropTypes.object,
};

export { Skill };
