import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import clsx from "clsx";
import { nanoid } from "nanoid";
// Date
import dayjs from "dayjs";
// hooks
import { useHistory } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

// Components
import Layout from "components/Layout";
import ChartCard from "components/ChartCard";
import Button from "components/Button";
import Loading from "components/Loading";
import Date from "components/Date";
import CustomSelect from "components/CustomSelect";
import useFilters from "hooks/useFilters";
import BrickLightbox from "./components/BrickLightbox";

// Charts and Themes
import AreaWithDateManager from "components/Charts/AreaWithDate/manager";
import StackedColumnManager from "components/Charts/StackedColumn/manager";
import GanttManager from "components/Charts/Gantt/manager";

// Constants
import {
  WILD_BLUE_YONDER,
  WHITE,
  PRIMARY,
  PACIFIC_BLU,
  MALACHITE,
} from "constants/colors";

// API
import {
  BRICKS,
  PLANNER_BRICKS_REPORT,
  PLANNER_BRICKS_ESTIMATE_FINISH,
  ATTACHMENTS,
  BRICKS_CHECKLIST,
} from "constants/api";

// Page components
import Evaluation from "./components/Evaluation";
import Note from "./components/Note";
import Report from "./components/Report";
import Skill from "./components/Skill";
import Checklist from "./components/Checklist";

// Enum
import { brickFiltersTypes } from "utils/enums/bricks";

//Hooks
import useFetch from "hooks/useHTTP";

//Utils
import { brickTypes } from "utils/enums/bricks";
import {
  timeToDays,
  timeToHours,
  truncateDecimals,
  parseDate,
  parseMonth,
  parseWeek,
} from "utils";

// Context
import { AppContext } from "context/AppContext";
import { planningMode } from "utils/enums/globals";
import AttachmentCarousel from "./components/AttachmentCarosuel/AttachmentCarousel";

import { Storage } from "aws-amplify";
import { attachmentType } from "./shapes";
import { fileEntityTypes } from "utils/enums/file";
import { useMemo } from "react";
import { AuthContext } from "context/AuthContext";

const useStyles = makeStyles({
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "12px 0 20px",
  },
  chartContainer: {
    height: 300,
    marginTop: 10,
  },
  disabledBrick: {
    background: WILD_BLUE_YONDER,
    color: WHITE,
  },
  filters: {
    display: "flex",
  },
  filter: {
    borderRadius: 20,
    textTransform: "uppercase",
    padding: 10,
    margin: 5,
    cursor: "pointer",
  },
  selectedFilter: {
    background: PRIMARY,
  },
  kpiContainer: {
    display: "flex",
    alignItems: "flex-start",
    margin: "50px 0 0 0",
    padding: "50px 0",
    flexWrap: "wrap",
  },
  noData: {
    margin: "30px 10px",
  },
  loadingWrapper: {
    height: "350px",
  },
  dateColumns: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dateRow: {
    height: "50px !important",
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 10px 10px 10px",
  },
  detailFirstCol: {
    flex: "0 0 457px",
  },
  titleProject: {
    fontSize: 15,
    fontWeight: "bold",
  },
  description: {
    marginBottom: 24,
  },
  headerMargin: {
    marginTop: "10px",
  },
  headerInfo: {
    color: WILD_BLUE_YONDER,
  },
});

const DEFAULT_QUALITY = 3;

const MAX_UPLOAD_FILE_SIZE = 10485760; // in bytes

const BrickDashboard = () => {
  const classes = useStyles();
  const { get, put, post, del } = useFetch();
  const history = useHistory();

  // Context
  const {
    addBreadcrumb,
    setPageTitle,
    setOwner,
    setRefreshSecondCol,
    settings,
    setModalConfig,
  } = useContext(AppContext);

  const { user } = useContext(AuthContext);

  // State
  const [loadingProject, setLoadingProject] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingEstimated, setLoadingEstimated] = useState(false);
  const [loadingToggleChecklist, setLoadingToggleChecklist] = useState(false);

  const [brickData, setBrickData] = useState(null);
  const [reportGraphData, setReportGraphData] = useState(null);
  const [estimateFinishGraphData, setEstimateFinishGraphData] = useState(null);
  const [ratingManager, setRatingManager] = useState(0);
  const [reportScrollBars, setReportScrollBars] = useState(false);
  const [lightboxMedia, setLightboxMedia] = useState(null);
  const [media, setMedia] = useState(null);
  const [mediaFolder, setMediaFolder] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [documentsFolder, setDocumentsFolder] = useState(null);
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const { id } = useParams();
  const { filters, setFilters } = useFilters({
    EstimatedFilter: brickFiltersTypes.DAYS,
    ReportFilter: brickFiltersTypes.DAYS,
  });

  const isQualityActive = settings?.isQualityActive;
  const isClassicMode = settings?.planningMode === planningMode.CLASSIC;
  const isCurrentUserBrick = brickData?.user.id === user.id;

  const formattedGanttData = useMemo(
    () => [
      {
        name: "FASE A",
        plannedName: "PIANIFICATO",
        realName: "REALE",
        plannedFromDateName: "Data di inizio pianificata",
        plannedFromDate:
          dayjs(brickData?.plannedStartDate)
            .locale("en")
            .format("DD MMM YYYY") || null,
        plannedToDateName: "Data di fine pianificata",
        plannedToDate:
          dayjs(brickData?.plannedEndDate).locale("en").format("DD MMM YYYY") ||
          null,
        plannedColor: PACIFIC_BLU,
        realColor: MALACHITE,
        realFromDate: dayjs(brickData?.displayStartDate)
          .locale("en")
          .format("DD MMM YYYY"),
        realToDate: dayjs(brickData?.displayEndDate)
          .locale("en")
          .format("DD MMM YYYY"),
        realFromDateName: "Data di inizio reale",
        realToDateName: `Data di fine ${
          brickData?.realEndDate ? "reale" : "prevista"
        }`,
      },
    ],
    [brickData]
  );

  // Effects
  useEffect(() => {
    // Attachments are fetched after bricks because attachments folders
    // are craeted at the first GET /brick/id (not before)
    fetchBrick().then(fetchAttachments);
  }, []);

  useEffect(() => {
    isClassicMode && fetchEstimateFinishGraph(filters.EstimatedFilter);
  }, [filters.EstimatedFilter]);

  useEffect(() => {
    fetchReportGraph(filters.ReportFilter);
  }, [filters.ReportFilter]);

  // Renders
  const renderLoading = () => (
    <Loading
      showWrapper={false}
      animationStyle={{ height: 80, width: 80, marginTop: -100 }}
    />
  );

  useEffect(() => {
    if (brickData) {
      addBreadcrumb({
        name: brickData?.name,
      });
      setPageTitle(brickData?.name);
    }
  }, [brickData]);

  // API GET BRICKS
  const fetchBrick = async () => {
    try {
      setLoadingProject(true);
      const res = await get(`${BRICKS}/${id}`);
      if (res.ok) {
        setBrickData(res.data);
        const path = res?.data?.user;
        //QUI CI VA L'OWNER, temporaneamente ci metto user
        setOwner({
          name: path?.name + " " + path?.surname,
          id: path?.id,
          image: path?.imageUrl,
          jobTitle: "owner di brick",
          onClick: () => history.push(`/user/${path?.id}`),
        });
      }
      setLoadingProject(false);
    } catch (err) {
      console.log(err);
      setLoadingProject(false);
    }
  };

  // API GET ATTACHMENTS
  const fetchAttachments = async () => {
    try {
      const { data } = await get(
        `${ATTACHMENTS}/brick/${id}?includeFiles=true`
      );
      const mediaFolder = data.find(f => f.name === "gallery");
      const documentsFolder = data.find(f => f.name === "documents");
      const nextMedia = mediaFolder?.children ?? [];
      const nextDocuments = documentsFolder?.children ?? [];
      setMedia(nextMedia);
      setMediaFolder(mediaFolder);
      setDocuments(nextDocuments);
      setDocumentsFolder(documentsFolder);
    } catch (err) {
      console.log(err);
    }
  };

  // API GET REPORT GRAPH
  const fetchReportGraph = async (range = 1) => {
    try {
      setLoadingReport(true);

      let filterFunction;
      switch (filters.ReportFilter) {
        case brickFiltersTypes.DAYS:
          filterFunction = (...args) => parseDate(...args);
          break;
        case brickFiltersTypes.WEEKS:
          filterFunction = (...args) => parseWeek(...args);
          break;
        case brickFiltersTypes.MONTHS:
          filterFunction = (...args) => parseMonth(...args);
          break;
        default:
          filterFunction = (...args) => parseDate(...args);
          break;
      }

      const res = await get(`${BRICKS}/${id}/${PLANNER_BRICKS_REPORT}${range}`);
      if (res.ok) {
        var newData = res.data.map(time => {
          var y = timeToHours(time.y) || 0;
          var extraY = timeToHours(time.extraY);
          return {
            x: filterFunction(time.x),
            y,
            extraY,
            dayToISOString: time.x,
          };
        });

        if (newData.length > 30) {
          setReportScrollBars(true);
        }

        setReportGraphData(newData);
      }
      setLoadingReport(false);
    } catch (err) {
      console.log(err);
      setLoadingReport(false);
    }
  };

  // API GET ESTIMATE FINISH GRAPH
  const fetchEstimateFinishGraph = async (range = 1) => {
    try {
      setLoadingEstimated(true);
      const res = await get(
        `${BRICKS}/${id}/${PLANNER_BRICKS_ESTIMATE_FINISH}${range}`
      );
      if (res.ok) {
        var newData = res.data.map(time => {
          var y = truncateDecimals(timeToDays(time.y) || 0, 1);
          return {
            x: time.x,
            y,
          };
        });
        setEstimateFinishGraphData(newData);
      }
      setLoadingEstimated(false);
    } catch (err) {
      console.log(err);
      setLoadingEstimated(false);
    }
  };

  const revalidateData = () => {
    fetchBrick();
    isClassicMode && fetchEstimateFinishGraph(filters.EstimatedFilter);
    fetchReportGraph(filters.ReportFilter);
    setRefreshSecondCol(true);
  };

  const onLightboxChange = direction => {
    let targetMediaIdx =
      media.findIndex(a => a.id === lightboxMedia.id) + direction;

    if (targetMediaIdx === media.length) targetMediaIdx = 0;

    const targetMedia = media.at(targetMediaIdx);
    targetMedia && handleLightboxContent(targetMedia);
  };

  const handleLightboxContent = async attachment => {
    const s3Key = attachment.s3Key.replace("public/", "");
    const presignedUrl = await Storage.get(s3Key, {
      bucket: process.env.REACT_APP_BUCKET_FILE,
    });
    setLightboxMedia({ ...attachment, url: presignedUrl });
  };

  const handleFileSelectionError = () => {
    // Hardcoded error message for now, if a map of errors
    // is needed it is possibile to use rejectedFiles passed
    // to this function (use first argument)
    setModalConfig({
      title: `Errore: il file è più grande di ${Math.round(
        MAX_UPLOAD_FILE_SIZE / (1024 * 1024)
      )} MB`,
      type: "error",
      primaryAction: {
        text: "OK",
      },
    });
  };

  const handleAttachmentUpload = async file => {
    const fileType = file.type.split("/")[0];
    const isMedia =
      fileType === attachmentType.IMAGE || fileType === attachmentType.VIDEO;
    const folderData = isMedia ? mediaFolder : documentsFolder;
    const fileExtension = file.name.split(".").pop();
    const uniqueFileName = `${nanoid()}.${fileExtension}`;
    const s3Key = `${folderData.s3Key.replace(
      "public/",
      ""
    )}/${uniqueFileName}`;

    try {
      await Storage.put(s3Key, file, {
        bucket: process.env.REACT_APP_BUCKET_FILE,
      });

      const createFileInput = {
        entity: fileEntityTypes.BRICK,
        entityId: id,
        name: file.name,
        folderId: folderData.id,
        size: file.size,
        s3Key: `${folderData.s3Key}/${uniqueFileName}`,
      };

      const { data: createdFile } = await post(
        `${ATTACHMENTS}/file`,
        createFileInput
      );

      const { currentList, listSetter } = getAttachmentState(isMedia);

      const nextAttachmentsList = [createdFile, ...currentList];
      listSetter(nextAttachmentsList);
    } catch (err) {
      console.error(err);
      showGenericError();
    }
  };

  const handleDeleteAttachments = async (formData, RHFKey) => {
    try {
      const deleteData = formData[RHFKey];
      const { currentList, listSetter } = getAttachmentState(
        RHFKey === "media"
      );
      const startingFilteredAttachments = {
        next: [],
        idsToDelete: [],
      };
      const attachmentsFiltered = currentList.reduce((filtered, curr, idx) => {
        // Using idx atm beacuse of shouldUnregister (original data is stripped away from the form)
        // if it causes problem, set it to false and manually reset checkbox when exit delete mode
        const hasToBeDeleted = deleteData[idx].delete;
        const updatedFiltered = {
          next: [...filtered.next, ...(hasToBeDeleted ? [] : [curr])],
          idsToDelete: [
            ...filtered.idsToDelete,
            ...(hasToBeDeleted ? [curr.id] : []),
          ],
        };
        return updatedFiltered;
      }, startingFilteredAttachments);

      const idsToDeleteQs = encodeURIComponent(
        attachmentsFiltered.idsToDelete.join(",")
      );
      await del(`${ATTACHMENTS}/file?ids=${idsToDeleteQs}`);

      listSetter(attachmentsFiltered.next);
    } catch (err) {
      showGenericError();
      console.error(err);
    }
  };

  const getAttachmentState = isMedia =>
    isMedia
      ? { currentList: media, listSetter: setMedia }
      : { currentList: documents, listSetter: setDocuments };

  const showGenericError = () =>
    setModalConfig({
      type: "error",
      primaryAction: {
        text: "OK",
      },
    });

  const closeOrReassignBrick = async () => {
    const ratingData = {
      rating: isQualityActive ? ratingManager : DEFAULT_QUALITY,
    };
    try {
      const res = await put(`${BRICKS}/${id}/rate`, ratingData);
      if (res.ok) {
        fetchBrick();
        fetchReportGraph();
        fetchEstimateFinishGraph();
        setRefreshSecondCol(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleToggleChecklist = async (checkListItemId, index) => {
    try {
      setLoadingToggleChecklist(true);
      const { data: nextChecklistItem } = await put(
        `${BRICKS_CHECKLIST}/${checkListItemId}`
      );
      const nextChecklists = [...brickData.checklists];
      nextChecklists.splice(index, 1, nextChecklistItem);
      const nextBrickData = { ...brickData, checklists: nextChecklists };
      setBrickData(nextBrickData);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingToggleChecklist(false);
    }
  };

  const onChange = (e, filterKey) => {
    let value = e.target.value;
    setFilters({ ...filters, [filterKey]: value });
  };

  const filtersRange = [
    { value: brickFiltersTypes.DAYS, label: "DAYS" },
    { value: brickFiltersTypes.WEEKS, label: "WEEKS" },
    { value: brickFiltersTypes.MONTHS, label: "MONTHS" },
  ];

  const renderSelect = (disabled, filterKey, minWidth) => {
    return (
      <div className={classes.select}>
        <CustomSelect
          options={filtersRange}
          disabled={disabled}
          onChange={e => onChange(e, filterKey)}
          value={filters[filterKey]}
          minWidth={minWidth}
        />
      </div>
    );
  };

  const renderHeader = () => (
    <div>
      <div className={classes.headerMargin}>
        <Typography className={classes.titleProject}>
          <span className={classes.headerInfo}>Nome progetto:</span>{" "}
          {brickData?.projectName}
        </Typography>
        <Typography className={classes.titleProject}>
          <span className={classes.headerInfo}>Nome fase:</span>{" "}
          <b>{brickData?.phaseName}</b>
        </Typography>
        {brickData?.description && (
          <Typography
            className={clsx(classes.titleProject, classes.description)}
          >
            <span className={classes.headerInfo}>Descrizione:</span>{" "}
            <b>{brickData?.description}</b>
          </Typography>
        )}
      </div>
      {brickData?.status === brickTypes.TO_VALIDATE && user?.canAccessWeb && (
        <div className={classes.buttonContainer}>
          {!!brickData.user?.disabled || (
            <Button
              variant="contained"
              disabled={ratingManager > 0}
              color={ratingManager === 0 ? "primary" : "default"}
              className={ratingManager > 0 ? classes.disabledBrick : ""}
              onClick={closeOrReassignBrick}
              style={{ marginRight: 10 }}
            >
              RIASSEGNA BRICK
            </Button>
          )}
          <Button
            variant="contained"
            disabled={ratingManager === 0 && isQualityActive}
            color={
              ratingManager > 0 || !isQualityActive ? "primary" : "default"
            }
            className={
              ratingManager === 0 && isQualityActive
                ? classes.disabledBrick
                : ""
            }
            onClick={closeOrReassignBrick}
          >
            CHIUDI BRICK
          </Button>
        </div>
      )}
    </div>
  );

  const renderEvaluation = () =>
    !loadingProject && (
      <Evaluation
        data={brickData}
        ratingManager={ratingManager}
        setRatingManager={setRatingManager}
      />
    );

  const renderGanttGraph = () =>
    loadingProject ? (
      <div className={classes.loadingWrapper}>{renderLoading()} </div>
    ) : (
      brickData &&
      isClassicMode && (
        <div className={classes.chartContainer}>
          <ChartCard height={"300px"} title="Time">
            <GanttManager id={"gantt1"} data={formattedGanttData} />
          </ChartCard>
        </div>
      )
    );

  const renderEstimatedGraph = () =>
    loadingEstimated ? (
      <div className={classes.loadingWrapper}>{renderLoading()} </div>
    ) : (
      estimateFinishGraphData && (
        <>
          <div className={classes.chartContainer}>
            <ChartCard
              title="Andamento modifica stima a finire"
              filters={renderSelect(false, "EstimatedFilter", "194px")}
            >
              <AreaWithDateManager
                id={"areaWithDate1"}
                data={estimateFinishGraphData}
              />
            </ChartCard>
          </div>
          {brickData && (
            <div className={classes.dateRow}>
              <div className={classes.dateColumns}>
                <Date variant="h6" dateString={brickData.displayStartDate} />
                {(brickData.realStartDate && "Data inizio reale") ||
                  "Data inizio pianifcata"}
              </div>
              <div className={classes.dateColumns}>
                <Date variant="h6" dateString={brickData.displayEndDate} />
                {(brickData.realEndDate && "Data fine reale") ||
                  "Data fine prevista"}
              </div>
            </div>
          )}
        </>
      )
    );

  const renderReportGraph = () =>
    loadingReport ? (
      <div className={classes.loadingWrapper}> {renderLoading()} </div>
    ) : (
      reportGraphData && (
        <div className={classes.chartContainer}>
          <ChartCard
            height={"350px"}
            title="Ore rendicontate nel tempo"
            filters={renderSelect(false, "ReportFilter", "194px")}
          >
            <StackedColumnManager
              id={"stackedColumn1"}
              scrollBars={reportScrollBars}
              data={reportGraphData}
            />
          </ChartCard>
        </div>
      )
    );

  const renderReportDetail = () =>
    loadingProject ? (
      <div className={classes.loadingWrapper}> {renderLoading()} </div>
    ) : brickData ? (
      <>
        <div className={classes.kpiContainer}>
          <div className={classes.detailFirstCol}>
            <Report
              brickData={brickData}
              isClassicMode={isClassicMode}
              onRevalidate={revalidateData}
            />
          </div>
          <Checklist
            disabled={!isCurrentUserBrick}
            loading={loadingToggleChecklist}
            data={brickData?.checklists}
            onToggleChecklistItem={handleToggleChecklist}
          />
          <Skill data={brickData?.skills} />
        </div>
        <Note data={brickData} />
      </>
    ) : (
      <Typography variant="h5" className={classes?.noData}>
        Non ci sono dati disponibili
      </Typography>
    );

  const renderAttachments = () => (
    <>
      {media && (
        <AttachmentCarousel
          title="Gallery"
          RHFKey="media"
          uploadText="Aggiungi media"
          attachments={media}
          accept={["image/*", "video/*"]}
          onItemClick={handleLightboxContent}
          loading={loadingMedia}
          setLoading={setLoadingMedia}
          onFileSelectionError={handleFileSelectionError}
          maxUploadFileSize={MAX_UPLOAD_FILE_SIZE}
          onUpload={handleAttachmentUpload}
          onDelete={handleDeleteAttachments}
        />
      )}
      {documents && (
        <AttachmentCarousel
          title="Documenti"
          RHFKey="documents"
          uploadText="Aggiungi documento"
          attachments={documents}
          style={{ paddingBottom: 40 }}
          loading={loadingDocuments}
          setLoading={setLoadingDocuments}
          onFileSelectionError={handleFileSelectionError}
          maxUploadFileSize={MAX_UPLOAD_FILE_SIZE}
          onUpload={handleAttachmentUpload}
          onDelete={handleDeleteAttachments}
        />
      )}
    </>
  );

  const renderProject = () => (
    <>
      {renderHeader()}
      {renderEvaluation()}
      {renderGanttGraph()}
      {renderEstimatedGraph()}
      {renderReportGraph()}
      {renderReportDetail()}
      {renderAttachments()}
    </>
  );

  const headerIcons = [
    {
      icon: "do",
    },
  ];

  return (
    <Layout headerIcons={headerIcons}>
      {renderProject()}
      <BrickLightbox
        media={lightboxMedia}
        onClose={() => setLightboxMedia(null)}
        onArrowClick={onLightboxChange}
      />
    </Layout>
  );
};

export default BrickDashboard;
