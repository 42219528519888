import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Components
import { CheckBox } from "components/Checkbox";
import PerfectScrollbar from "react-perfect-scrollbar";

import { makeStyles, Typography } from "@material-ui/core";

// Constants
import { WILD_BLUE_YONDER } from "constants/colors";
import Loading from "components/Loading";

const useStyles = makeStyles(() => ({
  container: {
    flex: "0 0 457px",
    padding: "0 20px",
    height: "289px",
    position: "relative",
  },
  checklistContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0 ",
  },
  checkContainer: {
    display: "flex",
    alignItems: "center",
    marginBlock: 1,
  },
  checkbox: {
    marginBottom: "auto",
    top: -1,
  },
  text: { fontSize: "20px" },
  text2: {
    fontSize: "15px",
  },
  text3: {
    fontSize: "15px",
    color: WILD_BLUE_YONDER,
  },
}));

const Checklist = ({ data, disabled, onToggleChecklistItem, loading }) => {
  const [count, setCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    const res = data?.filter(item => item.checked);
    setCount(res.length);
  }, [data]);

  if (data?.length === 0) return null;

  const renderText = (str, customClass) => {
    return (
      <Typography component="h3" className={customClass || classes.text}>
        {str}
      </Typography>
    );
  };

  return (
    <div className={classes.container}>
      {renderText("Checklist " + count + "/" + data.length)}
      <PerfectScrollbar id="pf-notes-brick">
        <div className={classes.checklistContainer}>
          {loading ? (
            <Loading
              showWrapper={false}
              animationStyle={{ height: 60, width: 60, marginTop: 16 }}
            />
          ) : (
            data?.map(({ checked, description, id }, index) => (
              <div className={classes.checkContainer} key={index}>
                <CheckBox
                  checked={checked}
                  disabled={disabled || data.disabled}
                  className={classes.checkbox}
                  onChange={() => onToggleChecklistItem?.(id, index)}
                />
                {/* <StyledCheckbox checked={checked} disabled={true} /> */}
                {renderText(
                  description,
                  checked ? classes.text2 : classes.text3
                )}
              </div>
            ))
          )}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

Checklist.defaultProps = {
  data: [],
  disabled: false,
  label: "Checklist",
};

Checklist.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onToggleChecklistItem: PropTypes.func,
};
export default Checklist;
