import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material UI
import { SvgIcon as MuiSvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Styles
const useStyles = makeStyles(() => ({
  root: ({ color, height, hoverColor, strokeWidth, width }) => ({
    width,
    height,
    "& path, circle": {
      strokeWidth: strokeWidth,
      fill: color,
      stroke: color,
      color: color,
    },
    "&:hover path, circle": {
      fill: hoverColor || color,
      stroke: hoverColor || color,
      color: hoverColor || color,
    },
  }),
}));

const SvgIcon = ({
  children,
  color,
  className,
  height,
  hoverColor,
  icon,
  strokeWidth,
  width,
  ...props
}) => {
  // Hooks
  const classes = useStyles({ color, height, hoverColor, strokeWidth, width });
  return (
    <MuiSvgIcon {...props} className={clsx(className, classes.root)}>
      {icon}
      {children}
    </MuiSvgIcon>
  );
};

SvgIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hoverColor: PropTypes.string,
  icon: PropTypes.node,
  strokeWidth: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
};

SvgIcon.defaultProps = {
  strokeWidth: 0,
};

export default SvgIcon;
