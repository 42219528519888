import React from "react";
import PropTypes from "prop-types";
import Truncate from "react-truncate";

// Constants
import {
  AMETHYST_SMOKE,
  OXFORD_BLUE,
  SPACE_CADET,
  WILD_BLUE_YONDER,
} from "constants/colors";
// Material UI
import SvgIcon from "components/SvgIcon";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
// Assets
import { ReactComponent as DragHandle } from "assets/drag-handle.svg";
import InfoIcon from "assets/info-icon.png";
// Enums
import { widgetHeightTypes } from "utils/enums/widgets";

// Styles
const useStyles = makeStyles(theme => ({
  wrapper: ({ active, clickable, height, searchable }) => ({
    backgroundColor: searchable ? SPACE_CADET : OXFORD_BLUE,
    width: "calc(100% - 5px)",
    height,
    borderRadius: 4,
    marginBottom: 10,
    display: "flex",
    padding: 12,
    paddingLeft: 9,
    cursor: clickable && "pointer",
    boxShadow: active
      ? `5px 5px 0px 0px ${theme.palette.primary.main}`
      : "none",
    "&:hover": {
      opacity: clickable && "0.6",
    },
  }),
  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: 35,
    padding: "2px 12px 0 0",
  },
  infoIcon: {
    display: "block",
    width: 16,
    height: 16,
  },
  contentWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 60px)",
  },
  title: {
    textAlign: "right",
    paddingTop: 2,
    fontSize: 9,
  },
  content: ({ secondaryText }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
    height: secondaryText ? "calc(100% - 30px)" : "100%",
    paddingBottom: secondaryText && "5px",
  }),
  secondaryText: {
    color: AMETHYST_SMOKE,
    fontSize: 9,
    width: "100%",
    textAlign: "right",
  },
}));

const Widget = ({
  active,
  children,
  onClick,
  searchable,
  secondaryText,
  size,
  title,
}) => {
  const widgetHeight = size === widgetHeightTypes.DOUBLE ? 208 : 104;
  // Hooks
  const classes = useStyles({
    active,
    height: widgetHeight,
    clickable: !!onClick,
    searchable,
    secondaryText,
  });

  // Renders
  const renderActions = () => (
    <div className={classes.actions}>
      <SvgIcon icon={<DragHandle />} color={WILD_BLUE_YONDER} height={18} />
      <img src={InfoIcon} className={classes.infoIcon} />
    </div>
  );

  const renderContent = () => (
    <div className={classes.contentWrapper}>
      <div className={classes.title}>
        <Truncate lines={size === widgetHeightTypes.DOUBLE ? 2 : 1}>
          {title}{" "}
        </Truncate>
      </div>

      <div className={classes.content}>{children}</div>
      {secondaryText && (
        <div className={classes.secondaryText}>
          <Truncate lines={1}>{secondaryText.toUpperCase()} </Truncate>
        </div>
      )}
    </div>
  );

  return (
    <div onClick={onClick} className={classes.wrapper}>
      {renderActions()}
      {renderContent()}
    </div>
  );
};

Widget.defaultProps = {
  size: widgetHeightTypes.NORMAL,
  searchable: false,
};

Widget.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
  size: PropTypes.oneOf([...Object.values(widgetHeightTypes)]),
  title: PropTypes.string,
  searchable: PropTypes.bool,
  secondaryText: PropTypes.string,
};

export default Widget;
