/* eslint-disable max-len */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { WILD_BLUE_YONDER, OXFORD_BLUE } from "constants/colors";

import { COLORS1 } from "./themes";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    height: "100%",
  },
}));

const StackedRadiusMultiColumn = ({
  data,
  id,
  columnWidth,
  theme,
  color,
  scrollBars,
  targetValue,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return;

    var chart = am4core.create(`div${id}`, am4charts.XYChart);
    chart.colors.list = theme;
    chart.paddingTop = 40;
    chart.paddingLeft = 0;

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "x";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.fill = am4core.color(color);
    categoryAxis.renderer.labels.template.maxWidth = 70;
    categoryAxis.renderer.minGridDistance = 30;

    categoryAxis.renderer.labels.template.rotation = -45;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";

    categoryAxis.dateFormatter.dateFormat = "DD MMM";
    categoryAxis.dateFormatter = new am4core.DateFormatter();

    categoryAxis.events.on("sizechanged", function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -45;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 0;
    valueAxis.renderer.labels.template.fill = am4core.color(color);
    valueAxis.renderer.grid.template.stroke = am4core.color(color);
    valueAxis.renderer.grid.template.strokeWidth = 3;
    valueAxis.renderer.minGridDistance = 30;

    valueAxis.title.text = "Ore";
    valueAxis.title.fill = am4core.color(color);
    valueAxis.title.rotation = 0;
    valueAxis.title.align = "center";
    valueAxis.title.valign = "top";
    valueAxis.title.dy = -25;
    valueAxis.title.dx = 30;
    valueAxis.title.fontWeight = 1000;

    if (Number.isInteger(targetValue)) {
      const range = valueAxis.axisRanges.create();
      range.value = targetValue;
      range.grid.stroke = am4core.color(WILD_BLUE_YONDER);
      range.grid.strokeWidth = 2;
      range.grid.strokeOpacity = 1;
      range.grid.tooltip = new am4core.Tooltip();
      range.grid.tooltipText = "Target";
      range.grid.tooltipPosition = "pointer";
    }

    // Create series
    function createSeries(field, name, stacked, index) {
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "x";
      series.name = name;
      series.stacked = stacked;

      series.columns.template.stroke = am4core.color(OXFORD_BLUE);
      series.columns.template.width = am4core.percent(columnWidth);
      series.columns.template.column.fillOpacity = 1 - index / 15 || 0.3;
      series.columns.template.propertyFields.fill = "fill";

      // tooltip
      series.columns.template.tooltipHTML = `<p>${name}</p><p><b>{${name}}</b> ore {toolTipText} <br> <b>{${name}Extra}</b> {${name}Text}</p>`;
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color(OXFORD_BLUE);
      series.tooltip.background.stroke = am4core.color(OXFORD_BLUE);
    }

    // Parsing server data and get all persons for specific day
    let keys = [];
    let newData = data.map(item => {
      let newobj = {
        x: item.x,
        type: item.type,
        fill: item.fill,
        toolTipText: item.toolTipText,
      };
      item.y.forEach(element => {
        newobj = {
          ...newobj,
          [element.name]: element.hours,
          [element.name + "Extra"]: element.extraY,
          [element.name + "Text"]: element.extraText,
        };
        if (!keys.includes(element.name)) keys.push(element.name);
      });
      return newobj;
    });

    chart.data = newData;

    // Cicle all person for days
    keys.forEach((item, index) => {
      createSeries(item, item, true, index);
    });

    function customizeGrip(grip) {
      grip.icon.disabled = true;
    }
    if (scrollBars) {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      chart.scrollbarX.thumb.minWidth = 50;
      chart.scrollbarX.maxHeight = 10;

      chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarY.maxHeight = 10;

      customizeGrip(chart.scrollbarX.startGrip);
      customizeGrip(chart.scrollbarX.endGrip);
      customizeGrip(chart.scrollbarY.startGrip);
      customizeGrip(chart.scrollbarY.endGrip);
    }
  }, [data]);

  return <div className={classes.container} id={`div${id}`} />;
};

StackedRadiusMultiColumn.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  theme: PropTypes.array,
  color: PropTypes.string,
  columnWidth: PropTypes.number,
  scrollBars: PropTypes.bool,
  targetValue: PropTypes.number,
};

StackedRadiusMultiColumn.defaultProps = {
  legend: false,
  color: WILD_BLUE_YONDER,
  columnWidth: 40,
  scrollBars: false,
  yMax: 60,
  theme: COLORS1,
};

export default StackedRadiusMultiColumn;
