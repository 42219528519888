import { Stack } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";

import { useEffect } from "react";
import { StaticDatePicker } from "components/pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const ExpectedEndDateModal = ({ currentValue, valueRef }) => {
  const [value, setValue] = useState(currentValue || dayjs());

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return (
    <Stack alignItems="center">
      <LocalizationProvider adapterLocale="it" dateAdapter={AdapterDayjs}>
        <StaticDatePicker value={value} onChange={setValue} themeType="light" />
      </LocalizationProvider>
    </Stack>
  );
};

ExpectedEndDateModal.propTypes = {
  // intended as iso datestring or dayjs date object
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  valueRef: PropTypes.object,
};

export { ExpectedEndDateModal };
