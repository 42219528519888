export const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};

export const getFileKey = (url, bucketName = process.env.REACT_APP_BUCKET_PROJECTS) =>
  url.replace(
    `https://${bucketName}.s3.amazonaws.com/public/`,
    ""
  );
