import React, { useState } from "react";
import PropTypes from "prop-types";

import { Typography, makeStyles } from "@material-ui/core";

import SentimentWithDate from ".";

const useStyles = makeStyles({
  noData: {
    width: "100%",
    textAlign: "center",
    paddingBlock: 90,
  },
});

const SentimentWithDateManager = props => {
  const [data] = useState(props.data);

  const classes = useStyles();

  if (Object.keys(data).length)
    return <SentimentWithDate data={data} {...props} />;

  return (
    <Typography variant="h6" className={classes.noData}>
      Al momento non sono presenti dati
    </Typography>
  );
};

SentimentWithDateManager.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

SentimentWithDateManager.defaultProps = {
  data: [],
};

export default SentimentWithDateManager;
