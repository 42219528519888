import React from "react";
import PropTypes from "prop-types";

import Loading from "components/Loading";

import SolidGauge from ".";

const SolidGaugeCost = props => {
  const { data } = props;

  if (Object.keys(data).length > 0) {
    return <SolidGauge data={data} {...props} />;
  }
  return <Loading />;
};

SolidGaugeCost.propTypes = {
  data: PropTypes.object.isRequired,
};

SolidGaugeCost.defaultProps = {
  data: {},
};

export default SolidGaugeCost;
