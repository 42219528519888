import React, { useContext, useEffect } from "react";
import useFetch from "hooks/useHTTP";
import clsx from "clsx";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Context
import { AuthContext } from "context/AuthContext";

// Components
import Avatar from "components/Avatar";
import Loading from "components/Loading";

// Constants
import { WHITE } from "constants/colors";
import { USER_DETAILS } from "constants/api";

// Enums
import { collarTypes } from "utils/enums/users";

// Styles
const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    marginTop: "30px",
  },
  name: {
    color: WHITE,
    marginTop: "25px",
    fontSize: "35px",
    textTransform: "capitalize",
  },
  userInfo: {
    fontSize: "20px",
    "&.white": {
      color: WHITE,
    },
    "&.margin": {
      margin: "30px",
    },
  },
  skillContainer: {
    display: "flex",
    alignItems: "center",
  },
  bar: {
    border: `1px solid ${WHITE}`,
    opacity: 0.2,
    width: "200px",
    height: "0px",
  },
  skillTitle: {
    fontSize: "15px",
    color: WHITE,
    marginBottom: "9px",
  },
  skill: {
    fontSize: "15px",
    marginBottom: "40px",
    textAlign: "center",
  },
  textWrap: { maxWidth: "380px" },
  loading: {
    height: "40vh",
  },
}));

const UserProfile = () => {
  const classes = useStyles({});
  const { user, setUser } = useContext(AuthContext);
  const { get, loading } = useFetch();
  const getUsers = async () => {
    await get(`${USER_DETAILS}/${user?.id}`)
      .then(response => {
        setUser(response?.data);
      })
      .catch(e => {
        console.log("e", e);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const userInfo = {
    company: "",
    jobTitle: user?.jobTitle?.name,
    department: user?.departmentName,
    managerName: user?.departmentManager,
  };

  const renderInfo = (str, customClass) => {
    return (
      <div className={classes.textWrap}>
        <Typography component="h3" className={customClass || classes.userInfo}>
          {str}
        </Typography>
      </div>
    );
  };
  const renderUserProfile = () => {
    return (
      <div className={classes.wrapper}>
        {loading ? (
          <div className={classes.loading}>
            <Loading showWrapper={false} />
          </div>
        ) : (
          <>
            <div className={classes.avatar}>
              <div className={classes.circle}>
                <Avatar
                  image={user?.imageUrl}
                  size={"140px"}
                  collar={collarTypes?.WHITE}
                />
              </div>
            </div>

            {renderInfo(user?.name + " " + user?.surname, classes.name)}
            {renderInfo(userInfo?.company)}
            {renderInfo(userInfo?.jobTitle, clsx(classes.userInfo, "white"))}
            {renderInfo(userInfo?.department)}
            {renderInfo(userInfo?.managerName)}

            <div className={classes.skillContainer}>
              <div className={classes.bar} />
              {renderInfo("Skill", clsx(classes.userInfo, "white", "margin"))}
              <div className={classes.bar} />
            </div>
            {user?.seniorSkills?.length > 0 && (
              <>
                {renderInfo("Senior", classes.skillTitle)}
                {renderInfo(
                  user?.seniorSkills?.map(el => el?.name)?.join(", "),
                  classes.skill
                )}
              </>
            )}
            {user?.intermediateSkills?.length > 0 && (
              <>
                {renderInfo("Intermediate", classes.skillTitle)}
                {renderInfo(
                  user?.intermediateSkills?.map(el => el?.name)?.join(", "),
                  classes.skill
                )}
              </>
            )}
            {user?.juniorSkills?.length > 0 && (
              <>
                {renderInfo("Junior", classes.skillTitle)}
                {renderInfo(
                  user?.juniorSkills?.map(el => el?.name)?.join(", "),
                  classes.skill
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  };

  return <div className={classes.wrapper}>{renderUserProfile()}</div>;
};

export default UserProfile;
