import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    height: props => props.height,
  },
  chartTitle: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: props => props.marginLeft,
    width: "100%",
  },
  chartContainer: {
    height: props => `calc(${props.height} - 60px)`,
  },
}));

const ChartCard = ({ children, title, height, filters, marginLeft }) => {
  const classes = useStyles({ height, marginLeft });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography className={classes.chartTitle}>{title}</Typography>
        {filters}
      </div>
      <div className={classes.chartContainer}>{children}</div>
    </div>
  );
};

ChartCard.defaultProps = {
  height: "100%",
};

ChartCard.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string,
  height: PropTypes.string,
  marginLeft: PropTypes.string,
  filters: PropTypes.object,
};

export default ChartCard;
