import React, { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";
import useFetch from "hooks/useHTTP";

import { useForm, FormProvider } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";

//AWS
import { Storage } from "aws-amplify";

// Components
import Drawer from "components/Drawer";
import UserForm from "pages/Settings/components/UserForm";
import PortfolioForm from "pages/Settings/components/PortfolioForm";
import DepartmentForm from "pages/Settings/components/DepartmentForm";

// Hooks
import useDrawer from "hooks/useDrawer";

// Context
import { AppContext, defaultlPageTitle } from "context/AppContext";

// Constants
import {
  projectImagesPrefix,
  PORTFOLIO,
  DEPARTMENTS,
  USERS_LIST,
} from "constants/api";
import { SETTINGS } from "constants/routes";

// Forms defaults
import { userFormDefaults as userDefaults } from "pages/Settings/components/UserForm/defaultValues";
import { departmentFormDefaults as departmentDefaults } from "pages/Settings/components/DepartmentForm/defaultValues";
import { portfolioFormDefaults as portfolioDefaults } from "pages/Settings/components/PortfolioForm/defaultValues";

export const ManagingDrawersContext = createContext();

const SettingsProvider = ({ children }) => {
  // Context
  const { setModalConfig, breadcrumbs } = useContext(AppContext);
  // Hooks
  const departmentFormMethods = useForm({ defaultValues: departmentDefaults });
  const portfolioFormMethods = useForm({ defaultValues: portfolioDefaults });
  const userFormMethods = useForm({ defaultValues: userDefaults });

  const { pathname } = useLocation();
  const { portfolioId, departmentId } = useParams();
  const { get, put, post } = useFetch();

  // State
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [blurredImage, setBlurredImage] = useState(true);
  const [files, setFiles] = useState([]);
  const [retrigger, setRetrigger] = useState();

  const actualDepartmentId =
    departmentId ||
    userFormMethods.watch("department")?.id ||
    departmentFormMethods.watch("area")?.id;
  const isSettings = pathname.indexOf(SETTINGS) >= 0;

  // Drawers
  const {
    isDrawerOpen: isDepartmentDrawerOpen,
    setIsDrawerOpen: setDepartmentDrawerOpen,
  } = useDrawer();
  const { isDrawerOpen: isUserDrawerOpen, setIsDrawerOpen: setUserDrawerOpen } =
    useDrawer();
  const {
    isDrawerOpen: isPortfolioDrawerOpen,
    setIsDrawerOpen: setPortfolioDrawerOpen,
  } = useDrawer();

  // Helpers
  const onSubmit = (type, data) =>
    files.length > 0 ? handleUpload(type, data) : saveItem(type, data);

  const handleClosing = type => {
    setErrorMessage("");
    setSelectedItem();
    setFiles([]);
    switch (type) {
      case "department": {
        setDepartmentDrawerOpen(false);
        departmentFormMethods.reset();
        break;
      }
      case "portfolio": {
        setPortfolioDrawerOpen(false);
        portfolioFormMethods.reset();
        break;
      }
      case "user": {
        setUserDrawerOpen(false);
        userFormMethods.reset();
        break;
      }
    }
    setLoadingDrawer(false);
  };

  const handleUpload = async (type, data) => {
    setLoadingDrawer(true);
    const bucket = process.env.REACT_APP_BUCKET_PROJECTS;
    try {
      const res = await Storage.put(
        `images/${type}/${files[0].name}`,
        files[0],
        {
          bucket,
        }
      );
      const updatedData = { ...data };
      updatedData.imageUrl = `${projectImagesPrefix}${res.key}`;
      updatedData.blurredImage = blurredImage;
      if (data?.imageUrl) {
        const newImageFileName = res.key.split("/").pop();
        const currentImageFileName = data?.imageUrl.split("/").pop();
        if (newImageFileName !== currentImageFileName)
          Storage.remove(`images/${currentImageFileName}`, { bucket });
      }
      saveItem(type, updatedData);
    } catch (err) {
      console.error(err);

      setErrorMessage(
        "Elaborazione non riuscita: impossibile caricare l'immagine"
      );
    }
  };

  const saveItem = async (type, data) => {
    setLoadingDrawer(true);
    let url = "";
    let objectItem = null;
    switch (type) {
      case "portfolio": {
        objectItem = {
          name: data?.name,
          imageUrl: data?.imageUrl,
          blurredImage: blurredImage,
          parentId:
            Number(portfolioId) ||
            Number(portfolioFormMethods.watch("area")?.id) ||
            null,
        };
        url = selectedItem ? `${PORTFOLIO}/${selectedItem?.id}` : PORTFOLIO;
        break;
      }
      case "department": {
        objectItem = {
          ...data,
          ownerId: data?.user?.id,
          imageUrl: data?.imageUrl,
          blurredImage: blurredImage,
          peopleIds: data?.people?.map(person => person.id),
          parentId: actualDepartmentId || "root",
        };
        delete objectItem.people;
        delete objectItem.user;
        url = selectedItem ? `${DEPARTMENTS}/${selectedItem?.id}` : DEPARTMENTS;
        break;
      }
      case "user": {
        objectItem = {
          ...data,
          userContractFrom: data?.userContractFrom
            ? data?.userContractFrom.format("YYYY-MM-DDTHH:mm:ss")
            : "",
          email: data?.email || selectedItem?.email,
          departmentId: actualDepartmentId,
          hourlyCost: parseFloat(data.hourlyCost),
          jobTitleId: data?.jobTitleId.id,
          seniorSkillsIds: data?.seniorSkills.map(skill => skill.id),
          intermediateSkillsIds: data?.intermediateSkills.map(
            skill => skill.id
          ),
          juniorSkillsIds: data?.juniorSkills.map(skill => skill.id),
        };
        if (!data?.userContractFrom) {
          delete objectItem.userContractFrom;
        }
        url = selectedItem ? `${USERS_LIST}/${selectedItem?.id}` : USERS_LIST;
        break;
      }
    }

    const apiFn = selectedItem ? put : post;

    selectedItem;
    apiFn(url, objectItem)
      .then(res => {
        if (res.ok) {
          handleClosing(type);
          setRetrigger(type);
        }
      })
      .catch(error => {
        if (type === "user" && error?.response?.status === 409) {
          if (error?.response?.data?.message === "license_exceeded") {
            setModalConfig({
              title: "ATTENZIONE",
              /* eslint-disable */
              content: `Impossibile salvare l' utente:
            Non ci sono più licenze disponibili.`,
              primaryAction: {
                text: "OK",
              },
            });
          } else {
            setModalConfig({
              title: "ATTENZIONE",
              /* eslint-disable */
              content: `Impossibile creare l' utente:
            l'e-mail scelta è già utilizzata.\n
            Inserisci un nuovo indirizzo e-mail per procedere. `,
              primaryAction: {
                text: "OK",
              },
            });
          }
        }
        console.error(error);
        setErrorMessage("Elaborazione non riuscita");
        setLoadingDrawer(false);
      });
  };

  // API
  const fetchDrawerData = async (type, id) => {
    setLoadingDrawer(true);
    let url = "";
    switch (type) {
      case "portfolio": {
        url = `${PORTFOLIO}/${id}`;
        break;
      }
      case "department": {
        url = `${DEPARTMENTS}/${id}`;
        break;
      }
      case "user": {
        url = `${USERS_LIST}/${id}`;
        break;
      }
    }
    try {
      const res = await get(url);
      if (res.ok) {
        setSelectedItem(res?.data);
        setLoadingDrawer(false);
      }
    } catch (e) {
      console.log("e", e);
      setLoadingDrawer(false);
    }
  };

  const settingsShared = {
    selectedItem,
    setSelectedItem,
    isUserDrawerOpen,
    setUserDrawerOpen,
    isDepartmentDrawerOpen,
    setDepartmentDrawerOpen,
    isPortfolioDrawerOpen,
    setPortfolioDrawerOpen,
    fetchDrawerData,
    loadingDrawer,
    setLoadingDrawer,
    errorMessage,
    setErrorMessage,
    onSubmit,
    retrigger,
    setRetrigger,
  };

  // Renders
  const renderDrawers = () => (
    <>
      {/* USERS */}
      <FormProvider {...userFormMethods}>
        <Drawer
          open={isUserDrawerOpen}
          title={
            !loadingDrawer &&
            (selectedItem ? "Modifica Utente" : "Nuovo Utente")
          }
          primaryText={!loadingDrawer ? "SALVA" : ""}
          secondaryText={!loadingDrawer ? "ANNULLA" : ""}
          onPrimary={userFormMethods.handleSubmit(data =>
            onSubmit("user", data)
          )}
          onSecondary={() => handleClosing("user")}
          errorMessage={errorMessage}
        >
          <UserForm
            departmentTitle={
              isSettings
                ? userFormMethods.watch("department")?.name
                : breadcrumbs[breadcrumbs.length - 1]?.name
            }
            userData={selectedItem}
            files={files}
            setFiles={setFiles}
            loading={loadingDrawer}
            fromSettings={isSettings}
          />
        </Drawer>
      </FormProvider>

      {/* DEPARTMENTS */}
      <FormProvider {...departmentFormMethods}>
        <Drawer
          open={isDepartmentDrawerOpen}
          title={
            !loadingDrawer &&
            (selectedItem ? "Modifica Dipartimento" : "Nuovo Dipartimento")
          }
          primaryText={!loadingDrawer ? "SALVA" : ""}
          secondaryText={!loadingDrawer ? "ANNULLA" : ""}
          onPrimary={departmentFormMethods.handleSubmit(data =>
            onSubmit("department", data)
          )}
          onSecondary={() => handleClosing("department")}
          errorMessage={errorMessage}
        >
          <DepartmentForm
            areaTitle={
              isSettings && departmentFormMethods.watch("area")
                ? departmentFormMethods.watch("area").name
                : defaultlPageTitle
            }
            departmentData={selectedItem}
            files={files}
            fromSettings={isSettings}
            setFiles={setFiles}
            blurredImage={blurredImage}
            setBlurredImage={setBlurredImage}
            loading={loadingDrawer}
          />
        </Drawer>
      </FormProvider>

      {/* PORTFOLIOS */}
      <FormProvider {...portfolioFormMethods}>
        <Drawer
          open={isPortfolioDrawerOpen}
          title={
            !loadingDrawer &&
            (selectedItem ? "Modifica Portfolio" : "Nuovo Portfolio")
          }
          primaryText={!loadingDrawer ? "SALVA" : ""}
          secondaryText={!loadingDrawer ? "ANNULLA" : ""}
          onPrimary={portfolioFormMethods.handleSubmit(data =>
            onSubmit("portfolio", data)
          )}
          onSecondary={() => handleClosing("portfolio")}
          errorMessage={errorMessage}
        >
          <PortfolioForm
            areaTitle={
              isSettings && portfolioFormMethods.watch("area")
                ? portfolioFormMethods.watch("area").name
                : defaultlPageTitle
            }
            portfolioData={selectedItem}
            files={files}
            fromSettings={isSettings}
            setFiles={setFiles}
            blurredImage={blurredImage}
            setBlurredImage={setBlurredImage}
            loading={loadingDrawer}
          />
        </Drawer>
      </FormProvider>
    </>
  );

  return (
    <ManagingDrawersContext.Provider value={settingsShared}>
      {renderDrawers()}
      {children}
    </ManagingDrawersContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default SettingsProvider;
