import React, { useEffect, useState, useContext, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";

// Hooks
import useFetch from "hooks/useHTTP";
import useDrawer from "hooks/useDrawer";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
// Icons
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-outlined.svg";
import { ReactComponent as ContractIcon } from "assets/contract.svg";
import { ReactComponent as DisableUserIcon } from "assets/user-forbidden.svg";
import { ReactComponent as SendEmailIcon } from "assets/send-email.svg";
import plusIcon from "assets/plus-white.png";
// Components
import Button from "components/Button";
import Table from "components/Table";
import Layout from "components/Layout";
import Loading from "components/Loading";
import SvgIcon from "components/SvgIcon";
import Drawer from "components/Drawer";
import Navigation from "../components/Navigation";
import ContractsForm from "./components/contractsForm";
// Constants
import { USERS_LIST, USERS_CONTRACTS, LAMBDA_CONTRACTS } from "constants/api";
// Context
import { AppContext } from "context/AppContext";
import { ManagingDrawersContext } from "context/ManagingDrawersContext";
// Table config
import { usersConfig } from "components/Table/config";
import { Grid, Stack } from "@mui/material";

import { TextFieldControl } from "components/TextField";
import { ReactComponent as SearchIcon } from "assets/search-image.svg";
import { ReactComponent as CancelIcon } from "assets/cancel.svg";

// Styles
const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  buttonIcon: {
    width: 16,
    height: 16,
  },
  actionButton: {
    minWidth: "unset",
    width: 44,
    flex: "0 0 auto",
    padding: 10,
    "& svg": {
      height: 18,
    },
  },
}));

const SettingsUsers = () => {
  // Context
  const {
    setUserDrawerOpen,
    errorMessage,
    setErrorMessage,
    setLoadingDrawer,
    setSelectedItem,
    selectedItem,
    retrigger,
    setRetrigger,
  } = useContext(ManagingDrawersContext);

  const { setModalConfig, setGlobalLoading } = useContext(AppContext);

  // Hooks
  const classes = useStyles({});
  const { get, del, put, post } = useFetch();
  const { control, getValues, reset, handleSubmit } = useForm({
    defaultValues: { searchText: "" },
  });

  // State
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingContractsDrawer, setLoadingContractsDrawer] = useState(false);
  const [contracts, setContracts] = useState([]);

  //Drawers state
  const { isDrawerOpen: contractsDrawerOpen, toggleDrawer: toggleDrawer } =
    useDrawer();

  const contractsFormMethods = useForm({
    defaultValues: { contracts: [] },
  });
  const fetchContracts = async id => {
    setLoadingContractsDrawer(true);
    await get(`${USERS_CONTRACTS}/${id}`)
      .then(res => {
        if (res.ok) {
          setContracts(res?.data);
          setLoadingContractsDrawer(false);
        }
      })
      .catch(() => {
        setLoadingContractsDrawer(false);
      });
  };

  const rowActions = useCallback(
    ({ disabled, confirmedAt }) => [
      {
        label: "Reinvita utente",
        onClick: handleResendEmail,
        disabled: disabled || !!confirmedAt,
        icon: <SendEmailIcon width={35} height={30} />,
        size: { width: 35, height: 18 },
      },
      {
        label: "Disattiva utente",
        onClick: handleDisableUser,
        disabled,
        icon: <DisableUserIcon />,
      },
      {
        label: "Gestisci costo orario",
        onClick: item => {
          setSelectedItem({ id: item?.id });
          fetchContracts(item?.id);
          toggleDrawer();
        },
        disabled,
        icon: <ContractIcon />,
      },
      {
        label: "Modifica utente",
        onClick: item => {
          fetchUser(item?.id);
          setUserDrawerOpen(true);
        },
        disabled,
        icon: <EditIcon />,
      },
      {
        label: "Elimina utente",
        onClick: item => handleDeleteUser(item),
        icon: <SvgIcon icon={<DeleteIcon />} width={20} height={20} />,
        disabled,
      },
    ],
    []
  );

  // Effects
  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (retrigger) {
      fetchUsers();
      setRetrigger();
    }
  }, [retrigger]);

  // Helpers
  const formatUserdata = userdata =>
    userdata.map(user => ({
      ...user,
      jobTitleName: user.jobTitle?.name,
      departmentName: user.department?.name,
      active: user?.disabled
        ? "Disattivato"
        : user?.confirmedAt
        ? "Attivato"
        : "Nuovo",
    }));

  const handleDeleteUser = user =>
    setModalConfig({
      title: "Eliminazione Utente",
      content: "Sei sicuro di voler eliminare questo utente?",
      primaryAction: {
        text: "OK",
        onClick: () => deleteUser(user.id),
      },
      secondaryAction: {
        text: "ANNULLA",
      },
    });

  const handleResendEmail = user =>
    setModalConfig({
      title: "Reinvita utente",
      content: `Sei sicuro di voler rimandare le credenziali all'utente ${
        user.name || ""
      } ${user.surname || ""}?`,
      primaryAction: {
        text: "OK",
        onClick: async () => await resendMail(user.id),
      },
      secondaryAction: {
        text: "ANNULLA",
      },
    });

  const handleDisableUser = user =>
    setModalConfig({
      title: "Disattivazione utente",
      content: `Sei sicuro di voler disattivare ${user.name || ""} ${
        user.surname || ""
      }?
          ATTENZIONE: l'utente non può essere riattivato.`,
      primaryAction: {
        text: "OK",
        onClick: async () => await disableUser(user.id),
      },
      secondaryAction: {
        text: "ANNULLA",
      },
    });

  // API
  const fetchUsers = async () => {
    setLoadingUsers(true);
    try {
      const searchText = getValues("searchText");
      const searchQs = searchText ? `&q=${searchText}` : "";
      const res = await get(
        `${USERS_LIST}?me=true&includeInactive=true&IncludeDisabled=true&page=0&pageSize=100${searchQs}`
      );
      setLoadingUsers(false);
      const remappedUsers = formatUserdata(res.data);
      setUsers(remappedUsers);
    } catch (err) {
      setLoadingUsers(false);
      setErrorMessage("Elaborazione non riuscita");
    }
  };

  const fetchUser = async id => {
    setLoadingDrawer(true);
    await get(`${USERS_LIST}/${id}`)
      .then(res => {
        if (res.ok) {
          setSelectedItem(res?.data);
          setLoadingDrawer(false);
        }
      })
      .catch(() => {
        setLoadingDrawer(false);
      });
  };

  const deleteUser = async userId => {
    setGlobalLoading(true);
    setModalConfig(null);
    try {
      await del(`${USERS_LIST}/${userId}`);
      setRetrigger("user");
      setGlobalLoading(false);
    } catch (e) {
      setRetrigger("user");
      setGlobalLoading(false);
      console.log("e", e);
    }
  };

  const disableUser = async userId => {
    setGlobalLoading(true);
    setModalConfig(null);
    try {
      await put(`${USERS_LIST}/disable/${userId}`);
    } catch (e) {
      console.log("e", e);
    } finally {
      setRetrigger("user");
      setGlobalLoading(false);
    }
  };

  const resendMail = async userId => {
    setGlobalLoading(true);
    setModalConfig(null);
    try {
      await put(`${USERS_LIST}/${userId}/resendActivation`); //mettere l'url corretto
    } catch (e) {
      console.log("e", e);
    } finally {
      setGlobalLoading(false);
    }
  };

  const handleClosing = () => {
    contractsFormMethods.reset();
    setErrorMessage("");
    setContracts([]);
    toggleDrawer();
  };

  const onSubmit = async data => {
    setLoadingContractsDrawer(true);
    // const filteredList = data?.contracts.filter(z => !z.userId);
    let formattedData = [];
    data?.contracts?.map(item => {
      const formattedItem = {
        hourlyCost: Number(item?.hourlyCost),
      };
      if (item?.from) {
        formattedItem.from = item.from
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss");
      }
      if (item?.to) {
        formattedItem.to = item.to.endOf("day").format("YYYY-MM-DDTHH:mm:ss");
      }
      if (item?.originalId && !item?.originalId?.startsWith("new-")) {
        formattedItem.id = item.originalId;
      }

      formattedData.push(formattedItem);
    });

    if (selectedItem) {
      await put(`${USERS_CONTRACTS}/${selectedItem.id}`, formattedData)
        .then(async response => {
          if (response.ok) {
            await post(`${LAMBDA_CONTRACTS}/${selectedItem.id}`, {}).then(
              res => {
                if (res.ok) {
                  handleClosing();
                  fetchContracts(selectedItem.id);
                }
              }
            );
          }
        })
        .catch(() => {
          setLoadingContractsDrawer(false);
          setErrorMessage("Elaborazione non riuscita");
        });
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    return handleSubmit(() => fetchUsers())();
  };

  const clearForm = () => {
    reset();
    fetchUsers();
  };

  return (
    <Layout showSecondCol={false} showHeader={false} showPosts={false}>
      <Navigation />
      <div className={classes.header}>
        <Typography variant="h5">Utenti</Typography>
        <Button
          color="primary"
          variant="contained"
          startIcon={<img src={plusIcon} className={classes.buttonIcon} />}
          onClick={() => setUserDrawerOpen(true)}
        >
          Nuovo utente
        </Button>
      </div>

      <Grid container marginBottom={2}>
        <Grid item xs={12} sm={3}>
          <form onSubmit={handleFilter}>
            <Stack direction="row" gap={1}>
              <TextFieldControl
                name="searchText"
                control={control}
                label="Filtra per utente"
                placeholder="Digita il nome dell'utente che desideri ricercare"
              />
              <Stack direction="row" gap={1}>
                <Button
                  className={classes.actionButton}
                  variant="outlined"
                  color="secondary"
                  onClick={clearForm}
                >
                  <CancelIcon />
                </Button>
                <Button
                  type="submit"
                  className={classes.actionButton}
                  variant="outlined"
                  color="primary"
                >
                  <SearchIcon />
                </Button>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>

      {loadingUsers ? (
        <div className={classes.loading}>
          <Loading showWrapper={false} />
        </div>
      ) : (
        <>
          <Table data={users} config={usersConfig} rowActions={rowActions} />
          <FormProvider {...contractsFormMethods}>
            <Drawer
              open={contractsDrawerOpen}
              title={"Modifica costo orario"}
              primaryText={!loadingContractsDrawer ? "SALVA" : ""}
              secondaryText={!loadingContractsDrawer ? "ANNULLA" : ""}
              onPrimary={contractsFormMethods.handleSubmit(onSubmit)}
              onSecondary={() => handleClosing()}
              errorMessage={errorMessage}
            >
              <ContractsForm
                contracts={contracts}
                loading={loadingContractsDrawer}
              />
            </Drawer>
          </FormProvider>
        </>
      )}
    </Layout>
  );
};

export default SettingsUsers;
