import React from "react";
import PropTypes from "prop-types";
// Constants
import { SPACE_CADET } from "constants/colors";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Styles
const useStyles = makeStyles(() => ({
  card: ({ halfSize, idx, columnOffset }) => ({
    width: 272,
    height: halfSize ? 100 : 215,
    display: "flex",
    borderRadius: 10,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    backgroundColor: SPACE_CADET,
    overflow: halfSize ? "hidden" : "auto",
    padding: "10px 15px",
    ...(!halfSize && {
      gridColumn: idx + columnOffset,
      gridRow: `1/3`,
    }),
  }),

  titleCard: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  textfield: {
    margin: "23px 0",
  },
}));

const FilterCard = ({ children, halfSize, idx, title, columnOffset }) => {
  const classes = useStyles({ halfSize, idx, columnOffset });
  return (
    <div className={classes.card}>
      <Typography className={classes.titleCard}>{title}</Typography>
      {children}
    </div>
  );
};

FilterCard.propTypes = {
  children: PropTypes.node.isRequired,
  halfSize: PropTypes.bool.isRequired,
  idx: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  columnOffset: PropTypes.number,
};

export default FilterCard;
