import { useState } from "react";

const useFilters = (defaultFilters = {}) => {
  // State
  const [filters, setFilters] = useState(defaultFilters);

  const filtersQuery = filtersKeys => {
    let query = "";
    filtersKeys.map(el => {
      if (filters[el] !== "" && filters[el] !== "100") {
        query += el + "=" + filters[el] + "&";
      }
    });
    const queryReturn =
      query?.length > 0 ? "?" + query.substring(0, query.length - 1) : query;
    return queryReturn;
  };

  return {
    filters,
    setFilters,
    filtersQuery,
  };
};

export default useFilters;
