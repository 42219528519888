/* eslint-disable max-len */
import React from "react";
import { PropTypes } from "prop-types";

const SortBricksIcon = ({ fill = "white", onClick, style }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    onClick={onClick}
  >
    <path
      d="M0.991013 8.32119V11.7034C0.988203 11.775 0.998569 11.8464 1.0215 11.9136C1.04443 11.9807 1.07948 12.0423 1.1246 12.0947C1.16972 12.1471 1.22402 12.1894 1.28435 12.2189C1.34467 12.2484 1.40982 12.2647 1.47601 12.2668H13.12C13.1862 12.2647 13.2514 12.2484 13.3117 12.2189C13.372 12.1894 13.4263 12.1471 13.4714 12.0947C13.5166 12.0423 13.5516 11.9807 13.5745 11.9136C13.5975 11.8464 13.6078 11.775 13.605 11.7034V8.32119C13.6078 8.24965 13.5975 8.1782 13.5745 8.11102C13.5516 8.04385 13.5166 7.98227 13.4714 7.92986C13.4263 7.87745 13.372 7.83526 13.3117 7.80573C13.2514 7.77619 13.1862 7.7599 13.12 7.75781L1.47601 7.75781C1.40982 7.7599 1.34467 7.77619 1.28435 7.80573C1.22402 7.83526 1.16972 7.87745 1.1246 7.92986C1.07948 7.98227 1.04443 8.04385 1.0215 8.11102C0.998569 8.1782 0.988203 8.24965 0.991013 8.32119ZM12.635 8.88453V11.139H1.96101V8.88453H12.635Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.1"
    />
    <path
      d="M0.990002 1.56334V4.9456C0.989738 5.01929 1.0029 5.09232 1.02874 5.16051C1.05458 5.22871 1.09259 5.29073 1.1406 5.34304C1.18861 5.39535 1.24567 5.43693 1.30854 5.4654C1.37141 5.49386 1.43884 5.50866 1.507 5.50894L19.087 5.50894C19.1552 5.50866 19.2226 5.49386 19.2855 5.4654C19.3483 5.43693 19.4054 5.39535 19.4534 5.34304C19.5014 5.29073 19.5394 5.22871 19.5653 5.16051C19.5911 5.09232 19.6043 5.01929 19.604 4.9456V1.56334C19.6043 1.48965 19.5911 1.41662 19.5653 1.34843C19.5394 1.28023 19.5014 1.21821 19.4534 1.1659C19.4054 1.11359 19.3483 1.07201 19.2855 1.04354C19.2226 1.01508 19.1552 1.00028 19.087 1L1.507 1C1.43884 1.00028 1.37141 1.01508 1.30854 1.04354C1.24567 1.07201 1.18861 1.11359 1.1406 1.1659C1.09259 1.21821 1.05458 1.28023 1.02874 1.34843C1.0029 1.41662 0.989738 1.48965 0.990002 1.56334ZM18.57 2.12669V4.38116L2.024 4.38116V2.12669L18.57 2.12669Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.1"
    />
    <path
      d="M0.990128 15.0804V18.4627C0.981568 18.6017 1.02399 18.7388 1.10822 18.8442C1.19244 18.9496 1.31168 19.015 1.44012 19.026H9.54013C9.66857 19.015 9.78782 18.9496 9.87204 18.8442C9.95626 18.7388 9.99869 18.6017 9.99013 18.4627V15.0804C9.99869 14.9414 9.95626 14.8044 9.87204 14.6989C9.78782 14.5935 9.66857 14.5282 9.54013 14.5171H1.44012C1.31168 14.5282 1.19244 14.5935 1.10822 14.6989C1.02399 14.8044 0.981568 14.9414 0.990128 15.0804ZM9.09013 15.6438V17.8983H1.89012V15.6438H9.09013Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.1"
    />
    <path
      d="M17.7186 18.8964L19.8866 16.5414C19.9611 16.4559 20.0017 16.3423 19.9999 16.2249C19.9982 16.1075 19.9541 15.9955 19.8771 15.9127C19.8001 15.8298 19.6963 15.7828 19.5877 15.7814C19.4791 15.7801 19.3743 15.8246 19.2956 15.9056L17.8406 17.4875V7.93869C17.8406 7.8191 17.7967 7.70442 17.7185 7.61986C17.6403 7.5353 17.5342 7.48779 17.4236 7.48779C17.313 7.48779 17.207 7.5353 17.1288 7.61986C17.0506 7.70442 17.0066 7.8191 17.0066 7.93869V17.4853L15.5506 15.9034C15.4719 15.8225 15.3671 15.7779 15.2586 15.7793C15.15 15.7806 15.0461 15.8277 14.9691 15.9105C14.8921 15.9933 14.8481 16.1053 14.8463 16.2228C14.8445 16.3402 14.8852 16.4537 14.9596 16.5392L17.1276 18.8942C17.1662 18.9364 17.2122 18.97 17.2628 18.993C17.3135 19.0159 17.3678 19.0279 17.4227 19.0281C17.4776 19.0283 17.532 19.0167 17.5828 18.9941C17.6336 18.9715 17.6798 18.9383 17.7186 18.8964Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.2"
    />
  </svg>
);

SortBricksIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export { SortBricksIcon };
