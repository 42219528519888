/*
    Skyview config
*/

// Size config (px)
export const PHASE_RADIUS = 30;
export const PHASE_SIZE = PHASE_RADIUS * 2;
export const OWNER_SIZE = 120;
export const CORE_RADIUS = 200;
export const PERSON_BASE_SIZE = 40;
// Considering a 50% a good range, an avatar can have size between 20 and 60 px
// 20 px is the 50% of 40px
export const PERCENTAGE_PERSON_BASERESIZE = 0.5;
export const PERIPHERAL_POINT_SIZE = 9;
export const MIN_SENTIMENT_VALUE = -1;
export const MAX_SENTIMENT_VALUE = 1;
export const PEOPLE_SAFE_OFFSET = 6; // Percentage
export const SAFE_CORE_DISTANCE = 5; // Percentage
export const SAFE_PERIPHERAl_DISTANCE = 4; // Percentage

// Canvas Scale
export const ZOOM_SCALE = 1.25;
export const SCALE_DEFAULTS = {
  scale: 1,
  x: 0,
  y: 0,
};

// Angles
export const MAX_ANGLE_OFFSET = 110;

// Debug
export const LOG_COORDS = false;
