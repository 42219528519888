import React from "react";
import PropTypes from "prop-types";
import theme from "themeV4";
import clsx from "clsx";

// Components
import Date from "components/Date";
import Avatar from "components/Avatar";
import SvgIcon from "components/SvgIcon";
// Assets
import calendarIcon from "assets/calendar.svg";
import meetingIcon from "assets/meeting.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-outlined.svg";
import { ReactComponent as CopyIcon } from "assets/copy-outlined-icon.svg";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton } from "@material-ui/core";
// Constants
import { WILD_BLUE_YONDER, OXFORD_BLUE } from "constants/colors";
import { planningMode, planningModeNumericMap } from "utils/enums/globals";
import dayjs from "dayjs";
import { Tooltip } from "@mui/material";

import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";

// Styles
const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    margin: "15px auto",
    overflow: "hidden",
  },
  brick: props => ({
    border: `1px solid ${WILD_BLUE_YONDER}80`,
    borderBottom: props.color
      ? `4px solid ${props.color}`
      : `1px solid ${WILD_BLUE_YONDER}80`,
    backgroundColor: OXFORD_BLUE,
    padding: "12px 25px 25px",
    borderRadius: 5,
    overflow: "hidden",
    position: "relative",
    "&:hover": {
      backgroundColor: WILD_BLUE_YONDER + "10",
      cursor: "pointer",
    },
  }),
  userDisplayName: {
    textTransform: "uppercase",
    fontSize: 10,
    color: WILD_BLUE_YONDER,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "90%",
  },
  avatarAndContent: {
    display: "flex",
    marginTop: 8,
  },
  avatar: { marginTop: 4 },
  draftWrapper: props => ({
    border: `1px dashed ${WILD_BLUE_YONDER}CC`,
    borderBottom: props.color
      ? `4px solid ${props.color}`
      : `1px dashed ${WILD_BLUE_YONDER}CC`,
  }),
  inactiveWrapper: {
    "&:hover": {
      backgroundColor: "transparent",
      cursor: "default",
    },
  },
  brickContent: {
    marginLeft: 20,
    maxWidth: 175,
  },
  brickTitle: {
    fontWeight: "bold",
    marginBottom: 4,
  },
  ellipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  grey: {
    color: WILD_BLUE_YONDER,
    lineHeight: 0.5,
  },
  calendarIcon: {
    width: 10,
    aspectRatio: "1/1",
    marginRight: 4,
  },
  dateWrapper: {
    display: "flex",
    alignItems: "center",
    color: WILD_BLUE_YONDER,
  },
  date: {
    marginTop: 3,
  },
  multipleDate: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: 1,
    position: "relative",
    top: 2,
  },
  sep: {
    margin: "0 3px",
  },
  genericAvatar: {
    width: 25,
    height: 25,
    borderRadius: "50%",
    backgroundColor: "white",
    backgroundImage: `url(${meetingIcon})`,
    backgroundSize: "60%",
    backgroundPosition: "center 7px",
    backgroundRepeat: "no-repeat",
  },
  brickIcon: {
    position: "absolute",
    right: -10,
    width: 55,
  },
  bottomActionIcon: {
    bottom: -4,
  },
  copy: {
    top: -4,
  },
});

const BuilderBrick = ({
  endDate,
  name,
  onEdit,
  onCopy,
  onDelete,
  onRestore,
  startDate,
  user,
  users,
  isDraft,
  isInactive,
  type,
  multipleDates,
  color,
}) => {
  // Hooks
  const classes = useStyles({ isDraft, isInactive, color });

  const isGeneric = users > 0;
  const isClassic = type === planningModeNumericMap[planningMode.CLASSIC];
  const userDisplayName = `${user ? `${user?.name} ` : ""}${
    user?.surname ? user?.surname : ""
  }`;

  const handleCopy = e => {
    e.stopPropagation();
    onCopy();
  };

  const handleDelete = e => {
    e.stopPropagation();
    onDelete();
  };

  const handleRestore = e => {
    e.stopPropagation();
    onRestore();
  };

  // Renders
  const renderAvatar = () => (
    <Tooltip title={user?.jobTitle?.name} arrow placement="bottom-start">
      <div className={classes.avatarWrapper}>
        {!isGeneric && (
          <Avatar size={25} image={user?.imageUrl} className={classes.avatar} />
        )}
        {isGeneric && <div className={classes.genericAvatar}></div>}
      </div>
    </Tooltip>
  );

  const renderBrickTitle = () => (
    <Typography
      variant="body1"
      className={clsx(classes.ellipsis, classes.brickTitle)}
      title={name}
    >
      {name}
    </Typography>
  );

  const renderSubInfo = () => {
    if (isGeneric)
      return (
        <Typography className={classes.grey} variant="subtitle1">
          {users} users
        </Typography>
      );
    else {
      if (isClassic)
        return (
          <div className={classes.dateWrapper}>
            <img src={calendarIcon} className={classes.calendarIcon} />
            <>
              <Date
                dateString={startDate}
                variant="subtitle1"
                className={clsx(classes.grey, classes.date)}
              />
              <Typography
                variant="caption"
                className={clsx(classes.grey, classes.sep)}
                component="span"
              >
                |
              </Typography>
              <Date
                dateString={endDate}
                variant="subtitle1"
                className={clsx(classes.grey, classes.date)}
              />
            </>
          </div>
        );
      else {
        const multiDatesString = multipleDates
          ?.map(
            (m, idx) =>
              dayjs(m).locale("it").format("DD MMM YYYY") +
              (idx < multipleDates.length - 1 ? ", " : "")
          )
          .join("");
        return (
          <div className={classes.dateWrapper} title={multiDatesString}>
            <img src={calendarIcon} className={classes.calendarIcon} />
            <Typography
              variant="subtitle1"
              className={clsx(classes.grey, classes.multipleDate)}
            >
              {multiDatesString}
            </Typography>
          </div>
        );
      }
    }
  };

  return (
    <div className={classes.wrapper}>
      <div
        className={clsx(classes.brick, {
          [classes.draftWrapper]: isDraft,
          [classes.inactiveWrapper]: isInactive,
        })}
        {...(!isInactive && { onClick: onEdit })}
      >
        <Typography className={classes.userDisplayName} title={userDisplayName}>
          {userDisplayName}
        </Typography>
        <div className={classes.avatarAndContent}>
          {renderAvatar()}
          <div className={classes.brickContent}>
            {renderBrickTitle()}
            {renderSubInfo()}
          </div>
        </div>
      </div>
      <IconButton
        className={clsx(classes.brickIcon, classes.copy)}
        onClick={handleCopy}
      >
        <SvgIcon
          icon={<CopyIcon />}
          color={WILD_BLUE_YONDER}
          hoverColor={theme.palette.primary.main}
          width={12}
        />
      </IconButton>
      <IconButton
        className={clsx(classes.brickIcon, classes.bottomActionIcon)}
        onClick={isInactive ? handleRestore : handleDelete}
      >
        {isInactive ? (
          <RestartAltRoundedIcon
            sx={{
              fill: WILD_BLUE_YONDER,
              strokeWidth: 0,
              height: 20,
              "&:hover": {
                fill: theme.palette.primary.main,
              },
            }}
          />
        ) : (
          <SvgIcon
            icon={<DeleteIcon />}
            color={WILD_BLUE_YONDER}
            hoverColor={theme.palette.secondary.main}
            width={13}
          />
        )}
      </IconButton>
    </div>
  );
};

BuilderBrick.propTypes = {
  avatarImageUrl: PropTypes.string,
  endDate: PropTypes.string,
  name: PropTypes.string,
  onEdit: PropTypes.func,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func,
  startDate: PropTypes.string,
  user: PropTypes.object,
  users: PropTypes.number,
  isDraft: PropTypes.bool,
  isInactive: PropTypes.bool,
  multipleDates: PropTypes.string,
  type: PropTypes.oneOf(Object.values(planningModeNumericMap)),
  color: PropTypes.string,
};

export default BuilderBrick;
