import React, { useEffect, useState } from "react";
/* import { getQueryString } from "utils/request"; */

// Hooks
import useFetch from "hooks/useHTTP";
import useDrawer from "hooks/useDrawer";
import { useForm, FormProvider } from "react-hook-form";

// // Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { skillsConfig } from "components/Table/config";

// Icons
import { ReactComponent as EditIcon } from "assets/edit.svg";
import plusIcon from "assets/plus-white.png";

// Components
import Button from "components/Button";
import Table from "components/Table";
import Layout from "components/Layout";
import Drawer from "components/Drawer";
import Loading from "components/Loading";
import Navigation from "../components/Navigation";

// Constants
import { SKILLS } from "constants/api";
import SkillForm from "./components/skillForm";

import { TextFieldControl } from "components/TextField";
import { ReactComponent as SearchIcon } from "assets/search-image.svg";
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
import { Grid, Stack } from "@mui/material";

// Styles
const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  buttonIcon: {
    width: 16,
    height: 16,
  },
  actionButton: {
    minWidth: "unset",
    width: 44,
    flex: "0 0 auto",
    padding: 10,
    "& svg": {
      height: 18,
    },
  },
}));

const SettingsSkills = () => {
  const classes = useStyles({});
  const { get, post, put } = useFetch();
  const [skills, setSkills] = useState([]);
  const [loadingSkills, setLoadingSkills] = useState(false);
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const { control, getValues, reset, handleSubmit } = useForm({
    defaultValues: { searchText: "" },
  });

  const skillsFormMethods = useForm({
    defaultValues: { name: "", department: null },
  });

  //Drawers state
  const {
    isDrawerOpen: isDrawerOpen,
    setIsDrawerOpen: setDrawerOpen,
    toggleDrawer: toggleDrawer,
  } = useDrawer();

  const formatSkillsdata = skillsData =>
    skillsData.map(skill => ({
      ...skill,
      department: skill?.department?.name || "--",
    }));

  const fetchSkills = async () => {
    setLoadingSkills(true);
    const searchText = getValues("searchText");
    const qs = searchText ? `?q=${searchText}` : "";
    await get(SKILLS + qs)
      .then(response => {
        setLoadingSkills(false);
        const remappedSkills = formatSkillsdata(response.data);
        setSkills(remappedSkills);
      })
      .catch(() => {
        setLoadingSkills(false);
        setErrorMessage("Elaborazione non riuscita");
      });
  };

  const fetchSkill = async id => {
    setLoadingDrawer(true);
    await get(`${SKILLS}/${id}`)
      .then(res => {
        if (res.ok) {
          setSelectedItem(res?.data);
          setLoadingDrawer(false);
        }
      })
      .catch(() => {
        setLoadingDrawer(false);
      });
  };

  const handleClosing = () => {
    setErrorMessage("");
    setSelectedItem();
    toggleDrawer();
    skillsFormMethods.reset();
    setLoadingDrawer(false);
  };

  const onSubmit = async data => {
    setLoadingDrawer(true);
    const formattedData = { ...data };
    formattedData.departmentId = data.department?.id || null;
    delete formattedData.department;

    if (selectedItem) {
      await put(`${SKILLS}/${selectedItem.id}`, formattedData)
        .then(response => {
          if (response.ok) {
            handleClosing();
            fetchSkills();
          }
        })
        .catch(() => {
          setLoadingDrawer(false);
          setErrorMessage("Elaborazione non riuscita");
        });
    } else {
      await post(SKILLS, formattedData)
        .then(response => {
          if (response.ok) {
            handleClosing();
            fetchSkills();
          }
        })
        .catch(() => {
          setLoadingDrawer(false);
          setErrorMessage("Elaborazione non riuscita");
        });
    }
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  const handleFilter = e => {
    e.preventDefault();
    return handleSubmit(() => fetchSkills())();
  };

  const clearForm = () => {
    reset();
    fetchSkills();
  };

  return (
    <Layout showSecondCol={false} showHeader={false} showPosts={false}>
      <Navigation />
      <div className={classes.header}>
        <Typography variant="h5">Skills</Typography>
        <Button
          color="primary"
          variant="contained"
          startIcon={<img src={plusIcon} className={classes.buttonIcon} />}
          onClick={() => setDrawerOpen(true)}
        >
          Nuova skill
        </Button>
      </div>

      <Grid container marginBottom={2}>
        <Grid item xs={12} sm={3}>
          <form onSubmit={handleFilter}>
            <Stack direction="row" gap={1}>
              <TextFieldControl
                name="searchText"
                control={control}
                label="Filtra per skill"
                placeholder="Digita il nome della skill che desideri ricercare"
              />
              <Stack direction="row" gap={1}>
                <Button
                  className={classes.actionButton}
                  variant="outlined"
                  color="secondary"
                  onClick={clearForm}
                >
                  <CancelIcon />
                </Button>
                <Button
                  type="submit"
                  className={classes.actionButton}
                  variant="outlined"
                  color="primary"
                >
                  <SearchIcon />
                </Button>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>

      {loadingSkills ? (
        <div className={classes.loading}>
          <Loading showWrapper={false} />
        </div>
      ) : (
        <Table
          data={skills}
          config={skillsConfig}
          rowActions={[
            {
              onClick: item => {
                fetchSkill(item?.id);
                setDrawerOpen(true);
              },
              icon: <EditIcon />,
            },
          ]}
        />
      )}
      <FormProvider {...skillsFormMethods}>
        <Drawer
          open={isDrawerOpen}
          title={
            !loadingDrawer && (selectedItem ? "Modifica Skill" : "Nuova Skill")
          }
          primaryText={!loadingDrawer && "SALVA"}
          secondaryText={!loadingDrawer && "ANNULLA"}
          onPrimary={skillsFormMethods.handleSubmit(onSubmit)}
          onSecondary={() => handleClosing()}
          errorMessage={errorMessage}
        >
          <SkillForm data={selectedItem} loading={loadingDrawer} />
        </Drawer>
      </FormProvider>
    </Layout>
  );
};

export default SettingsSkills;
