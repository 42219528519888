// Images
import beLogo from "assets/be-icon-white.svg";
import grooveLogo from "assets/groove-logo.svg";
import doLogo from "assets/do-icon-white.svg";
import Step1FullHD from "assets/walkthrough-step1-fullHD.jpg";
import Step1_4K from "assets/walkthrough-step1-4K.jpg";
import Step2FullHD from "assets/walkthrough-step2-fullHD.jpg";
import Step2_4K from "assets/walkthrough-step2-4K.jpg";
import Step3FullHD from "assets/walkthrough-step3-fullHD.jpg";
import Step3_4K from "assets/walkthrough-step3-4K.jpg";

export const data = [
  {
    title: "Groove",
    // eslint-disable-next-line max-len
    subtitle: `Il GROOVE rappresenta l’orchestrazione della\nperformance aziendale.\n Una community che condivide informazioni\n e senso di appartenenza.\n\n DO & BE !`,
    lighterBackgroundImage: Step1FullHD,
    heavierBackgroundImage: Step1_4K,
    image: grooveLogo,
  },
  {
    title: "Do",
    // eslint-disable-next-line max-len
    subtitle: `Tempi, Costi, Qualità.\nIndicatori fondamentali per raggiungere un risultato comune.\nIl lato RAZIONALE è la base del goal aziendale,\ndettato dalla chiarezza delle attività pianificate.\n\nWORK BETTER!`,
    lighterBackgroundImage: Step2FullHD,
    heavierBackgroundImage: Step2_4K,
    image: doLogo,
  },
  {
    title: "Be",
    // eslint-disable-next-line max-len
    subtitle: `Skills, Sentiment e Reputation.\nLa persona e il team di lavoro al centro di ogni obiettivo\naziendale. Il lato HUMAN\nnon è secondario ma fortemente complementare.\n\nLET'S TEAM UP!`,
    lighterBackgroundImage: Step3FullHD,
    heavierBackgroundImage: Step3_4K,
    image: beLogo,
  },
];
