import {
  HEADER_HEIGHT,
  SAFE_MARGIN_BOTTOM,
  SAFE_MARGIN_TOP,
} from "./components/Skyline/config";

export const getHorizonLevel = ({ activePercentage, canvasHeight }) => {
  const horizonLevel = (canvasHeight * activePercentage || 1) / 100;

  let safeHorizonLevel = horizonLevel;
  if (horizonLevel <= HEADER_HEIGHT)
    safeHorizonLevel = HEADER_HEIGHT + SAFE_MARGIN_TOP;
  if (horizonLevel === canvasHeight)
    safeHorizonLevel = horizonLevel - SAFE_MARGIN_BOTTOM;

  return safeHorizonLevel;
};
