import React from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { nanoid } from "nanoid";

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
  },
  headerCell: {
    fontWeight: "bold",
  },
}));

function RenderHeader({ value }) {
  const classes = useStyles();

  return <Typography className={classes.headerCell}>{value}</Typography>;
}

RenderHeader.propTypes = {
  value: PropTypes.string,
};

// Header Handler
function TableHeader({ order, orderBy, onRequestSort, columns, hasActions }) {
  const createSortHandler = key => event => {
    if (columns[key].id) {
      onRequestSort(event, columns[key].id);
    }
  };

  const classes = useStyles();

  return (
    <TableHead data-value="tableHead">
      <TableRow data-value="tableRow" className={classes.header}>
        {Object.keys(columns).map(key => {
          return (
            <TableCell
              align="left"
              key={columns[key].id || nanoid()}
              sortDirection={orderBy === columns[key].id ? order : false}
              data-value="tableCell"
            >
              {columns[key].id ? (
                <TableSortLabel
                  active={orderBy === columns[key].id}
                  direction={order}
                  onClick={createSortHandler(key)}
                >
                  <RenderHeader
                    value={columns[key].label}
                    maxWidthCol={columns[key].maxWidth}
                  />
                </TableSortLabel>
              ) : (
                <RenderHeader
                  value={columns[key].label}
                  maxWidthCol={columns[key].maxWidth}
                />
              )}
            </TableCell>
          );
        })}
        {hasActions && <TableCell align="left" />}
      </TableRow>
    </TableHead>
  );
}

TableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  columns: PropTypes.object,
  hasActions: PropTypes.bool,
};

TableHeader.defaultProps = {
  hasActions: false,
};

export default TableHeader;
