import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import theme from "themeV4";

// Icons
import { ReactComponent as Arrow } from "assets/down-arrow.svg";

// Material UI components
import {
  Select as SelectMUI,
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import { WHITE } from "constants/colors";
import { Controller } from "react-hook-form";

const useStyles = makeStyles(() => ({
  select: ({ themeType }) => ({
    backgroundColor:
      themeType === "dark"
        ? theme.palette.componentsBackground.main
        : theme.palette.componentsBackground.light,
    color:
      themeType === "dark"
        ? theme.palette.componentsBackground.light
        : theme.palette.common.black,
  }),
  menuItem: ({ themeType }) => ({
    color:
      themeType === "dark"
        ? theme.palette.componentsBackground.main
        : theme.palette.common.black,
  }),
  label: ({ themeType }) => ({
    color:
      themeType === "dark" ? WHITE : theme.palette.componentsBackground.main,
    "&.Mui-focused": {
      color:
        themeType === "dark" ? WHITE : theme.palette.componentsBackground.main,
    },
  }),
  icon: {
    width: 16,
    height: "auto",
    marginRight: theme.spacing(1),
    cursor: "pointer",
  },
}));

const Select = forwardRef(
  (
    {
      themeType,
      label,
      data,
      helperText,
      required,
      showEmpty,
      showEmptyText,
      ...other
    },
    ref
  ) => {
    const classes = useStyles({ themeType });

    return (
      <FormControl fullWidth variant="outlined" required={required}>
        <InputLabel className={classes.label} shrink={other.shrink}>{label}</InputLabel>
        <SelectMUI
          {...other}
          className={classes.select}
          inputProps={{ inputRef: ref }}
          IconComponent={() => <Arrow className={classes.icon} />}
          label={label}
        >
          {showEmpty && (
            <MenuItem value="">
              <em>{showEmptyText || "None"} </em>
            </MenuItem>
          )}
          {data?.map((item, index) => (
            <MenuItem
              className={classes.menuItem}
              key={index}
              value={item.value}
            >
              <em>{item.name}</em>
            </MenuItem>
          ))}
        </SelectMUI>
        {other.error && <FormHelperText error>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

Select.displayName = "Select";

Select.propTypes = {
  data: PropTypes.array,
  helperText: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  themeType: PropTypes.string,
  showEmpty: PropTypes.bool,
  showEmptyText: PropTypes.string,
};

Select.defaultProps = {
  data: [],
  helperText: "Campo obbligatorio",
  required: false,
  themeType: "dark",
  showEmpty: false,
};

const SelectControl = ({ control, name, rules, defaultValue, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <Select
          value={field.value}
          onChange={field.onChange}
          error={!!error}
          helperText={error?.message || Select.defaultProps.helperText}
          {...field}
          {...rest}
        />
      )}
    />
  );
};

SelectControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
};

export { SelectControl };

export default Select;
