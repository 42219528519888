import React, { useEffect, useState, useContext } from "react";
import useFetch from "hooks/useHTTP";

import { Typography, Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useHistory, useLocation, useParams } from "react-router-dom";

// Components
import Layout from "components/Layout";
import Loading from "components/Loading";
import Carousel from "./Carousel";
import Popper from "components/Popper";
import Person from "components/Person";
import Card from "./Carousel/Card";

// Context
import { AppContext, defaultlPageTitle } from "context/AppContext";
import { ManagingDrawersContext } from "context/ManagingDrawersContext";
import { AuthContext } from "context/AuthContext";
import { SearchContext } from "context/SearchContext";

// Assets
import plusIconBlue from "assets/plus-blue.png";
import plusIcon from "assets/plus-white.png";

// Constants
import { PORTFOLIO, DEPARTMENTS, USERS_LIST, PROJECT } from "constants/api";
import { PLANNER, ROOT, SETTINGS } from "constants/routes";

// Enums
import { carouselTypes } from "utils/enums/carousels";
import { metricTypes } from "utils/enums/grooveMetrics";
import { AiGeneratorPrompt } from "./AiGeneratorPrompt";
import { AiGeneratorUpload } from "./AiGeneratorUpload";
import { MagicIcon } from "assets/icon-components";
import { darken } from "@mui/material";

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: 300,
  },
  portfoliosListWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: 20,
  },
  portfoliosListItemWrapper: {
    width: 310,
  },
  sectionTitle: {
    display: "flex",
    marginBottom: 10,
  },
  icon: {
    width: "1.5rem",
    marginRight: "1rem",
  },
  iconButton: {
    position: "absolute",
    bottom: theme.spacing(7),
    right: theme.spacing(7),
    width: 65,
    height: 65,
    padding: 20,
  },
  aiButton: {
    backgroundColor: "#C2C2FF",
    position: "absolute",
    bottom: 60,
    padding: theme.spacing(2),
    right: 144,
    width: 55,
    height: 55,
    "&:hover": {
      backgroundColor: darken("#C2C2FF", 0.3),
    },
  },
  carousel: {
    margin: "4vh 0 ",
  },
  peopleWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  person: {
    marginRight: 30,
    marginBottom: 80,
  },
  plusIcon: {
    width: "75%",
    height: "75%",
  },
  emptyBranchText: {
    fontWeight: 400,
  },
}));

const Groove = () => {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { portfolioId, departmentId } = useParams();
  const { get, del } = useFetch();

  // State
  const [isLeaf, setIsLeaf] = useState(false);
  const [portfolios, setPortfolios] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [people, setPeople] = useState(null);
  const [loadingDo, setLoadingDo] = useState(false);
  const [loadingBe, setLoadingBe] = useState(false);
  const [aiAnchorEl, setAiAnchorEl] = useState(null);
  const [standardAnchorEl, setStandardAnchorEl] = useState(null);
  const [skillsCountDepartment, setSkillsCountDepartment] = useState(0);
  const [aiPromptVisible, setAiPromptVisibile] = useState(false);

  // Context
  const {
    activeMetric,
    addBreadcrumb,
    filters,
    filtersQuery,
    owner,
    resetBreadcrumbs,
    setActiveMetric,
    setGlobalLoading,
    setModalConfig,
    setPageTitle,
    setOwner,
    settings,
  } = useContext(AppContext);

  const {
    setUserDrawerOpen,
    setPortfolioDrawerOpen,
    setDepartmentDrawerOpen,
    fetchDrawerData,
    retrigger,
    setRetrigger,
  } = useContext(ManagingDrawersContext);

  const { setIsSearchOpen } = useContext(SearchContext);

  const { user } = useContext(AuthContext);

  const isGroove = !departmentId && !portfolioId;
  const isDo = !isGroove && portfolioId;
  const isBe = !isGroove && departmentId;

  const showAiGeneratorButton =
    isDo && (isLeaf || !portfolios?.length) && settings?.isAIActive;

  const aiFabItems = [
    {
      img: plusIconBlue,
      onClick: () => {
        setAiAnchorEl(null);
        setAiPromptVisibile(true);
      },
      text: "Suggerisci skyline da ricerca",
      enabled: true,
    },
    {
      img: plusIconBlue,
      onClick: () => {
        setAiAnchorEl(null);
        setModalConfig({
          title: "Carica i file",
          content: (
            <AiGeneratorUpload
              setModalConfig={setModalConfig}
              portfolioId={portfolioId}
            />
          ),
          closeOnBackdrop: true,
        });
      },
      text: "Crea skyline da documento",
      enabled: true,
    },
  ];

  const fabItems = [
    {
      img: plusIconBlue,
      onClick: () => {
        setStandardAnchorEl(null);
        openPortfolioDrawer();
      },
      text: "Add Portfolio",
      enabled: isGroove || isDo,
    },
    {
      img: plusIconBlue,
      onClick: () => {
        setStandardAnchorEl(null);
        createProject();
      },
      text: "Add Project",
      enabled: !isGroove && isDo,
    },
    {
      img: plusIconBlue,
      onClick: () => {
        setStandardAnchorEl(null);
        openDepartmentDrawer();
      },
      text: "Add Department",
      enabled: isGroove || isBe,
    },
    {
      img: plusIconBlue,
      onClick: () => {
        setStandardAnchorEl(null);
        openUserDrawer();
      },
      text: "Add User",
      enabled: !isGroove && isBe && user.canManageUsers,
    },
  ];

  // Effects
  // Runs at mount and every route mutation inside Groove/Gallery
  useEffect(() => {
    // Reset department people
    setPeople(null);
    setDepartments(null);
    setAiAnchorEl(null);
    setStandardAnchorEl(null);
    setAiPromptVisibile(false);
    if (!departmentId) getPortfolio();
    if (!portfolioId) getDepartments();

    // Reset all when landing at Groove
    if (isGroove) {
      setActiveMetric(null);
      resetBreadcrumbs();
    }
  }, [location, filters]);

  useEffect(() => {
    setPageTitle(settings?.tenantName || defaultlPageTitle);
  }, [settings]);

  // Retrigger correct data fetch after
  // a manipulation from a managing drawer
  useEffect(() => {
    handleRetrigger();
  }, [retrigger]);

  const handleRetrigger = () => {
    if (!retrigger) return null;
    if (retrigger === "portfolio") getPortfolio();
    if (retrigger === "department" || retrigger === "user") getDepartments();
    setRetrigger();
  };

  const handleBreadcrumbs = name => {
    if ((isDo || isBe) && name) {
      addBreadcrumb({ name });
      setPageTitle(name);
    }
  };

  const createProject = () =>
    history.push(`${PLANNER}?portfolioId=${portfolioId}`);

  const openPortfolioDrawer = () => setPortfolioDrawerOpen(true);

  const openDepartmentDrawer = () => setDepartmentDrawerOpen(true);

  const openUserDrawer = () => setUserDrawerOpen(true);

  const handleFabClick = () => {
    if (isDo) {
      if (isLeaf) return createProject();
      else if (portfolios?.length > 0) return openPortfolioDrawer();
    }

    if (isBe) {
      if (departments?.length > 0) return openDepartmentDrawer();
      if (people?.length > 0) return openUserDrawer();
    }
  };

  const handleFabHover = event => {
    if (
      (isDo && portfolios?.length !== 0) ||
      (isBe && departments?.length > 0) ||
      people?.length > 0
    )
      return;

    setStandardAnchorEl(standardAnchorEl ? null : event.currentTarget);
  };

  // API DELETE
  const deletePortfolio = async itemId => {
    setGlobalLoading(true);
    setModalConfig(null);

    await del(`${PORTFOLIO}/${itemId}`)
      .then(async res => {
        if (res.ok) {
          await getPortfolio();
          setGlobalLoading(false);
        }
      })
      .catch(e => {
        setGlobalLoading(false);
        console.log("e", e);
      });
  };

  const deleteProject = async itemId => {
    setGlobalLoading(true);
    setModalConfig(null);

    await del(`${PROJECT}/${itemId}`)
      .then(async res => {
        if (res.ok) {
          await getPortfolio();
          setGlobalLoading(false);
        }
      })
      .catch(e => {
        setGlobalLoading(false);
        console.log("e", e);
      });
  };
  const deletePerson = async userId => {
    setGlobalLoading(true);
    setModalConfig(null);
    await del(`${USERS_LIST}/${userId}`)
      .then(async res => {
        if (res.ok) {
          await getDepartments();
          setGlobalLoading(false);
        }
      })
      .catch(e => {
        setGlobalLoading(false);
        console.log("e", e);
      });
  };

  // API GET
  const getPortfolio = async () => {
    setLoadingDo(true);
    const query = filtersQuery(["TimeFilterType", "TimeFilter", "Status"]);
    const url = portfolioId ? `${PORTFOLIO}/${portfolioId}` : PORTFOLIO;
    await get(url + query)
      .then(res => {
        setLoadingDo(false);
        if (res.ok) {
          const { data } = res;
          handleBreadcrumbs(data?.name);
          if (portfolioId) {
            if (data.children.length > 0) {
              setPortfolios(data.children);
              setIsLeaf(false);
            } else if (data.projects.length > 0) {
              setPortfolios(data.projects);
              setIsLeaf(true);
            } else {
              setPortfolios([]);
            }
          } else {
            setIsLeaf(false);
            setPortfolios(data);
          }
        }
      })
      .catch(e => {
        setLoadingDo(false);
        console.log("e", e);
      });
  };

  const getDepartments = async () => {
    setLoadingBe(true);
    const query = filtersQuery(["PeopleCountFilter"]);
    const url = departmentId ? `${DEPARTMENTS}/${departmentId}` : DEPARTMENTS;
    try {
      const { data } = await get(url + query);
      handleBreadcrumbs(data?.name);
      if (isGroove) {
        setPeople(null);
        setDepartments(data);
        setSkillsCountDepartment(0);
      } else {
        const { children, people, owner, name, skillsCountDepartment } = data;

        setOwner({
          name: owner?.name + " " + owner?.surname,
          id: owner?.id,
          image: owner?.imageUrl,
          jobTitle: "owner di dipartimento",
          onClick: () => history.push(`/user/${owner?.id}`),
        });
        setPageTitle(name);
        if (!activeMetric) {
          setActiveMetric(metricTypes.BE);
        }

        if (children) setDepartments(children);
        if (children.length === 0) {
          setPeople(people);
          const baseUsers = people?.filter(
            person => person?.id !== owner?.id && !person.disabled
          );
          const disabledUsers =
            people?.filter(person => !!person.disabled) || [];
          const nextPeople = [...baseUsers, ...disabledUsers];
          !!owner && nextPeople.unshift(owner);
          setPeople(nextPeople);
          setSkillsCountDepartment(skillsCountDepartment || 0);
        }
      }

      setLoadingBe(false);
    } catch (err) {
      setLoadingBe(false);
      console.error(err);
    }
  };

  // Renders
  const renderPortfoliosList = portfolio => (
    <div className={classes.portfoliosListItemWrapper}>
      <Card
        key={portfolio.id}
        portfolio={portfolio}
        metric={activeMetric || metricTypes.DO}
        isLeaf={isLeaf}
        onEdit={() => {
          fetchDrawerData("portfolio", portfolio?.id);
          setPortfolioDrawerOpen(true);
        }}
        itemId={portfolio?.id}
        deletePortfolio={deletePortfolio}
        deleteProject={deleteProject}
        canBeRemoved={portfolio?.deletable}
      />
    </div>
  );

  const renderPortfoliosCarousel = () =>
    portfolios && portfolios?.length > 0 ? (
      isGroove ? (
        <Carousel
          slidesToScroll={2}
          items={portfolios}
          type={carouselTypes.PORTFOLIO}
          metric={activeMetric || metricTypes.DO}
          isLeaf={isLeaf}
          onEdit={item => {
            fetchDrawerData("portfolio", item?.id);
            setPortfolioDrawerOpen(true);
          }}
          deletePortfolio={deletePortfolio}
          deleteProject={deleteProject}
        />
      ) : (
        <div className={classes.portfoliosListWrapper}>
          {portfolios?.map(renderPortfoliosList)}
        </div>
      )
    ) : (
      <Typography variant="h5" className={classes.emptyBranchText}>
        Crea un nuovo portfolio {!isGroove && "o un nuovo progetto"}
      </Typography>
    );

  const renderDepartmentsCarousel = () =>
    departments && departments.length > 0 ? (
      <Carousel
        items={departments}
        type={carouselTypes.DEPARTMENT}
        metric={activeMetric || metricTypes.BE}
        onEdit={item => {
          fetchDrawerData("department", item?.id);
          setDepartmentDrawerOpen(true);
        }}
        slidesToScroll={2}
      />
    ) : (
      <Typography variant="h5" className={classes.emptyBranchText}>
        Crea un nuovo dipartimento {!isGroove && "o un nuovo utente"}
      </Typography>
    );
  const renderDepartmentDetail = () => {
    return people?.length > 0 ? (
      <div className={classes.peopleWrapper}>
        {people?.map(data => {
          return (
            <Person
              key={data?.id}
              {...data}
              className={classes.person}
              name={`${data?.name} ${data?.surname}`}
              metric={activeMetric || metricTypes.BE}
              isOwner={data?.id === owner?.id}
              onClick={e => {
                e.stopPropagation();
                history.push(`${"/user"}/${data?.id}`);
              }}
              onEdit={() => {
                fetchDrawerData("user", data?.id);
                setUserDrawerOpen(true);
              }}
              userId={data?.id}
              deletePerson={deletePerson}
              skillsCountDepartment={skillsCountDepartment}
            />
          );
        })}
      </div>
    ) : (
      <Typography variant="h5">
        Crea un nuovo dipartimento o un nuovo utente
      </Typography>
    );
  };

  const renderCreationButtons = () => {
    if (loadingDo || loadingBe || (!user.canManageUsers && people?.length > 0))
      return null;
    return (
      <>
        {showAiGeneratorButton && (
          <Fab
            color="default"
            aria-label="ai-creation-button"
            aria-describedby="ai-project-creation-button"
            className={classes.aiButton}
            onMouseEnter={e => setAiAnchorEl(e.currentTarget)}
            onMouseLeave={() => setAiAnchorEl(null)}
          >
            <MagicIcon />
            <Popper
              id="ai-project-creation-button"
              open={!!aiAnchorEl}
              placement="bottom"
              anchorEl={aiAnchorEl}
            >
              {aiFabItems.filter(item => item.enabled)}
            </Popper>
          </Fab>
        )}
        <Fab
          color="primary"
          aria-label="standard-button"
          aria-describedby="standard-creation-button"
          className={classes.iconButton}
          onClick={handleFabClick}
          onMouseEnter={handleFabHover}
          onMouseLeave={() => setStandardAnchorEl(null)}
        >
          <img className={classes.plusIcon} src={plusIcon} />
          <Popper
            id="standard-creation-button"
            open={!!standardAnchorEl}
            placement="bottom"
            anchorEl={standardAnchorEl}
          >
            {fabItems.filter(item => item.enabled)}
          </Popper>
        </Fab>
      </>
    );
  };

  const renderDo = () => {
    if (loadingDo) return <Loading showWrapper={false} />;

    return (
      <div className={classes.carousel}>
        {!portfolioId && (
          <div className={classes.sectionTitle}>
            <Typography variant="h5">
              Do{" "}
              {`${
                settings?.doGrooveTitle ? `- ${settings?.doGrooveTitle}` : ""
              }`}
            </Typography>
          </div>
        )}
        {renderPortfoliosCarousel()}
      </div>
    );
  };

  const renderBe = () => {
    if (loadingBe) return <Loading showWrapper={false} />;

    return (
      <div className={classes.carousel}>
        {!departmentId && (
          <div className={classes.sectionTitle}>
            <Typography variant="h5">
              Be{" "}
              {`${
                settings?.beGrooveTitle ? `- ${settings?.beGrooveTitle}` : ""
              }`}
            </Typography>
          </div>
        )}
        {!people && renderDepartmentsCarousel()}
        {people && renderDepartmentDetail()}
      </div>
    );
  };

  const headerIcons1 = [
    {
      icon: "search",
      action: () => setIsSearchOpen(true),
    },
    {
      icon: "settings",
      action: () => history.push(SETTINGS),
    },
  ];

  const headerIcons2 = [
    {
      icon: "do",
      action: () => setActiveMetric(metricTypes.DO),
      disabled: activeMetric === metricTypes.BE,
    },
    {
      icon: "be",
      action: () => setActiveMetric(metricTypes.BE),
      disabled: activeMetric === metricTypes.DO,
    },
  ];

  return (
    <Layout
      showFilters={!aiPromptVisible}
      headerIcons={location?.pathname === ROOT ? headerIcons1 : headerIcons2}
    >
      <div className={classes.wrapper}>
        {!departmentId && renderDo()}
        {!portfolioId && renderBe()}
      </div>
      {renderCreationButtons()}
      {showAiGeneratorButton && (
        <AiGeneratorPrompt
          visible={aiPromptVisible}
          onClose={() => setAiPromptVisibile(false)}
        />
      )}
    </Layout>
  );
};

export default Groove;
