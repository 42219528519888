import React, { useEffect, useState, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
// Hooks
import useFetch from "hooks/useHTTP";
import useDrawer from "hooks/useDrawer";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Stack, Grid } from "@mui/material";
// Icons
import { ReactComponent as EditIcon } from "assets/edit.svg";
import plusIcon from "assets/plus-white.png";
import { ReactComponent as DeleteIcon } from "assets/delete-outlined.svg";
// Components
import Button from "components/Button";
import Table from "components/Table";
import Layout from "components/Layout";
import Loading from "components/Loading";
import Navigation from "../components/Navigation";
import Drawer from "components/Drawer";
import CustomerForm from "pages/Settings/components/CustomerForm";
// Constants
import { CUSTOMERS } from "constants/api";
// Context
import { ManagingDrawersContext } from "context/ManagingDrawersContext";
import { AppContext } from "context/AppContext";

import { customersConfig } from "components/Table/config";
import { customerFormDefaults as customerDefaults } from "pages/Settings/components/CustomerForm/defaultValues";
import { TextFieldControl } from "components/TextField";
import { ReactComponent as SearchIcon } from "assets/search-image.svg";
import { ReactComponent as CancelIcon } from "assets/cancel.svg";

// Styles
const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  buttonIcon: {
    width: 16,
    height: 16,
  },
  actionButton: {
    minWidth: "unset",
    width: 44,
    flex: "0 0 auto",
    padding: 10,
    "& svg": {
      height: 18,
    },
  },
}));

const SettingsCustomers = () => {
  // Context
  const { retrigger, setRetrigger } = useContext(ManagingDrawersContext);
  const { setModalConfig, setGlobalLoading } = useContext(AppContext);

  // Hooks
  const classes = useStyles({});
  const { control, getValues, reset, handleSubmit } = useForm({
    defaultValues: { searchText: "" },
  });
  const { get, post, put, del } = useFetch();
  const customerFormMethods = useForm({
    defaultValues: customerDefaults,
    shouldUnregister: true,
  });
  const {
    isDrawerOpen: isCustomerDrawerOpen,
    setIsDrawerOpen: setCustomerDrawerOpen,
  } = useDrawer();

  // State
  const [customers, setCustomers] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  // Effects
  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (retrigger) {
      fetchCustomers();
      setRetrigger();
    }
  }, [retrigger]);

  // Helpers
  const handleClose = () => {
    customerFormMethods.reset();
    setErrorMessage("");
    setSelectedItem();
    setCustomerDrawerOpen(false);
  };

  const onSubmit = async data => {
    setLoadingDrawer(true);
    const apiCall = selectedItem ? put : post;
    const apiEndpoint = selectedItem
      ? `${CUSTOMERS}/${selectedItem.id}`
      : CUSTOMERS;
    const validReferences = data.references
      .filter(ref => !!ref.name || !!ref.email || !!ref.number)
      // TODO: Remove this map(), used to maintain name value in case
      // of disabled references name
      // (Material UI related problem - undefined value)
      .map(({ id, ...refRest }) => ({
        ...refRest,
        ...(!!id && { id }),
        name:
          refRest.name ||
          selectedItem.references.find(
            selectedItemReference => selectedItemReference.id === id
          ).name,
      }));

    const deletedReferences =
      selectedItem?.references
        .filter(
          ref =>
            validReferences.findIndex(validRef => validRef.id === ref.id) < 0
        )
        .map(deletedRef => ({
          ...deletedRef,
          deleted: !!deletedRef.deletable,
        })) || [];

    // Add customerId to every reference object
    let updatedReferences = [...validReferences, ...deletedReferences];

    if (selectedItem)
      updatedReferences = updatedReferences.map(ref => ({
        ...ref,
        customerId: selectedItem.id,
      }));

    const formattedData = {
      ...selectedItem,
      ...data,
      references: updatedReferences,
    };

    try {
      await apiCall(apiEndpoint, formattedData);
      handleClose();
      setLoadingDrawer(false);
      setRetrigger("customer");
    } catch (err) {
      setLoadingDrawer(false);
      setErrorMessage("Elaborazione non riuscita");
      console.error(err);
    }
  };

  const handleCustomerDelete = id => {
    setModalConfig({
      title: "Eliminazione Cliente",
      content: "Sei sicuro di voler eliminare questo cliente?",
      primaryAction: {
        text: "OK",
        onClick: () => deleteCustomer(id),
      },
      secondaryAction: {
        text: "ANNULLA",
      },
    });
  };

  // API
  const fetchCustomers = async () => {
    setLoadingCustomers(true);
    try {
      const searchText = getValues("searchText");
      const qs = searchText ? `?q=${searchText}` : "";
      const res = await get(CUSTOMERS + qs);
      setLoadingCustomers(false);
      setCustomers(res.data);
      setLoadingDrawer(false);
    } catch (err) {
      setLoadingCustomers(false);
      setLoadingDrawer(false);
      setErrorMessage("Elaborazione non riuscita");
    }
  };

  const fetchCustomer = async id => {
    setLoadingDrawer(true);
    try {
      const res = await get(
        `${CUSTOMERS}/${id}?checkDeletableInReferences=true`
      );
      if (res.ok) {
        setSelectedItem(res?.data);
        setLoadingDrawer(false);
      }
    } catch (err) {
      console.error(err);
      setLoadingDrawer(false);
    }
  };

  const deleteCustomer = async id => {
    setGlobalLoading(true);
    setModalConfig(null);
    try {
      await del(`${CUSTOMERS}/${id}`);
      setRetrigger("customer");
      setGlobalLoading(false);
    } catch (err) {
      setGlobalLoading(false);
      console.error(err);
      if (err?.response?.status === 403) {
        setModalConfig({
          title: "Impossibile eliminare il Cliente",
          /* eslint-disable */
          content: "Esistono progetti associati al cliente",
          primaryAction: {
            text: "OK",
          },
        });
      }
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    return handleSubmit(data => {
      console.log("submit", data);
      fetchCustomers();
    })();
  };

  const clearForm = () => {
    reset();
    fetchCustomers();
  };

  return (
    <Layout showSecondCol={false} showHeader={false} showPosts={false}>
      <Navigation />
      <div className={classes.header}>
        <Typography variant="h5">Clienti</Typography>
        <Button
          color="primary"
          variant="contained"
          startIcon={<img src={plusIcon} className={classes.buttonIcon} />}
          onClick={() => setCustomerDrawerOpen(true)}
        >
          Nuovo cliente
        </Button>
      </div>
      <Grid container marginBottom={2}>
        <Grid item xs={12} sm={3}>
          <form onSubmit={handleFilter}>
            <Stack direction="row" gap={1}>
              <TextFieldControl
                name="searchText"
                control={control}
                label="Filtra per cliente"
                placeholder="Digita il nome del cliente che desideri ricercare"
             />
              <Stack direction="row" gap={1}>
                <Button
                  className={classes.actionButton}
                  variant="outlined"
                  color="secondary"
                  onClick={clearForm}
                >
                  <CancelIcon />
                </Button>
                <Button
                  type="submit"
                  className={classes.actionButton}
                  variant="outlined"
                  color="primary"
                >
                  <SearchIcon />
                </Button>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
      {loadingCustomers ? (
        <div className={classes.loading}>
          <Loading showWrapper={false} />
        </div>
      ) : (
        <Table
          data={customers}
          config={customersConfig}
          rowActions={[
            {
              onClick: item => {
                fetchCustomer(item?.id);
                setCustomerDrawerOpen(true);
              },
              icon: <EditIcon />,
            },
            {
              onClick: item => handleCustomerDelete(item?.id),
              icon: <DeleteIcon />,
            },
          ]}
        />
      )}

      <FormProvider {...customerFormMethods}>
        <Drawer
          open={isCustomerDrawerOpen}
          title={
            !loadingDrawer &&
            (selectedItem ? "Modifica Cliente" : "Nuovo Cliente")
          }
          primaryText={!loadingDrawer ? "SALVA" : ""}
          secondaryText={!loadingDrawer ? "ANNULLA" : ""}
          onPrimary={customerFormMethods.handleSubmit(onSubmit)}
          onSecondary={handleClose}
          errorMessage={errorMessage}
        >
          <CustomerForm customerData={selectedItem} loading={loadingDrawer} />
        </Drawer>
      </FormProvider>
    </Layout>
  );
};

export default SettingsCustomers;
