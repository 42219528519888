import React from "react";
import PropTypes from "prop-types";
// Constants
import { MALACHITE, DARK_ORANGE, HARVARD_CRIMSON } from "constants/colors";
// Components
import SvgIcon from "components/SvgIcon";
// Enums
import { sentimentTypes, checkSentiments } from "utils/enums/sentiments";
// Assets
import { ReactComponent as PositiveIcon } from "assets/sentiment-positive.svg";
import { ReactComponent as NeutralIcon } from "assets/sentiment-neutral.svg";
import { ReactComponent as NegativeIcon } from "assets/sentiment-negative.svg";

const Sentiment = ({ height, sentiment, width }) => {
  const getSentimentData = sentiment => {
    if (checkSentiments(sentiment) === sentimentTypes.POSITIVE) {
      return {
        icon: <PositiveIcon />,
        color: MALACHITE,
      };
    } else if (checkSentiments(sentiment) === sentimentTypes.NEUTRAL) {
      return {
        icon: <NeutralIcon />,
        color: DARK_ORANGE,
      };
    } else
      return {
        icon: <NegativeIcon />,
        color: HARVARD_CRIMSON,
      };
  };

  return (
    <SvgIcon
      icon={getSentimentData(sentiment).icon}
      width={width}
      height={height}
      color={getSentimentData(sentiment).color}
    />
  );
};

Sentiment.defaultProps = {
  orientation: "column",
  sentiment: 0,
  width: 28,
  height: 28,
};

Sentiment.propTypes = {
  orientation: PropTypes.string,
  sentiment: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Sentiment;
