import React, { useEffect } from "react";
import useStorage from "./useStorage";
import useFetch from "./useHTTP";
import { useForm } from "react-hook-form";
import { useContext } from "react";
import { AppContext } from "context/AppContext";
import { BeNotificationModal } from "pages/App/modals/BeNotification";
import { AuthContext } from "context/AuthContext";
import dayjs from "dayjs";
import { NOTIFICATION_DAILY, PROJECT } from "constants/api";
import { beNotificationMap } from "utils/enums/be";

const useBeNotification = () => {
  const [lastNotificationRequestMap, setLastNotificationRequestMap] =
    useStorage("tg-last-be-notification-request", null);

  const { get, post } = useFetch();
  const form = useForm();
  const { setModalConfig, settings } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const isBeActive = settings?.isBeActive;

  const { handleSubmit, reset, getValues } = form;

  useEffect(() => {
    if (!user || !isBeActive) return;
    if (
      lastNotificationRequestMap &&
      dayjs().isSame(dayjs(lastNotificationRequestMap?.[user.id]), "day")
    )
      return;
    fetchBeNotification().then(res => {
      if (!res) return;
      setLastNotificationRequestMap({
        ...lastNotificationRequestMap,
        [user.id]: dayjs().toISOString(),
      });
      const { notificationType, projectId } = res;
      reset({ notificationType, projectId });
      handleModal(res);
    });
  }, [lastNotificationRequestMap, user, isBeActive]);

  const handleModal = (res, error) =>
    setModalConfig({
      content: (
        <BeNotificationModal
          form={form}
          error={error}
          user={user}
          data={res}
          onClose={closeModal}
          onCloseWithoutAnswer={closeWithoutAnswer}
        />
      ),
      primaryAction: {
        text: "Invia risposta",
        onClick: handleSubmit(onSubmit, () =>
          handleModal(res, "Seleziona una risposta")
        ),
      },
    });

  const closeModal = () => {
    setModalConfig(null);
    reset({});
  };

  const fetchBeNotification = async () => {
    try {
      const res = await get(NOTIFICATION_DAILY);
      return res.data;
    } catch (err) {
      console.error(err);
    }
  };

  const answerBeNotification = async (
    value,
    projectId,
    endpoint,
    notificationType
  ) => {
    // projectId presence is used to discriminate AI vs NON-AI questions
    try {
      const url = projectId
        ? `${PROJECT}/${projectId}/${endpoint}`
        : `${PROJECT}/${endpoint}`;
      await post(url, { value, ...(!projectId && { type: notificationType }) });
    } catch (err) {
      console.error(err);
    }
  };

  const closeWithoutAnswer = async () => {
    const { projectId, notificationType } = getValues();
    const endpoint = beNotificationMap[notificationType];
    closeModal();
    try {
      const url = projectId
        ? `${PROJECT}/${projectId}/${endpoint}`
        : `${PROJECT}/${endpoint}`;
      post(url, { value: null, ...(!projectId && { type: notificationType }) });
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = ({ beNotificationAnswer, projectId, notificationType }) => {
    if (!beNotificationAnswer || !notificationType) return;
    const endpoint = beNotificationMap[notificationType];
    answerBeNotification(
      +beNotificationAnswer,
      projectId,
      endpoint,
      notificationType
    );
    closeModal();
  };
};

export { useBeNotification };
