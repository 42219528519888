import { searchTimeValues } from "utils/enums/search";
import { brickTypes } from "utils/enums/bricks";

const filterSentimentValues = {
  high: 1,
  medium: 0,
  low: -1,
};
const filterProjectStatusValues = {
  planned: 0,
  stillRolling: 1,
  closed: 2,
};

const filterOwnershipsValues = {
  departmentOwner: 1,
  phaseOwner: 2,
  projectOwner: 3,
};

export const getQueryParamsFromFilters = submittedData =>
  Object.entries(submittedData)
    .map(([key, values]) =>
      values
        .filter(val => val !== null)
        .map(val => `${key}=${val}`)
        .join("&")
    )
    .filter(val => val !== "")
    .join("&");

export const getFiltersFromQueryParams = searchContext => {
  const params = new URLSearchParams(location.search);
  const paramsArray = Array.from(params.entries());
  return paramsArray.map(pair => unparseFilter(pair, searchContext));
};

export const parseFilter = (rawData, defaultValues, searchContext) => ({
  //if at least one key includes 'customerId', it has to be a Customer
  ...(Object.keys(rawData).some(k => k.includes("customerId")) && {
    CustomersId: Object.keys(rawData)
      .filter(key => key.includes("customerId") && !!rawData[key])
      .map(k => k.replace("customerId-", "")),
  }),
  //if at least one key includes 'skillId', it has to be a Skill
  ...(Object.keys(rawData).some(k => k.includes("skillId")) && {
    [searchContext === "bricks" ? "SkillsIds" : "SkillIds"]: Object.keys(
      rawData
    )
      .filter(key => key.includes("skillId") && !!rawData[key])
      .map(k => k.replace("skillId-", "")),
  }),
  //if at least one key includes 'jobTitleId', it has to be a JobTitle
  ...(Object.keys(rawData).some(k => k.includes("jobTitleId")) && {
    JobIds: Object.keys(rawData)
      .filter(key => key.includes("jobTitleId") && !!rawData[key])
      .map(k => k.replace("jobTitleId-", "")),
  }),
  //if at least one key includes 'year', it has to be an Year
  ...(Object.keys(rawData).some(k => k.includes("year")) &&
    rawData.period === searchTimeValues.FISCAL_YEAR && {
      CompetenceYears: Object.keys(rawData)
        .filter(key => key.includes("year") && !!rawData[key])
        .map(k => k.replace("year-", "")),
    }),
  //if at least one key includes 'interval', it has to be an Interval
  ...(Object.keys(rawData).some(k => k.includes("interval")) &&
    rawData.period === searchTimeValues.INTERVAL && {
      TimeFilter: [rawData.interval],
    }),
  //if at least one key includes 'owner', it has to be an Ownership filter
  ...(Object.keys(rawData).some(k => k.includes("Owner")) && {
    Ownership: checkCommonInEnums(
      {
        ...(rawData.departmentOwner && {
          departmentOwner: rawData.departmentOwner,
        }),
        ...(rawData.phaseOwner && { phaseOwner: rawData.phaseOwner }),
        ...(rawData.projectOwner && { projectOwner: rawData.projectOwner }),
      },
      filterOwnershipsValues
    ),
  }),
  //if max price or min price are different from the default values, it has been set
  ...((defaultValues?.pricing?.[0] !== rawData?.pricing?.[0] ||
    defaultValues?.pricing?.[1] !== rawData?.pricing?.[1]) && {
    MinPircing: [rawData?.pricing?.[0] || null],
    MaxPircing: [rawData.pricing?.[1] || null],
  }),
  //if max marginality is greater than min, it has been set
  ...((defaultValues?.marginality?.[0] !== rawData?.marginality?.[0] ||
    defaultValues?.marginality?.[1] !== rawData?.marginality?.[1]) && {
    MinMargin: [rawData.marginality?.[0] || null],
    MaxMargin: [rawData.marginality?.[1] || null],
  }),
  //add quality only if it is set
  ...(rawData.quality && {
    MinQuality: [rawData.quality],
    MaxQuality: [rawData.quality],
  }),
  // add Sentiment only if there are corresponding keys in his enum
  Sentiment: checkCommonInEnums(
    {
      ...(rawData.high && { high: rawData.high }),
      ...(rawData.medium && { medium: rawData.medium }),
      ...(rawData.low && { low: rawData.low }),
    },
    filterSentimentValues
  ),
  // add Status only if there are corresponding keys in his enum
  Status:
    rawData.context === "projects"
      ? checkCommonInEnums(
          {
            ...(rawData.planned && { planned: rawData.planned }),
            ...(rawData.stillRolling && {
              stillRolling: rawData.stillRolling,
            }),
            ...(rawData.closed && { closed: rawData.closed }),
          },
          filterProjectStatusValues
        )
      : checkCommonInEnums(
          {
            ...(rawData.PLANNED && { PLANNED: rawData.PLANNED }),
            ...(rawData.ACTIVE && { ACTIVE: rawData.ACTIVE }),
            ...(rawData.CLOSED && { CLOSED: rawData.CLOSED }),
            ...(rawData.TO_VALIDATE && { TO_VALIDATE: rawData.TO_VALIDATE }),
          },
          brickTypes
        ),
});
const unparseFilter = (paramArray, searchContext = "projects") => {
  const queryKey = paramArray[0];
  const queryValue = paramArray[1];
  let key = "";
  let value = "";
  let enums = "";
  switch (queryKey) {
    case "Sentiment":
    case "Status":
      enums =
        queryKey === "Status"
          ? searchContext === "projects"
            ? filterProjectStatusValues
            : brickTypes
          : filterSentimentValues;
      key = Object.keys(enums).find(key => enums[key] === +queryValue);
      value = !!key;
      break;
    case "MinQuality":
    case "MaxQuality":
      key = "quality";
      value = queryValue;
      break;
    case "MaxPircing":
      key = "pricing";
      value = [
        +new URLSearchParams(location.search).get("MinPircing") || 0,
        +queryValue,
      ];
      break;
    case "MaxMargin":
      key = "marginality";
      value = [
        +new URLSearchParams(location.search).get("MinMargin") || 0,
        +queryValue,
      ];
      break;
    case "CompetenceYears":
      key = `year-${queryValue}`;
      value = true;
      break;
    case "TimeFilter":
      key = `interval`;
      value = queryValue;
      break;
    case "CustomersId":
      key = `customerId-${queryValue}`;
      value = true;
      break;
    case "SkillIds":
    case "SkillsIds":
      key = `skillId-${queryValue}`;
      value = true;
      break;
    case "JobIds":
      key = `jobTitleId-${queryValue}`;
      value = true;
      break;
    case "Ownership":
      enums = filterOwnershipsValues;
      key = Object.keys(enums).find(key => enums[key] === +queryValue);
      value = !!key;
      break;

    default:
      key = queryKey;
      value = queryValue;
      break;
  }
  return {
    key,
    value,
  };
};

export const checkCommonInEnums = (submittedData, enums) =>
  Object.keys(enums)
    .filter(k => k in submittedData)
    .map(k => enums[k]);
