import React from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

// Assests
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
// Components
import SvgIcon from "components/SvgIcon";
// Material UI
import { Button, Drawer as MuiDrawer, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
// Constants
import {
  WHITE,
  BLACK,
  WILD_BLUE_YONDER,
  GHOST_WHITE,
  WE_PEEP,
  HARVARD_CRIMSON,
} from "constants/colors";

// Styles
const useStyles = makeStyles(theme => ({
  paper: ({ themeType }) => ({
    backgroundColor:
      themeType === "light" ? WHITE : theme.palette.componentsBackground.main,
    color: themeType === "light" ? BLACK : WILD_BLUE_YONDER,
    display: "flex",
  }),
  title: ({ themeType }) => ({
    fontWeight: "bold",
    color: themeType === "dark" && WHITE,
    flexGrow: 1,
  }),
  contentWrapper: {
    overflow: "auto",
    overflowX: "hidden",
    margin: "1rem 0",
    flexGrow: 1,
  },
  closeIcon: {
    cursor: "pointer",
    width: 30,
    height: 30,
    align: "right",
    position: "absolute",
    right: 60,
    top: 55,
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  actionsWrapper: ({ onlyOneButton }) => ({
    flexGrow: 0,
    display: "flex",
    justifyContent: onlyOneButton || "flex-end",
  }),
  action: {
    flex: 1,
    marginLeft: theme.spacing(1),
    "&:first-child": {
      marginLeft: 0,
    },
  },
  errorText: {
    color: HARVARD_CRIMSON,
    marginBottom: "20px",
    padding: "10px",
    fontSize: "11px",
    background: WE_PEEP,
    borderRadius: "5px",
    fontWeight: "bold",
    width: "100%",
    wordWrap: "break-word",
  },
}));

const Drawer = ({
  anchor,
  children,
  headerAction,
  onClose,
  onPrimary,
  onSecondary,
  open,
  primaryText,
  secondaryText,
  themeType,
  title,
  errorMessage,
}) => {
  const onlyOneButton = primaryText && !secondaryText ? "center" : null;
  // Hooks
  const classes = useStyles({ themeType, onlyOneButton });

  const renderCloseIcon = () => {
    if (!onClose) return null;
    return (
      <SvgIcon
        color={themeType === "dark" ? GHOST_WHITE : BLACK}
        className={classes.closeIcon}
        icon={<CancelIcon />}
        onClick={onClose}
        strokeWidth={2}
      />
    );
  };

  const renderHeader = () => {
    if (!title && !headerAction) return null;
    return (
      <div className={classes.header}>
        {title && (
          <Typography component="div" variant="h5" className={classes.title}>
            {title}
          </Typography>
        )}
        {headerAction && <div>{headerAction}</div>}
      </div>
    );
  };

  const renderContent = () => (
    <div className={classes.contentWrapper}>
      <PerfectScrollbar>
        {typeof content === "string" ? (
          <Typography
            variant="h6"
            component="span"
            className={classes.bodyText}
          >
            {children}
          </Typography>
        ) : (
          <div>{children}</div>
        )}
      </PerfectScrollbar>
    </div>
  );

  const renderError = () => {
    return (
      <Typography component="span" className={classes.errorText}>
        {errorMessage}
      </Typography>
    );
  };

  const renderActions = () => {
    if (!primaryText && !secondaryText) return null;

    return (
      <div className={classes.actionsWrapper}>
        {secondaryText && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onSecondary}
            className={classes.action}
          >
            {secondaryText}
          </Button>
        )}
        {primaryText && (
          <Button
            variant="contained"
            color="primary"
            onClick={onPrimary}
            className={classes.action}
          >
            {primaryText}
          </Button>
        )}
      </div>
    );
  };

  return (
    <MuiDrawer
      open={!!open}
      anchor={anchor}
      onClose={onClose}
      classes={{
        paper: classes.paper,
      }}
    >
      {renderCloseIcon()}
      {renderHeader()}
      {renderContent()}
      {errorMessage && renderError()}
      {renderActions()}
    </MuiDrawer>
  );
};

Drawer.defaultProps = {
  anchor: "right",
  themeType: "light",
  errorMessage: "",
};

Drawer.propTypes = {
  anchor: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  headerAction: PropTypes.node,
  onClose: PropTypes.func,
  onPrimary: PropTypes.func,
  onSecondary: PropTypes.func,
  open: PropTypes.bool,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  themeType: PropTypes.oneOf(["dark", "light"]),
  title: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default Drawer;
