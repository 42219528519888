import { BASE_URL } from "constants/hosts";

const projectPrefix = BASE_URL + process.env.REACT_APP_PROJECT_API_PREFIX;
export const projectImagesPrefix = process.env.REACT_APP_PROJECT_IMAGES_PREFIX;

const usersPrefix = BASE_URL + process.env.REACT_APP_USER_API_PREFIX;
const postsPrefix = BASE_URL + process.env.REACT_APP_POSTS_API_PREFIX;
const widgetsPrefix = BASE_URL + process.env.REACT_APP_WIDGETS_PREFIX;
const filePrefix = BASE_URL + process.env.REACT_APP_FILE_PREFIX;
const notificationPrefix =
  BASE_URL + process.env.REACT_APP_NOTIFICATION_API_PREFIX;
const aiGeneratorPrefix =
  BASE_URL + process.env.REACT_APP_AI_GENERATOR_LAMBDA_PREFIX;

// Authentication
export const LOGIN = usersPrefix + "Users/signIn";
export const SET_PASSWORD = usersPrefix + "Users/setPassword";
export const FORGOT_PASSWORD = usersPrefix + "Users/forgotPassword";
export const RESET_PASSWORD = usersPrefix + "Users/resetPassword";
export const USER_DETAILS = usersPrefix + "Users";

// Projects
export const PORTFOLIO = projectPrefix + "portfolios";
export const PROJECT = projectPrefix + "Projects";
export const PROJECT_SEARCH = projectPrefix + "Projects/search";
export const TAGS = projectPrefix + "Projects/tags";
export const CUSTOMERS = projectPrefix + "Customers";
export const ACTIVITIES = projectPrefix + "Activities";
export const ACTIVITIES_CALENDAR = projectPrefix + "Activities/calendar";
export const LAMBDA_CONTRACTS =
  projectPrefix + "CustomLambdaEvents/refreshUserBricksCosts";

// Planner/Builder
export const PLANNER_PROJECT = projectPrefix + "Planner";
export const PLANNER_BRICKS = projectPrefix + "Planner/Bricks";
export const PLANNER_PHASES = projectPrefix + "Planner/Phases";
export const PLANNER_PHASES_REORDER = projectPrefix + "Planner/Phases/reorder";

// Brick Dashboard
export const BRICKS = projectPrefix + "Bricks";
export const BRICKS_CHECKLIST = projectPrefix + "Bricks/checklist";
export const BRICKS_SEARCH = projectPrefix + "Bricks/search";
export const PLANNER_BRICKS_REPORT = "graph/report?range=";
export const PLANNER_BRICKS_ESTIMATE_FINISH =
  "graph/estimationCompletion?range=";

// Users
export const USERS_LIST = usersPrefix + "Users";
export const DEPARTMENTS = usersPrefix + "Departments";
export const USERS_SEARCH = usersPrefix + "Users/search";
export const USERS_CONTRACTS = usersPrefix + "UserContracts/bulk";

// WorkLoad
export const WORKLOAD_EFFORT = "graph/effort";
export const WORKLOAD_BURNDOWN = "graph/burndown";
export const WORKLOAD_BURNDOWN_DETAIL = "graph/burndown/detail";

// Posts
export const POSTS = postsPrefix;

// Notification
export const NOTIFICATION = notificationPrefix + "/notification";
export const NOTIFICATION_DAILY = notificationPrefix + "/notification/daily";

// Jobs
export const JOB_TITLE = usersPrefix + "JobTitle";

// Skills
export const SKILLS = usersPrefix + "Skills";

// Widgets
export const WIDGETS_BRICKS = widgetsPrefix + "Widgets/bricks";
export const WIDGETS_DEPARTMENTS = widgetsPrefix + "Widgets/departments";
export const WIDGETS_PORTFOLIOS = widgetsPrefix + "Widgets/portfolios";
export const WIDGETS_USERS = widgetsPrefix + "Widgets/users";
export const WIDGETS_PROJECTS = widgetsPrefix + "Widgets/projects";
export const WIDGETS_COMPANY = widgetsPrefix + "Widgets/company";
export const WIDGETS_SEARCH_BRICKS = widgetsPrefix + "Widgets/bricks/search";
export const WIDGETS_SEARCH_PROJECTS =
  widgetsPrefix + "Widgets/projects/search";
export const WIDGETS_SEARCH_USERS = widgetsPrefix + "Widgets/users/search";

// Settings
export const GENERAL_SETTINGS = usersPrefix + "GeneralSettings";
export const SETTINGS_PORTFOLIOS = projectPrefix + "portfolios/settings";
export const SETTINGS_DEPARTMENTS = usersPrefix + "departments/settings";

// Export
const EXPORT = projectPrefix + "Export";
export const EXPORT_REPORT = EXPORT + "/reportXlsx";

// File
export const ATTACHMENTS = filePrefix + "attachments";

// AI Generator
export const AI_GENERATOR = aiGeneratorPrefix;
