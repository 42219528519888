import React from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Avatar from "components/Avatar";

// Icons
import { WILD_BLUE_YONDER } from "constants/colors";
import Accordion from "components/Accordion";
import BrickList from "components/BrickList";

// Svg
import rigthArrow from "assets/right-arrow.svg";
import PlaceholderIcon from "assets/placeholder.png";
import userPlaceholder from "assets/user_placeholder.svg";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    paddingTop: 15,
    paddingBottom: 15,
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${WILD_BLUE_YONDER}70`,
  },
  accordionContainer: {
    marginTop: 5,
    paddingBottom: 5,

    "&:first-child": {
      marginTop: 12,
    },
  },
  infoContainer: {
    display: "flex",
  },
  avatarContainer: {
    cursor: props => (props.isAvatarClickable ? "pointer" : "unset"),
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: 20,
  },
  goContainer: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    cursor: props => (props.isAvatarClickable ? "pointer" : "unset"),
  },
  title: {
    fontSize: "0.9rem",
  },
  subtitle: {
    fontSize: "0.75rem",
    color: theme.palette.action.disabledBackground,
  },
  goTo: {
    fontSize: "0.7",
  },
}));

const ResultRow = ({
  img,
  title,
  subtitle,
  type,
  isFirst,
  onClickArrow,
  onClickAvatar,
  bricks,
  defaultExpanded,
}) => {
  // Hooks
  const classes = useStyles({
    isFirst,
    isAvatarClickable: !!onClickAvatar,
    isArrowClickable: !!onClickArrow,
  });

  return (
    <>
      {type === "brick" ? (
        <div className={classes.accordionContainer}>
          <Accordion
            title={{ name: title }}
            details={
              <BrickList
                bricks={bricks}
                actions={[
                  {
                    text: "Vai",
                    icon: { img: rigthArrow, width: 15, height: 15 },
                    function: onClickArrow,
                  },
                ]}
              />
            }
            subtitle={subtitle}
            defaultExpanded={defaultExpanded}
          />
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.infoContainer}>
            <div className={classes.avatarContainer} onClick={onClickAvatar}>
              {type === "project" ? (
                <img src={img || PlaceholderIcon} height={40} width={40} />
              ) : (
                <Avatar image={img || userPlaceholder} />
              )}
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>{title}</Typography>
              <Typography className={classes.subtitle}>{subtitle}</Typography>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <div className={classes.goContainer} onClick={onClickArrow}>
              <Typography className={classes.goTo}>Vai</Typography>
              <div>
                <img src={rigthArrow} height={15} width={15} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ResultRow.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isFirst: PropTypes.bool,
  onClickArrow: PropTypes.func,
  onClickAvatar: PropTypes.func,
  bricks: PropTypes.array,
  type: PropTypes.oneOf(["project", "user", "brick"]),
  defaultExpanded: PropTypes.bool,
};

export default ResultRow;
