import React, { useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import { nanoid } from "nanoid";

// Dayjs plugins
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

// Components
import TextField from "components/TextField";
import Autocomplete from "components/Autocomplete";
import Button from "components/Button";
// import Switch from "components/Switch";
import SvgIcon from "components/SvgIcon";
import Loading from "components/Loading";

// Material UI
import { Grid, Typography, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Api
import { USERS_LIST } from "constants/api";

// Icons
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
import { ReactComponent as DragHandle } from "assets/drag-handle.svg";

// Constants
import { WILD_BLUE_YONDER } from "constants/colors";
import { multipleBrickFormDefaults } from "./defaultValues";
import { MultiDatePickerControl } from "components/MultiDatePicker";

// Styles
const useStyles = makeStyles(() => ({
  formWrapper: {
    width: "100%",
  },
  formRow: {
    marginBottom: 15,
  },
  switchWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  dragIcon: {
    marginRight: 10,
  },
  dragIconRequired: {
    marginRight: 10,
    marginBottom: 20,
  },
  loading: {
    height: "40vh",
  },
  title: {
    fontWeight: "bold",
    fontSize: 13,
  },
}));

const THEME_TYPE = "light";

const MultipleBrickForm = ({ brickData, loading, phaseTitle }) => {
  // Hooks
  const classes = useStyles();
  const {
    formState: { errors },
    control,
    reset,
    register,
  } = useFormContext();

  const {
    fields,
    prepend,
    insert: setChecklists,
    remove,
    move,
  } = useFieldArray({
    name: "checklists", // unique name for your Field Array
  });

  // Effects
  useEffect(() => {
    if (brickData) {
      const { name, user, description, multipleDates, checklists } = brickData;

      // Place an empty value if dates array is empty
      // (at least one date needed for brick an thus for validation)
      // const formattedMultipleDates = [
      //   ...multipleDates,
      //   ...(multipleDates.length ? [] : [null]),
      // ].map(d => ({ value: d }));

      reset({
        name,
        user,
        description,
        multipleDates: multipleDates.map(d => dayjs(d)),
        checklists: [],
      });
      setChecklists(
        0,
        checklists.map(ck => ({ ...ck, originalId: ck.id }))
      );
    } else reset(multipleBrickFormDefaults);
  }, [brickData]);

  const addItem = () => {
    prepend({ id: nanoid(), description: "" });
  };

  const deleteItem = index => {
    remove(index);
  };

  const handleCheckListReorder = result => {
    const { destination, source } = result;
    // Dropped outside the list
    if (!destination) return;
    // Dropped in place
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    // Reorder items after drop
    move(source.index, destination.index);
  };

  if (loading)
    return (
      <div className={classes.loading}>
        <Loading showWrapper={false} />
      </div>
    );

  return (
    <DragDropContext onDragEnd={handleCheckListReorder}>
      <Fade in timeout={400}>
        <form>
          <Grid container spacing={1} style={{ width: "100%" }}>
            <Grid item xs={12} className={classes.formRow}>
              <Typography
                color="primary"
                variant="h6"
                className={classes.title}
              >{`FASE: ${phaseTitle}`}</Typography>
            </Grid>

            <Grid item xs={12} className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <TextField
                    label="Nome Brick"
                    error={!!errors.name}
                    required
                    themeType={THEME_TYPE}
                    shrink={!!field.value}
                    {...field}
                  />
                )}
                name="name"
                control={control}
                rules={{ required: true }}
              />
            </Grid>

            <Grid item xs={12} className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    label="Owner"
                    endpoint={`${USERS_LIST}?me=true&q`}
                    error={!!errors.user}
                    getOptionLabel={option =>
                      `${option.name} ${option.surname}`
                    }
                    themeType={THEME_TYPE}
                    required
                    {...field}
                    onChange={(_, data) => field.onChange(data)}
                  />
                )}
                name="user"
                control={control}
                rules={{ required: true }}
              />
            </Grid>

            <Grid item xs={12} className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <TextField
                    label="Descrizione"
                    multiline
                    rows={5}
                    themeType={THEME_TYPE}
                    shrink={!!field.value}
                    {...field}
                  />
                )}
                name="description"
                control={control}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>Date</Typography>
            </Grid>
            <Grid item xs={12}>
              <MultiDatePickerControl
                control={control}
                name="multipleDates"
                label="Date"
                error={errors.multipleDates}
                // Required true seems to work out of the box on array length (?)
                rules={{ required: "Almeno una data obbligatoria" }}
              />
            </Grid>
            {/* <Grid item xs={12} className={classes.formRow}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                themeType={THEME_TYPE}
                onClick={() => appendDate(DEFAULT_DATE_FIELDARRAY)}
                disabled={watch("multipleDates")?.some(df => !df.value)}
              >
                + Aggiungi date
              </Button>
            </Grid> */}

            {/* <Grid container item xs={12} className={classes.formRow}>
              {dateFields.map((_, idx) => (
                <Grid
                  container
                  item
                  xs={12}
                  key={idx}
                  wrap="nowrap"
                  spacing={1}
                >
                  <Grid item xs={12} className={classes.formRow}>
                    <Controller
                      render={({ field }) => (
                        <DatePicker
                          themeType={THEME_TYPE}
                          label={`Data ${idx + 1}`}
                          required
                          error={!!errors.multipleDates?.[idx]}
                          {...field}
                          onChange={dayJsDate =>
                            handleDateChange(dayJsDate, idx)
                          }
                          shouldDisableDate={date =>
                            shouldDisableDate(date, field)
                          }
                        />
                      )}
                      name={`multipleDates.${idx}.value`}
                      control={control}
                      rules={{
                        required: true,
                        validate: validateDate,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} xl={1}>
                    <Button
                      className="iconButton"
                      themeType={THEME_TYPE}
                      disabled={watch("multipleDates").length <= 1}
                      variant="outlined"
                      color="secondary"
                      onClick={() => removeDate(idx)}
                      icon={<CancelIcon />}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid> */}

            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>Check list</Typography>
            </Grid>
            <Grid item xs={12} className={classes.formRow}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                themeType={THEME_TYPE}
                onClick={addItem}
              >
                + LIST ITEM
              </Button>
            </Grid>
          </Grid>
          <Droppable droppableId="droppable" direction="vertical">
            {provided => (
              <>
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {fields?.map((field, idx) => (
                    <Draggable
                      key={field.id}
                      draggableId={field.id?.toString()}
                      index={idx}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Grid container spacing={1} style={{ width: "100%" }}>
                            <Grid item xs={11} xl={11}>
                              <div
                                {...provided?.dragHandleProps}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <SvgIcon
                                  className={
                                    errors.checklists?.[idx]?.description
                                      ? classes.dragIconRequired
                                      : classes.dragIcon
                                  }
                                  icon={<DragHandle />}
                                  color={WILD_BLUE_YONDER}
                                  width={14}
                                />
                                <TextField
                                  label="Item"
                                  required
                                  themeType={THEME_TYPE}
                                  disabled={field.checked}
                                  error={
                                    !!errors.checklists?.[idx]?.description
                                  }
                                  {...register(
                                    `checklists.${idx}.description`,
                                    { required: true }
                                  )}
                                  defaultValue={field.description} // make sure to include defaultValue
                                />
                              </div>
                            </Grid>
                            <Grid item xs={1} xl={1}>
                              <Button
                                className="iconButton"
                                themeType={THEME_TYPE}
                                disabled={field.checked}
                                variant="outlined"
                                color="secondary"
                                onClick={() => deleteItem(idx)}
                                icon={<CancelIcon />}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
                {provided.placeholder}
              </>
            )}
          </Droppable>
        </form>
      </Fade>
    </DragDropContext>
  );
};

MultipleBrickForm.propTypes = {
  brickData: PropTypes.object,
  phaseTitle: PropTypes.string,
  loading: PropTypes.bool,
};

export default MultipleBrickForm;
