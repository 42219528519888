import { Box, FormHelperText, Rating, Stack, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { PropTypes } from "prop-types";

import { WILD_BLUE_YONDER } from "constants/colors";
import { useEffect } from "react";

import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& :is(.MuiRating-iconEmpty, .MuiRating-iconFilled)": {
    color: theme.palette.primary.main,
  },
  "& .MuiRating-icon": {
    marginInline: theme.spacing(1),
  },
}));

const RequestCloseModal = ({ owner, error, valueRef }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return (
    <Stack alignItems="center" my={1}>
      <Typography
        variant="h6"
        color={WILD_BLUE_YONDER}
        fontWeight="bold"
        fontSize={18}
      >
        Invia richiesta di chiusura a {owner} e inserisci autovalutazione
      </Typography>

      <Box mt={5}>
        <StyledRating
          size="large"
          name="test-controlled"
          defaultValue={value}
          value={value}
          onChange={(_, newValue) => setValue(newValue)}
          icon={<StarRateRoundedIcon scale={2} fontSize="inherit" />}
          emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
        />
        <FormHelperText error sx={{ mt:2, textAlign: "center" }}>
          {error}
        </FormHelperText>
      </Box>
    </Stack>
  );
};

RequestCloseModal.propTypes = {
  currentValue: PropTypes.number,
  owner: PropTypes.string,
  valueRef: PropTypes.object,
  error: PropTypes.string,
};

export { RequestCloseModal };
