import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { WILD_BLUE_YONDER } from "constants/colors";
import { useEffect } from "react";
import { useState } from "react";
import NumberFormat from "react-number-format";

const EXTRA_COLOR = "#3792E6";

const BaseProgress = styled(CircularProgress)({
  position: "absolute",
  display: "block",
});

const Background = styled(BaseProgress)({
  color: "rgba(255, 255, 255, 0.2)",
  transform: "rotate(-90deg) scale(0.8) !important",
});

const Foreground = styled(BaseProgress, {
  shouldForwardProp: prop => !["customColor"].includes(prop),
})(({ customColor }) => ({
  color: customColor,
  zIndex: 5,
  transform: "rotate(-90deg) scale(0.8) !important",
}));

const Extra = styled(BaseProgress, {
  shouldForwardProp: prop => !["customColor"].includes(prop),
})(({ customColor }) => ({
  color: customColor,
  zIndex: 10,
}));

const StyledBox = styled(Box)(({ size }) => ({
  position: "relative",
  width: size,
  height: size,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ReportProgress = ({
  color = "white",
  extraColor = EXTRA_COLOR,
  size = 30,
  value,
  extraValue,
  valuePercentage,
  extraValuePercentage,
  title,
  legendSuffix,
  ...rest
}) => {
  const commonProps = { size, variant: "determinate", thickness: 2, ...rest };

  const [animationValues, setAnimationValues] = useState({
    value: 0,
    extraValue: 0,
  });

  useEffect(() => {
    const timetout = setTimeout(() => {
      setAnimationValues({
        value: valuePercentage,
        extra: extraValuePercentage,
      });
    }, 600);
    return () => clearTimeout(timetout);
  }, [valuePercentage, extraValuePercentage]);

  // Renders
  const renderLegend = () => (
    <Box textAlign="right">
      <Typography lineHeight={1.2} fontWeight="bold" color={WILD_BLUE_YONDER}>
        {title}
      </Typography>
      <Typography lineHeight={1.2} color="white">
        ordinario{" "}
        <span style={{ fontWeight: "bold" }}>
          <NumberFormat
            value={value}
            displayType="text"
            decimalScale={2}
            suffix={legendSuffix}
            fixedDecimalScale
          />
        </span>
      </Typography>
      <Typography lineHeight={1.2} color={EXTRA_COLOR}>
        straordinario{" "}
        <span style={{ fontWeight: "bold" }}>
          <NumberFormat
            value={extraValue}
            displayType="text"
            decimalScale={2}
            suffix={legendSuffix}
            fixedDecimalScale
          />
        </span>
      </Typography>
    </Box>
  );

  const renderProgress = () => (
    <StyledBox size={size}>
      <Extra
        value={animationValues.extra}
        customColor={extraColor}
        {...commonProps}
        sx={{ opacity: animationValues.extra ? 1 : 0 }}
      />
      <Foreground
        value={animationValues.value}
        customColor={color}
        {...commonProps}
      />
      <Background value={100} {...commonProps} />
    </StyledBox>
  );

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {renderLegend()}
      {renderProgress()}
    </Stack>
  );
};

ReportProgress.propTypes = {
  color: PropTypes.string,
  extraColor: PropTypes.string,
  size: PropTypes.number,
  value: PropTypes.number,
  valuePercentage: PropTypes.number,
  extraValue: PropTypes.number,
  extraValuePercentage: PropTypes.number,
  title: PropTypes.string,
  legendSuffix: PropTypes.string,
};

export { ReportProgress };
