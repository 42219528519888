import React, { useState } from "react";
import PropTypes from "prop-types";

import { Typography, makeStyles } from "@material-ui/core";

import AreaWithDate from ".";

const data1 = [
  {
    x: "4/9/2021",
    y: "3",
  },
  {
    x: "5/9/2021",
    y: "-5",
  },
  {
    x: "6/9/2021",
    y: "3",
  },
];

const useStyles = makeStyles({
  noData: {
    width: "100%",
    textAlign: "center",
    paddingBlock: 90
  },
});

const AreaWithDateManager = props => {
  const [data] = useState(props.data);

  const classes = useStyles();

  if (Object.keys(data).length) return <AreaWithDate data={data} {...props} />;

  return (
    <Typography variant="h6" className={classes.noData}>
      Al momento non sono presenti dati
    </Typography>
  );
};

AreaWithDateManager.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

AreaWithDateManager.defaultProps = {
  data: data1,
};

export default AreaWithDateManager;
