export const dummySkyview = {
  project: {
    id: 4,
    name: "Artigiano in Fiera 2021",
    percentagePercentage: 21.5,
    user: {
      id: "a09abbb0-3968-4472-8eda-ce38fbe52aca",
      name: "Andrea",
      surname: "Rossetti",
      email: "andrea.rossetti@nautes.com",
      area: "DES",
      jobTitle: "Head Digital Design",
      confirmedAt: "2021-03-03T08:11:00",
      image:
        "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/Andrea+Rossetti.jpg",
    },
  },
  phases: [
    {
      id: 5,
      name: "Project Management",
      status: 1,
      plannedStartDate: "2021-01-15T00:00:00",
      plannedEndDate: "2021-12-15T00:00:00",
      realStartDate: null,
      realEndDate: null,
      percentagePercentage: 0,
      time: 1,
      cost: 0.1820987654320986,
      quality: 5,
      user: {
        id: "a09abbb0-3968-4472-8eda-ce38fbe52aca",
        name: "Andrea",
        surname: "Rossetti",
        email: "andrea.rossetti@nautes.com",
        area: "DES",
        jobTitle: "Head Digital Design",
        confirmedAt: "2021-03-03T08:11:00",
        image:
          "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/Andrea+Rossetti.jpg",
      },
      position: 0,
      people: [
        {
          id: "de018ddc-eeb8-406a222-8fc0-f450d946c11ca",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 25,
        },
        {
          id: "de018ddc-eeb12128-406a-8fc0-f450d946c11cs",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 34,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cd",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 0,
        },
      ],
    },
    {
      id: 6,
      name: "Design App",
      status: 1,
      plannedStartDate: "2021-02-15T00:00:00",
      plannedEndDate: "2021-12-15T00:00:00",
      realStartDate: null,
      realEndDate: null,
      percentagePercentage: 0,
      time: 0,
      cost: 0,
      quality: 0,
      user: {
        id: "a09abbb0-3968-4472-8eda-ce38fbe52aca",
        name: "Andrea",
        surname: "Rossetti",
        email: "andrea.rossetti@nautes.com",
        area: "DES",
        jobTitle: "Head Digital Design",
        confirmedAt: "2021-03-03T08:11:00",
        image:
          "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/Andrea+Rossetti.jpg",
      },
      position: 1,
      people: [
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cf",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 22,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cg",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 54,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11ce",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 10,
        },
      ],
    },
    {
      id: 7,
      name: "Design Web",
      status: 1,
      plannedStartDate: "2021-02-01T00:00:00",
      plannedEndDate: "2021-09-15T00:00:00",
      realStartDate: null,
      realEndDate: null,
      percentagePercentage: 0,
      time: 0,
      cost: 0,
      quality: 0,
      user: {
        id: "a09abbb0-3968-4472-8eda-ce38fbe52aca",
        name: "Andrea",
        surname: "Rossetti",
        email: "andrea.rossetti@nautes.com",
        area: "DES",
        jobTitle: "Head Digital Design",
        confirmedAt: "2021-03-03T08:11:00",
        image:
          "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/Andrea+Rossetti.jpg",
      },
      position: 2,
      people: [
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cy",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 45,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cq",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 65,
        },
      ],
    },
    {
      id: 8,
      name: "Design Datalake",
      status: 1,
      plannedStartDate: "2021-02-01T00:00:00",
      plannedEndDate: "2021-03-15T00:00:00",
      realStartDate: null,
      realEndDate: "2021-03-15T00:00:00",
      percentagePercentage: 0,
      time: 1,
      cost: 1,
      quality: 5,
      user: {
        id: "34c5ba03-d1b1-4a8b-b838-241eca97bdc7",
        name: "Marco",
        surname: "Marsili",
        email: "marco.marsili@nautes.com",
        area: "DEV",
        jobTitle: "Head Software Development",
        confirmedAt: "2021-03-26T17:43:43",
        image:
          "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/Marco+Marsili.jpg",
      },
      position: 3,
      people: [
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11ca",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 95,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cb",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 72,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cc",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 87,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cd",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 41,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11ce",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 77,
        },
      ],
    },
    {
      id: 9,
      name: "Dev Datalake",
      status: 1,
      plannedStartDate: "2021-03-01T00:00:00",
      plannedEndDate: "2021-04-30T00:00:00",
      realStartDate: null,
      realEndDate: "2021-04-19T00:00:00",
      percentagePercentage: 0,
      time: 1,
      cost: 0.06329113924050633,
      quality: 5,
      user: {
        id: "34c5ba03-d1b1-4a8b-b838-241eca97bdc7",
        name: "Marco",
        surname: "Marsili",
        email: "marco.marsili@nautes.com",
        area: "DEV",
        jobTitle: "Head Software Development",
        confirmedAt: "2021-03-26T17:43:43",
        image:
          "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/Marco+Marsili.jpg",
      },
      position: 4,
      people: [
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11ch",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 12,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11ca",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 89,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cn",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 17,
        },
      ],
    },
    {
      id: 10,
      name: "Dev App iOS",
      status: 1,
      plannedStartDate: "2021-03-26T00:00:00",
      plannedEndDate: "2021-11-30T00:00:00",
      realStartDate: null,
      realEndDate: null,
      percentagePercentage: 0,
      time: 1,
      cost: 1,
      quality: 5,
      user: {
        id: "34c5ba03-d1b1-4a8b-b838-241eca97bdc7",
        name: "Marco",
        surname: "Marsili",
        email: "marco.marsili@nautes.com",
        area: "DEV",
        jobTitle: "Head Software Development",
        confirmedAt: "2021-03-26T17:43:43",
        image:
          "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/Marco+Marsili.jpg",
      },
      position: 5,
      people: [
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11ck",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 12,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cl",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 95,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11ca",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 75,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cp",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 32,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cw",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 82,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11ce",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 95,
        },
      ],
    },
    {
      id: 11,
      name: "Dev App Android",
      status: 1,
      plannedStartDate: "2021-03-26T00:00:00",
      plannedEndDate: "2021-11-30T00:00:00",
      realStartDate: null,
      realEndDate: null,
      percentagePercentage: 0,
      time: 1,
      cost: 1,
      quality: 5,
      user: {
        id: "34c5ba03-d1b1-4a8b-b838-241eca97bdc7",
        name: "Marco",
        surname: "Marsili",
        email: "marco.marsili@nautes.com",
        area: "DEV",
        jobTitle: "Head Software Development",
        confirmedAt: "2021-03-26T17:43:43",
        image:
          "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/Marco+Marsili.jpg",
      },
      position: 6,
      people: [
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cx",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 85,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cw",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 20,
        },
      ],
    },
    {
      id: 12,
      name: "Dev Web Frontend",
      status: 1,
      plannedStartDate: "2021-02-15T00:00:00",
      plannedEndDate: "2021-12-31T00:00:00",
      realStartDate: null,
      realEndDate: null,
      percentagePercentage: 0,
      time: 1,
      cost: 1,
      quality: 5,
      user: {
        id: "34c5ba03-d1b1-4a8b-b838-241eca97bdc7",
        name: "Marco",
        surname: "Marsili",
        email: "marco.marsili@nautes.com",
        area: "DEV",
        jobTitle: "Head Software Development",
        confirmedAt: "2021-03-26T17:43:43",
        image:
          "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/Marco+Marsili.jpg",
      },
      position: 7,
      people: [
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11ca",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 45,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cb",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 65,
        },
      ],
    },
    {
      id: 13,
      name: "Dev Server Logic",
      status: 1,
      plannedStartDate: "2021-03-01T00:00:00",
      plannedEndDate: "2021-11-30T00:00:00",
      realStartDate: null,
      realEndDate: null,
      percentagePercentage: 0,
      time: 1,
      cost: 1,
      quality: 5,
      user: {
        id: "34c5ba03-d1b1-4a8b-b838-241eca97bdc7",
        name: "Marco",
        surname: "Marsili",
        email: "marco.marsili@nautes.com",
        area: "DEV",
        jobTitle: "Head Software Development",
        confirmedAt: "2021-03-26T17:43:43",
        image:
          "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/Marco+Marsili.jpg",
      },
      position: 8,
      people: [
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cn",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 95,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cm",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 72,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cl",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 35,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11ck",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 80,
        },
      ],
    },
    {
      id: 14,
      name: "Digital Marketing",
      status: 1,
      plannedStartDate: "2021-03-01T00:00:00",
      plannedEndDate: "2021-05-31T00:00:00",
      realStartDate: null,
      realEndDate: null,
      percentagePercentage: 0,
      time: 1,
      cost: 1,
      quality: 5,
      user: {
        id: "a09abbb0-3968-4472-8eda-ce38fbe52aca",
        name: "Andrea",
        surname: "Rossetti",
        email: "andrea.rossetti@nautes.com",
        area: "DES",
        jobTitle: "Head Digital Design",
        confirmedAt: "2021-03-03T08:11:00",
        image:
          "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/Andrea+Rossetti.jpg",
      },
      position: 9,
      people: [
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11cq",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 1,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11ca",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 25,
        },
        {
          id: "de018ddc-eeb8-406a-8fc0-f450d946c11ct",
          name: "Denni",
          surname: "Bevilacqua",
          email: "denni.bevilacqua@nautes.com",
          confirmedAt: "2021-03-26T13:18:16",
          image:
            "https://teamgroove-dev-content.s3.amazonaws.com/users_avatar/bevilacqua.jpg",
          area: "DEV",
          jobTitle: "Frontend Software Developer",
          termsAndConditionsAcceptanceDateTime: "2021-05-31T09:34:03",
          mustAcceptTermsAndConditions: false,
          departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
          departmentName: "Frontend",
          departmentManager: "Marco Marsili",
          seniorSkillsIds: ["reactjs"],
          seniorSkills: [
            {
              id: "reactjs",
              name: "React JS",
              departmentId: "4f72e098-ccf6-11eb-b8bc-0242ac130003",
            },
          ],
          deleted: false,
          percentage: 74,
        },
      ],
    },
  ],
};
