import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// Others components
import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Button,
  Typography,
  Accordion as AccordionMUI,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@material-ui/core";

import {
  DREARY_BLUE,
  SPACE_CADET,
  WHITE,
  WILD_BLUE_YONDER,
} from "constants/colors";
import SvgIcon from "components/SvgIcon";
// Icons
import { ReactComponent as Arrow } from "assets/down-arrow.svg";

import theme from "themeV4";
import { Link } from "components/Link/Link";

const useStyles = makeStyles(() => ({
  accordionContent: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0,
    padding: `12px 0px 12px ${theme.spacing(4)}px`,
  },
  accordionActions: {
    display: "flex",
    alignItems: "center",
  },
  expanded: {
    backgroundColor: DREARY_BLUE,
    borderRadius: 5,
  },
  summaryRoot: {
    padding: 0,
    paddingRight: theme.spacing(2),
  },
  accordionRoot: {
    backgroundColor: SPACE_CADET,
    padding: 0,
    borderRadius: 5,
  },
  accordionDetails: ({ heightScroll }) => ({
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    display: "flex",
    flexDirection: "column",
    maxHeight: heightScroll || "100%",
    position: "relative",
  }),
  item: {
    display: "flex",
    flexDirection: "column",
  },
  itemHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  accordionAction: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: theme.palette.common.white,
    "&:hover": {
      textDecoration: "unset",
    },
  },
  startIcon: {
    paddingRight: theme.spacing(1),
  },
  icon: {
    width: 12,
    height: 12,
    color: theme.palette.common.white,
  },
  divider: {
    backgroundColor: theme.palette.common.white,
    margin: `0px ${theme.spacing(2)}px`,
    height: 12,
  },
  subtitle: {
    color: WILD_BLUE_YONDER,
  },
  title: ({ title }) => ({
    "&:hover": {
      textDecoration: title?.onClick ? "underline" : "default",
    },
  }),
}));

const Accordion = ({
  actions,
  defaultExpanded,
  expanded,
  onChange,
  details,
  heightScroll,
  showDetail,
  subtitle,
  themeType,
  title,
  style,
}) => {
  const classes = useStyles({ themeType, title, heightScroll });

  return (
    <AccordionMUI
      classes={{
        root: classes.accordionRoot,
      }}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={onChange}
      style={style}
    >
      <AccordionSummary
        expandIcon={
          <SvgIcon
            color={WILD_BLUE_YONDER}
            className={classes.icon}
            icon={<Arrow />}
            strokeWidth={2}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          root: classes.summaryRoot,
          expanded: classes.expanded,
          content: classes.accordionContent,
        }}
      >
        <div>
          <Typography
            variant="h6"
            onClick={title?.onClick}
            className={classes.title}
          >
            {title?.to ? <Link to={title.to}>{title?.name}</Link> : title?.name}
          </Typography>
          {subtitle && (
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          )}
        </div>
        <div className={classes.accordionActions}>
          {actions &&
            actions.map(action => {
              return (
                <>
                  <Button
                    className={classes.accordionAction}
                    classes={{ startIcon: classes.startIcon }}
                    onClick={action.onClick}
                    startIcon={
                      action.icon && (
                        <SvgIcon
                          icon={action.icon}
                          width={15}
                          height={15}
                          color={WHITE}
                        />
                      )
                    }
                  >
                    {action.label}
                  </Button>
                  <Divider
                    orientation="vertical"
                    classes={{ root: classes.divider }}
                  />
                </>
              );
            })}
          {showDetail && (
            <Button className={classes.accordionAction}>DETTAGLIO</Button>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>
        <PerfectScrollbar id={"pf-scrollbar-accordion"}>
          {details}
        </PerfectScrollbar>
      </AccordionDetails>
    </AccordionMUI>
  );
};
Accordion.defaultProps = {
  defaultExpanded: false,
  showDetail: false,
};

Accordion.propTypes = {
  themeType: PropTypes.string,
  title: PropTypes.object,
  actions: PropTypes.arrayOf(PropTypes.object),
  details: PropTypes.node,
  heightScroll: PropTypes.string,
  subtitle: PropTypes.string,
  showDetail: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

export default Accordion;
