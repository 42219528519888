export const brickFormDefaults = {
  name: "",
  description: "",  
  plannedStartDate: null,
  plannedEndDate: null,
  plannedHours: "",
  user: null,
  message: "Ciao, ti ho assegnato un nuovo brick",
  checklists: [],
  skillsIds: [],
};
