import { PLANNED_COLOR, REAL_COLOR, NOT_STARTED_COLOR } from "components/Charts/Gantt";
import dayjs from "dayjs";

const formatGanttDate = datestring =>
  datestring ? dayjs(datestring).locale("en").format("DD MMM YYYY") : null;

export const mapGanttData = data => {
  const mappedData = data.phases.map(phase => ({
    name: phase.name,
    plannedName: "Pianificato",
    realName: `Reale ${Math.round(phase.completionPercentage).toFixed(0)}%`,
    plannedColor: PLANNED_COLOR,
    realColor: phase.realStartDate ? REAL_COLOR : NOT_STARTED_COLOR,
    plannedFromDateName: "Data di inizio pianificata",
    plannedToDateName: "Data di fine pianificata",
    realFromDateName: "Data di inizio reale",
    realToDateName: `Data di fine ${phase.realEndDate ? "reale" : "prevista"}`,
    plannedFromDate: formatGanttDate(phase.plannedStartDate),
    plannedToDate: formatGanttDate(phase.plannedEndDate),
    realFromDate: formatGanttDate(phase.realStartDate || phase.plannedStartDate),
    realToDate: formatGanttDate(phase.realEndDate || phase.expectedEndDate),
  }));
  return mappedData;
};
