import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles, Typography } from "@material-ui/core";

import StackedColumn from ".";

const data1 = [
  {
    x: "03/05/2021",
    y: 8,
    extraY: null,
  },
  {
    x: "04/05/2021",
    y: 8,
    extraY: 2,
  },
  {
    x: "05/05/2021",
    y: 8,
    extraY: 1,
  },
  {
    x: "06/05/2021",
    y: 8,
    extraY: 1,
  },
  {
    x: "07/05/2021",
    y: 8,
    extraY: 5,
  },
  {
    x: "08/05/2021",
    y: null,
    extraY: null,
  },
  {
    x: "09/05/2021",
    y: null,
    extraY: null,
  },
  {
    x: "10/05/2021",
    y: 8,
    extraY: null,
  },
  {
    x: "11/05/2021",
    y: 8,
    extraY: 2,
  },
  {
    x: "12/05/2021",
    y: 8,
    extraY: 6,
  },
  {
    x: "13/05/2021",
    y: 8,
    extraY: 2,
  },
  {
    x: "14/05/2021",
    y: 8,
    extraY: null,
  },
  {
    x: "15/05/2021",
    y: null,
    extraY: null,
  },
  {
    x: "16/05/2021",
    y: null,
    extraY: null,
  },
  {
    x: "Oggi",
    y: 8,
    extraY: 2,
  },
];

const useStyles = makeStyles({
  noData: {
    width: "100%",
    textAlign: "center",
    paddingBlock: 90,
  },
});

const StackedColumnManager = props => {
  const [data] = useState(props.data);

  const classes = useStyles();

  if (Object.keys(data).length > 0)
    return <StackedColumn data={data} {...props} />;

  return (
    <Typography variant="h6" className={classes.noData}>
      Al momento non sono presenti dati
    </Typography>
  );
};

StackedColumnManager.propTypes = {
  data: PropTypes.array.isRequired,
};

StackedColumnManager.defaultProps = {
  data: data1,
};

export default StackedColumnManager;
