import React, { useContext, useEffect, useState } from "react";
import useFetch from "hooks/useHTTP";

// context
import { AppContext } from "context/AppContext";

import SidebarSmallContent from "pages/SidebarSmallContent";
import SidebarContent from "pages/SidebarContent";

// Api
import { POSTS } from "constants/api";

const Posts = () => {
  const { isFirstColOpen } = useContext(AppContext);
  const { get } = useFetch();
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [forMe, setForMe] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const moreData = () => {
    if (posts.length >= total) {
      setHasMore(false);
      return;
    }
    // Call fetchPosts with incremental page number
    setPage(page + 1);
  };

  const fetchPosts = async () => {
    await get(`${POSTS}?pageNumber=${page}&pageSize=20`)
      .then(res => {
        setTotal(res.data?.count);
        setForMe(res.data?.taggedCount);

        setPosts([...posts, ...res.data.data]);
      })
      .catch(e => console.log("e", e));
  };

  useEffect(() => {
    fetchPosts();
  }, [page]);

  return isFirstColOpen ? (
    <SidebarContent
      posts={posts}
      hasMore={hasMore}
      total={total}
      moreData={moreData}
    />
  ) : (
    <SidebarSmallContent post={total} postForMe={forMe} />
  );
};

export default Posts;
