import React from "react";
import PropTypes from "prop-types";
import PersonInfo from "./PersonInfo";
import { PersonShape } from "../shapes";

const AvatarWrapper = ({
  onEnterCallBack,
  onLeaveCallBack,
  className,
  style,
  showPersonalInfo,
  person,
  avatarSize,
  children,
  phaseName,
  projectName,
}) => {
  return (
    <div
      onMouseEnter={onEnterCallBack}
      onMouseLeave={onLeaveCallBack}
      className={className}
      style={style}
    >
      {children}
      {showPersonalInfo && (
        <PersonInfo
          data={person}
          avatarSize={avatarSize}
          phaseName={phaseName}
          projectName={projectName}
        />
      )}
    </div>
  );
};

AvatarWrapper.propTypes = {
  onEnterCallBack: PropTypes.func.isRequired,
  onLeaveCallBack: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  showPersonalInfo: PropTypes.bool,
  person: PropTypes.shape(PersonShape).isRequired,
  avatarSize: PropTypes.number.isRequired,
  phaseName: PropTypes.string,
  projectName: PropTypes.string,
};

AvatarWrapper.defaultProps = {
  showPersonalInfo: false,
};

export default React.memo(AvatarWrapper);
