import React from "react";
import {
  Divider,
  FormHelperText,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { PropTypes } from "prop-types";

import { RadioControl } from "components/Radio";
import { WILD_BLUE_YONDER } from "constants/colors";
import Avatar from "components/Avatar";
import Date from "components/Date";
import dayjs from "dayjs";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const StyledStack = styled(Stack)(({ theme }) => ({
  direction: "column",
  justifyContent: "center",
  gap: theme.spacing(2),
  maxWidth: 350,
  textAlign: "left",
  margin: "auto",
}));

const StyledProjectName = styled(Typography)(({ theme }) => ({
  variant: "h6",
  color: theme.palette.primary.main,
  fontWeight: "bold",
  textTransform: "capitalize",
  textAlign: "center",
  fontSize: 18,
  display: "-webkit-box",
  WebkitLineClamp: 2,
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
}));

const StyledDate = styled(Date)({
  textTransform: "uppercase",
  color: WILD_BLUE_YONDER,
  fontSize: 12,
});

const StyledRadioControl = styled(RadioControl)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    color: "black",
    fontSize: 16,
  },

  "& :not(.Mui-checked) .MuiIconButton-label > :last-child": {
    backgroundColor: "transparent",
    border: `2px solid ${WILD_BLUE_YONDER}`,
  },

  "& .Mui-checked .MuiIconButton-label > :last-child": {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

const StyledCloseButton = styled(IconButton)({
  position: "absolute",
  top: 40,
  right: 60,

  "& svg": {
    color: "black",
    width: 42,
    height: 42,
  },
});

const BeNotificationModal = ({
  form,
  error,
  user,
  data,
  onCloseWithoutAnswer,
}) => {
  if (!form || !data) return null;

  const answers = JSON.parse(data.answers).map(a => ({
    label: a.value,
    value: a.id.toString(),
  }));

  return (
    <StyledStack>
      <StyledCloseButton onClick={onCloseWithoutAnswer}>
        <CloseRoundedIcon />
      </StyledCloseButton>
      {data.projectId && (
        <>
          <Tooltip title={data.projectName} arrow>
            <StyledProjectName>{data.projectName}</StyledProjectName>
          </Tooltip>

          <Divider />
        </>
      )}

      <Stack margin={0} direction="row" gap={4}>
        <Avatar image={user?.imageUrl} size={64} />
        <div>
          <Typography
            variant="h6"
            color="black"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {`${user.name} ${user.surname}`}
          </Typography>
          {user?.department && (
            <Typography
              variant="caption"
              color={WILD_BLUE_YONDER}
              textTransform="uppercase"
              component="div"
              lineHeight={1.4}
            >
              {user?.department.name}
            </Typography>
          )}
          <StyledDate
            dateString={dayjs().toISOString()}
            format={"D MMM YYYY"}
          />
        </div>
      </Stack>
      <div>
        <Typography
          color="black"
          fontWeight="bold"
          fontSize={18}
          textAlign="center"
          mt={2}
          component="div"
        >
          {data.body}
        </Typography>
      </div>
      <StyledRadioControl
        horizontalDirection={false}
        control={form.control}
        name="beNotificationAnswer"
        defaultValue={""}
        rules={{ required: "Campo obbligatorio" }}
        themeType="light"
        options={answers}
      />
      <FormHelperText error sx={{ mt: 2, textAlign: "center" }}>
        {error}
      </FormHelperText>
    </StyledStack>
  );
};

BeNotificationModal.propTypes = {
  currentValue: PropTypes.number,
  valueRef: PropTypes.object,
  answers: PropTypes.array,
  form: PropTypes.object,
  user: PropTypes.object,
  data: PropTypes.object,
  error: PropTypes.string,
  onClose: PropTypes.func,
  onCloseWithoutAnswer: PropTypes.func,
};

export { BeNotificationModal };
