import React from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import Button from "components/Button";

const ButtonFileUploader = ({
  text,
  startIcon,
  style,
  onFileSelection,
  onFileSelectionError,
  maxFiles, // in bytes
  maxSizePerFile, // in bytes
  accept,
  ...buttonProps
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: onFileSelection,
    onDropRejected: onFileSelectionError,
    disabled: buttonProps.disabled,
    maxFiles: maxFiles || 0,
    maxSize: maxSizePerFile || Infinity,
    multiple: maxFiles !== 1,
    accept,
  });

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      <Button
        variant="contained"
        color="primary"
        startIcon={startIcon}
        style={style}
        {...buttonProps}
      >
        {text}
      </Button>
    </div>
  );
};

ButtonFileUploader.propTypes = {
  text: PropTypes.string.isRequired,
  startIcon: PropTypes.node,
  style: PropTypes.object,
  maxFiles: PropTypes.number,
  maxSizePerFile: PropTypes.number,
  accept: PropTypes.array,
  onFileSelection: PropTypes.func.isRequired,
  onFileSelectionError: PropTypes.func,
};

export default ButtonFileUploader;
