import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// Constants
import { WILD_BLUE_YONDER } from "constants/colors";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  lightWeight: {
    fontWeight: 200,
  },
  value: {
    color: WILD_BLUE_YONDER,
    height: 35,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  actualValue: {
    fontSize: 32,
    lineHeight: 1.15,
    marginRight: 2,
  },
}));

const Skills = ({ maxSkillValue, skillValue }) => {
  const classes = useStyles();
  return (
    <div className={classes.value}>
      <Typography
        variant="h5"
        className={clsx(classes.actualValue, classes.lightWeight)}
      >
        {skillValue}
      </Typography>
      {maxSkillValue >= 0 && (
        <Typography variant="h5" className={classes.lightWeight}>
          /{maxSkillValue}
        </Typography>
      )}
    </div>
  );
};

Skills.defaultProps = {
  skillValue: 0,
};

Skills.propTypes = {
  maxSkillValue: PropTypes.number,
  skillValue: PropTypes.number,
};

export default Skills;
