import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

// Material UI components
import { Button as ButtonMUI } from "@material-ui/core";
import { GHOST_WHITE, SPACE_CADET, WHITE } from "constants/colors";
import SvgIcon from "components/SvgIcon";

const useStyles = makeStyles(theme => ({
  button: ({ themeType, color, variant }) => ({
    color:
      themeType === "dark"
        ? theme.palette.componentsBackground.light
        : variant === "outlined" &&
          (color === "primary"
            ? theme.palette.primary.main
            : theme.palette.error.main),
    "&:hover": {
      backgroundColor:
        variant === "outlined"
          ? themeType === "dark"
            ? SPACE_CADET
            : GHOST_WHITE
          : undefined,
    },
  }),
}));

export default function Button({ className, themeType, icon, size, ...other }) {
  const classes = useStyles({
    themeType,
    color: other.color,
    variant: other.variant,
  });

  return (
    <ButtonMUI {...other} className={clsx(classes.button, className)}>
      {icon ? (
        <SvgIcon
          icon={icon}
          color={
            other.variant === "outlined"
              ? other.disabled
                ? "disabled"
                : other.color
              : WHITE
          }
          width={size && size.width}
          height={size && size.height}
        />
      ) : (
        other.children
      )}
    </ButtonMUI>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  themeType: PropTypes.string,
  icon: PropTypes.node,
  size: PropTypes.object,
};

Button.defaultProps = {
  themeType: "dark",
};
