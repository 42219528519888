import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { WILD_BLUE_YONDER, SPACE_CADET, DARK_ORANGE } from "constants/colors";
import { ganttLabelStyleTypes } from "utils/enums/gantt";

export const PLANNED_COLOR = "#FFFFFF";
export const REAL_COLOR = "#93FFA9";
export const NOT_STARTED_COLOR =  "rgba(255, 255,255, 0.1)";
export const STACK_BG_COLOR = "rgba(255,255,255,0.16)";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    height: "100%",
  },
  stackLabelWrapper: {
    padding: "8px 20px",
    borderRadius: 8,
    width: 170,
    height: 60,
    backgroundColor: STACK_BG_COLOR,
  },
  stackTitle: {
    fontSize: 16,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  stackSubtitle: {
    fontSize: 9,
  },
  stackText: {
    fontSize: 10,
  },
}));

const Gantt = ({
  data,
  id,
  color,
  showStackLabels,
  showZoom,
  showTodayMarker,
  labelStyle,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return;

    const chart = am4core.create(`div${id}`, am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0;

    chart.paddingLeft = 30;
    chart.dateFormatter.dateFormat = "yyyy-MM-dd";
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

    const colorSet = new am4core.ColorSet();
    colorSet.saturation = 0.4;

    chart.data = data;

    if (showZoom) {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.thumb.minWidth = 50;
      chart.scrollbarX.thumb.height = 13;
      chart.scrollbarX.maxHeight = 10;
    }

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.labels.template.disabled = !showStackLabels;
    categoryAxis.renderer.labels.template.fill = "white";
    categoryAxis.renderer.labels.template.html = `
    <div class="${classes.stackLabelWrapper}">
    <div class="${classes.stackTitle}" title="{name}">{name}</div>
    <div class="${classes.stackSubtitle}" title="{realToDate}">{realToDate}</div>
    <div class="${classes.stackText}">END DATE</div>
    </div>`;
    categoryAxis.renderer.grid.template.stroke = "white";
    categoryAxis.renderer.grid.template.strokeWidth = 1;
    categoryAxis.renderer.grid.template.strokeDasharray =
      data.length > 1 ? 4 : 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dateFormatter.dateFormat = "yyyy-MM-dd";
    dateAxis.baseInterval = { count: 30, timeUnit: "minute" };
    dateAxis.renderer.minGridDistance = 70;
    dateAxis.strictMinMax = true;
    dateAxis.renderer.tooltipLocation = 0;
    dateAxis.renderer.opposite = true;
    dateAxis.renderer.labels.template.fill = am4core.color(color);
    dateAxis.renderer.grid.template.stroke = am4core.color(color);
    dateAxis.renderer.grid.template.strokeWidth = 1.5;

    if (showTodayMarker) {
      const range = dateAxis.axisRanges.create();
      range.date = new Date();
      range.grid.stroke = am4core.color(DARK_ORANGE);
      range.grid.strokeWidth = 4;
      range.grid.strokeOpacity = 1;
      range.grid.tooltip = new am4core.Tooltip();
      range.grid.tooltipText = "Oggi";
      range.grid.tooltip.getFillFromObject = false;
      range.grid.tooltip.background.fill = am4core.color(DARK_ORANGE);
    }

    const createSeries = (fromField, toField) => {
      const isPlanned = fromField === "plannedFromDate";
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(80);
      series.columns.template.height = am4core.percent(15);
      series.columns.template.column.cornerRadiusTopLeft = 10;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.cornerRadiusBottomLeft = 10;
      series.columns.template.column.cornerRadiusBottomRight = 10;
      series.columns.template.tooltipHTML = `<div style="color:${WILD_BLUE_YONDER}; display: inline-block;">{${
        isPlanned ? "plannedFromDateName" : "realFromDateName"
      }}</div> <b>{${
        isPlanned ? "plannedFromDate" : "realFromDate"
      }}</b> <div style="color:${WILD_BLUE_YONDER}; display: inline-block"> &nbsp-&nbsp {${
        isPlanned ? "plannedToDateName" : "realToDateName"
      }}</div>&nbsp <b>{${isPlanned ? "plannedToDate" : "realToDate"}}</b>`;
      series.columns.template.tooltipY = am4core.percent(0);
      series.tooltip.background.stroke = am4core.color(WILD_BLUE_YONDER);
      series.tooltip.pointerOrientation = "down";

      series.dataFields.openDateX = fromField;
      series.dataFields.dateX = toField;
      series.dataFields.categoryY = "name";
      series.columns.template.propertyFields.fill = isPlanned
        ? "plannedColor"
        : "realColor";
      series.columns.template.propertyFields.stroke = isPlanned
        ? "plannedColor"
        : "realColor";
      series.columns.template.strokeOpacity = 1;
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color(SPACE_CADET);
      series.tooltip.background.stroke = am4core.color(SPACE_CADET);

      // Labels (series bullets)
      const bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.label.text = `{${
        isPlanned ? "plannedName" : "realName"
      }}`;

      bullet.label.truncate = false;
      bullet.label.hideOversized = false;
      bullet.label.fontSize = 10;

      if (labelStyle === ganttLabelStyleTypes.CLASSIC) {
        bullet.label.fill = am4core.color(color);
        bullet.label.dy = -20;
      } else if (labelStyle === ganttLabelStyleTypes.COMPACT) {
        bullet.label.fill = isPlanned ? PLANNED_COLOR : REAL_COLOR;
        bullet.label.dy = -10;
      }
    };

    // Create "planned" and "real" dates series
    createSeries("plannedFromDate", "plannedToDate");
    createSeries("realFromDate", "realToDate");
  }, [data]);

  return <div className={classes.container} id={`div${id}`} />;
};

Gantt.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  theme: PropTypes.array,
  legend: PropTypes.bool,
  color: PropTypes.string,
  showStackLabels: PropTypes.bool,
  showZoom: PropTypes.bool,
  showTodayMarker: PropTypes.bool,
  labelStyle: PropTypes.oneOf(Object.values(ganttLabelStyleTypes)),
};

Gantt.defaultProps = {
  legend: false,
  color: WILD_BLUE_YONDER,
  showStackLabels: false,
  showZoom: false,
  showTodayMaker: false,
  labelStyle: ganttLabelStyleTypes.CLASSIC,
};

export default Gantt;
