import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material";
import BlueIcon from "components/BlueIcon";
import {
  DARK_ORANGE,
  HARVARD_CRIMSON,
  MALACHITE,
  WILD_BLUE_YONDER,
} from "constants/colors";
import { Link } from "components/Link/Link";
import {
  brickCostTypes,
  brickTypes,
  completionStatusTypes,
} from "utils/enums/bricks";

import calendarIcon from "assets/calendar.svg";
import Date from "components/Date";
import { timeToHours } from "utils";

import EditRoundedIcon from "@mui/icons-material/EditRounded";
import NumberFormat from "react-number-format";

import { Reputation } from "components/KpiBe/Reputation";

const StyledButton = styled(Button)({ color: "white", borderColor: "white" });

const StyledCompletion = styled(Typography, {
  shouldForwardProp: prop => !["dotColor"].includes(prop),
})(({ dotColor }) => ({
  textTransform: "uppercase",
  transform: "translateY(6px)",
  "&:before": {
    content: '""',
    display: "inline-block",
    width: 10,
    height: 10,
    backgroundColor: dotColor,
    borderRadius: "100%",
    marginRight: 5,
    lineHeight: 1,
  },
}));

const SavingEffortTypography = styled(Typography)({
  fontSize: 10,
  lineHeight: 1.2,
  marginTop: 2,
});

const StyledIconButton = styled(Button)({
  color: "white",
  borderColor: "white",
  width: 25,
  height: 25,
  minWidth: "unset",
  "& svg": {
    width: 12,
    height: 12,
  },
});

const StyledCalendarIcon = styled("img")({
  width: 10,
  aspectRatio: "1/1",
  marginRight: 4,
});

const completionStatusColorMap = {
  [completionStatusTypes.GREEN]: MALACHITE,
  [completionStatusTypes.ORANGE]: DARK_ORANGE,
  [completionStatusTypes.RED]: HARVARD_CRIMSON,
};

const BrickHead = ({ data }) => {
  const phaseManagerName = `${data?.phaseManager?.name || ""} ${
    data?.phaseManager?.surname || ""
  }`.trim();

  const renderCompletion = (value, completionStatus) => {
    if (!Number.isFinite(value)) return null;
    const rounded = Math.round(value);
    let color = completionStatusColorMap[completionStatus] || HARVARD_CRIMSON;

    return (
      <StyledCompletion variant="caption" component="p" dotColor={color}>
        {rounded}% completato
      </StyledCompletion>
    );
  };

  return (
    <Stack direction="row" gap={2}>
      <Box mt={0.5}>
        <BlueIcon width={"12px"} height={"12px"} borderRadius={"3px"} />
      </Box>
      <div>
        <Typography variant="body2" mb={1} fontSize={16} fontWeight="bold">
          <Link to={`/brick/${data.id}`}>{data?.name}</Link>
        </Typography>
        <Typography
          variant="caption"
          color={WILD_BLUE_YONDER}
          my={0.4}
          component="p"
        >
          {data?.phaseName}
        </Typography>
        <Typography
          variant="caption"
          color={WILD_BLUE_YONDER}
          my={0.4}
          component="p"
        >
          {phaseManagerName}
        </Typography>

        {renderCompletion(data?.completionPercentage, data?.completionStatus)}
      </div>
    </Stack>
  );
};

BrickHead.propTypes = {
  data: PropTypes.object,
};

const BrickDetails = ({
  bricks = [],
  onEstimationEdit,
  onExpectedEndDateEdit,
  onCreateReport,
  onRequestClose,
  isCurrentUserDashboard,
}) => {
  return bricks.map((b, idx) => {
    const buttonsDisabled = b.status >= brickTypes.CLOSED;
    const estimationCompletionInHours = timeToHours(b.estimationCompletion);
    const hasEstimationCompletion =
      b.estimationCompletionInDays + estimationCompletionInHours >= 0;

    const estimationCompletionString = hasEstimationCompletion
      ? `${b.estimationCompletionInDays}gg (${timeToHours(
          b.estimationCompletion
        )}h)`
      : "";

    const renderSavingEffort = (cost, costInHours = 0) => {
      let string = "";
      let color = "white";
      if (cost <= brickCostTypes.SAVING) {
        string = "Saving";
        color = MALACHITE;
      }
      if (cost > brickCostTypes.SAVING && cost < brickCostTypes.IN_LINE) {
        string = "In Linea";
        color = WILD_BLUE_YONDER;
      }
      if (cost >= brickCostTypes.IN_LINE && cost < brickCostTypes.NOT_IN_LINE) {
        string = "Extracosto";
        color = DARK_ORANGE;
      }
      if (cost >= brickCostTypes.NOT_IN_LINE) {
        string = "Extracosto";
        color = HARVARD_CRIMSON;
      }

      return (
        <SavingEffortTypography color={color}>
          {`${string} `}
          <NumberFormat
            value={cost}
            decimalScale={1}
            fixedDecimalScale
            displayType="text"
            suffix="%"
            decimalSeparator=","
            {...(costInHours > 0 && { prefix: "+" })}
          />
          &nbsp; (
          {
            <NumberFormat
              value={costInHours}
              decimalScale={0}
              displayType="text"
              suffix="h"
              {...(costInHours > 0 && { prefix: "+" })}
            />
          }
          )
        </SavingEffortTypography>
      );
    };

    return (
      <Grid
        key={b.id}
        container
        sx={{
          py: 1,
          ...(idx < bricks.length - 1 && {
            borderBottom: "1px solid rgba(255, 255, 255, .2);",
          }),
        }}
        gap={4}
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item xs={12} sm={4.5} height="100%">
          <BrickHead data={b} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack direction="row" alignItems="center" gap={2} minHeight={40}>
            {buttonsDisabled ? (
              <Reputation widthIcon={18} starsHeight={18} />
            ) : (
              <>
                {isCurrentUserDashboard && (
                  <StyledIconButton
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => onExpectedEndDateEdit(b)}
                    disabled={buttonsDisabled}
                  >
                    <EditRoundedIcon />
                  </StyledIconButton>
                )}
                <Box flexGrow={1}>
                  <Typography variant="caption" component="div">
                    data fine prevista
                  </Typography>
                  <Typography variant="caption" color={WILD_BLUE_YONDER}>
                    <StyledCalendarIcon src={calendarIcon} />
                    <Date dateString={b.expectedEndDate || b.plannedEndDate} />
                  </Typography>
                </Box>
              </>
            )}
          </Stack>
          <Stack mt={1} direction="row" alignItems="center" gap={2}>
            {isCurrentUserDashboard && !buttonsDisabled && (
              <>
                <StyledIconButton
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => onEstimationEdit(b)}
                  disabled={buttonsDisabled}
                >
                  <EditRoundedIcon />
                </StyledIconButton>
              </>
            )}
            <Box flexGrow={1}>
              <Typography variant="caption" component="div">
                stima a finire
              </Typography>
              {hasEstimationCompletion && !b.requestValidationDateTime && (
                <Typography variant="caption" color={WILD_BLUE_YONDER}>
                  {estimationCompletionString}
                </Typography>
              )}
              {b.requestValidationDateTime && (
                <Typography variant="caption" color={WILD_BLUE_YONDER}>
                  <StyledCalendarIcon src={calendarIcon} />
                  Chiuso il <Date dateString={b.requestValidationDateTime} />
                </Typography>
              )}
            </Box>
          </Stack>
          {renderSavingEffort(b.cost, b.costInHours)}
        </Grid>
        <Grid item xs={12} sm={3.5}>
          {isCurrentUserDashboard && (
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                sx={{ mb: 2 }}
                onClick={() => onCreateReport({ brick: b })}
                disabled={buttonsDisabled}
              >
                <Typography variant="button-small">Nuovo report</Typography>
              </Button>

              <StyledButton
                variant="outlined"
                color="primary"
                size="small"
                fullWidth
                disabled={buttonsDisabled}
                onClick={() => onRequestClose(b)}
                sx={{ opacity: buttonsDisabled ? 0.25 : 1 }}
              >
                <Typography variant="button-small">
                  Richiesta chiusura
                </Typography>
              </StyledButton>
            </>
          )}
        </Grid>
      </Grid>
    );
  });
};

BrickDetails.propTypes = {
  bricks: PropTypes.array,
  onEstimationEdit: PropTypes.func,
  onExpectedEndDateEdit: PropTypes.func,
  onCreateReport: PropTypes.func,
  onRequestClose: PropTypes.func,
  isCurrentUserDashboard: PropTypes.bool,
};

export { BrickDetails };
