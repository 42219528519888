import { FormHelperText } from "@mui/material";
import { Upload } from "components/upload";
import { useUpload } from "hooks/use-upload";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import PropTypes from "prop-types";

const RHFUpload = ({ name, multiple, helperText, ...other }) => {
  const formContext = useFormContext();

  const upload = useUpload({
    formContext,
    name,
    multiple,
  });

  const { control } = formContext;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Upload
          multiple={multiple}
          files={field.value}
          error={!!error}
          helperText={
            (!!error || helperText) && (
              <FormHelperText error={!!error} sx={{ px: 2 }}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )
          }
          {...other}
          onDrop={other.onDrop || upload.onDrop}
          onRemove={other.onRemove || upload.onRemove}
          onRemoveAll={other.onRemoveAll || upload.onRemoveAll}
        />
      )}
    />
  );
};

RHFUpload.propTypes = {
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
};

export { RHFUpload };
