import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import Loading from "components/Loading";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  container: ({ height }) => ({
    width: "100%",
    height: height || 300,
    position: "relative",
  }),
  imageWrapper: {
    height: "100%",
    display: ({ isCover, loading }) =>
      loading ? "none" : isCover ? "block" : "flex",
  },
  textWrapper: {
    width: "100%",
    height: "100%",
    backdropFilter: props =>
      props.blurred || !props.imageUrl ? "blur(8px)" : "none",
    borderRadius: "0.7rem",
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

const LoadingImage = ({
  children,
  height,
  imageUrl,
  placeholder,
  blurred,
  className,
  imageSize = "cover",
}) => {
  const isCover = imageSize === "cover";

  const [loading, setLoading] = useState(true);
  const classes = useStyles({ height, loading, blurred, imageUrl, isCover });

  const imageStyle = {
    ...(isCover
      ? { width: "100%", height: "100%" }
      : { maxWidth: "100%", maxHeight: "100%", margin: "auto" }),
  };

  return (
    <div className={clsx(classes.container, className)}>
      {loading && <Loading />}
      <div className={classes.imageWrapper}>
        <img
          src={imageUrl || placeholder}
          onError={e => {
            e.target.onerror = null;
            e.target.src = placeholder;
          }}
          onLoad={() => setLoading(false)}
          style={{ ...imageStyle, borderRadius: "0.5rem" }}
        />
        <div className={classes.textWrapper}>{children}</div>
      </div>
    </div>
  );
};

LoadingImage.propTypes = {
  children: PropTypes.node,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageUrl: PropTypes.string,
  placeholder: PropTypes.node,
  blurred: PropTypes.bool,
  className: PropTypes.string,
  imageSize: PropTypes.oneOf(["cover", "contain"]),
};

LoadingImage.defaultProps = {
  blurred: true,
};

export default LoadingImage;
