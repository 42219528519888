import React, { useReducer, useState, useEffect } from "react";
import clsx from "clsx";
import useFetch from "hooks/useHTTP";
import { useHistory, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Button } from "@material-ui/core";
import ConditionalCheck from "./Components/ConditionalCheck";

// Custom components
import Logo from "components/Logo";
import Layout from "components/Layout";
import Loading from "components/Loading";
import TextField from "components/TextField";

// icons
import { ReactComponent as LockOpenIcon } from "assets/lock.svg";
import { ReactComponent as VisibilityIcon } from "assets/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "assets/visibilityOff.svg";
import { RESET_PASSWORD, SET_PASSWORD } from "constants/api";
import { PASSWWORD_CHANGED, WALKTHROUGH } from "constants/routes";
import { WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    flexDirection: "column",
  },
  logoWrapper: {
    maxWidth: "20%",
  },
  title: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(1),
    alignSelf: "center",
  },
  subtitle: {
    color: WILD_BLUE_YONDER,
  },
  form: {
    display: "flex",
    width: "20%",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: theme.spacing(5),
  },
  formItem: {
    minHeight: 54,
  },
  criteriaLabel: {
    color: WILD_BLUE_YONDER,
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
    "&:focus": {
      outline: "none",
    },
  },
  iconButton: {
    color: "white",
    width: "100%",
  },
  marginTop: {
    marginTop: theme.spacing(7),
  },
  check: {
    width: "3%",
    marginRight: theme.spacing(0.5),

    "& path": {
      fill: theme.palette.componentsBackground.main,
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
  },
}));

const initialState = {
  newPassword: "",
  confirmPassword: "",
  showPassword: false,
  showConfirmPassword: false,
};

function reducer(state, action) {
  return { ...state, [action.type]: action.value };
}

function ChangePassword() {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const { put, loading } = useFetch();
  const [state, setState] = useReducer(reducer, initialState);
  const [isValid, setIsValid] = useState(false);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    setIsValid(
      state.newPassword.length >= 8 &&
        /\d/.test(state.newPassword) &&
        /[a-z]/.test(state.newPassword) &&
        /[A-Z]/.test(state.newPassword)
    );
  }, [state.newPassword]);

  const query = new URLSearchParams(search);
  const userId = query.get("userId");
  const verificationCode = query.get("code");

  const canAccede = () => {
    const checkPasswordEquality =
      state.newPassword == state.confirmPassword &&
      state.newPassword.length !== 0;
    return checkPasswordEquality && isValid;
  };

  const resetOrSetPassword = async () => {
    if (userId || verificationCode) {
      // Reset password
      await put(RESET_PASSWORD, {
        userId,
        verificationCode,
        newPassword: state.newPassword,
      }).then(res => {
        if (res.ok) {
          history.push(PASSWWORD_CHANGED);
        }
      });
    } else {
      // Set password
      await put(SET_PASSWORD, {
        password: state.newPassword,
      }).then(res => {
        if (res.ok) {
          history.push(WALKTHROUGH);
        }
      });
    }
  };

  return (
    <Layout showHeader={false} showFirstCol={false} showSecondCol={false}>
      <div className={classes.wrapper}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={classes.logoWrapper}>
              <Logo isBlue className={classes.logoWrapper} />
            </div>
            <Typography variant="h5" className={classes.title}>
              {userId || verificationCode
                ? "Crea nuova password"
                : "Cambio password obbligatorio"}
            </Typography>
            <Typography variant="subtitle2" className={classes.subtitle}>
              {userId || verificationCode
                ? "Scegli una nuova password per accedere"
                : "Per accedere al sistema è necessario cambiare la password associata al tuo account"}
            </Typography>
            <form className={classes.form} noValidate autoComplete="off">
              <div style={{ marginBottom: 30 }}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Criteri della nuova password
                </Typography>
                <div className={classes.listItem}>
                  <ConditionalCheck
                    isOk={state.newPassword.length >= 8}
                    className={classes.check}
                  />
                  <Typography
                    variant="subtitle1"
                    className={classes.criteriaLabel}
                  >
                    8 caratteri
                  </Typography>
                </div>
                <div className={classes.listItem}>
                  <ConditionalCheck
                    isOk={/\d/.test(state.newPassword)}
                    className={classes.check}
                  />
                  <Typography
                    variant="subtitle1"
                    className={classes.criteriaLabel}
                  >
                    Almeno un numero
                  </Typography>
                </div>
                <div className={classes.listItem}>
                  <ConditionalCheck
                    isOk={/[A-Z]/.test(state.newPassword)}
                    className={classes.check}
                  />
                  <Typography
                    variant="subtitle1"
                    className={classes.criteriaLabel}
                  >
                    Almeno una maiuscola
                  </Typography>
                </div>
                <div className={classes.listItem}>
                  <ConditionalCheck
                    isOk={/[a-z]/.test(state.newPassword)}
                    className={classes.check}
                  />
                  <Typography
                    variant="subtitle1"
                    className={classes.criteriaLabel}
                  >
                    Almeno una minuscola
                  </Typography>
                </div>
              </div>

              <TextField
                className={clsx(classes.margin, classes.formItem)}
                type={state.showPassword ? "text" : "password"}
                label="NUOVA PASSWORD"
                value={state.newPassword}
                onChange={e =>
                  setState({ type: "newPassword", value: e.target.value })
                }
                icon={<LockOpenIcon />}
                endIcon={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setState({
                        type: "showPassword",
                        value: !state.showPassword,
                      })
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    className={classes.iconButton}
                  >
                    {state.showPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                }
              />
              <TextField
                className={clsx(classes.margin, classes.formItem)}
                type={state.showConfirmPassword ? "text" : "password"}
                label="CONFERMA NUOVA PASSWORD"
                value={state.confirmPassword}
                onChange={e =>
                  setState({ type: "confirmPassword", value: e.target.value })
                }
                icon={<LockOpenIcon />}
                endIcon={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setState({
                        type: "showConfirmPassword",
                        value: !state.showConfirmPassword,
                      })
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    className={classes.iconButton}
                  >
                    {state.showConfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                }
                disabled={!isValid}
              />
              <div className={classes.listItem}>
                <ConditionalCheck
                  isOk={
                    state.newPassword == state.confirmPassword &&
                    state.newPassword.length !== 0
                  }
                  className={classes.check}
                />
                <Typography
                  variant="subtitle1"
                  className={classes.criteriaLabel}
                >
                  Password corrispondente
                </Typography>
              </div>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.marginTop}
                disabled={!canAccede()}
                onClick={resetOrSetPassword}
              >
                {userId || verificationCode ? "CONFERMA" : "ACCEDI"}
              </Button>
            </form>
          </>
        )}
      </div>
    </Layout>
  );
}

export default ChangePassword;
