import React from "react";
import PropTypes from "prop-types";

//Components
import Sentiment from "components/KpiBe/Sentiment";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "100%",
    display: "flex",
    float: "right",
    flexDirection: "row",
  },
  column: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  number: {
    width: "100%",
    textAlign: "right",
    color: WILD_BLUE_YONDER,
    fontSize: 32,
    marginTop: 10,
    fontWeight: "bold",
    marginRight: "7px",
    display: "flex",
    alignItems: "flex-end",
  },
  decimalNumber: {
    color: WILD_BLUE_YONDER,
    fontSize: 22.5,
    marginBottom: "3px",
  },
  reputation: {
    marginBottom: "10px",
    marginRight: "5px",
  },
}));

const SentimentWidget = ({ value }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.row}>
            <Sentiment sentiment={value} width={43} height={43} />
          </div>
        </div>
      </div>
    </div>
  );
};

SentimentWidget.defaultProps = {
  value: 0,
};

SentimentWidget.propTypes = {
  value: PropTypes.number,
};

export default SentimentWidget;
