import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

// Material
import { makeStyles } from "@material-ui/core/styles";

//Hooks
import useFetch from "hooks/useHTTP";

// Components
import Loading from "components/Loading";
import SvgIcon from "components/SvgIcon";

import { PROJECT } from "constants/api";

// Charts and Themes
import ChartCard from "components/ChartCard";
import GanttManager from "components/Charts/Gantt/manager";
import { ganttLabelStyleTypes } from "utils/enums/gantt";
import { Card, Popover, Typography } from "@material-ui/core";
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as GrabIcon } from "assets/gantt-action-grab.svg";
import { ReactComponent as ZoomOutIcon } from "assets/gantt-action-zoomout.svg";
import { ReactComponent as PanIcon } from "assets/gantt-action-pan.svg";
import { mapGanttData } from "./utils";

const useStyles = makeStyles(() => ({
  chartContainer: {
    height: "100%",
    width: "100%",
  },
  select: {
    marginRight: "10px",
  },
  customSelect: {
    display: "flex",
    maxWidth: "639px",
  },
  closeIcon: {
    cursor: "pointer",
    width: 16,
    height: 16,
  },
  detailHeader: {
    display: "flex",
    padding: "20px",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#1E2840",
    justifyContent: "space-between",
  },
  closeDetailIcon: {
    height: "100%",
    marginRight: "0px",
    marginTop: "auto",
  },
  detailRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "20px",
  },
  detailColumn: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  detailBody: {
    marginLeft: "10px",
    marginRight: "20px",
  },
  loadingWrapper: {
    height: "300px",
  },
  legend: {
    display: "flex",
    float: "right",
  },
  legendIcon: {
    maxWidth: "100%",
    height: 15,
    marginTop: 0,
    marginBottom: 30,
  },
  zoomInfoIcon: {
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    fontSize: 20,

    "& svg": { marginLeft: 10, cursor: "pointer", marginBottom: 2 },
  },
  zoomInfoWrapper: {
    width: 380,
    padding: "16px 20px",
  },
  infoItem: {
    marginBlock: 8,
    fontSize: 14,
    color: "#808080",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  infoItemIcon: {
    width: 25,
    height: 25,
    marginRight: 18,
    marginLeft: 8,
  },
}));

const STACK_HEIGHT = 75;
const GANTT_OFFSET = 85 + 60; // Offset to remove from ChartCard styles and then here;

const Gantt = () => {
  const classes = useStyles();
  const { get } = useFetch();

  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingGantt, setLoadingGantt] = useState(false);
  const [ganttData, setGanttData] = useState(null);

  const { projectId } = useParams();

  useEffect(() => {
    fetchGanttData();
  }, []);

  // APIs
  const fetchGanttData = async () => {
    setLoadingGantt(true);
    try {
      const res = await get(`${PROJECT}/${projectId}/gantt`);
      const mappedData = mapGanttData(res.data);
      setGanttData(mappedData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingGantt(false);
    }
  };

  if (loadingGantt)
    return (
      <Loading
        showWrapper={false}
        animationStyle={{ height: 80, width: 80, marginTop: -100 }}
      />
    );

  if (!ganttData) return null;

  const chartWrapperHeight = `${
    GANTT_OFFSET + ganttData.length * STACK_HEIGHT
  }px`;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const openZoomInfo = event => setAnchorEl(event.currentTarget);
  const closeZoomInfo = () => setAnchorEl(null);

  return (
    <div className={classes.chartContainer}>
      <Typography className={classes.zoomInfoIcon}>
        Zoom Gantt
        <InfoIcon onClick={openZoomInfo} />
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeZoomInfo}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ transform: "translateY(8px)", maxHeight: "auto" }}
      >
        <Card className={classes.zoomInfoWrapper}>
          <div className={classes.infoItem}>
            <SvgIcon
              className={classes.infoItemIcon}
              icon={<GrabIcon />}
              color="#808080"
            />
            Per zoomare la linea temporale utilizza la barra dai suoi estremi,
            trascinando verso il centro le icone.
          </div>
          <div className={classes.infoItem}>
            <SvgIcon
              className={classes.infoItemIcon}
              icon={<ZoomOutIcon />}
              color="#808080"
            />
            Per tornare rapidamente indietro usa l&apos;icona &quot;zoom
            out&quot;, cliccandoci sopra.
          </div>
          <div className={classes.infoItem}>
            <SvgIcon
              className={classes.infoItemIcon}
              icon={<PanIcon />}
              color="#808080"
            />
            Per scorrere il Gantt orizzontalmente da sinistra a destra e
            viceversa, puoi cliccare e trascinare la barra dello zoom.
          </div>
        </Card>
      </Popover>
      <ChartCard title="" height={chartWrapperHeight}>
        <GanttManager
          id={`project-${projectId}-gantt`}
          data={ganttData}
          showStackLabels
          showZoom
          showTodayMarker
          labelStyle={ganttLabelStyleTypes.COMPACT}
        />
      </ChartCard>
    </div>
  );
};

export default Gantt;
