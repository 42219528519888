import React from "react";
import PropTypes from "prop-types";

// Context
import ManagingDrawersProvider from "context/ManagingDrawersContext";

const withManagingDrawers = WrappedComponent => (
  <ManagingDrawersProvider>
    <WrappedComponent />
  </ManagingDrawersProvider>
);

withManagingDrawers.propTypes = {
  WrappedComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default withManagingDrawers;
