import { useState } from "react";

const useLightBox = (defaultLightbox = {}) => {
  // State
  const [lightbox, setLightbox] = useState(defaultLightbox);

  // Functions
  const handleLightBoxClose = () => {
    setLightbox(null);
  };

  return {
    lightbox,
    setLightbox,
    handleLightBoxClose,
  };
};

export default useLightBox;
