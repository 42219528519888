/* eslint-disable max-len */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import {
  WILD_BLUE_YONDER,
  HARVARD_CRIMSON,
  PACIFIC_BLU,
  OXFORD_BLUE,
} from "constants/colors";

import { COLORS1 } from "./themes";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    height: "100%",
  },
}));

const StackedColumn = props => {
  const classes = useStyles();
  const { data, id, columnWidth, yMax, theme, scrollBars } = props;

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return;

    var chart = am4core.create(`div${id}`, am4charts.XYChart);
    chart.data = data;
    chart.colors.list = theme;

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "x";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.fill = am4core.color(props.color);
    categoryAxis.renderer.labels.template.maxWidth = 40;
    categoryAxis.renderer.minGridDistance = 30;

    categoryAxis.events.on("sizechanged", function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -45;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = yMax;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;
    valueAxis.renderer.labels.template.fill = am4core.color(props.color);
    valueAxis.renderer.grid.template.stroke = am4core.color(props.color);
    valueAxis.renderer.grid.template.strokeWidth = 3;
    valueAxis.renderer.minGridDistance = 30;

    var series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.width = am4core.percent(columnWidth);
    series1.dataFields.categoryX = "x";
    series1.dataFields.valueY = "y";
    series1.stacked = true;
    series1.columns.template.tooltipText = "{y}H";
    series1.tooltip.background.fill = am4core.color(OXFORD_BLUE);
    series1.tooltip.background.stroke = am4core.color(OXFORD_BLUE);
    series1.columns.template.stroke = am4core.color(PACIFIC_BLU);

    series1.columns.template.adapter.add("fill", function (fill, target) {
      return new Date(target.dataItem._dataContext.dayToISOString).setHours(
        0,
        0,
        0,
        0
      ) <= new Date().setHours(0, 0, 0, 0)
        ? am4core.color(PACIFIC_BLU)
        : am4core.color(PACIFIC_BLU).lighten(0.8);
    });

    series1.columns.template.adapter.add(
      "fillOpacity",
      function (fill, target) {
        return new Date(target.dataItem._dataContext.dayToISOString).setHours(
          0,
          0,
          0,
          0
        ) <= new Date().setHours(0, 0, 0, 0)
          ? 1
          : 0.5;
      }
    );

    var series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.columns.template.width = am4core.percent(columnWidth);
    series2.dataFields.categoryX = "x";
    series2.dataFields.valueY = "extraY";
    series2.stacked = true;
    series2.columns.template.tooltipText = "{extraY}H";
    series2.tooltip.background.fill = am4core.color(HARVARD_CRIMSON);
    series2.tooltip.background.stroke = am4core.color(HARVARD_CRIMSON);

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;
    chart.cursor.lineX.opacity = 0;

    function customizeGrip(grip) {
      grip.icon.disabled = true;
    }
    if (scrollBars) {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      chart.scrollbarX.thumb.minWidth = 50;
      chart.scrollbarX.maxHeight = 10;

      chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarY.maxHeight = 10;

      customizeGrip(chart.scrollbarX.startGrip);
      customizeGrip(chart.scrollbarX.endGrip);
      customizeGrip(chart.scrollbarY.startGrip);
      customizeGrip(chart.scrollbarY.endGrip);
    }
  }, [data]);

  return <div className={classes.container} id={`div${id}`} />;
};

StackedColumn.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  theme: PropTypes.array,
  legend: PropTypes.bool,
  color: PropTypes.string,
  columnWidth: PropTypes.number,
  yMax: PropTypes.number,
  scrollBars: PropTypes.bool,
};

StackedColumn.defaultProps = {
  legend: false,
  color: WILD_BLUE_YONDER,
  columnWidth: 60,
  yMax: 16,
  theme: COLORS1,
  scrollBars: false,
};

export default StackedColumn;
