import { BRICKS_SEARCH, PROJECT_SEARCH, USERS_SEARCH } from "constants/api";

export const searchCardTypes = {
  CHECKBOX: 0,
  RANGE: 1,
  STAR: 2,
  RADIO: 3,
};

export const searchFilterContext = {
  PROJECTS: "projects",
  BRICK: "bricks",
  USERS: "users",
};

export const searchTimeValues = {
  NO_FILTERS: "all",
  FISCAL_YEAR: "fiscalYear",
  INTERVAL: "interval",
};

export const searchTimeIntervalValues = {
  LAST_SIX_MONTHS: "0",
  LAST_THREE_MONTHS: "1",
  YEAR_TO_DATE: "2",
  YEAR_TO_MONTH: "3",
  MONTH_TO_DATE: "4",
};

export const searchUserRoles = {
  DEPARTMENT_OWNER: 1,
  PHASE_OWNER: 2,
  PROJECT_OWNER: 3,
};

export const searchEndpoints = {
  [searchFilterContext.PROJECTS]: PROJECT_SEARCH,
  [searchFilterContext.BRICK]: BRICKS_SEARCH,
  [searchFilterContext.USERS]: USERS_SEARCH,
};
