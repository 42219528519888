import React from "react";
import PropTypes from "prop-types";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { WHITE, WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles({
  root: ({ height, width }) => ({
    flexGrow: 1,
    height: height,
    width: width,
    borderRadius: 40,
  }),
  value: ({ value }) => ({
    fontSize: 10,
    left: `${value}%`,
    top: -3,
    position: "relative",
    transform: "translateX(-50%)",
    display: "inline-block",
  }),
});

const CustomizedProgressBar = ({
  height,
  value,
  width,
  showValue,
  backgroundColor,
}) => {
  const classes = useStyles({ height, width, value });

  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: "100%",
      width: "100%",
      borderRadius: 40,
    },
    colorPrimary: {
      backgroundColor: backgroundColor || WILD_BLUE_YONDER,
    },
    bar: {
      borderRadius: 5,
      backgroundColor: WHITE,
    },
  }))(LinearProgress);

  return (
    <div className={classes.root}>
      <BorderLinearProgress variant="determinate" value={value} />
      {showValue && <span className={classes.value}>{value}%</span>}
    </div>
  );
};
CustomizedProgressBar.defaultProps = {
  height: 8,
  width: 180,
};

CustomizedProgressBar.propTypes = {
  height: PropTypes.number,
  value: PropTypes.number,
  width: PropTypes.number,
  showValue: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

export default CustomizedProgressBar;
