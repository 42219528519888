import React from "react";
import PropTypes from "prop-types";
import Backdrop from "components/Backdrop";

import { Typography, makeStyles } from "@material-ui/core";

import nextIcon from "assets/next.svg";
import prevIcon from "assets/previous.svg";
import VideoPlayer from "components/VideoPlayer";
import dayjs from "dayjs";
import { attachmentType } from "pages/BrickDashboard/shapes";
import LoadingImage from "components/LoadingImage";

const useStyles = makeStyles(() => ({
  arrow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    zIndex: 1,
    top: 0,
    height: "100%",
    paddingInline: 16,
  },
  imageWrapper: {
    height: "unset",
    maxHeight: "100%"
  },
  contentWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 500,
    height: "80vh",
  },
  metadata: {
    marginTop: 15,
    width: "100%",
    textAlign: "center",
  },
  fileName: {
    fontWeight: 700,
    fontSize: 18,
  },
}));

const BrickLightbox = ({ media, onArrowClick, onClose }) => {
  const classes = useStyles();

  if (!media) return null;

  const { fileType, url, name, date } = media;

  const TypeSpecificContent = () => {
    if (fileType === attachmentType.IMAGE)
      return (
        <LoadingImage
          imageUrl={url}
          height={"100%"}
          blurred={false}
          imageSize="contain"
          // Uncomment this to change how smaller images are rendered (scaled or not)
          // className={classes.imageWrapper}
        />
      );

    if (fileType === attachmentType.VIDEO)
      return (
        <VideoPlayer
          content={url}
          customVideoHeight={"90%"}
          autoStart
          rounded
        />
      );
  };

  return (
    <Backdrop
      backgroundOpacity={0.8}
      onClose={onClose}
      open={!!media}
      showCancel
    >
      <div className={classes.contentWrapper}>
        <div
          className={classes.arrow}
          style={{
            left: 0,
            borderBottomLeftRadius: 8,
            borderTopLeftRadius: 8,
          }}
          onClick={() => onArrowClick(-1)}
        >
          <img src={prevIcon} height={45} />
        </div>

        <div className={classes.content}>
          <TypeSpecificContent />
          <div className={classes.metadata}>
            <Typography className={classes.fileName}>{name}</Typography>
            <Typography>{dayjs(date).format("DD/MM/YYYY")}</Typography>
          </div>
        </div>

        <div
          className={classes.arrow}
          style={{
            right: 0,
          }}
          onClick={() => onArrowClick(1)}
        >
          <img src={nextIcon} height={45} />
        </div>
      </div>
    </Backdrop>
  );
};

BrickLightbox.propTypes = {
  media: PropTypes.object,
  onArrowClick: PropTypes.func,
  onClose: PropTypes.func,
};

export default BrickLightbox;
