import React, { useContext, useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
// Context
import { AppContext } from "context/AppContext";
// Components
import Widget from "components/Widget";
import Avatar from "components/Avatar";
import PerfectScrollbar from "react-perfect-scrollbar";
import Loading from "components/Loading";
import SvgIcon from "components/SvgIcon";
// Constants
import {
  SPACE_CADET,
  OXFORD_BLUE,
  WILD_BLUE_YONDER,
  GHOST_WHITE,
} from "constants/colors";
// Layout config
import { firstColConfig, secondColConfig } from "../config";
// Assets
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
// Components
import Economics from "widgets/Economics";
import Workload from "widgets/WorkLoad";
import Be from "widgets/Be";

//Hooks
import useFetch from "hooks/useHTTP";
//configuration
import { widgetConfiguration, widgetsUrl } from "widgets/Configuration";

//HOOK
import usePrevious from "hooks/usePrevious";
import Gantt from "widgets/Gantt";
import { workloadContext } from "utils/enums/workLoad";

// Styles
const useStyles = makeStyles({
  wrapper: {
    transition: "0.2s width linear",
    flexShrink: 0,
    backgroundColor: OXFORD_BLUE,
    display: "flex",
    zIndex: 5,
  },
  colContent: ({ headerHeight }) => ({
    overflowY: "auto",
    height: `calc(100% - ${headerHeight + "px" || "130px"})`,
  }),
  fixedCol: {
    width: secondColConfig.width.collapsed,
    height: "100%",
    padding: "40px 40px 0",
    backgroundColor: SPACE_CADET,
    flexShrink: 0,
  },
  data: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 15,
    justifyContent: "center",
  },
  header: ({ isFirstColOpen, owner }) => ({
    marginBottom: owner ? 24 : 35,
    padding: isFirstColOpen ? "0 25px" : 0,
  }),
  image: {
    maxWidth: "100%",
    height: "auto",
  },
  responsibleData: {
    display: "flex",
    margin: "20px 0",
  },
  sectionTitle: {
    fontSize: 28,
    lineHeight: "30px",
    fontWeight: "bold",
    textAlign: "left",
    width: "100%",
  },
  sub: {
    fontSize: "10px",
    color: WILD_BLUE_YONDER,
    textTransform: "uppercase",
  },
  title: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  closeIcon: {
    cursor: "pointer",
  },
  widgetDetail: {
    flexGrow: 0,
    overflow: "hidden",
    width: "100%",
    padding: 60,
    paddingTop: 30,
  },
  widgetDetailTitle: {
    fontSize: 30,
    fontWeight: "bold",
  },
  widgetDetailBody: {
    height: "100%",
    margin: "1rem",
    marginLeft: 0,
    overflow: "auto",
    flexGrow: 1,
    overflowX: "hidden",
  },
});

const SecondCol = () => {
  // Context
  const {
    isFirstColOpen,
    isSecondColOpen,
    setIsSecondColOpen,
    pageTitle,
    owner,
    widgets,
    setWidgets,
    refreshSecondCol,
    setRefreshSecondCol,
    filters,
    filtersQuery,
  } = useContext(AppContext);

  const expandedWidth = `calc(100vw - ${
    isFirstColOpen
      ? firstColConfig.width.expanded
      : firstColConfig.width.collapsed
  }px)`;
  const widgetRef = useRef();
  const headerHeight =
    widgetRef?.current?.getBoundingClientRect()?.height + owner ? 45 : 35; // paddingTop
  // Hooks
  const classes = useStyles({
    expandedWidth,
    isSecondColOpen,
    owner,
    headerHeight,
  });
  // State
  const { get } = useFetch();
  const [loading, setLoading] = useState(false);
  const [activeWidget, setActiveWidget] = useState();

  const prevFilters = usePrevious(filters);

  const [info, setInfo] = useState({
    title: "",
    component: null,
  });
  const location = useLocation();
  const { projectId, portfolioId, id, departmentId } = useParams();

  // API
  const getData = async () => {
    setLoading(true);
    const widgetKey = Object.keys(widgetConfiguration)?.find(
      el =>
        el === location?.pathname ||
        (location?.pathname?.startsWith(el) && el !== "/")
    );
    const url = widgetsUrl[widgetKey];
    const query =
      widgetKey === "/departments"
        ? filtersQuery(["PeopleCountFilter"])
        : widgetKey === "/portfolios" || widgetKey === "/"
        ? filtersQuery(["TimeFilterType", "TimeFilter", "Status"])
        : "";

    const entityId = projectId || portfolioId || id || departmentId || "";
    const getWidgetEndpoint =
      url + location.search + (entityId && `/${entityId}`);
    try {
      const res = await get(getWidgetEndpoint + query);
      const result = [];
      res?.data?.map(el => {
        const widgetAdditionalData = widgetConfiguration[widgetKey][el?.type];
        if (widgetAdditionalData)
          result.push({ ...el, ...widgetAdditionalData });
      });
      setWidgets(result);
      setLoading(false);
    } catch (error) {
      setWidgets([]);
      setLoading(false);
      console.log("e", error);
    } finally {
      setRefreshSecondCol(false);
    }
  };

  useEffect(() => {
    const pathname = location?.pathname;
    const isDepartment = pathname?.startsWith("/departments");
    const isUser = pathname?.startsWith("/user");
    if (pathname?.startsWith("/project")) {
      if (
        activeWidget === "MARGINE PREVISTO" ||
        activeWidget === "EROSIONE DEL BUDGET"
      ) {
        setInfo({
          title: "Economics di progetto",
          component: Economics,
          props: {
            isErosion: activeWidget === "EROSIONE DEL BUDGET",
          },
        });
      } else if (activeWidget === "INFO") {
        setInfo({
          title: "Carico di lavoro",
          component: Workload,
        });
      } else if (activeWidget === "RITARDO PREVISTO") {
        setInfo({
          title: "Gantt",
          component: Gantt,
        });
      } else {
        setInfo({
          title: "Widget Title",
          component: null,
        });
      }
    }
    if (isDepartment || isUser) {
      if (activeWidget === "SENTIMENT") {
        setInfo({
          title: "Be",
          component: Be,
        });
      }
      if (activeWidget === "INFO") {
        setInfo({
          title: "Carico di lavoro",
          component: Workload,
          props: {
            context: isDepartment
              ? workloadContext.DEPARTMENT
              : workloadContext.USER,
          },
        });
      }
    }
  }, [activeWidget]);

  useEffect(() => {
    getData();
  }, [location]);

  useEffect(() => {
    const widgetKey = Object.keys(widgetConfiguration)?.find(
      el =>
        el === location?.pathname ||
        (location?.pathname?.startsWith(el) && el !== "/")
    );
    if (widgetKey === "/departments") {
      if (prevFilters?.PeopleCountFilter !== filters?.PeopleCountFilter) {
        //sto cambiando persone per dipartimento
        getData();
      }
    } else if (widgetKey === "/portfolios" || widgetKey === "/") {
      if (prevFilters?.TimeFilterType !== filters?.TimeFilterType) {
        // sto cambiando TimeFilterType per portfolio o groove
        getData();
      } else if (prevFilters?.TimeFilter !== filters?.TimeFilter) {
        //sto cambiando TimeFilter per portfolio o groove
        getData();
      } else if (prevFilters?.Status !== filters?.Status) {
        //sto cambiando TimeFilterType per portfolio o groove
        getData();
      }
    }
  }, [filters]);

  useEffect(() => {
    refreshSecondCol && getData();
  }, [refreshSecondCol, filters]);

  const handleDrill = widgetTitle => {
    setActiveWidget(widgetTitle);
    setIsSecondColOpen(true);
  };

  // Renders
  const renderText = (str, className) => {
    return (
      <Typography component="h2" className={className || classes.title}>
        {str}
      </Typography>
    );
  };

  const renderData = () => {
    return (
      <div className={classes.responsibleData}>
        <Avatar image={owner?.image} size={"50px"} onClick={owner?.onClick} />
        <div className={classes.data}>
          {renderText(owner?.name)}
          {owner?.jobTitle && renderText(owner?.jobTitle, classes.sub)}
        </div>
      </div>
    );
  };

  const renderWidgetComponent = (item, idx) => {
    const CustomComponent = item?.component;

    return (
      CustomComponent && (
        <Widget
          active={isSecondColOpen && activeWidget === item?.title}
          id={item?.id}
          key={idx}
          onClick={item?.clickable && (() => handleDrill(item?.title))}
          title={item?.title?.toUpperCase()}
          size={item?.size}
          secondaryText={item?.secondaryText}
        >
          <CustomComponent {...item} />
        </Widget>
      )
    );
  };

  const renderFixedCol = () => (
    <div className={classes.fixedCol}>
      <div ref={widgetRef} className={classes.header}>
        <Typography component="h1" className={classes.sectionTitle}>
          {pageTitle}
        </Typography>
        {owner?.id && renderData()}
      </div>
      {loading && <Loading showWrapper={false} />}
      {!loading && (
        <div className={classes.colContent}>
          <PerfectScrollbar
            options={{ suppressScrollX: true }}
            id={"pf-scrollbar-widgets"}
            style={{ maxWidth: 348, paddingBottom: 100 }}
          >
            <div>{renderWidgets()}</div>
          </PerfectScrollbar>
        </div>
      )}
    </div>
  );

  const renderWidgets = () => widgets?.map(renderWidgetComponent);

  const renderHead = () => {
    return (
      <div className={classes.head}>
        <Typography className={classes.widgetDetailTitle}>
          {info?.title}
        </Typography>
        <div className={classes.closeIcon}>
          <SvgIcon
            color={GHOST_WHITE}
            icon={<CancelIcon />}
            onClick={() => setIsSecondColOpen(false)}
            strokeWidth={2}
          />
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    const CustomComponent = info?.component;
    return (
      CustomComponent && (
        <div className={classes.widgetDetailBody}>
          <PerfectScrollbar id={"pf-scrollbar-customComponent"}>
            <div style={{ width: "100%", height: "100%" }}>
              <CustomComponent {...info?.props} />
            </div>
          </PerfectScrollbar>
        </div>
      )
    );
  };

  const renderWidgetDetail = () =>
    isSecondColOpen && (
      <Fade in timeout={500}>
        <div className={classes.widgetDetail}>
          {renderHead()}
          {renderDetail()}
        </div>
      </Fade>
    );

  return (
    <div
      className={classes.wrapper}
      style={{
        width: isSecondColOpen
          ? expandedWidth
          : secondColConfig.width.collapsed,
      }}
    >
      {renderFixedCol()}
      {renderWidgetDetail()}
    </div>
  );
};

export default SecondCol;
