import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
// import useFetch from "hooks/useHTTP";
// Components
import Backdrop from "components/Backdrop";
import SearchBar from "components/SearchBar";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
// Constants
import { SEARCH_ROOT } from "constants/routes";

// Styles
const useStyles = makeStyles(() => ({
  title: {
    fontSize: 30,
    marginBottom: 20,
  },
  searchBody: ({ hasResults }) => ({
    width: 1000,
    height: 200,
    maxWidth: "85%",
    position: "fixed",
    top: hasResults ? "calc(50% - 100px)" : "50%",
    left: "50%",
    transition: "0.25s ease-in-out all",
    transform: "translate(-50%, -50%)",
    zIndex: 2001,
  }),
}));

export const SearchContext = createContext();

const SearchContextProvider = ({ children }) => {
  // State
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState();
  const [filterQueryParams, setFilterQueryParams] = useState("");
  // ! TODO: handle suggestions
  // const [results, setResults] = useState([]);

  // Hooks
  const classes = useStyles({ hasResults: false });
  const history = useHistory();
  // ! TODO: handle suggestions
  // const { get } = useFetch();

  // Helpers
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  // ! TODO: Handle data transfer to search result page
  const pushSearch = text => {
    setIsSearchOpen(false);
    setSearchText(text);
    history.push(`${SEARCH_ROOT}/projects?Query=${text}`);
  };

  const searchShared = {
    searchText,
    isSearchOpen,
    setIsSearchOpen,
    filterQueryParams,
    setFilterQueryParams,
  };

  // API
  // ! TODO: Call actual API, remove dummy data in SearchBar components
  // const search = async text => get(`/SEARCH?q=${text}`);

  // Renders
  const renderSearchBody = () =>
    isSearchOpen && (
      <div className={classes.searchBody}>
        <Typography variant="h5" className={classes.title}>
          Search
        </Typography>
        <SearchBar
          // ! TODO: handle suggestions
          searchFunction={() => {}}
          // ! TODO: handle suggestions
          fetchedResultsHandler={() => {}}
          resultPickedHandler={pushSearch}
          placeholder="Cerca un Progetto, un Brick, un Utente..."
        />
      </div>
    );

  return (
    <SearchContext.Provider value={searchShared}>
      {children}
      {renderSearchBody()}
      <Backdrop
        backgroundOpacity={0.8}
        onClose={toggleSearch}
        open={isSearchOpen}
        showCancel
      />
    </SearchContext.Provider>
  );
};

SearchContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default SearchContextProvider;
