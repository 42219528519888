import React from "react";
import PropTypes from "prop-types";
import {
  Content,
  GradientTypography,
  StyledInputBase,
  Wrapper,
} from "./AiGeneratorPrompt.styles";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { Button, Fade, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import { AppContext } from "context/AppContext";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { WILD_BLUE_YONDER } from "constants/colors";
import useFetch from "hooks/useHTTP";
import { AI_GENERATOR } from "constants/api";
import { useHistory, useParams } from "react-router-dom";

const AiGeneratorPrompt = ({ visible, onClose }) => {
  // hooks
  const { isFirstColOpen, setGlobalLoading } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const { portfolioId } = useParams();
  const { post } = useFetch();
  const history = useHistory();

  // state
  const [prompt, setPrompt] = useState("");

  // functions
  const generateSkyline = async () => {
    try {
      if (!portfolioId) throw new Error("Portfolio id is missing!");
      setGlobalLoading(true);
      const input = {
        prompt,
        portfolioId,
      };
      const { data } = await post(AI_GENERATOR, input);
      history.push(`/planner/${data.projectId}/builder`);
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  };

  const handleKeyDown = event => {
    if (!prompt) return;
    if (event.key === "Enter") generateSkyline();
  };

  if (!visible) return null;

  return (
    <Fade in={true}>
      <Wrapper isFirstColOpen={isFirstColOpen}>
        {onClose && (
          <IconButton
            sx={{ position: "absolute", right: 50, top: 110, zIndex: 150 }}
          >
            <CloseRoundedIcon
              onClick={onClose}
              sx={{
                width: 40,
                height: 40,
                color: WILD_BLUE_YONDER,
                "&:hover": {
                  color: "white",
                },
              }}
            />
          </IconButton>
        )}
        <Content>
          <GradientTypography variant="h1" fontWeight={700} pt={2}>
            Ciao {user.name}
          </GradientTypography>
          <GradientTypography variant="h1" fontWeight={400} pb={2}>
            che progetto posso suggerirti?
          </GradientTypography>

          <Stack mt="100px" direction="row" gap={3} height={56}>
            <StyledInputBase
              fullWidth
              autoFocus={false}
              placeholder="Che progetto vorresti creare?"
              onChange={e => setPrompt(e.target.value)}
              onKeyDown={handleKeyDown}
              value={prompt}
            />
            <Button
              color="primary"
              variant="contained"
              size="large"
              sx={{ width: 230 }}
              onClick={generateSkyline}
              disabled={!prompt}
            >
              GENERA SKYLINE
            </Button>
          </Stack>
        </Content>
      </Wrapper>
    </Fade>
  );
};

AiGeneratorPrompt.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export { AiGeneratorPrompt };
