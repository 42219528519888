import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from "react";
import { useHistory, useParams } from "react-router-dom";

import { Stage, Layer, Group, Line, Circle } from "react-konva";
import { Html } from "react-konva-utils";
// Components
import Layout from "components/Layout";
import Loading from "components/Loading";
import Avatar from "components/Avatar";
import AvatarWrapper from "./components/AvatarWrapper";
import SkyviewActions from "./components/SkyviewActions";
// Hooks
import useWindowSize from "hooks/useWindowSize";
import useFetch from "hooks/useHTTP";
// Context
import { AppContext } from "context/AppContext";
// !-- Dummy Data
import { dummySkyview as dummyData } from "./dummyData";
import {
  OWNER_SIZE,
  LOG_COORDS,
  PHASE_RADIUS,
  PHASE_SIZE,
  CORE_RADIUS,
  PERSON_BASE_SIZE,
  PERIPHERAL_POINT_SIZE,
  PERCENTAGE_PERSON_BASERESIZE,
  SAFE_CORE_DISTANCE,
  SAFE_PERIPHERAl_DISTANCE,
  ZOOM_SCALE,
  SCALE_DEFAULTS,
} from "./config";
// Constants
import { WILD_BLUE_YONDER } from "constants/colors";
import { PROJECT } from "constants/api";
import { metricTypes } from "utils/enums/grooveMetrics";
// Material UI
import { Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Math Utils
import {
  getThirdPoint,
  getCircumferencePoint,
  getIntermediatePoint,
} from "./utils";

// Styles
const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    overflow: "hidden",
    position: "relative",
  },
  animatedAvatar: {
    marginLeft: 10,
    marginTop: 10,
    transform: "scale(0.1)",
    animation: "$bubbleEffect 1s ease-in-out",
    animationFillMode: "forwards",
  },
  "@keyframes bubbleEffect": {
    "0%": {
      transform: "scale(0.1)",
    },
    "85%": {
      transform: "scale(1.5)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
}));

const Skyview = () => {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const canvasRef = useRef();
  const stageRef = useRef();
  const { projectId } = useParams();
  const { get } = useFetch();
  const [width, height] = useWindowSize();
  const { setActiveMetric } = useContext(AppContext);
  // State
  const [canvasSize, setCanvasSize] = useState();
  const [projectData, setProjectData] = useState();
  const [hoveredPerson, setHoveredPerson] = useState();
  const [phases, setPhases] = useState([]);
  const [stage, setStage] = useState(SCALE_DEFAULTS);

  const canvasCenter = {
    x: canvasSize?.width / 2 || 0,
    y: canvasSize?.height / 2 || 0,
  };

  // Effects
  useEffect(() => {
    fetchProject();
  }, []);

  useEffect(() => {
    calculatePhasesCoords();
  }, [projectData, canvasSize]);

  useLayoutEffect(() => {
    calcCanvasSize();
  }, [width, height]);

  // API
  const fetchProject = async () => {
    try {
      const res = await get(`${PROJECT}/${projectId}/skyview`);
      setProjectData(res.data);
    } catch (err) {
      console.error(err);
      // TODO: Remove dummy data
      setProjectData(dummyData);
    }
  };

  // Helpers
  const calculatePhasesCoords = () => {
    const phasesCount = projectData?.phases?.length;
    if (!phasesCount) return;

    const phasesData = projectData.phases.map((phase, idx) => {
      // Calculate phase point on core circumference
      // (equidistant from other phases points)
      const circumferencePoint = getCircumferencePoint({
        centerX: canvasCenter.x,
        centerY: canvasCenter.y,
        radius: CORE_RADIUS,
        index: idx,
        pointsCount: phasesCount,
        offset: PHASE_RADIUS,
      });

      // Calculate maximum phase line point (0% weight)
      const peripheralPoint = getThirdPoint({
        firstPointX: circumferencePoint.x,
        firstPointY: circumferencePoint.y,
        secondPointX: canvasCenter.x,
        secondPointY: canvasCenter.y,
        offset: PHASE_RADIUS,
        distanceRatio: 6,
      });

      // Calucate minimum and maximum safe points between
      // circumference point and peripheral point
      const minSafePoint = getIntermediatePoint({
        startPoint: circumferencePoint,
        endPoint: peripheralPoint,
        percentage: SAFE_CORE_DISTANCE,
        pivotPoint: canvasCenter,
        phasesCount,
      });

      const maxSafePoint = getIntermediatePoint({
        startPoint: circumferencePoint,
        endPoint: peripheralPoint,
        percentage: 100 - SAFE_PERIPHERAl_DISTANCE,
        pivotPoint: canvasCenter,
        phasesCount,
      });

      // Calculate peple coordinates based on "phase weight"
      const sortedPeople = phase.people?.sort(
        (a, b) => b.percentage - a.percentage
      );

      let lastAdaptedPercentage = 0;
      const calculatedPeople = sortedPeople.map((person, idx) => {
        const personCoords = getIntermediatePoint({
          startPoint: minSafePoint.coords,
          endPoint: maxSafePoint.coords,
          percentage: 100 - person.percentage,
          prevPersonPercentage: lastAdaptedPercentage,
          pivotPoint: canvasCenter,
          negativeRotation: idx % 2 === 0,
          phasesCount,
        });

        lastAdaptedPercentage = personCoords.adaptedPercentage;

        return {
          ...person,
          sentiment: person.sentiment,
          inPhaseId: `${phase.id}_${person.id}`,
          coords: personCoords.coords,
          angle: personCoords.angle,
        };
      });
      //I need to draw a line until the peripheralPoint,
      //I'm gonna push an object with his coords inside calculatedPeople
      calculatedPeople.push({ coords: peripheralPoint });

      const phaseData = {
        ...phase,
        user: {
          ...phase.user,
          inPhaseId: `phase-${phase.id}-owner_${phase.user.id}`,
        },
        people: calculatedPeople,
        coords: { x: circumferencePoint.x, y: circumferencePoint.y },
        peripheralPoint: { x: peripheralPoint.x, y: peripheralPoint.y },
        minSafePoint: minSafePoint.coords,
        maxSafePoint: maxSafePoint.coords,
      };

      return phaseData;
    });
    setPhases(phasesData);
  };

  const getPersonSegment = (phase, sortedPeople, person, idx) => {
    const prevPoint = idx === 0 ? phase.coords : sortedPeople[idx - 1].coords;
    const currentPoint = person.coords;

    const origin = {
      x: prevPoint.x + PHASE_RADIUS,
      y: prevPoint.y + PHASE_RADIUS,
    };

    const targetPoint = {
      x: currentPoint.x + PHASE_RADIUS,
      y: currentPoint.y + PHASE_RADIUS,
    };
    const segmentPoints = [origin.x, origin.y, targetPoint.x, targetPoint.y];

    return segmentPoints;
  };

  const handlePhaseCursor = e => {
    const cursorStyle = e.type === "mouseenter" ? "grab" : "default";
    e.target.getStage().container().style.cursor = cursorStyle;
  };

  const logCoords = e => {
    if (LOG_COORDS) {
      // eslint-disable-next-line max-len
      console.log("CLICKED COORDS", e.target.getStage().getPointerPosition());
    }
  };

  const handleWheel = e => {
    e.evt.preventDefault();

    const scaleBy = ZOOM_SCALE;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    setStage({
      scale: newScale,
      x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
      y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
    });
  };

  const calcCanvasSize = () => {
    const size = canvasRef.current.getBoundingClientRect();
    const { width, height } = size;
    setCanvasSize({ width, height });
  };

  // Renders
  const renderCircle = person => (
    <Circle
      x={person.coords.x + PERIPHERAL_POINT_SIZE * 3.2}
      y={person.coords.y + PERIPHERAL_POINT_SIZE * 3.2}
      radius={PERIPHERAL_POINT_SIZE}
      fill="white"
    />
  );

  const renderPerson = (person, personIdx) => {
    // Commitment / Reputation average used for avatar size
    const commitmentReputationAvg =
      person.projectCommitment + person.projectReputation / 2;
    const calculatedSize =
      // Multiply by base resize (keep min and max between 20 and 80px)
      PERSON_BASE_SIZE * PERCENTAGE_PERSON_BASERESIZE +
      // Increase size by 15 (avoid fluid sizes and allow multiple of 15 starting from base size)
      Math.round(commitmentReputationAvg / 10) * 15;

    const offsetCalcAfterResize = (PERSON_BASE_SIZE - calculatedSize) / 2;
    const animationDelay = `${Math.random().toFixed(2) * 1000}ms`;
    return (
      <Html
        key={personIdx}
        groupProps={{
          x: person.coords.x + offsetCalcAfterResize,
          y: person.coords.y + offsetCalcAfterResize,
        }}
        divProps={{
          style: { zIndex: hoveredPerson === person.inPhaseId ? 250 : 10 },
        }}
      >
        <AvatarWrapper
          onEnterCallBack={() => setHoveredPerson(person.inPhaseId)}
          onLeaveCallBack={() => setHoveredPerson()}
          className={classes.animatedAvatar}
          style={{ animationDelay }}
          showPersonalInfo={hoveredPerson === person.inPhaseId}
          person={person}
          avatarSize={calculatedSize}
        >
          <Avatar
            image={person.imageUrl}
            className={classes.personAvatar}
            size={calculatedSize}
            onClick={() => history.push(`/user/${person.id}`)}
          />
        </AvatarWrapper>
      </Html>
    );
  };

  const renderPeople = () => {
    if (phases.length < 1) return;
    return phases.map((phase, idx) => {
      const sortedPeople = phase.people.sort(
        (a, b) => b.percentage - a.percentage
      );
      return (
        <Group key={idx}>
          {sortedPeople.map((person, personIdx) => (
            <Group key={personIdx}>
              {person.name
                ? renderPerson(person, personIdx)
                : renderCircle(person)}
              <Line
                stroke={"white"}
                points={getPersonSegment(
                  phase,
                  sortedPeople,
                  person,
                  personIdx
                )}
                lineCap="round"
                lineJoin="round"
                strokeWidth={3}
                dash={[2, 12]}
              />
            </Group>
          ))}
        </Group>
      );
    });
  };

  const renderProjectOwner = () => (
    <>
      <Circle
        x={canvasCenter.x}
        y={canvasCenter.y}
        radius={69}
        stroke={WILD_BLUE_YONDER + "50"}
        strokeWidth={6}
      />
      <Html
        groupProps={{
          x: canvasCenter.x - PHASE_SIZE,
          y: canvasCenter.y - PHASE_SIZE,
        }}
        divProps={{
          style: {
            zIndex: hoveredPerson === projectData.project?.id ? 250 : 10,
          },
        }}
      >
        <AvatarWrapper
          onEnterCallBack={() => setHoveredPerson(projectData.project?.id)}
          onLeaveCallBack={() => setHoveredPerson()}
          showPersonalInfo={hoveredPerson === projectData.project?.id}
          person={projectData.project?.user}
          avatarSize={OWNER_SIZE}
          projectName={projectData.project.name}
        >
          <Avatar
            image={projectData.project?.user?.imageUrl}
            size={OWNER_SIZE}
            onClick={() => history.push(`/user/${projectData.project.user.id}`)}
          />
        </AvatarWrapper>
      </Html>
    </>
  );

  const renderPhases = () =>
    phases.map(phase => (
      <Html
        key={phase.id}
        groupProps={{
          x: phase.coords.x,
          y: phase.coords.y,
        }}
        divProps={{
          style: {
            zIndex: hoveredPerson === phase.user.inPhaseId ? 250 : 10,
          },
        }}
      >
        <AvatarWrapper
          onEnterCallBack={() => setHoveredPerson(phase.user.inPhaseId)}
          onLeaveCallBack={() => setHoveredPerson()}
          showPersonalInfo={hoveredPerson === phase.user.inPhaseId}
          person={phase.user}
          avatarSize={PHASE_SIZE}
          phaseName={phase.name}
        >
          <Avatar
            image={phase.user?.imageUrl}
            size={PHASE_SIZE}
            onClick={() => history.push(`/user/${phase.user.id}`)}
          />
        </AvatarWrapper>
      </Html>
    ));

  const renderCore = () => (
    <Layer>
      <Circle
        fillPriority="radial-gradient"
        x={canvasCenter.x}
        y={canvasCenter.y}
        radius={CORE_RADIUS}
        stroke="white"
        strokeWidth={4}
        fillRadialGradientStartPoint={{ x: 0, y: 0 }}
        fillRadialGradientStartRadius={0}
        fillRadialGradientEndPoint={{ x: 0, y: 0 }}
        fillRadialGradientEndRadius={CORE_RADIUS}
        fillRadialGradientColorStops={[
          0,
          "rgba(255,255,255,0.25)",
          1,
          "transparent",
        ]}
      />
      {renderProjectOwner()}
      {renderPhases()}
      {renderPeople()}
    </Layer>
  );

  const renderCanvas = () => (
    <div ref={canvasRef} className={classes.wrapper}>
      {projectData && (
        <>
          <Fade in timeout={400}>
            <SkyviewActions
              canvasCenter={canvasCenter}
              stage={stageRef.current}
              stageData={stage}
              onStageChange={setStage}
            />
          </Fade>
          <Fade in timeout={400}>
            <Stage
              ref={stageRef}
              scaleX={stage?.scale}
              scaleY={stage?.scale}
              x={stage.x}
              y={stage.y}
              width={canvasSize?.width}
              height={canvasSize?.height}
              onClick={logCoords}
              draggable
              onMouseEnter={handlePhaseCursor}
              onMouseLeave={handlePhaseCursor}
              onWheel={handleWheel}
            >
              {renderCore()}
            </Stage>
          </Fade>
        </>
      )}

      {!projectData && <Loading showWrapper={false} />}
    </div>
  );

  const headerIcons = [
    {
      icon: "do",
      action: () => {
        setActiveMetric(metricTypes.DO);
        history.push(`/project/${projectId}`);
      },
    },
    {
      icon: "be",
      action: () => {
        setActiveMetric(metricTypes.BE);
      },
    },
  ];

  return (
    <Layout headerIcons={headerIcons} padRight={false}>
      {renderCanvas()}
    </Layout>
  );
};

export default Skyview;
