import React, { useContext, useState } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Link, Typography } from "@material-ui/core";

// hooks
import { useHistory } from "react-router-dom";

// constants
import { ROOT } from "constants/routes";

// Context
import { AppContext } from "context/AppContext";
import { WHITE } from "constants/colors";
import Popper from "components/Popper";

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    marginRight: "auto",
    paddingLeft: 8,
    display: "flex",
    alignItems: "baseline",
  },

  breadContainer: ({ isPlanner }) => ({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:last-child": {
      cursor: isPlanner ? "planner" : "default",
    },
  }),
  link: {
    color: "white",
    textTransform: "capitalize",
    margin: "0 5px",
  },

  numberValue: {
    width: 24,
    height: 24,
    borderRadius: 4,
    lineHeight: "1.7em",
    textAlign: "center",
    fontSize: "15px",
    backgroundColor: theme.palette.primary.main,
    color: WHITE,
    margin: "10px 5px ",
    cursor: "pointer",
  },
}));

const Breadcrumb = () => {
  const history = useHistory();
  const { breadcrumbs, sliceBreadcrumbs, setPageTitle, location } =
    useContext(AppContext);

  const isPlanner = location?.pathname.includes("planner");

  const [anchorEl, setAnchorEl] = useState(null);
  const popperItems = [];

  const open = Boolean(anchorEl);
  const id = open ? "user-popper" : undefined;

  const classes = useStyles({ isPlanner });

  const handleBreadcrumb = breadcrumb => {
    sliceBreadcrumbs(breadcrumb.id);
    setPageTitle(breadcrumb.name);
    history?.push(breadcrumb.url || ROOT);
  };

  const setPopperItems = breadcrumb => {
    popperItems.push({
      onClick: () => {
        setAnchorEl(null);
        handleBreadcrumb(breadcrumb);
      },
      text: breadcrumb.name,
    });
  };

  const numberValue = breadcrumbs?.length > 3 ? breadcrumbs?.length - 3 : 0;

  return (
    <div className={classes.breadcrumb}>
      {breadcrumbs?.map((breadcrumb, index) => {
        if (index > 0 && breadcrumbs?.length > 3) {
          if (index < breadcrumbs?.length - 2) {
            setPopperItems(breadcrumb);
          }
        }
        const lastElement = index === breadcrumbs?.length - 1;
        return (
          <div
            key={`breadcrumb-${breadcrumb.id}-${index}`}
            className={classes.breadContainer}
          >
            {index === 0 ||
            breadcrumbs?.length < 3 ||
            (breadcrumbs?.length >= 3 &&
              (lastElement || index === breadcrumbs?.length - 2)) ? (
              <>
                {index !== 0 && (
                  <span className={classes.separator}>{` > `}</span>
                )}
                <Typography
                  component={!lastElement || isPlanner ? Link : "div"}
                  id={breadcrumb.id}
                  className={classes.link}
                  onClick={() =>
                    (!lastElement || isPlanner) && handleBreadcrumb(breadcrumb)
                  }
                  variant={index === 0 ? "h5" : "h6"}
                >
                  {breadcrumb.name}
                </Typography>
              </>
            ) : null}
            {index === 0 && numberValue > 0 && (
              <div
                onMouseEnter={e =>
                  setAnchorEl(anchorEl ? null : e.currentTarget)
                }
                onMouseLeave={() => setAnchorEl(null)}
              >
                <div className={classes.numberValue}>
                  {numberValue}
                  <Popper
                    id={id}
                    open={open}
                    placement="bottom-start"
                    anchorEl={anchorEl}
                    darkMode={true}
                    divider={false}
                  >
                    {popperItems}
                  </Popper>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

Breadcrumb.propTypes = {};

export default Breadcrumb;
