import React from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

// Styles
const useStyles = makeStyles(theme => ({
  wrapper: ({ isActive }) => ({
    width: 30,
    height: 30,
    backgroundColor: isActive
      ? theme.palette.primary.main
      : theme.palette.componentsBackground.main,
    marginBottom: 6,
    borderRadius: 4,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > img": {
      width: "50%",
      height: "auto",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));

const HorizonAction = ({ icon, isActive, onClick, tooltipText }) => {
  // Hooks
  const classes = useStyles({ isActive });

  return (
    <ButtonBase
      className={classes.wrapper}
      onClick={onClick}
      title={tooltipText}
    >
      <img src={icon} />
    </ButtonBase>
  );
};

HorizonAction.propTypes = {
  icon: PropTypes.node,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  tooltipText: PropTypes.string,
};

export default HorizonAction;
