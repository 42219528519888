import React from "react";
import PropTypes from "prop-types";
// Material UI
import { Backdrop as MuiBackdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Components
import SvgIcon from "components/SvgIcon";
// Constants
import { GHOST_WHITE, WILD_BLUE_YONDER } from "constants/colors";
// Assests
import { ReactComponent as CancelIcon } from "assets/cancel.svg";

// Styles
const useStyles = makeStyles(() => ({
  backdrop: ({ backgroundOpacity }) => ({
    zIndex: 500,
    backgroundColor: `rgba(0,0,0,${backgroundOpacity})`,
  }),
  closeIcon: {
    position: "fixed",
    cursor: "pointer",
    top: 50,
    right: 50,
    zIndex: 2000,
  },
}));

const Backdrop = ({
  backgroundOpacity,
  open,
  onClose,
  showCancel,
  ...other
}) => {
  // Hooks
  const classes = useStyles({ backgroundOpacity });

  return (
    <>
      {showCancel && onClose && open && (
        <SvgIcon
          icon={<CancelIcon />}
          className={classes.closeIcon}
          onClick={onClose}
          strokeWidth={1.5}
          color={WILD_BLUE_YONDER}
          hoverColor={GHOST_WHITE}
        />
      )}
      <MuiBackdrop
        open={open}
        classes={{ root: classes.backdrop }}
        {...other}
      />
    </>
  );
};

Backdrop.defaultProps = {
  backgroundOpacity: 0.5,
  showCancel: false,
};

Backdrop.propTypes = {
  backgroundOpacity: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  showCancel: PropTypes.bool,
};

export default Backdrop;
