import React from "react";
import PropTypes from "prop-types";

// Components
import HorizonAction from ".//HorizonAction";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
// Assets
import ExpandIcon from "assets/expand.png";
import UpIcon from "assets/up_pressed.png";
import DownIcon from "assets/down_pressed.png";
import ResetIcon from "assets/reset_pressed.png";

// Styles
const useStyles = makeStyles({
  wrapper: {
    width: 30,
    position: "absolute",
    zIndex: 100,
    left: 15,
    transition: "all 0.3s ease-out",
  },
});

const HorizonActions = ({
  offsetTop,
  activeView,
  onLevelChange,
  onViewChange,
  setActiveView,
}) => {
  // Hooks
  const classes = useStyles();

  const handleViewChange = (fixedPercentage, view) => {
    onLevelChange(fixedPercentage);
    setActiveView(view);
  };

  return (
    <div
      className={classes.wrapper}
      style={{ top: offsetTop - (activeView ? 0 : 38) }}
    >
      {!activeView && (
        <HorizonAction icon={ExpandIcon} isActive onClick={onViewChange} />
      )}
      <HorizonAction
        icon={UpIcon}
        isActive={activeView === viewTypes.UP}
        onClick={() => handleViewChange(1, viewTypes.UP)}
        tooltipText="Up"
      />
      <HorizonAction
        icon={ResetIcon}
        onClick={() => handleViewChange(null)}
        tooltipText="Reset"
      />
      <HorizonAction
        icon={DownIcon}
        isActive={activeView === viewTypes.DOWN}
        onClick={() => handleViewChange(100, viewTypes.DOWN)}
        tooltipText="Down"
      />
    </div>
  );
};

const viewTypes = {
  OVERALL: "overall",
  UP: "up",
  DOWN: "down",
  RESET: "reset",
};

HorizonActions.defaultProps = {
  offsetTop: 0,
};

HorizonActions.propTypes = {
  activeView: PropTypes.oneOf([...Object.values(viewTypes)]),
  offsetTop: PropTypes.number,
  onLevelChange: PropTypes.func,
  onViewChange: PropTypes.func,
  setActiveView: PropTypes.func,
};

export default HorizonActions;
