/* eslint-disable max-len */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { ReactComponent as PositiveIcon } from "assets/sentiment-positive.svg";
import { ReactComponent as NeutralIcon } from "assets/sentiment-neutral.svg";
import { ReactComponent as NegativeIcon } from "assets/sentiment-negative.svg";
// Constants
import {
  WILD_BLUE_YONDER,
  HARVARD_CRIMSON,
  MALACHITE,
  PACIFIC_BLU,
  DARK_ORANGE,
} from "constants/colors";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    height: 300,
    marginLeft: 30,
  },
}));

const SentimentWithDate = props => {
  const classes = useStyles();
  const { data, id, color = PACIFIC_BLU } = props;

  useEffect(() => {
    const removeAmChartsDomElement = () => {
      Array.from(document.getElementsByTagName("title"))
        .find(i => i.textContent === "Chart created using amCharts library")
        ?.parentNode?.parentNode?.remove?.();
    };

    if (!data || Object.keys(data).length === 0) return;

    // Create chart
    let chart = am4core.create(`div${id}`, am4charts.XYChart);

    chart.data = data;

    // xAxis
    let xAxis = chart.xAxes.push(new am4charts.DateAxis());
    xAxis.renderer.labels.template.fill = am4core.color(WILD_BLUE_YONDER);
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.baseGrid.disabled = true;
    xAxis.baseInterval = { timeUnit: "day", count: 14 };
    xAxis.gridIntervals.setAll([{ timeUnit: "day", count: 14 }]);
    xAxis.dateFormats.setKey("day", "dd/MM/yyyy");
    xAxis.periodChangeDateFormats.setKey("day", "dd/MM/yyyy");

    // yAxis
    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.baseValue = -1;
    yAxis.max = 1;
    yAxis.min = -1;
    yAxis.tooltip.disabled = true;
    yAxis.renderer.grid.template.disabled = false;
    yAxis.renderer.grid.template.strokeDasharray = "2 2";
    yAxis.renderer.grid.template.stroke = am4core.color(WILD_BLUE_YONDER);
    yAxis.renderer.grid.template.scale = 1.5;
    yAxis.renderer.baseGrid.disabled = false;
    yAxis.renderer.labels.template.disabled = true;

    // series
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "x";
    series.dataFields.valueY = "y";
    // series.tensionX = 0.8; //servono entrambi per arrotondare le linee non capisco quale è meglio bisogna vedere coi dati
    // series.tensionY = 0.8; //servono entrambi per arrotondare le linee non capisco quale è meglio bisogna vedere coi dati
    // eslint-disable-next-line max-len
    series.smoothing = "monotoneX"; //servono entrambi per arr"red"otondare le linee non capisco quale è meglio bisogna vedere coi dati
    series.fillOpacity = 1;
    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color(color, 0.3));
    gradient.addColor(am4core.color(color, 0));
    gradient.rotation = 90;
    series.fill = gradient;
    series.stroke = am4core.color(color, 0.9);
    series.strokeWidth = 3;
    series.tooltipHTML = `<div><span style="font-size: 20px">{valueY}</span><br><span style="font-size: 10px">&nbsp;giorni&nbsp;</span></div>`;
    series.tooltip.getFillFromObject = false;

    series.tooltip.background.cornerRadius = 60;
    series.tooltip.background.strokeOpacity = 1;
    series.tooltip.background.pointerLength = 0;
    series.tooltip.label.minWidth = 40;
    series.tooltip.label.minHeight = 40;
    series.tooltip.label.textAlign = "middle";

    series.adapter.add("tooltipText", tooltipText => {
      if (series.tooltipDataItem.valueY < 0)
        series.tooltip.background.fill = am4core.color(MALACHITE);
      else series.tooltip.background.fill = am4core.color(HARVARD_CRIMSON);
      return tooltipText;
    });

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;
    chart.cursor.lineX.opacity = 0;

    chart.seriesContainer.draggable = false;
    chart.seriesContainer.resizable = false;
    chart.cursor.behavior = "none";

    removeAmChartsDomElement();
  }, [data]);

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: 260,
          justifyContent: "space-between",
        }}
      >
        <Tooltip title="Positivo" arrow>
          <PositiveIcon width="30" height="30" style={{ stroke: MALACHITE }} />
        </Tooltip>
        <Tooltip title="Neutro" arrow>
          <NeutralIcon width="30" height="30" style={{ stroke: DARK_ORANGE }} />
        </Tooltip>
        <Tooltip title="Negativo" arrow>
          <NegativeIcon
            width="30"
            height="30"
            style={{ stroke: HARVARD_CRIMSON }}
          />
        </Tooltip>
      </div>
      <div className={classes.container} id={`div${id}`} />
    </div>
  );
};

SentimentWithDate.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  theme: PropTypes.array,
  legend: PropTypes.bool,
  color: PropTypes.string,
};

SentimentWithDate.defaultProps = {
  legend: false,
};

export default SentimentWithDate;
