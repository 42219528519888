import React from "react";
import PropTypes from "prop-types";

import Truncate from "react-truncate";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { OXFORD_BLUE, PRIMARY, WHITE } from "constants/colors";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "100%",
    display: "flex",
    float: "right",
    flexDirection: "row",
    backgroundColor: OXFORD_BLUE,
  },
  column: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: "10px",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    fontSize: 13,
    alignItems: "center",
    justifyContent: "flex-end",
    "& ::first-letter": {
      textTransform: "capitalize",
    },
  },
  box: {
    backgroundColor: PRIMARY,
    color: WHITE,
    fontSize: 18,
    fontWeight: "bold",
    height: 38,
    width: 60,
    textAlign: "left",
    marginLeft: 10,
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
  },
  text1: {
    width: "calc(100% - 70px)",
    textAlign: "right",
  },
  text2: {
    width: "calc(100% - 55px)",
    textAlign: "right",
  },
  text3: {
    width: "calc(100% - 45px)",
    textAlign: "right",
  },
}));

const MostUsedSkills = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.column}>
          {data?.[0] && (
            <div className={classes.row}>
              <div className={classes?.text1}>
                <Truncate lines={1} width={100}>
                  {data?.[0]}
                </Truncate>
              </div>
              <div className={classes?.box}>1°</div>
            </div>
          )}
          {data?.[1] && (
            <div className={classes.row}>
              <div className={classes?.text2}>
                <Truncate lines={1} width={130}>
                  {data?.[1]}
                </Truncate>
              </div>
              <div
                className={classes?.box}
                style={{
                  backgroundColor: "rgba(0,0,255,0.7)",
                  width: 48,
                  fontSize: 15,
                }}
              >
                <span>2°</span>
              </div>
            </div>
          )}
          {data?.[2] && (
            <div className={classes.row}>
              <div className={classes?.text3}>
                <Truncate lines={1} width={150}>
                  {data?.[2]}
                </Truncate>
              </div>
              <div
                className={classes?.box}
                style={{
                  backgroundColor: "rgba(0,0,255,0.5)",
                  width: 35,
                  fontSize: 13,
                }}
              >
                3°
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MostUsedSkills.propTypes = {
  data: PropTypes.array,
};

export default MostUsedSkills;
