// Style Guide primary colors
export const OXFORD_BLUE = "#12162B";
export const SPACE_CADET = "#1E2840";
export const PRIMARY = "#0000FF";
// Style Guide secondary colors
export const HARVARD_CRIMSON = "#DB0015";
export const DARK_ORANGE = "#FF8800";
export const MEDIUM_PURPLE = "#9475FF";
export const PACIFIC_BLU = "#06AED5";
export const MALACHITE = "#28CD60";
// Style Guide neutral colors
export const BLACK = "#000000";
export const WILD_BLUE_YONDER = "#A5A9C7";
export const GHOST_WHITE = "#E6E8EF";
export const WHITE = "#FFFFFF";
// Other/Custom colors
export const CORNFLOWER_BLUE = "#12162BF7";
export const FOUNTAIN_BLUE = "#50BBBF";
export const ERROR = "#CC0000";
export const AMETHYST_SMOKE = "#8F91B5";
export const WE_PEEP = "#F7DEDE";
export const DREARY_BLUE = "#273453"