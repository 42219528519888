import React from "react";
import PropTypes from "prop-types";

// Material
import { Typography, makeStyles } from "@material-ui/core";

// Components
import BlueIcon from "components/BlueIcon";

// Constants
import { WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(theme => ({
  container: {
    flex: "0 0 457px",
    padding: "0 20px",
  },
  days: {
    fontSize: "15px",
    color: theme.palette.text.primary,
  },
  label: {
    marginLeft: 20,
    fontSize: "15px",
    color: theme.palette.text.primary,
  },
  leftColumn: {
    display: "flex",
    alignItems: "center",
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    color: WILD_BLUE_YONDER,
    "& >div": {
      padding: "0 0 5px 0",
      borderBottom: `1px solid rgba(255, 255, 255, .2)`,
    },
  },
  text: {
    fontSize: "20px",
  },
  valueContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0 0 0",
  },
}));

const Skill = ({ data }) => {
  const classes = useStyles();
  if (data?.length === 0) return null;
  const renderText = (str, customClass) => {
    return (
      <Typography component="h3" className={customClass || classes.text}>
        {str}
      </Typography>
    );
  };

  const renderSkill = () => {
    return (
      <div className={classes.container}>
        {renderText("Skill associate")}
        <div className={classes.listContainer}>
          {data?.map((el, index) => (
            <div key={index} className={classes.valueContainer}>
              <div className={classes.leftColumn}>
                <BlueIcon width={"12.5px"} height={"12.5px"} />
                {renderText(el?.name, classes.label)}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return renderSkill();
};

Skill.propTypes = {
  data: PropTypes.array,
};

export default Skill;
