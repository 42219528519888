import React from "react";
import PropTypes from "prop-types";

import Loading from "components/Loading";

import Gantt from ".";

import { PACIFIC_BLU, MALACHITE } from "constants/colors";
import { Typography, makeStyles } from "@material-ui/core";

const data1 = [
  {
    name: "Data pianificata",
    fromDateName: "Data di inizio pianificata",
    fromDate: "2020-10-15",
    toDateName: "Data di fine pianificata",
    toDate: "2021-07-28",
    color: PACIFIC_BLU,
  },
  {
    name: "Data reale",
    fromDateName: "Data di inizio reale",
    fromDate: "2020-11-03",
    toDateName: "Data di fine prevista",
    toDate: "2021-09-20",
    color: MALACHITE,
  },
];

const useStyles = makeStyles({
  noData: {
    width: "100%",
    textAlign: "center",
    paddingBlock: 90,
  },
});

const GanttManager = props => {
  const classes = useStyles();
  const { loading, data } = props;

  if (loading) return <Loading />;
  if (data?.length) return <Gantt {...props} />;
  return (
    <Typography variant="h6" className={classes.noData}>
      Al momento non sono presenti dati
    </Typography>
  );
};

GanttManager.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

GanttManager.defaultProps = {
  data: data1,
};

export default GanttManager;
