import { styled } from "@mui/material";
import Button from "components/Button";

export const StyledWrapper = styled("div")({
  color: "#212B36",
  overflow: "hidden",
});

export const StyledButton = styled(Button)({
  minWidth: "auto",
  flex: "1 0 auto",
});
