import * as am4core from "@amcharts/amcharts4/core";
import {
  WHITE,
  MALACHITE,
  WILD_BLUE_YONDER,
  HARVARD_CRIMSON,
  DARK_ORANGE,
} from "constants/colors";

export const COLORS1 = [am4core.color(WHITE)];
export const COLORS2 = [am4core.color(MALACHITE)];
export const COLORS3 = [am4core.color(WILD_BLUE_YONDER)];
export const COLORS4 = [am4core.color(HARVARD_CRIMSON)];
export const COLORS5 = [am4core.color(DARK_ORANGE)];
