import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";

// Context
import { AppContext } from "context/AppContext";

// Hooks
import useFetch from "hooks/useHTTP";

// Costants
import { GENERAL_SETTINGS } from "constants/api";
import { SETTINGS_GENERAL } from "constants/routes";

const SettingsGuard = ({ children }) => {
  const { settings, setSettings, setGlobalLoading } = useContext(AppContext);
  const { get } = useFetch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
     // If we are in settings page skip the effect (the page will refetch settings)
    if (location.pathname === SETTINGS_GENERAL) return;
    if (!settings?.planningMode) getSettings();
  }, [location]);

  // Helper
  const goToSettings = () => history.push(SETTINGS_GENERAL);

  // API
  const getSettings = async () => {
    try {
      setGlobalLoading(true);
      const res = await get(GENERAL_SETTINGS);
      if (!res.ok)
        throw new Error("Something went wrong while fetching general settings");
      const settingsData = res.data;
      if (!settingsData?.planningMode) return goToSettings();
      setSettings(settingsData);
    } catch (e) {
      console.log(e);
      goToSettings();
    } finally {
      setGlobalLoading(false);
    }
  };

  return children;
};

SettingsGuard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default SettingsGuard;
