import React from "react";
import Lottie from "react-lottie";
// Enums
import { modalTypes } from "utils/enums/modals";
// Animations
import * as elaboration_success from "assets/animations/elaboration_success.json";
import * as elaboration_error from "assets/animations/elaboration_error.json";
import * as loading from "assets/animations/loading.json";

export const dialogDefaults = {
  [modalTypes.LOADING]: {
    title: "Elaborazione in corso...",
    content: (
      <Lottie
        options={{
          loop: true,
          animationData: loading.default,
        }}
        style={{
          width: 120,
          height: 120,
        }}
      />
    ),
  },
  [modalTypes.SUCCESS]: {
    title: "Elaborazione avvenuta con successo",
    content: (
      <Lottie
        options={{
          loop: false,
          animationData: elaboration_success.default,
        }}
        style={{
          width: 120,
          height: 120,
        }}
      />
    ),
  },
  [modalTypes.ERROR]: {
    title: "Elaborazione fallita",
    content: (
      <Lottie
        options={{
          loop: false,
          animationData: elaboration_error.default,
        }}
        style={{
          width: 120,
          height: 120,
        }}
      />
    ),
  },
};
