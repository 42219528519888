import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import { nanoid } from "nanoid";

// Components

import Loading from "components/Loading";

import Button from "components/Button";
import DatePicker from "components/DatePicker";
import TextField from "components/TextField";

import dayjs from "dayjs";

// Material UI
import { Grid, Typography, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Dayjs plugins
// const isBefore = require("dayjs/plugin/isBefore");
// const add = require("dayjs/plugin/add");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
// dayjs.extend(isBefore);
// dayjs.extend(add);

// Styles
const useStyles = makeStyles(() => ({
  formWrapper: {
    width: "100%",
  },
  switchWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  dragIcon: {
    marginRight: 10,
  },
  loading: {
    height: "40vh",
  },
  title: {
    fontWeight: "bold",
    fontSize: 13,
  },
  containerGrid: {
    width: "100%",
    marginTop: 10,
  },
  formRow: {
    marginBottom: 20,
    marginLeft: 5,
  },
  formRowSpaced: {
    marginBottom: 30,
  },
}));

const THEME_TYPE = "light";

// useEffect(() => {}, [getValue("")]);

// Helpers

const ContractsForm = ({ contracts, loading }) => {
  // Hooks
  const classes = useStyles();

  const {
    control,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const { fields, insert } = useFieldArray({
    control,
    name: "contracts",
  });
  const defaultContract = () => {
    const lastField = fields[0];
    let startDate = lastField?.from.add(1, "day");
    if (lastField?.from.startOf("day").isBefore(dayjs().startOf("day"))) {
      startDate = dayjs();
      setValue(`contracts.${0}.to`, dayjs().subtract(1, "day"));
    } else {
      setValue(`contracts.${0}.to`, startDate.subtract(1, "day"));
    }
    const newId = `new-${nanoid()}`;
    return {
      hourlyCost: getValues(`contracts.${0}.hourlyCost`) || 0, // costo della riga precedente
      from: startDate,
      to: null,
      id: newId,
      originalId: newId,
    };
  };
  const addContract = () => {
    insert(0, defaultContract());
  };

  useEffect(() => {
    if (contracts) {
      const contractValues = contracts?.map((el, index) => ({
        ...el,
        from: el?.from ? dayjs(el.from) : null,
        to: el?.to ? dayjs(el.to) : null,
        id: el?.id || "new-" + index,
        originalId: el?.id || "new-" + index,
      }));
      setValue("contracts", contractValues);
    }
  }, [contracts]);

  // Renders
  const renderContracts = (field, idx) => {
    const maxDateFrom =
      idx === 0 || idx === fields.length - 1
        ? dayjs().add("2000", "year")
        : watch(`contracts.${idx}.to`);
    const minDateFrom =
      idx === fields.length - 1
        ? dayjs().add("2000", "year")
        : watch(`contracts.${idx + 1}.from`)
        ? watch(`contracts.${idx + 1}.from`).add(1, "day")
        : dayjs().add("2000", "year");

    return (
      <Grid key={idx} container item xs={12}>
        <Grid item xs className={classes.formRow}>
          <Controller
            render={({ field }) => (
              <TextField
                label="Costo"
                type="number"
                required
                inputProps={{ min: 0 }}
                themeType={THEME_TYPE}
                error={!!errors.contracts?.[idx]?.hourlyCost}
                {...field}
                helperText={
                  errors.contracts?.[idx]?.hourlyCost?.type === "validate"
                    ? "Il costo deve essere maggiore o uguale a 0."
                    : "Campo obbligatorio"
                }
              />
            )}
            name="hourlyCost"
            control={control}
            defaultValue={field.hourlyCost || null}
            {...register(`contracts.${idx}.hourlyCost`, { required: true })}
            rules={{
              required: true,
              validate: num => num >= 0,
            }}
          />
        </Grid>

        <Grid item xs className={classes.formRow}>
          <Controller
            render={({ field }) => (
              <DatePicker
                InputProps={{
                  readOnly: true,
                }}
                themeType={THEME_TYPE}
                label="Data inizio"
                error={!!errors.contracts?.[idx]?.from}
                disabled={idx === fields.length - 1}
                helperText={
                  errors.contracts?.[idx]?.from?.type === "validate"
                    ? "Data non valida"
                    : "Campo obbligatorio"
                }
                required
                minDate={minDateFrom}
                maxDate={maxDateFrom}
                {...field}
                onChange={e => {
                  if (e) {
                    setValue(`contracts.${idx}.from`, e);
                    setValue(`contracts.${idx + 1}.to`, e.subtract(1, "day"));
                  }
                }}
              />
            )}
            name="from"
            control={control}
            defaultValue={field.from || null}
            {...register(`contracts.${idx}.from`, { required: true })}
            rules={{
              required: true,
              validate: selectedDate => {
                if (idx !== fields.length - 1)
                  return (
                    selectedDate.isSameOrBefore(maxDateFrom) &&
                    selectedDate.isSameOrAfter(minDateFrom)
                  );
              },
            }}
          />
        </Grid>
        <Grid item xs className={classes.formRow}>
          <Controller
            render={({ field }) => (
              <DatePicker
                themeType={THEME_TYPE}
                label="Data fine"
                // required={idx !== 0}
                disabled={true}
                placeholder={idx === 0 && "--"}
                error={!!errors.contracts?.[idx]?.to}
                // maxDate={idx === fields.length ? field.from : dayjs()}
                // minDate={dayjs()}
                {...field}
              />
            )}
            defaultValue={field.to || null}
            name="to"
            control={control}
            {...register(`contracts.${idx}.to`)}
            // rules={{
            //   required: idx !== 0,
            //   // validate: selectedDate =>
            //   //   selectedDate.isSameOrBefore(dayjs()),
            // }}
          />
        </Grid>
      </Grid>
    );
  };

  const filteredContracts = () => {
    return fields.map((field, idx) => renderContracts(field, idx));
  };

  if (loading)
    return (
      <div className={classes.loading}>
        <Loading showWrapper={false} />
      </div>
    );
  return (
    <Fade in timeout={400}>
      <form>
        <Grid container spacing={1} style={{ width: "100%" }}>
          <Grid item xs={12} className={classes.formRow}>
            <Typography color="primary" className={classes.title} />
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            <Button
              fullWidth
              themeType="light"
              variant="outlined"
              color="primary"
              onClick={() => addContract()}
            >
              + COSTO ORARIO
            </Button>
          </Grid>

          {filteredContracts()}
        </Grid>
      </form>
    </Fade>
  );
};

ContractsForm.propTypes = {
  contracts: PropTypes.array,
  loading: PropTypes.bool,
};

export default ContractsForm;
