import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// Components
import SidebarKPI from "components/SidebarKPI";
import { IconButton } from "@material-ui/core";

// Assets
import postIcon from "assets/posts.svg";

// Context
import { AppContext } from "context/AppContext";
import { DARK_ORANGE, SPACE_CADET } from "constants/colors";

const SidebarSmallContent = ({ post, postForMe }) => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > div": {
        marginBottom: "0.6rem",
      },
    },
    button: {
      width: 48,
      height: 48,
      background: SPACE_CADET,
      borderRadius: 5,
      "&:hover": {
        background: theme.palette.primary.main,
      },
      marginBottom: 24,
    },
    logoContainer: {
      display: "flex",
      alignItems: "flex-end",
      height: "70px",
      marginBottom: theme.spacing(2),
      textAlign: "center",
    },
    postLogo: {
      width: "30px",
      height: "25px",
    },
  }));
  const { setIsFirstColOpen } = useContext(AppContext);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <IconButton
        className={classes.button}
        onClick={() => setIsFirstColOpen(true)}
      >
        <img src={postIcon} className={classes.postLogo} />
      </IconButton>

      <SidebarKPI number={post} legend="Posts" />
      {/*<SidebarKPI number="21" legend="Non letti" />*/}
      <SidebarKPI
        number={postForMe}
        legend="Per te"
        legendColor={DARK_ORANGE}
      />
    </div>
  );
};
SidebarSmallContent.propTypes = {
  post: PropTypes.number,
  postForMe: PropTypes.number,
};

SidebarSmallContent.defaultProps = {
  post: 0,
  postForMe: 0,
};

export default SidebarSmallContent;
