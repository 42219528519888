import background0 from "assets/skyline/background0.png";
import background1 from "assets/skyline/background1.png";
import background2 from "assets/skyline/background2.png";
import background3 from "assets/skyline/background3.png";
import background4 from "assets/skyline/background4.png";
import background5 from "assets/skyline/background5.png";
/*
    Skyline config
*/

// Size config (px)
export const HORIZON_WIDTH = 4;
export const HORIZON_RADIUS = 10;
export const HORIZON_HEIGHT = 100;
export const HEADER_HEIGHT = 110;
export const INDICATORS_WIDTH = 250;
export const SAFE_MARGIN_TOP = 20;
export const SAFE_MARGIN_BOTTOM = 18;

// Animation config (in ms)
export const INDICATORS_ANIMATION_DURATION = 500;
export const PHASE_ANIMATION_DURATION = 850;

// Background images
export const BackgroundImages = {
  0: background0,
  1: background1,
  2: background2,
  3: background3,
  4: background4,
  5: background5,
};
