import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

import effortLegend from "assets/legenda_carico.png";

// Components
import ChartCard from "components/ChartCard";
import Loading from "components/Loading";
import CustomSelect from "components/CustomSelect";

// Charts and Themes
import StackedRadiusMultiColumnManager from "components/Charts/StackedRadiusMultiColumn/manager";

// key
import { nanoid } from "nanoid";

// Hooks
import { filtersMap, useWorkloadEffort } from "./useWorkloadEffort";
import { workloadContext } from "utils/enums/workLoad";

const useStyles = makeStyles(() => ({
  chartContainer: {
    height: "380px",
    width: "100%",
  },
  select: {
    marginRight: "10px",
  },
  loadingWrapper: {
    height: "300px",
  },
  legend: {
    display: "flex",
    float: "right",
  },
  legendIcon: {
    maxWidth: "100%",
    height: 15,
    marginTop: 0,
    marginBottom: 30,
  },
}));

const SCROLLBARS_TRESHOLD = 30;

const filtersRange = Object.entries(filtersMap).map(([k, v]) => ({
  value: +k,
  label: v.label,
}));

const effortTitleMap = {
  [workloadContext.PROJECT]: "Effort erogato nel tempo sul progetto",
  [workloadContext.DEPARTMENT]: "Effort erogato nel tempo sui progetti",
  [workloadContext.USER]: "Effort erogato nel tempo sui progetti",
};

const EffortWorkload = ({ context }) => {
  const classes = useStyles();
  const { data, loading, filters, setFilters, target } =
    useWorkloadEffort(context);

  const isProject = context === workloadContext.PROJECT;
  const scrollbarsEnabled = !isProject || data?.length > SCROLLBARS_TRESHOLD;

  // API GET REPORT INFO
  const onChange = (e, filterKey) => {
    const value = e.target.value;
    setFilters({ ...filters, [filterKey]: value });
  };

  // Renders
  const renderLoading = () => (
    <Loading
      showWrapper={false}
      animationStyle={{ height: 80, width: 80, marginTop: -100 }}
    />
  );

  const renderSelect = (disabled, filterKey, minWidth) => {
    return (
      <div key={nanoid()} className={classes.select}>
        <CustomSelect
          options={filtersRange}
          disabled={disabled}
          onChange={e => onChange(e, filterKey)}
          value={filters[filterKey]}
          minWidth={minWidth}
        />
      </div>
    );
  };

  return loading ? (
    <div className={classes.loadingWrapper}>{renderLoading()} </div>
  ) : (
    <div className={classes.chartContainer}>
      <ChartCard
        height={"380px"}
        marginLeft={"0px"}
        title={effortTitleMap[context]}
        filters={renderSelect(false, "EffortFilter", "194px")}
      >
        <StackedRadiusMultiColumnManager
          id={"stackedMultiColumn1"}
          scrollBars={scrollbarsEnabled}
          data={data}
          targetValue={target}
        />
      </ChartCard>
      <div className={classes.legend}>
        <img
          src={effortLegend}
          className={classes.legendIcon}
          alt={"effort legend"}
        />
      </div>
    </div>
  );
};

EffortWorkload.propTypes = {
  context: PropTypes.oneOf(Object.values(workloadContext)),
};

export { EffortWorkload };
