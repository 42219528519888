import React from "react";
import Drawer from "components/Drawer";
import PropTypes from "prop-types";
import {
  Box,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { WILD_BLUE_YONDER } from "constants/colors";
import TextField, { TextFieldControl } from "components/TextField";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  SentimentNegative,
  SentimentNeutral,
  SentimentPositive,
} from "assets/icon-components";
import { sentimentValues } from "utils/enums/sentiments";
import dayjs from "dayjs";
import { useEffect } from "react";
import useFetch from "hooks/useHTTP";
import { ACTIVITIES } from "constants/api";
import Loading from "components/Loading";
import { SelectControl } from "components/Select";
import { reportHourOptions, reportMinuteOptions } from "./config";
import { useContext } from "react";
import { AppContext } from "context/AppContext";

const defaultValues = { minutes: "00", hours: "00", note: "", sentiment: null };

const sentimentMap = {
  positive: {
    value: sentimentValues.POSITIVE,
    component: SentimentPositive,
  },
  neutral: {
    value: sentimentValues.NEUTRAL,
    component: SentimentNeutral,
  },
  negative: {
    value: sentimentValues.NEGATIVE,
    component: SentimentNegative,
  },
};

const ReportDrawer = ({
  open,
  setOpen,
  loading,
  data,
  date,
  onActivitySaved,
}) => {
  const { brick, activity } = data || {};

  // hooks
  const { settings } = useContext(AppContext);
  const theme = useTheme();
  const form = useForm({
    defaultValues,
  });
  const { put, post, loading: internalLoading } = useFetch();

  const {
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = form;

  const isBeActive = settings?.isBeActive;

  // Effects
  useEffect(() => {
    if (!activity) return reset({ ...defaultValues });
    const { note, sentiment } = activity;
    const [hours, minutes] = activity.time.split(":");
    reset({ hours, minutes, note, sentiment });
  }, [data]);

  // Api
  const saveActivity = async activityInput => {
    const isNew = !activity;
    const apiFn = isNew ? post : put;
    const apiEndpoint = isNew ? ACTIVITIES : `${ACTIVITIES}/${activity.id}`;

    await apiFn(apiEndpoint, activityInput);
  };

  // Helpers
  const handleClose = () => {
    setOpen(false);
    form.reset();
  };

  const getSentimentColor = (targetSentiment, currentValue) => {
    if (targetSentiment !== currentValue) return WILD_BLUE_YONDER;
  };

  const getTimeValidation = (fieldValue, name) => {
    const isHours = name === "hours";
    const crossField = isHours ? "minutes" : "hours";

    const numFieldValue = +fieldValue;
    const crossFieldValue = getValues(crossField);
    const numCrossFieldValue = +crossFieldValue;

    return (
      numFieldValue > 0 || numCrossFieldValue > 0 || "Seleziona ore e/o minuti"
    );
  };

  const onSubmit = async data => {
    const { note, sentiment, hours, minutes } = data;
    const time =
      hours.padStart(2, "0") + ":" + minutes.padStart(2, "0") + ":00";

    const input = {
      note,
      time,
      sentiment: isBeActive ? sentiment : 0,
      brickId: brick.id,
      expectedEndDate: brick.expectedEndDate,
      estimationCompletionInDays: brick.estimationCompletionInDays,
      date: dayjs(date).toISOString(),
      // delay: true, ??
    };

    try {
      await saveActivity(input);
      handleClose();
      onActivitySaved?.();
    } catch (err) {
      console.error(err);
    }
  };

  const titlesProps = {
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: 1,
    py: theme.spacing(2),
  };

  const ReportForm = () => (
    <FormProvider {...form}>
      <Typography
        color="primary"
        variant="h6"
        fontWeight="bold"
      >{`PROGETTO: ${brick?.projectName}`}</Typography>
      <Typography
        color={WILD_BLUE_YONDER}
        component="div"
        variant="caption"
        textTransform="uppercase"
        mt={0.5}
      >{`${brick?.phaseName}`}</Typography>

      <Box textAlign="center" mt={2}>
        <Typography {...titlesProps}>Conferma esecuzione del brick</Typography>
        <Typography fontSize={16} fontWeight="bold" color={WILD_BLUE_YONDER}>
          Utilizza il calendario per cambiare data.
        </Typography>

        <Box my={2}>
          <TextField
            label="Data selezionata"
            value={dayjs(date).format("DD/MM/YYYY")}
            themeType="light"
            shrink
            disabled
          />
        </Box>

        <Typography {...titlesProps}>Inserisci orario</Typography>
        <Stack direction="row" my={1.5} gap={2} textAlign="left">
          <SelectControl
            label="Ore"
            name="hours"
            themeType="light"
            defaultValue="00"
            shrink
            rules={{
              validate: val => getTimeValidation(val, "hours"),
            }}
            data={reportHourOptions}
          />

          <SelectControl
            label="Minuti"
            themeType="light"
            name="minutes"
            shrink
            defaultValue="00"
            rules={{
              validate: val => getTimeValidation(val, "minutes"),
            }}
            data={reportMinuteOptions}
          />
        </Stack>

        {isBeActive && (
          <>
            <Typography {...titlesProps}>
              Come va con l&apos;attività
            </Typography>
            <Stack direction="row" justifyContent="center">
              {Object.values(sentimentMap).map(({ component, value }) => {
                const Component = component;
                return (
                  <Controller
                    key={value}
                    control={form.control}
                    name="sentiment"
                    defaultValue={null}
                    rules={{ required: "Seleziona il sentiment" }}
                    render={({ field }) => (
                      <IconButton
                        sx={{ p: 1.5 }}
                        onClick={() => field.onChange(value)}
                      >
                        <Component
                          fill={getSentimentColor(value, field.value)}
                        />
                      </IconButton>
                    )}
                  />
                );
              })}
            </Stack>
          </>
        )}
        <FormHelperText error sx={{ m: "auto", textAlign: "center" }}>
          {errors?.sentiment?.message}
        </FormHelperText>

        <Typography {...titlesProps}>Inserisci una nota</Typography>
        <Box my={1.5}>
          <TextFieldControl
            name="note"
            label="Inserisci una nota"
            themeType="light"
            multiline
            rows={4}
          />
        </Box>
      </Box>
    </FormProvider>
  );

  if (!data) return null;

  return (
    <Drawer
      open={open}
      title={!loading && brick?.name}
      primaryText={!loading && !internalLoading ? "SALVA" : ""}
      secondaryText={!loading && !internalLoading ? "ANNULLA" : ""}
      onPrimary={handleSubmit(onSubmit)}
      onSecondary={handleClose}
    >
      {loading || internalLoading ? (
        <Box height="40vh">
          <Loading showWrapper={false} />
        </Box>
      ) : (
        <ReportForm />
      )}
    </Drawer>
  );
};

ReportDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.object,
  errorMessage: PropTypes.string,
  onActivitySaved: PropTypes.func,
  // eslint-disable-next-line max-len
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, // intended as iso date string or dayjs date object
};

export { ReportDrawer };
