import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { WHITE } from "constants/colors";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
  },

  suffix: ({ color }) => ({
    fontSize: 22.5,
    fontWeight: "bold",
    color: color,
    marginBottom: "3px",
  }),
}));

const NumericWidget = ({ color, customClass, number, suffix }) => {
  const classes = useStyles({ color });
  return (
    <div className={clsx(classes.wrapper, customClass)}>
      {number} {suffix && <span className={classes.suffix}>{suffix}</span>}
    </div>
  );
};

NumericWidget.defaultProps = {
  color: WHITE,
};

NumericWidget.propTypes = {
  color: PropTypes.string,
  customClass: PropTypes.string,
  number: PropTypes.node,
  suffix: PropTypes.string,
};

export default NumericWidget;
