import React from "react";
import PropTypes from "prop-types";

import NumberFormatCustom from "components/NumberFormat";
import NumericWidget from "../NumericWidget";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

//Utils
import { truncateDecimals } from "utils/index";

// Constants
import {
  DARK_ORANGE,
  HARVARD_CRIMSON,
  MALACHITE,
  OXFORD_BLUE,
  WHITE,
} from "constants/colors";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "100%",
    display: "flex",
    float: "right",
    flexDirection: "row",
    backgroundColor: OXFORD_BLUE,
  },
  column: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  number: ({ color }) => ({
    width: "100%",
    textAlign: "right",
    color: color,
    fontSize: 32,
    marginTop: 10,
    fontWeight: "bold",
  }),
}));

const Margin = ({ value, target }) => {
  /* 
    Le fasce di colore sono assegnate secondo i segueti criteri:
    - Margine < 0 → ROSSO
    - 0 ≤ Margine < Margine target (settings) → ARANCIONE
    - Margine ≥ Margine target (settings) → VERDE 
    
  */

  const customValue = truncateDecimals(value, 0) || 0;
  const color = !target
    ? WHITE
    : customValue < 0
    ? HARVARD_CRIMSON
    : customValue >= 0 && customValue < target
    ? DARK_ORANGE
    : customValue >= target
    ? MALACHITE
    : WHITE;

  const classes = useStyles({ color });

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.row}>
            <NumericWidget
              number={
                <div className={classes.number}>
                  <NumberFormatCustom value={customValue} decimalScale={0} />
                </div>
              }
              suffix={"%"}
              color={color}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Margin.propTypes = {
  value: PropTypes.number,
  target: PropTypes.number,
};

export default Margin;
