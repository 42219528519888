// icons
import beIcon from "assets/be-icon-new.svg";
import doIcon from "assets/do-icon-new.svg";
import searchIcon from "assets/search-image.svg";
import settingsIcon from "assets/settings-icon.svg";

export const metricTypes = {
  DO: 1,
  BE: 2,
};

export const iconMap = {
  be: beIcon,
  do: doIcon,
  search: searchIcon,
  settings: settingsIcon,
};
