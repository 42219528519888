import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

// Components
import TextField from "components/TextField";
import Autocomplete from "components/Autocomplete";
import Loading from "components/Loading";
import ImageUploader from "components/ImageUploader";
// Material UI
import { Grid, Typography, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Api
import { SETTINGS_DEPARTMENTS, USERS_LIST } from "constants/api";

// Styles
const useStyles = makeStyles(() => ({
  formWrapper: {
    width: "100%",
  },
  formRow: {
    marginBottom: 15,
  },
  switchWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  dragIcon: {
    marginRight: 10,
  },
  loading: {
    height: "40vh",
  },
  title: {
    fontWeight: "bold",
    fontSize: 13,
  },
}));

const THEME_TYPE = "light";

const DepartmentForm = ({
  blurredImage,
  departmentData,
  files,
  fromSettings,
  loading,
  setBlurredImage,
  setFiles,
}) => {
  // Hooks
  const classes = useStyles();
  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext();

  const departmentId = departmentData?.id;
  const url =
    `${SETTINGS_DEPARTMENTS}?OnlyPotentialDepartmentContainer=true` +
    (departmentId ? `&ExcludeBranchFromRootId=${departmentId}` : "") +
    "&q";

  // Effects
  useEffect(() => {
    if (departmentData) {
      setValue("name", departmentData.name);
      setValue("user", departmentData.owner);
      setValue("hashtag", departmentData.hashtag);
      setValue("imageUrl", departmentData.imageUrl);
      setValue("area", {
        path: departmentData?.path,
        name: "",
      });
      setBlurredImage(departmentData.blurredImage);
    }
  }, [departmentData]);

  if (loading)
    return (
      <div className={classes.loading}>
        <Loading showWrapper={false} />
      </div>
    );

  return (
    <Fade in timeout={400}>
      <form>
        <Grid container spacing={1} style={{ width: "100%" }}>
          <Grid item xs={12} className={classes.formRow}>
            <Typography color="primary" className={classes.title}>{`PERCORSO: ${
              typeof watch("area") === "string"
                ? watch("area")
                : watch("area")?.path || watch("area")?.name
                ? watch("area")?.path +
                  (watch("area")?.path !== "/" &&
                  watch("area")?.path !== "undefined" &&
                  watch("area")?.name &&
                  watch("area")?.name !== "undefined"
                    ? "/"
                    : "") +
                  watch("area")?.name
                : "/"
            }`}</Typography>
          </Grid>

          {fromSettings && (
            <Grid item xs={12} className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    label="Percorso"
                    endpoint={url}
                    error={!!errors.area}
                    getOptionLabel={option =>
                      option?.path || option?.name
                        ? option?.path +
                          (option?.path !== "/" &&
                          option?.path !== "undefined" &&
                          option?.name &&
                          option?.name !== "undefined"
                            ? "/"
                            : "") +
                          option?.name
                        : "/"
                    }
                    themeType={THEME_TYPE}
                    {...field}
                    onChange={(_, data) => field.onChange(data)}
                  />
                )}
                name="area"
                control={control}
              />
            </Grid>
          )}

          <Grid item xs={12} className={classes.formRow}>
            <Controller
              render={({ field }) => (
                <TextField
                  label="Nome dipartimento"
                  error={!!errors.name}
                  required
                  themeType={THEME_TYPE}
                  shrink={!!field.value}
                  {...field}
                />
              )}
              name="name"
              control={control}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  label="Owner di dipartimento"
                  endpoint={`${USERS_LIST}?me=true&q`}
                  error={!!errors.user}
                  getOptionLabel={option => `${option.name} ${option.surname}`}
                  themeType={THEME_TYPE}
                  {...field}
                  onChange={(_, data) => field.onChange(data)}
                />
              )}
              name="user"
              control={control}
            />
          </Grid>

          <Grid item xs={12} className={classes.formRow}>
            <Controller
              render={({ field }) => (
                <TextField
                  label="Hashtag di dipartimento"
                  themeType={THEME_TYPE}
                  shrink={!!field.value}
                  {...field}
                />
              )}
              name="hashtag"
              control={control}
            />
          </Grid>
          <Grid item xs>
            <ImageUploader
              selectedFiles={files}
              onSelect={setFiles}
              onBlurChange={setBlurredImage}
              blurrable
              blurActive={blurredImage}
              imageUrl={watch("imageUrl")}
              themeType={THEME_TYPE}
            />
          </Grid>
          {/* <Grid
            item
            xs={12}
            className={classes.formRow}
            style={!departmentData?.id ? { display: "none" } : null}
          >
            <Autocomplete
              label="People"
              endpoint={`${USERS_LIST}?q`}
              error={!!errors.people}
              getOptionLabel={option => `${option?.name} ${option?.surname}`}
              disabled
              defaultValue={departmentData?.people || []}
              multiple
              themeType={THEME_TYPE}
            />
          </Grid> */}
        </Grid>
      </form>
    </Fade>
  );
};

DepartmentForm.propTypes = {
  areaTitle: PropTypes.string,
  blurredImage: PropTypes.bool,
  departmentData: PropTypes.object,
  files: PropTypes.array,
  fromSettings: PropTypes.bool,
  loading: PropTypes.bool,
  setBlurredImage: PropTypes.func,
  setFiles: PropTypes.func,
};

export default DepartmentForm;
