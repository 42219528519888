import React from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { PRIMARY } from "constants/colors";
import { CheckBoxControl } from "components/Checkbox";

// Styles
const useStyles = makeStyles(() => ({
  coloredScrollbar: {
    width: "100%",
    "& .ps__thumb-y": {
      backgroundColor: PRIMARY,
    },
    "& :hover": {
      backgroundColor: "transparent",
    },
  },
  checkBoxContainer: ({ horizontalDirection }) => ({
    display: "flex",
    flexDirection: !horizontalDirection ? "column" : "row",
  }),
  checkBox: ({ horizontalDirection }) => ({
    margin: !horizontalDirection ? "5px 0" : "0 5px",
    display: "flex",
    alignItems: "center",
  }),
}));
/**
 * @param  control - [react-hook-form control Object](https://react-hook-form.com/api/useform/control)
 * @param  checkBoxArray - list of elements to create the CheckboxList
 * @param  horizontalDirection - explicit in direction of container is horizontal
 * @example <CheckList 
 *              control={control}
 *              checkBoxArray={[
 *                {
                    key: "isFormatted",
                    defaultValue: false,
                    disabled: false,
                    label: "Format TimeZone",
                  }
 *              ]}
 *           />
 */
const CheckList = ({ control, checkBoxArray, horizontalDirection }) => {
  // Hooks
  const classes = useStyles({ horizontalDirection });
  return (
    <PerfectScrollbar
      id="pf-radio-groups"
      className={classes.coloredScrollbar}
      options={{ suppressScrollY: horizontalDirection }}
    >
      <div className={classes.checkBoxContainer}>
        {checkBoxArray?.map((el, idx) => (
          <div key={idx} className={classes.checkBox}>
            <CheckBoxControl
              control={control}
              name={el.key}
              defaultValue={el?.defaultValue || false}
              disabled={el?.disabled || false}
              label={el?.label || ""}
              customColorChecked={el?.customColorChecked || ""}
              customColorUnchecked={el?.customColorUnchecked || ""}
            />
            {el?.label && <p>{el.label}</p>}
            {el?.icon && el.icon}
          </div>
        ))}
      </div>
    </PerfectScrollbar>
  );
};

CheckList.propTypes = {
  control: PropTypes.object.isRequired,
  checkBoxArray: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      defaultValue: PropTypes.bool,
      disabled: PropTypes.bool,
      label: PropTypes.string,
      icon: PropTypes.node,
      customColorChecked: PropTypes.string,
      customColorUnchecked: PropTypes.string,
    })
  ).isRequired,
  horizontalDirection: PropTypes.bool,
};

export default CheckList;
