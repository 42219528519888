import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "./TabPanel";
import { SPACE_CADET, PRIMARY, OXFORD_BLUE } from "constants/colors";

const a11yProps = index => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: OXFORD_BLUE,
    "& .MuiTabs-indicator, .Mui-selected": {
      backgroundColor: PRIMARY,
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: `2px solid ${PRIMARY}`,
      backgroundColor: SPACE_CADET,
    },
    "& .MuiTabs-fixed": {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
  },
}));

const CustomTab = withStyles(() => ({
  root: {
    textTransform: "none",
  },
}))(props => (
  <Tab {...props} style={{ fontWeight: props.selected ? 700 : 100 }} />
));

const CustomTabs = ({ currentOpenTab, tabs, setCurrentOpenTab }) => {
  const classes = useStyles();
  const [value, setValue] = useState(currentOpenTab);

  useEffect(() => {
    setValue(currentOpenTab);
  }, [currentOpenTab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentOpenTab && setCurrentOpenTab(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        {tabs.map((tab, idx) => (
          <CustomTab key={idx} label={tab.label} {...a11yProps(idx)} />
        ))}
      </Tabs>
      {tabs.map((tab, idx) => (
        <TabPanel key={idx} value={value} index={idx}>
          {tab.content}
        </TabPanel>
      ))}
    </div>
  );
};

CustomTabs.defaultProps = {
  currentOpenTab: 0,
};

CustomTabs.propTypes = {
  currentOpenTab: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.node,
    })
  ).isRequired,
  setCurrentOpenTab: PropTypes.func,
};

export default CustomTabs;
