import React from "react";
import PropTypes from "prop-types";

import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material";

const StyledLink = styled(RouterLink)({
  color: "white",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  "&:active,:visited": {
    color: "white",
  },
});

const Link = props => {
  return <StyledLink {...props} />;
};

Link.propTypes = {
  to: PropTypes.string,
};

export { Link };
