import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
// Components
import Switch from "components/Switch";
import SvgIcon from "components/SvgIcon";
// Material UI
import theme from "themeV4";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
// Constants
import { WILD_BLUE_YONDER } from "constants/colors";
// Assests
import { ReactComponent as UploadIcon } from "assets/upload.svg";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: " flex",
    alignItems: "flex-end",
  },
  fullPreview: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "absolute",
    left: 0,
    top: 0,
    "& img": {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
  },
  blurOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.15)",
    backdropFilter: "blur(8px)",
    zIndex: 5,
  },
  hint: {
    position: "absolute",
    top: "50%",
    zIndex: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  switchWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  typography: {
    color: WILD_BLUE_YONDER,
  },
  icon: {
    cursor: "pointer",
    marginRight: theme.spacing(2),
  },
  hintBackground: ({ themeType }) => ({
    display: "flex",
    alignItems: "center",
    backgroundColor:
      themeType === "dark"
        ? theme.palette.cardCarouselBackground.light
        : theme.palette.componentsBackground.light,
    borderRadius: 10,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  }),
}));

const ImageUploader = ({
  accept,
  blurrable,
  blurActive,
  multiple,
  onBlurChange,
  onSelect,
  selectedFiles,
  imageUrl,
  themeType,
  dimensions,
}) => {
  // Hooks
  const classes = useStyles({ themeType });

  // Styles
  const dropzoneStyle = {
    backgroundColor: theme.palette.componentsBackground.main,
    border: `2px dotted ${WILD_BLUE_YONDER}`,
    borderRadius: 4,
    width: dimensions.width,
    height: dimensions.height,
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
  };

  const onDrop = useCallback(
    acceptedFiles => {
      const updatedFiles = multiple
        ? [...selectedFiles, ...acceptedFiles]
        : acceptedFiles;
      onSelect(updatedFiles);
    },
    [selectedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
    multiple,
  });

  const renderFullPreview = () => {
    if (multiple || (selectedFiles.length !== 1 && !imageUrl)) return null;

    return (
      <>
        {blurrable && blurActive && <div className={classes.blurOverlay}></div>}
        <div className={classes.fullPreview}>
          <img
            src={
              selectedFiles[0]
                ? URL.createObjectURL(selectedFiles[0])
                : imageUrl
            }
          />
        </div>
      </>
    );
  };

  const renderHint = () => {
    const hintText =
      selectedFiles.length > 0 || imageUrl
        ? "Sostituisci immagine da qui"
        : "Carica immagine qui";
    return (
      <div className={classes.hint}>
        <div className={classes.hintBackground}>
          <SvgIcon
            icon={<UploadIcon />}
            color={
              themeType === "light"
                ? theme.palette.componentsBackground.main
                : "inherit"
            }
            className={classes.icon}
            width={24}
            height="auto"
          />
          <Typography>{hintText}</Typography>
        </div>
      </div>
    );
  };

  const renderBlurSwitch = () => {
    if (!blurrable || multiple) return null;

    return (
      <div className={classes.switchWrapper}>
        <Switch
          checked={blurActive}
          onChange={e => onBlurChange(e.target.checked)}
          disabled={!(selectedFiles[0] || imageUrl)}
        />
        <Typography className={classes.typography}>
          Applica effetto blur
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.wrapper}>
      <div
        {...getRootProps({
          style: {
            ...dropzoneStyle,
            backgroundColor:
              themeType === "light" && theme.palette.componentsBackground.light,
          },
        })}
      >
        <input {...getInputProps()} />
        {renderHint()}
        {renderFullPreview()}
      </div>
      {renderBlurSwitch()}
    </div>
  );
};

ImageUploader.defaultProps = {
  accept: "image/*",
  blurActive: false,
  multiple: false,
  selectedFiles: [],
  themeType: "dark",
  dimensions: { width: 350, height: 250 },
};

ImageUploader.propTypes = {
  accept: PropTypes.string,
  blurrable: PropTypes.bool,
  blurActive: PropTypes.bool,
  multiple: PropTypes.bool,
  onBlurChange: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array,
  imageUrl: PropTypes.string,
  themeType: PropTypes.string,
  dimensions: PropTypes.object,
};

export default ImageUploader;
