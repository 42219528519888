//Hooks
import useFetch from "hooks/useHTTP";
import useFilters from "hooks/useFilters";
import { useState } from "react";
import { useEffect } from "react";
import {
  effortGraphColor,
  workLoadFiltersTypes,
  workloadContext,
} from "utils/enums/workLoad";
import {
  DEPARTMENTS,
  PROJECT,
  USERS_LIST,
  WORKLOAD_EFFORT,
} from "constants/api";
import { MALACHITE, PACIFIC_BLU, WILD_BLUE_YONDER } from "constants/colors";
import {
  parseDate,
  parseMonth,
  parseWeek,
  timeToHours,
  truncateDecimals,
} from "utils";
import { useParams } from "react-router-dom";

export const filtersMap = {
  [workLoadFiltersTypes.DAYS]: {
    label: "DAYS",
    filterFn: parseDate,
  },
  [workLoadFiltersTypes.WEEKS]: {
    label: "WEEKS",
    filterFn: parseWeek,
  },
  [workLoadFiltersTypes.MONTHS]: {
    label: "MONTHS",
    filterFn: parseMonth,
  },
};

const stacksMap = {
  [effortGraphColor.PAST]: {
    color: PACIFIC_BLU,
    tooltipTexts: ["rendicontate"],
  },
  [effortGraphColor.TODAY]: {
    color: MALACHITE,
    tooltipTexts: ["totali", "ore rendicontate"],
  },
  [effortGraphColor.FUTURE]: {
    color: WILD_BLUE_YONDER,
    tooltipTexts: ["da rendicontare"],
  },
};

const useWorkloadEffort = (context = workloadContext.PROJECT) => {
  const isUser = context === workloadContext.USER;

  // Hooks
  const { get } = useFetch();
  const { departmentId, id: userId, projectId } = useParams();

  const { filters, setFilters } = useFilters({
    EffortFilter: workLoadFiltersTypes.DAYS,
  });
  // State
  const [data, setData] = useState(false);
  const [target, setTarget] = useState(null);
  const [loading, setLoading] = useState(false);

  // Effects
  useEffect(() => {
    fetchEffortGraph(filters.EffortFilter);
  }, [filters.EffortFilter]);

  const workloadContextMap = {
    [workloadContext.PROJECT]: {
      baseEndpoint: `${PROJECT}/${projectId}`,
    },
    [workloadContext.DEPARTMENT]: {
      baseEndpoint: `${DEPARTMENTS}/${departmentId}`,
    },
    [workloadContext.USER]: {
      baseEndpoint: `${USERS_LIST}/${userId}`,
    },
  };

  // API
  const fetchEffortGraph = async range => {
    try {
      setLoading(true);
      const filterFunction =
        filtersMap[filters.EffortFilter]?.filterFn || parseDate;

      const qs = range ? `?type=${range}` : "";

      const res = await get(
        `${workloadContextMap[context]?.baseEndpoint}/${isUser ? "effortGraph" : WORKLOAD_EFFORT}${qs}`
      );
      if (res.ok) {
        const newData = res.data.bars.map(item => {
          const x = filterFunction(item.x).toString();
          const text = stacksMap[item.type]?.tooltipTexts || ["rendicontate"];
          const y = item.y.map(({ name, expectedHours, reportedHours }) => {
            return {
              name: name
                ? name
                : `#${isUser ? "PROGETTO" : "UTENTE"} SCONOSCIUTO`,
              hours:
                truncateDecimals(timeToHours(reportedHours), 1) +
                truncateDecimals(timeToHours(expectedHours), 1),
              extraText:
                (item.type === effortGraphColor.TODAY &&
                  reportedHours &&
                  text[1]) ||
                "",
              extraY:
                (item.type === effortGraphColor.TODAY &&
                  (truncateDecimals(timeToHours(reportedHours), 1) || "0")) ||
                "",
            };
          });

          return {
            x,
            type: item.type,
            fill: stacksMap[item.type]?.color || PACIFIC_BLU,
            toolTipText: text[0],
            y,
          };
        });
        setData(newData);
        setTarget(res.data.target);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    filters,
    setFilters,
    target,
  };
};

export { useWorkloadEffort };
