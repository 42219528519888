import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
// Material UI
import { Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Components
import Sentiment from "components/KpiBe/Sentiment";
// Constants
import { SPACE_CADET, WHITE } from "constants/colors";
// Shapes
import { PersonShape } from "../shapes";

// Styles
const useStyles = makeStyles(() => ({
  wrapper: ({ avatarSize }) => ({
    marginTop: 10,
    width: 250,
    backgroundColor: SPACE_CADET,
    padding: 16,
    borderRadius: 4,
    left: `calc(-125px + ${avatarSize / 2}px)`,
    position: "absolute",
    boxShadow: "0px 12px 32px #00000052",
  }),
  additionalName: {
    color: WHITE,
    fontWeight: "bold",
    fontSize: 20,
  },
  name: {
    color: WHITE,
    fontWeight: "bold",
    fontSize: 15,
  },
  jobTitle: {
    color: WHITE,
    fontFamily: "Nunito-Regular",
    fontSize: 11,
    textTransform: "uppercase",
  },
  stats: {
    display: "flex",
    color: WHITE,
    gap: 10,
    marginTop: 6,
    alignItems: "flex-end",
  },
  skills: {
    flexGrow: 0,
  },
  skillsValues: {
    fontWeight: "bold",
    fontSize: 15,
  },
  sentiment: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 0,
  },
  sentimentIcon: {
    flexGrow: 1,
  },
  statsLabel: {
    flexGrow: 0,
    fontSize: 11,
    textTransform: "uppercase",
  },
}));

const PersonInfo = ({
  className,
  data,
  avatarSize,
  phaseName,
  projectName,
}) => {
  const {
    name,
    surname,
    jobTitle,
    sentiment = 1,
    skillCount = 0,
    departmentSkillCount = 0,
  } = data;

  // Hooks
  const classes = useStyles({ avatarSize });

  return (
    <Fade in timeout={250}>
      <div className={clsx(classes.wrapper, className)}>
        {(phaseName || projectName) && (
          <div className={classes.additionalName}>
            {phaseName || projectName}
          </div>
        )}
        <div className={classes.name}>
          {name} {surname}
        </div>
        <div className={classes.jobTitle}>{jobTitle?.name}</div>

        <div className={classes.stats}>
          <div className={classes.skills}>
            <span className={classes.skillsValues}>
              {skillCount}/{departmentSkillCount}
            </span>
            <div className={classes.statsLabel}>Skills</div>
          </div>

          <div className={classes.sentiment}>
            <div className={classes.sentimentIcon}>
              <Sentiment sentiment={sentiment} width={14} height={14} />
            </div>
            <div className={classes.statsLabel}>Sentiment</div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

PersonInfo.defaultProps = {};

PersonInfo.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape(PersonShape),
  avatarSize: PropTypes.number.isRequired,
  phaseName: PropTypes.string,
  projectName: PropTypes.string,
};

export default PersonInfo;
