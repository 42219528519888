export const firstColConfig = {
  width: {
    collapsed: 100,
    expanded: 400,
  },
};

export const secondColConfig = {
  width: {
    collapsed: 340,
  },
};

export const mainColConfig = {
  horizontalPadding: 100,
};
