import React from "react";

// assets
import { ReactComponent as General } from "assets/settings/general.svg";
import { ReactComponent as Department } from "assets/settings/department.svg";
import { ReactComponent as Skill } from "assets/settings/skill.svg";
import { ReactComponent as User } from "assets/settings/user.svg";
import { ReactComponent as JobTitle } from "assets/settings/job-title.svg";
import { ReactComponent as Portfolio } from "assets/settings/portfolio.svg";
import { ReactComponent as Project } from "assets/settings/project.svg";
import { ReactComponent as Brick } from "assets/settings/brick.svg";
import { ReactComponent as Customer } from "assets/settings/customer.svg";
import { ReactComponent as Widget } from "assets/settings/widget.svg";

export const settingsMock = [
  {
    icon: <General />,
    title: "Generale",
    key: "general",
  },
  {
    icon: <Department />,
    title: "Dipartimenti",
    key: "departments",
  },
  {
    icon: <User />,
    title: "Utenti",
    key: "users",
  },
  {
    icon: <Skill />,
    title: "Skill",
    key: "skills",
  },
  {
    icon: <JobTitle />,
    title: "Job Title",
    key: "jobtitles",
  },
  {
    icon: <Portfolio />,
    title: "Portfolio",
    key: "portfolios",
  },
  {
    icon: <Project />,
    title: "Progetti",
    key: "projects",
  },
  {
    icon: <Brick />,
    title: "Brick",
    key: "bricks",
  },
  {
    icon: <Customer />,
    title: "Clienti",
    key: "customers",
  },
  {
    icon: <Widget />,
    title: "Widget",
    key: "widgets",
  },
];
