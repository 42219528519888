import { useState, useCallback } from "react";

const useDrawer = () => {
  // State
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = useCallback(() => {
    setIsDrawerOpen(!isDrawerOpen);
  }, [isDrawerOpen]);

  return {
    isDrawerOpen,
    setIsDrawerOpen,
    toggleDrawer,
  };
};

export default useDrawer;
