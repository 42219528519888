import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

// components
import Logo from "components/Logo";
import Layout from "components/Layout";

// icons
import { ReactComponent as CheckIcon } from "assets/check.svg";
import { LOGIN } from "constants/routes";

// constants
import { WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "20%",
  },
  margin: {
    margin: theme.spacing(1),
    width: "100%",
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    color: WILD_BLUE_YONDER,
    textAlign: "center",
  },
  greatherIconWrapper: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(8),
    width: 60,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.light,
  },
  iconWrapper: {
    width: 45,
    height: 45,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    height: "4%",
  },
}));

const PasswordChanged = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Layout showHeader={false} showFirstCol={false} showSecondCol={false}>
      <div className={classes.wrapper}>
        <div className={classes.contentWrapper}>
          <Logo isBlue />
          <Typography variant="h5" className={classes.title}>
            Password modificata con successo
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {`Una mail di conferma è stata mandata al tuo indirizzo e-mail.\n Per
          accedere effettua il login con la nuova password`}
          </Typography>
          <div className={classes.greatherIconWrapper}>
            <div className={classes.iconWrapper}>
              <CheckIcon alt="check_icon" />
            </div>
          </div>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => history.push(LOGIN)}
          >
            OK
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default PasswordChanged;
