export const mounts = [
  "Genn",
  "Feb",
  "Mar",
  "Apr",
  "Mag",
  "Giu",
  "Lug",
  "Ago",
  "Set",
  "Ott",
  "Nov",
  "Dic",
];

export const planningMode = {
  CLASSIC: "classic",
  MULTIPLE: "multiple-dates",
};

export const planningModeNumericMap = {
  [planningMode.CLASSIC]: 1,
  [planningMode.MULTIPLE]: 2,
};
