import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { WHITE, WILD_BLUE_YONDER, SPACE_CADET } from "constants/colors";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    height: "100%",
  },
}));

const SolidGauge = props => {
  const classes = useStyles();
  const { data, id, legend, color, theme } = props;

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return;

    // Create chart instance
    const chart = am4core.create(`div${id}`, am4charts.RadarChart);
    chart.colors.list = theme;
    chart.data = data.series;
    chart.innerRadius = 30;
    chart.paddingTop = 0;
    chart.paddingBottom = 0;
    chart.paddingLeft = 0;
    chart.paddingRight = 0;

    // Create axes
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.renderer.innerRadius = 80;
    categoryAxis.renderer.opposite = true;

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.labels.template.disabled = true;

    var series1 = chart.series.push(new am4charts.RadarColumnSeries());
    series1.dataFields.valueX = "full";
    series1.dataFields.categoryY = "category";
    series1.clustered = false;
    series1.columns.template.cornerRadiusTopLeft = 100;
    series1.columns.template.strokeWidth = 0;
    series1.columns.template.radarColumn.cornerRadius = 100;
    series1.columns.template.adapter.add("fill", function () {
      return am4core.color(SPACE_CADET);
    });
    // Create series
    var series = chart.series.push(new am4charts.RadarColumnSeries());
    series.dataFields.valueX = "value";
    series.dataFields.categoryY = "category";
    series.clustered = true;
    series.columns.template.stroke = false;
    series.columns.template.strokeWidth = 0;
    //series.columns.template.width = am4core.percent(60);
    series.columns.template.tooltipText = "{category}: [bold]{value}[/]";
    series.columns.template.radarColumn.cornerRadius = 10;
    series.columns.template.radarColumn.strokeWidth = 0;

    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    // Add a legend
    if (legend) {
      chart.legend = new am4charts.Legend();
      chart.legend.position = "middle";
      chart.legend.labels.template.fontSize = "13px";
      chart.legend.labels.template.truncate = true;
      chart.legend.labels.template.fill = am4core.color(color);
      chart.legend.itemContainers.template.paddingTop = 0;
      chart.legend.itemContainers.template.paddingBottom = 2;
      const marker = chart.legend.markers.template;
      marker.width = 10;
      marker.height = 10;
      marker.children.getIndex(0).cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 0;

      series.events.on("ready", function () {
        var data = [];
        series.dataItems.each(function (dataItem) {
          data.push({
            name: dataItem.categoryY,
            fill: dataItem.column.fill,
            seriesDataItem: dataItem,
          });
        });
        chart.legend.data = data;
        chart.legend.itemContainers.template.events.on(
          "toggled",
          function (event) {
            var seriesDataItem =
              event.target.dataItem.dataContext.seriesDataItem;
            if (event.target.isActive) {
              seriesDataItem.hide(series.interpolationDuration, 0, 0, [
                "valueX",
              ]);
            } else {
              seriesDataItem.show(series.interpolationDuration, 0, ["valueX"]);
            }
          }
        );
      });
    }

    if (data.percentage) {
      let label = chart.radarContainer.createChild(am4core.Label);
      label.html =
        "<div style='display: flex; flex-direction: column; align-items: center;'>" +
        `<div>${data.percentage}%</div><div style='font-size: 12px;font-weight:normal'>` +
        `${data.labelPercentage}</div></div>`;
      label.fill = am4core.color(WHITE);
      label.fontSize = 30;
      label.fontWeight = "bold";
      label.horizontalCenter = "middle";
      label.verticalCenter = "middle";
      label.isMeasured = true;
    }
  }, [data]);

  return <div className={classes.container} id={`div${id}`} />;
};

SolidGauge.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  theme: PropTypes.array,
  legend: PropTypes.bool,
  color: PropTypes.string,
};

SolidGauge.defaultProps = {
  legend: false,
  color: WILD_BLUE_YONDER,
};

export default SolidGauge;
