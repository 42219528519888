import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// Material UI
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Constants
import { SPACE_CADET, WHITE, WILD_BLUE_YONDER } from "constants/colors";
// Components
// import KpiDo from "components/KpiDo";
import KpiBe from "components/KpiBe";
import SvgIcon from "components/SvgIcon";
import Popper from "components/Popper";
// Enums
import { metricTypes } from "utils/enums/grooveMetrics";
import { beKpiTypes } from "utils/enums/grooveKpis";
// Assets
import { ReactComponent as BulletIcon } from "assets/bullets.svg";
import userPlaceholder from "assets/user_placeholder.svg";
import { useContext } from "react";
import { AppContext } from "context/AppContext";

// Style
const useStyles = makeStyles(theme => ({
  wrapper: {
    width: 225,
  },
  header: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    aspectRatio: 1,
    borderRadius: "100%",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
  },
  background: ({ image, blur }) => ({
    backgroundColor: image ? "transparent" : theme.palette.primary.main,
    background: `url("${image}")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: 84,
    filter: blur && image && "blur(8px)",
  }),
  info: {
    flex: 1,
    backgroundColor: SPACE_CADET,
    padding: "40px 10px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
  },
  user: ({ owner }) => ({
    position: "absolute",
    width: 45,
    aspectRatio: 1,
    borderRadius: "100%",
    top: "0",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundImage: `url("${owner?.imageUrl || userPlaceholder}")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }),
  title: {
    textAlign: "center",
    width: "calc(100% - 40px)",
    fontSize: 20,
  },
  kpiWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: "auto",
    marginTop: 6,
    width: "89%",
  },
  kpiNumber: {
    fontSize: 25,
    height: 30,
  },
  doLabel: {
    marginTop: 5,
  },
  icon: {
    cursor: "pointer",
    position: "relative",
    zIndex: 10,
  },
}));

const REPUTATION_OFFSET = 3;

const Department = ({
  blur,
  // cost,
  image,
  membersCount,
  // metric,
  name,
  onClick,
  owner,
  // quality,
  reputation,
  sentiment,
  skillsCountDepartment,
  // time,
  onEdit,
}) => {
  const { settings } = useContext(AppContext);
  const classes = useStyles({ image, owner, blur });
  const typoRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const isBeActive = settings?.isBeActive;

  const popperItems = [
    {
      onClick: () => {
        setAnchorEl(null);
        onEdit();
      },
      text: "Gestisci dipartimento",
    },
  ];

  const open = Boolean(anchorEl);
  const id = open ? "card-popper" : undefined;

  useEffect(() => {
    if (typoRef.current) {
      //Getting computed line-heght for a specific element (the h5 Typography)
      const computedDivLineHeight =
        window.getComputedStyle &&
        document.defaultView
          .getComputedStyle(typoRef.current, null)
          .getPropertyValue("line-height")
          .replace("px", "");
      //The effective height of the text
      const actualTextHeight = typoRef.current.clientHeight;
      //Calculate the number of lines rendered
      const lines =
        actualTextHeight && computedDivLineHeight
          ? +actualTextHeight / +computedDivLineHeight
          : 1;
      if (Math.ceil(lines) > 2) {
        typoRef.current.style.fontSize = "13px";
      }
    }
  }, []);

  // Renders
  const renderHeader = () => (
    <>
      <div className={classes.header}>
        <div className={classes.background}></div>
        <div className={classes.info}>
          <div className={classes.user}></div>
          <Typography className={classes.title} variant="h5" ref={typoRef}>
            {name}
          </Typography>
          <Typography>{membersCount} People</Typography>
        </div>
      </div>
    </>
  );

  const renderKpis = () => (
    <div className={classes.kpiWrapper}>
      {/* {metric === metricTypes.DO && (
        <>
          <KpiDo
            number={time}
            label="time"
            orientation="column"
            numberClassname={classes.kpiNumber}
            labelClassname={classes.doLabel}
            alignItems="center"
          />
          <KpiDo
            number={cost}
            label="cost"
            orientation="column"
            numberClassname={classes.kpiNumber}
            labelClassname={classes.doLabel}
            alignItems="center"
          />
          <KpiDo
            number={quality}
            label="quality"
            orientation="column"
            numberClassname={classes.kpiNumber}
            labelClassname={classes.doLabel}
            alignItems="center"
          />
        </>
      )}
      {metric === metricTypes.BE && (
        <> */}
      {isBeActive && <KpiBe type={beKpiTypes.SENTIMENT} value={sentiment} />}
      <KpiBe
        type={beKpiTypes.REPUTATION}
        value={reputation + REPUTATION_OFFSET}
      />
      <KpiBe type={beKpiTypes.SKILLS} value={skillsCountDepartment} />
      {/* </>
      )} */}
    </div>
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: -12,
        }}
      >
        <SvgIcon
          color={anchorEl ? WHITE : WILD_BLUE_YONDER}
          onMouseEnter={e => setAnchorEl(anchorEl ? null : e.currentTarget)}
          onMouseLeave={() => setAnchorEl(null)}
          icon={<BulletIcon />}
          width={24}
          height="auto"
          className={classes.icon}
        >
          <Popper
            id={id}
            open={open}
            placement="bottom-end"
            anchorEl={anchorEl}
          >
            {popperItems}
          </Popper>
        </SvgIcon>
      </div>
      <div className={classes.wrapper} onClick={onClick}>
        {renderHeader()}
        {renderKpis()}
      </div>
    </>
  );
};

Department.defaultProps = {
  cost: 0,
  membersCount: 0,
  metric: metricTypes.BE,
  quality: 0,
  reputation: 0,
  sentiment: 0,
  skillsCountDepartment: 0,
  time: 0,
  blur: true,
};

Department.propTypes = {
  blur: PropTypes.bool,
  cost: PropTypes.number,
  image: PropTypes.string,
  membersCount: PropTypes.number,
  metric: PropTypes.oneOf(Object.values(metricTypes)),
  name: PropTypes.string,
  onClick: PropTypes.func,
  owner: PropTypes.object,
  quality: PropTypes.number,
  reputation: PropTypes.number,
  sentiment: PropTypes.number,
  skillsCountDepartment: PropTypes.number,
  time: PropTypes.number,
  onEdit: PropTypes.func,
};

export default Department;
