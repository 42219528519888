import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { PickersDay } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";
import { ReactComponent as CalendarIcon } from "assets/calendar-light.svg";
import { IconButton, InputAdornment } from "@mui/material";
import { useState } from "react";
import { Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { ERROR } from "constants/colors";
import PerfectScrollbar from "react-perfect-scrollbar";

require("dayjs/locale/it");
dayjs.locale("it"); // use locale globally

const StyledPickerDay = styled(PickersDay, {
  shouldForwardProp: prop => prop !== "selected",
})(({ theme, selected, isSunday, isToday }) => ({
  color: theme.palette.common.black,
  ...(isToday && {
    color: theme.palette.primary.main,
  }),
  ...(isSunday && { color: ERROR }),
  ...(selected && {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
    border: `1px solid ${theme.palette.primary.main}`,
  }),
}));

const StyledDatePicker = styled(DatePicker, {
  shouldForwardProp: prop => prop !== "selected",
})(({ theme, themeType }) => ({
  width: "100%",
  "& .MuiInputBase-root": {
    color:
      themeType === "dark"
        ? theme.palette.componentsBackground.light
        : theme.palette.common.black,
    backgroundColor:
      themeType === "dark"
        ? theme.palette.componentsBackground.main
        : theme.palette.componentsBackground.light,

    "& .MuiPickersCalendarHeader-label": {
      color: theme.palette.common.black,
    },
  },
  ...(themeType === "dark" && {
    "& .MuiFormLabel-root:not(.Mui-focused)": {
      color: theme.palette.componentsBackground.light,
    },
  }),
}));

const SelectedDates = styled("div")(({ theme, selectedCount }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(3),
  paddingRight: 0,
  width: "100%",
  backgroundColor: theme.palette.componentsBackground.light,
  borderRadius: 4,

  ...(selectedCount > 6 && { height: 170, overflow: "auto" }),

  "& .selected-dates-title": {
    textTransform: "uppercase",
    fontSize: 9,
    fontWeight: 700,
    color: "#666",
  },

  "& .selected-date": {
    fontWeight: 500,
  },
}));

const SelectedScrollBar = styled("div")(({ selectedCount }) => ({
  ...(selectedCount > 6 && { height: 107, overflow: "auto" }),
}));

const MultiDatePicker = ({ value, onChange, ...rest }) => {
  const [open, setOpen] = useState(false);

  const findDate = (dates, date) =>
    dates.find(item => item.isSame(date, "day"));

  const findIndexDate = (dates, date) =>
    dates.findIndex(item => item.isSame(date, "day"));

  const renderPickerDay = (date, selectedDates, pickersDayProps) => {
    const selected = findDate(value, date);
    return (
      <StyledPickerDay
        {...pickersDayProps}
        selected={selected}
        isSunday={date.day() === 0}
        isToday={date.isSame(dayjs(), "day")}
        onClick={() => handleChange(date)}
      />
    );
  };

  const handleChange = newValue => {
    if (value.length === 1 && newValue.isSame(value[0], "day"))
      return onChange([]);

    const array = [...value];
    const date = newValue.startOf("day");
    const index = findIndexDate(array, date);

    if (index >= 0) {
      array.splice(index, 1);
    } else {
      array.push(date);
    }
    const sortedArray = array.sort((a, b) => a.valueOf() - b.valueOf());
    onChange(sortedArray);
  };

  return (
    <>
      <StyledDatePicker
        displayStaticWrapperAs="desktop"
        label="Week picker"
        closeOnSelect={false}
        value={value}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={() => {}}
        renderDay={renderPickerDay}
        disableMaskedInput
        disableHighlightToday
        PopperProps={{
          sx: { "& .MuiCalendarPicker-root": { color: "#000" } },
        }}
        renderInput={params => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              value: "Seleziona date",
              style: { color: "#666" },
              disabled: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="end">
                    <CalendarIcon style={{ width: "auto", height: 18 }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={rest.error}
            onChange={() => {}}
            onClick={() => setOpen(prevOpen => !prevOpen)}
          />
        )}
        inputFormat="dd/MM/yyyy"
        {...rest}
      />
      {value?.length ? (
        <SelectedDates themeType="light">
          <Typography className="selected-dates-title">
            Date selezionate
          </Typography>
          <SelectedScrollBar selectedCount={value?.length}>
            <PerfectScrollbar id={"pf-scrollbar-selected-dates"} options={{ wheelSpeed: 0.1}}>
              {value.map(d => (
                <Typography className="selected-date" key={d.valueOf()}>
                  {d.locale("it").format("DD MMMM YYYY")}
                </Typography>
              ))}
            </PerfectScrollbar>
          </SelectedScrollBar>
        </SelectedDates>
      ) : null}
    </>
  );
};

const MultiDatePickerControl = ({
  control,
  name,
  defaultValue,
  rules,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <MultiDatePicker
          {...rest}
          {...field}
          onChange={selectedDates => {
            field.onChange(selectedDates);
          }}
        />
      )}
    />
  );
};

MultiDatePicker.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  rest: PropTypes.shape({
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    formControlStyle: PropTypes.object,
    disabled: PropTypes.bool,
    addAsteriks: PropTypes.bool,
  }),
};

MultiDatePickerControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  rest: PropTypes.shape({
    label: PropTypes.string,
    formControlStyle: PropTypes.object,
    disabled: PropTypes.bool,
    addAsteriks: PropTypes.bool,
  }),
};

export { MultiDatePicker, MultiDatePickerControl };
