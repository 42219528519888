import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TextFieldControl } from "components/TextField";

import { ReactComponent as SearchIcon } from "assets/search-image.svg";
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
import { WILD_BLUE_YONDER } from "constants/colors";
import { useWatch } from "react-hook-form";

import {
  ExpandBricksIcon,
  CollapseBricksIcon,
  SortBricksIcon,
} from "assets/icon-components";
import Popper from "components/Popper";
import { useState } from "react";

import CheckIcon from "assets/check.svg";
import { ProjectSorting } from "utils/enums/filters";

const UserDashboardHeader = ({
  globalExpanded,
  onGlobalExpandedClick,
  formControl,
  onClearForm,
  onChangeSorting,
  activeSorting,
  sx,
  loading,
}) => {
  const wSearchText = useWatch({ name: "Query", control: formControl });

  const [anchorEl, setAnchorEl] = useState(null);

  const popperItems = [
    {
      text: "Ultimi report fatti",
      onClick: () => onChangeSorting(ProjectSorting.LAST_ACTIVITY),
      ...(activeSorting === ProjectSorting.LAST_ACTIVITY && {
        img: CheckIcon,
      }),
    },
    {
      text: "Prossimi alla scadenza",
      onClick: () => onChangeSorting(ProjectSorting.PLANNED_END_DATE),
      ...(activeSorting === ProjectSorting.PLANNED_END_DATE && {
        img: CheckIcon,
      }),
    },
  ];

  const ExpandStatusIcon = globalExpanded
    ? CollapseBricksIcon
    : ExpandBricksIcon;
  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={4} sx={sx}>
        <Typography variant="h6" fontWeight="bold">
          Brick in corso
        </Typography>
        <Stack direction="row" gap={3}>
          <ExpandStatusIcon
            onClick={onGlobalExpandedClick}
            style={{ cursor: "pointer" }}
          />
          <div
            onMouseEnter={e => setAnchorEl(e.currentTarget)}
            onMouseLeave={() => setAnchorEl(null)}
          >
            <SortBricksIcon style={{ cursor: "pointer" }} />
            <Popper
              id="sort-bricks-popper"
              open={Boolean(anchorEl)}
              placement="bottom-end"
              anchorEl={anchorEl}
              darkMode
              title="Ordina progetti"
            >
              {popperItems}
            </Popper>
          </div>
        </Stack>
      </Stack>
      <Box mb={2}>
        <TextFieldControl
          control={formControl}
          name="Query"
          placeholder="Digita il nome del brick"
          icon={<SearchIcon width={16} height={16} />}
          disabled={loading}
          endIcon={
            wSearchText &&
            !loading && (
              <CancelIcon
                width={16}
                height={16}
                cursor="pointer"
                fill={WILD_BLUE_YONDER}
                onClick={onClearForm}
              />
            )
          }
        />
      </Box>
    </>
  );
};

UserDashboardHeader.propTypes = {
  globalExpanded: PropTypes.bool,
  onGlobalExpandedClick: PropTypes.func,
  onClearForm: PropTypes.func,
  onChangeSorting: PropTypes.func,
  formControl: PropTypes.object,
  loading: PropTypes.bool,
  activeSorting: PropTypes.oneOf(Object.values(ProjectSorting)),
  sx: PropTypes.object,
};

export { UserDashboardHeader };
