const cellTypes = {
  IMAGE: "IMAGE",
};

export const jobTitleConfig = {
  columns: {
    name: { label: "Job title" /* , id: 1 */ },
    peopleCount: { label: "Persone corrispondenti" /* , id: 2 */ },
    departmentCount: { label: "Dipartimenti corrispondenti" /* , id: 3 */ },
  },
};

export const skillsConfig = {
  columns: {
    name: { label: "Skill" /* , id: 1 */ },
    department: { label: "Dipartimento associato" },
    peopleCount: { label: "Persone corrispondenti" /* , id: 2 */ },
  },
};

export const usersConfig = {
  columns: {
    imageUrl: { label: "" },
    surname: { label: "Cognome" },
    name: { label: "Nome" },
    email: { label: "E-mail" },
    jobTitleName: { label: "Job Title" },
    departmentName: { label: "Dipartimento" },
    role: { label: "Ruolo" },
    active: { label: "Stato" },
  },
  types: {
    imageUrl: cellTypes.IMAGE,
  },
};

export const departmentsConfig = {
  columns: {
    name: { label: "Nome" },
    path: { label: "Percorso" },
  },
};

export const portfoliosConfig = {
  columns: {
    name: { label: "Nome" },
    path: { label: "Percorso" },
  },
};

export const customersConfig = {
  columns: {
    name: { label: "Nome" },
    referencesCount: { label: "Numero referenti" },
  },
};
