import { styled } from "@mui/material";
import {
  StaticDatePicker as MUIStaticDatePicker,
  PickersDay,
} from "@mui/x-date-pickers";
import {
  ERROR,
  OXFORD_BLUE,
  DREARY_BLUE,
  SPACE_CADET,
  WILD_BLUE_YONDER,
} from "constants/colors";

export const StyledDatePicker = styled(MUIStaticDatePicker, {
  shouldForwardProp: prop => !["selected", "themeType"].includes(prop),
})(({ themeType, theme }) => ({
  alignItems: "flex-start",
  "& .MuiPickerStaticWrapper-content": {
    backgroundColor: "transparent",
  },

  ...(themeType === "light"
    ? {
        "& .MuiCalendarPicker-root": {
          color: theme.palette.primary.main,
          backgroundColor: "transparent",
          borderRadius: 5,
        },

        "& .MuiDayPicker-weekDayLabel": {
          color: theme.palette.primary.main,
        },

        "& .MuiPickersArrowSwitcher-button": {
          color: theme.palette.primary.main,
          "&.Mui-disabled": {
            color: WILD_BLUE_YONDER,
          },
        },
      }
    : {
        "& .MuiCalendarPicker-root": {
          backgroundColor: SPACE_CADET,
          borderRadius: 5,
        },

        "& .MuiDayPicker-weekDayLabel": {
          color: "white",
        },

        "& .MuiPickersArrowSwitcher-button": {
          color: "white",
          "&.Mui-disabled": {
            color: WILD_BLUE_YONDER,
          },
        },
      }),
}));

export const StyledPickerDay = styled(PickersDay, {
  shouldForwardProp: prop =>
    !["selected", "isMarked", "themeType", "isToday"].includes(prop),
})(({ theme, themeType, isMarked, selected }) => ({
  backgroundColor: "transparent",
  lineHeight: 1,

  ...(isMarked &&
    !selected && {
      border: `1px solid ${ERROR} !important`,
    }),

  "&.Mui-disabled": {
    color: WILD_BLUE_YONDER,
  },

  ...(themeType === "light"
    ? {
        color: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: DREARY_BLUE,
          color: theme.palette.common.white,
        },
      }
    : {
        color: theme.palette.common.white,
        "&:hover": {
          backgroundColor: OXFORD_BLUE,
        },
      }),

  ...(selected && {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));
