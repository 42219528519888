import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import { PRIMARY } from "constants/colors";

const useStyles = makeStyles(() => ({
  default: {
    background: PRIMARY,
    height: props => props.height,
    width: props => props.width,
    borderRadius: props => props.borderRadius,
  },
}));

const BlueIcon = ({ height, width, borderRadius }) => {
  const classes = useStyles({ height, width, borderRadius });

  return <div className={classes.default}></div>;
};

BlueIcon.defaultProps = {
  width: "25px",
  height: "25px",
  borderRadius: "25px",
};

BlueIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
};

export default BlueIcon;
