import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

// Components
import { Typography } from "@material-ui/core";
import BlueIcon from "components/BlueIcon";

// Constants
import { WHITE, WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  icon: {
    margin: "5px 0",
  },
  text: {
    fontSize: "20px",
  },
  phase: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "column",
  },
  subPhase: {
    fontSize: "12px",
    color: WILD_BLUE_YONDER,
  },
  title: {
    fontSize: "15px",
    color: WHITE,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  wrap: {
    width: "100%",
    height: 60,
    padding: 10,
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "1px solid rgba(255, 255, 255, .2);",
  },
  valueContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const LabelList = ({ data, onClick, circleIcon }) => {
  const classes = useStyles({});

  const renderText = (str, customClass) => {
    return (
      <Typography component="h3" className={customClass || classes.text}>
        {str}
      </Typography>
    );
  };

  const renderLabel = el => {
    return (
      <div className={classes.wrap}>
        <div className={classes.icon}>
          {circleIcon ? (
            <BlueIcon width={"12px"} height={"12px"} borderRadius={"3px"} />
          ) : (
            <BlueIcon width={"12.5px"} height={"12.5px"} borderRadius={"0px"} />
          )}
        </div>
        <div className={classes.valueContainer}>
          <div className={classes.phase}>
            {onClick !== null ? (
              <Typography
                component="h2"
                className={classes.title}
                onClick={onClick}
              >
                {el?.name}
              </Typography>
            ) : (
              <Typography component="h2" className={classes.text}>
                {el?.name}
              </Typography>
            )}
            <Typography component="h2" className={classes.subPhase}>
              {el?.phaseName}
            </Typography>
          </div>
          {renderText(el.rightText)}
        </div>
      </div>
    );
  };

  return data?.map(el => {
    return renderLabel(el);
  });
};

LabelList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  circleIcon: PropTypes.bool,
};

LabelList.defaultProps = {
  data: [],
  clickAble: false,
  onClick: null,
  circleIcon: false,
};

export default LabelList;
