import React from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { HARVARD_CRIMSON, MALACHITE, WILD_BLUE_YONDER } from "constants/colors";

// Components
import { Reputation } from "components/KpiBe/Reputation";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "100%",
    display: "flex",
    float: "right",
    flexDirection: "row",
  },
  column: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  number: ({ color }) => ({
    width: "100%",
    textAlign: "right",
    color: color,
    fontSize: 32,
    marginTop: 10,
    fontWeight: "bold",
    marginRight: "7px",
    display: "flex",
    alignItems: "flex-end",
  }),
  decimalNumber: ({ color }) => ({
    color: color,
    fontSize: 22.5,
    marginBottom: "3px",
  }),
  reputation: {
    marginBottom: "10px",
    marginRight: "5px",
  },
}));

const Quality = ({ value }) => {
  /* 
    Le fasce di colore sono assegnate secondo i segueti criteri:
    - 4,5 - 5 -> verde
    - 3,5 - 4,4 -> grigio
    - 0 - 3.4 -> rosso
    
  */
  const color =
    value >= 0 && value <= 3.4
      ? HARVARD_CRIMSON
      : value >= 3.5 && value <= 4.4
      ? WILD_BLUE_YONDER
      : MALACHITE;

  const classes = useStyles({ color });
  const integerNumber = Math.floor(Math.round(value * 10) / 10);
  const decimal = (Math.round(value * 10) / 10).toString().split(".");

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.row}>
            <Reputation
              reputation={value}
              widthIcon={16}
              precision={0.5}
              starsHeight={16}
              customClass={classes.reputation}
            />

            <>
              <div className={classes.number}>
                {integerNumber}

                <div className={classes.decimalNumber}>
                  {","}
                  {decimal?.[1] || 0}
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

Quality.defaultProps = {
  value: 0,
};

Quality.propTypes = {
  value: PropTypes.number,
};

export default Quality;
