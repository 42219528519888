import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow as Row } from "@material-ui/core";
import clsx from "clsx";
import TableCell from "./TableCell";
import Button from "components/Button";
import { useState } from "react";
import { WHITE } from "constants/colors";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles(theme => ({
  rowWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  clickableRow: {
    cursor: "pointer",
  },
  popover: {
    pointerEvents: "none",
    transform: "translateY(-30px)",
    color: "red",
  },
  paper: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    backgroundColor: "#707070",
    color: WHITE,
  },
}));

function TableRow({ row, selected, config, onRowClick, actions }) {
  const classes = useStyles();

  const renderActions = () => {
    const tableActions = typeof actions === "function" ? actions(row) : actions;

    return (
      <div>
        {tableActions.map((action, idx) => (
          <Tooltip title={action.label} key={idx}>
            <span>
              <Button
                size={{ width: 18, height: 18 }}
                className="table-row-action"
                {...action}
                onClick={() => action.onClick(row)}
              />
            </span>
          </Tooltip>
        ))}
      </div>
    );
  };

  return (
    <Row
      className={clsx(
        {
          [classes.clickableRow]: onRowClick,
        },
        classes.rowWrapper
      )}
      hover={!selected}
      tabIndex={-1}
      key={row.id}
      selected={selected}
      onClick={onRowClick ? () => onRowClick(row) : undefined}
      data-value="tableRow"
    >
      {Object.keys(config.columns).map(key => {
        return (
          <TableCell
            key={row.id + key}
            value={row[key]}
            type={config.types && config.types[key]}
          />
        );
      })}
      {actions && <TableCell value={renderActions()} />}
    </Row>
  );
}

TableRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.bool,
  actions: PropTypes.array,
  config: PropTypes.object,
  onRowClick: PropTypes.func,
};

export default TableRow;
