import { PropTypes } from "prop-types";

import dayjs from "dayjs";
import React from "react";
import { StyledDatePicker, StyledPickerDay } from "./styles";

const StaticDatePicker = ({
  value,
  onChange,
  markedDates = [],
  themeType = "dark",
  ...pickerProps
}) => {
  const renderPickerDay = (date, selectedDates, pickersDayProps) => {
    return (
      <StyledPickerDay
        {...pickersDayProps}
        themeType={themeType}
        selected={date.isSame(value, "day")}
        isToday={date.isSame(dayjs(), "day")}
        isMarked={markedDates.some(md => md.isSame(date, "day"))}
      />
    );
  };

  const isWeekend = date => date.day() === 0 || date.day() === 6;

  return (
    <StyledDatePicker
      displayStaticWrapperAs="desktop"
      variant="static"
      value={value}
      open={open}
      onChange={onChange}
      renderDay={renderPickerDay}
      disableMaskedInput
      inputFormat="dd/MM/yyyy"
      themeType={themeType}
      disableHighlightToday
      shouldDisableDate={isWeekend} // no weekends hardcoded for now
      renderInput={params => <input {...params} />}
      {...pickerProps}
    />
  );
};

StaticDatePicker.propTypes = {
  value: PropTypes.object.isRequired, // days date
  onChange: PropTypes.func.isRequired,
  markedDates: PropTypes.arrayOf(PropTypes.object), // days dates
  themeType: PropTypes.oneOf(["dark", "light"]),
};

export { StaticDatePicker };
