import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import useFetch from "hooks/useHTTP";

import { Typography, Button } from "@material-ui/core";

// icons
import { ReactComponent as MailOutlineIcon } from "assets/email.svg";

// custom components
import Logo from "components/Logo";
import Layout from "components/Layout";
import Loading from "components/Loading";
import TextField from "components/TextField";

// constants
import { FORGOT_PASSWORD } from "constants/api";
import { PASSWORD_REQUESTED } from "constants/routes";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
  },
  margin: {
    margin: `${theme.spacing(1)}px 0px`,
    width: "100%",
    "&:focus": {
      outline: "none",
    },
  },
  title: {
    marginTop: theme.spacing(7),
    alignSelf: "center",
  },
  description: {
    textAlign: "center",
    fontSize: "0.8rem",
    lineHeight: "1.3rem",
    marginBottom: "2.75rem",
  },
  formItem: {
    minHeight: 54,
    marginBottom: "3.6rem",
  },
  adornmentIcon: {
    width: "6%",
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const { get, loading } = useFetch();

  // Form
  const {
    handleSubmit,
    setError,
    formState: { errors },
    control,
    getValues,
  } = useForm({ email: "" });

  const forgotPassword = async formData => {
    const { email } = formData;
    await get(`${FORGOT_PASSWORD}?email=${email}`)
      .then(res => {
        if (res.ok) {
          history.push(PASSWORD_REQUESTED);
        }
      })
      .catch(() => {
        setError("email", {
          type: "manual",
        });
      });
  };
  return (
    <Layout showHeader={false} showFirstCol={false} showSecondCol={false}>
      <div className={classes.wrapper}>
        {loading ? (
          <Loading />
        ) : (
          <div className={classes.contentWrapper}>
            <Logo isBlue />
            <Typography variant="h5" className={classes.title}>
              Richiedi password
            </Typography>
            <p className={classes.description}>
              Hai dimenticato la password?
              <br />
              Inserisci il tuo indirizzo e-mail, riceverai indicazioni per
              procedere con il reset della tua password
            </p>
            <form noValidate autoComplete="off">
              <Controller
                render={({ field }) => (
                  <TextField
                    className={clsx(classes.margin, classes.formItem)}
                    type="email"
                    label="EMAIL"
                    icon={<MailOutlineIcon />}
                    {...field}
                    error={!!errors.email}
                    helperText={
                      getValues("email")
                        ? "Utente non trovato"
                        : "Campo obbligatorio"
                    }
                  />
                )}
                control={control}
                name="email"
                rules={{
                  required: { value: true },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit(forgotPassword)}
              >
                INVIA
              </Button>
            </form>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ForgotPassword;
