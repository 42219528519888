import React from "react";
import PropTypes from "prop-types";

import Loading from "components/Loading";

import StackedRadiusMultiColumn from ".";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  noData: {
    width: "100%",
    textAlign: "center",
    paddingBlock: 90,
  },
});

const StackedRadiusColumnManager = props => {
  const classes = useStyles();
  const { loading, data } = props;

  if (loading) return <Loading />;
  
  if (data?.length) return <StackedRadiusMultiColumn data={data} {...props} />;

  return (
    <Typography variant="h6" className={classes.noData}>
      Al momento non sono presenti dati
    </Typography>
  );
};

StackedRadiusColumnManager.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
};

export default StackedRadiusColumnManager;
