import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const NumberFormatCustom = props => {
  const {
    inputRef,
    onChange,
    suffix,
    fixedDecimalScale,
    decimalScale,
    ...other
  } = props;

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        if (!onChange) return;
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      suffix={suffix}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={decimalScale}
      type="text"
      displayType="text"
      isNumericString
      {...other}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.objectOf(PropTypes.func),
  onChange: PropTypes.func,
  suffix: PropTypes.string,
  fixedDecimalScale: PropTypes.bool,
  decimalScale: PropTypes.number,
};

NumberFormatCustom.defaultProps = {
  fixedDecimalScale: true,
  decimalScale: 2,
};

export default NumberFormatCustom;
