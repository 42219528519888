import React from "react";
import PropTypes from "prop-types";

//Assets
import { ReactComponent as DoIcon } from "assets/do-icon-small-blu.svg";
import { ReactComponent as BeIcon } from "assets/be-icon-small-blu.svg";

// Components
import SvgIcon from "components/SvgIcon";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { OXFORD_BLUE, WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    backgroundColor: OXFORD_BLUE,
  },
  column: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  phrase: {
    color: WILD_BLUE_YONDER,
    marginTop: 0,
    fontSize: 8,
    fontFamily: "Nunito-Regular, Nunito-Light, Nunito-Bold, Arial",
    textAlign: "right",
    opacity: 0.8,
  },
  number: {
    fontSize: 25,
    fontFamily: "Nunito-Regular, Nunito-Light, Nunito-Bold, Arial",
    fontWeight: "bold",
  },
}));

const Info = ({ firstValue, secondValue, label1, label2 }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.row}>
            <div className={classes.number}>{firstValue}</div>
            <SvgIcon icon={<DoIcon />} width={27.5} height={27.5} />
          </div>
          <div className={classes.phrase}>{label1?.toUpperCase()}</div>
        </div>
        <div className={classes.column}>
          <div className={classes.row}>
            <div className={classes.number}>{secondValue}</div>
            <SvgIcon icon={<BeIcon />} width={27.5} height={27.5} />
          </div>
          <div className={classes.phrase}>{label2?.toUpperCase()}</div>
        </div>
      </div>
    </div>
  );
};

Info.propTypes = {
  firstValue: PropTypes.number,
  secondValue: PropTypes.number,
  label1: PropTypes.string,
  label2: PropTypes.string,
};

export default Info;
