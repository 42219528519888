import React from "react";
import PropTypes from "prop-types";

import { TableCell as TableCellMU } from "@material-ui/core";
import Avatar from "components/Avatar";

import { GHOST_WHITE } from "constants/colors";

function TableCell({ value, type }) {
  const renderCell = () => {
    switch (type) {
      case "IMAGE": {
        return <Avatar image={value} />;
      }
    }
  };

  return (
    <TableCellMU
      align={typeof value !== "object" ? "left" : "right"}
      data-value="tableCell"
      style={{
        borderBottom: `1px solid ${GHOST_WHITE}50`,
      }}
    >
      {type ? renderCell() : value}
    </TableCellMU>
  );
}

TableCell.propTypes = {
  value: PropTypes.any,
  type: PropTypes.string,
};

export default React.memo(TableCell);
