export const POST_TYPE = {
  WHITE: 1,
  BLUE: 2,
};

export const POST_REACTION = {
  LIKE: 1,
  LOVE: 2,
  HINT: 3,
};
