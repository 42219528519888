import React from "react";
import { createRoot } from "react-dom/client";
import App from "pages/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider as ThemeProviderV4 } from "@material-ui/core/styles";
import { ThemeProvider as ThemeProviderV5 } from "@mui/material/styles";
import themeV4 from "./themeV4";
import themeV5 from "./themeV5";
import "globals.css";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <ThemeProviderV4 theme={themeV4}>
      <ThemeProviderV5 theme={themeV5}>
        <App />
      </ThemeProviderV5>
    </ThemeProviderV4>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
