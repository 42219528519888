import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

//Hooks
import useFetch from "hooks/useHTTP";

// Components
import Layout from "components/Layout";
import Accordion from "components/Accordion";
import Loading from "components/Loading";

// Context
import { AppContext } from "context/AppContext";

//constants
import {
  ACTIVITIES,
  ACTIVITIES_CALENDAR,
  BRICKS,
  PROJECT,
  USERS_LIST,
} from "constants/api";
import { Box, Grid, Typography, styled, useTheme } from "@mui/material";
import { BrickDetails } from "./components/BrickDetails/BrickDetails";
import { UserDashboardHeader } from "./components/UserDashboardHeader/UserDashboardHeader";
import { useForm } from "react-hook-form";
import { getQueryString } from "utils/request";
import { ProjectSorting } from "utils/enums/filters";
import { useRef } from "react";
import { WILD_BLUE_YONDER } from "constants/colors";

import { StaticDatePicker } from "components/pickers";
import dayjs from "dayjs";
import Date from "components/Date";
import Activity from "./components/Activity/Activity";
import { useReducer } from "react";
import { loadingInitialValues, loadingReducer } from "./loadingReducer";
import { ExpectedEndDateModal } from "./components/modals/ExpectedEndDate";
import { ReportDrawer } from "./components/drawers/Report";
import useDrawer from "hooks/useDrawer";
import { RequestCloseModal } from "./components/modals/RequestClose";
import { AuthContext } from "context/AuthContext";
import { NOT_FOUND } from "constants/routes";
import { useEstimationModal } from "pages/App/modals/Estimation";

const StyledDateTitle = styled(Date)({
  fontWeight: "bold",
  textTransform: "capitalize",
  display: "block",
  marginBottom: 18,
});

const headerIcons = [
  {
    icon: "be",
  },
];

const defaultFilterFormValues = {
  Query: "",
};

const initialActiveFilters = {
  ...defaultFilterFormValues,
  OrderBy: ProjectSorting.LAST_ACTIVITY,
};

const DEFAULT_QUALITY = 3;

const UserDashboard = () => {
  const history = useHistory();
  const {
    addBreadcrumb,
    setPageTitle,
    setOwner,
    setModalConfig,
    setRefreshSecondCol,
    settings,
    resetBreadcrumbs,
  } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const { get, put, del } = useFetch();
  const { openEstimationModal } = useEstimationModal({
    onUpdateEstimationStart: () =>
      dispatchLoading({ type: "updateEstimation", value: true }),
    onUpdateEstimationEnd: () =>
      dispatchLoading({ type: "updateEstimation", value: false }),
  });
  const theme = useTheme();

  const reportDrawer = useDrawer();

  const filterForm = useForm({ defaultValues: defaultFilterFormValues });

  // State
  const [userBricks, setUserBricks] = useState(null);
  const [globalExpanded, setGlobalExpanded] = useState(false);
  const [expandedMap, setExpandedMap] = useState({});
  const [activeFilters, setActiveFilters] = useState(initialActiveFilters);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [calendarActivities, setCalendarActivities] = useState([]);
  const [currentActivities, setCurrentActivities] = useState([]);
  const [activityData, setActivityData] = useState();

  const [loadings, dispatchLoading] = useReducer(
    loadingReducer,
    loadingInitialValues
  );

  // Refs
  const miscValueRef = useRef(null);
  const bricksScrollbarRef = useRef(null);
  const bricksScroolValueRef = useRef(0);

  const targetBricks = userBricks || [];
  const isCurrentUserDashboard = user?.id === id;
  const isMaker = !user?.canAccessWeb;
  const isQualityActive = settings?.isQualityActive;

  // Effects
  useEffect(() => {
    // Clear breadcrumbs: avoid maker user to use session storage links to other users
    if (user && !isCurrentUserDashboard && isMaker) {
      resetBreadcrumbs();
      history.replace(NOT_FOUND);
    }
  }, [user, isCurrentUserDashboard, id]);

  useEffect(() => {
    if (!bricksScrollbarRef?.current) return;
    bricksScrollbarRef.current.scrollTop = bricksScroolValueRef.current || 0;
  });

  useEffect(() => {
    fetchUserDashboard();
    fetchCalendarActivities();
  }, [id]);

  useEffect(() => {
    handleAllBricksExpanded(false);
    fetchUserBricks();
  }, [activeFilters, id]);

  useEffect(() => {
    if (!userBricks) return;
    handleAllBricksExpanded();
  }, [userBricks]);

  useEffect(() => {
    if (!userBricks) return;
    handleAllBricksExpanded(globalExpanded);
  }, [globalExpanded]);

  useEffect(() => {
    fetchActivitiesByDate();
  }, [currentDate, id]);

  // api
  const fetchUserDashboard = async () => {
    dispatchLoading({ type: "dashboard", value: true });
    try {
      // Fetch old user dashboard (ensuring user data is present)
      const res = await get(`${USERS_LIST}/${id}/dashboard`);
      if (res.ok) {
        const user = res?.data?.user;
        const userDisplayName = user?.name + " " + user?.surname;
        setOwner({
          name: userDisplayName,
          id: user?.id,
          image: user?.imageUrl,
          jobTitle: user?.jobTitle?.name,
          onClick: () => history.push(`/user/${user?.id}`),
        });

        if (!user) return;
        addBreadcrumb({
          name: userDisplayName,
        });
        setPageTitle(userDisplayName);
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatchLoading({ type: "dashboard", value: false });
    }
  };

  const fetchUserBricks = async () => {
    dispatchLoading({ type: "userBricks", value: true });
    try {
      const qs = activeFilters
        ? getQueryString({ ...activeFilters, UserId: id })
        : "";
      const res = await get(`${PROJECT}${qs}`);
      if (res?.ok) setUserBricks(res.data);
    } catch (err) {
      console.error(err);
    } finally {
      dispatchLoading({ type: "userBricks", value: false });
    }
  };

  const fetchCalendarActivities = async () => {
    if (!currentDate) return;
    dispatchLoading({ type: "calendar", value: true });
    const fromDateString = dayjs().subtract(1, "year").toISOString();
    const toDateString = dayjs().toISOString();
    try {
      const res = await get(
        `${ACTIVITIES_CALENDAR}?UserId=${id}&from=${fromDateString}&to=${toDateString}`
      );
      setCalendarActivities(res.data);
    } catch (err) {
      console.error(err);
    } finally {
      dispatchLoading({ type: "calendar", value: false });
    }
  };

  const fetchActivitiesByDate = async () => {
    if (!currentDate) return;
    dispatchLoading({ type: "activities", value: true });
    const dateString = currentDate.toISOString();
    try {
      const res = await get(
        `${ACTIVITIES}?UserId=${id}&fromDate=${dateString}&toDate=${dateString}`
      );
      setCurrentActivities(res.data.data);
    } catch (err) {
      console.error(err);
    } finally {
      dispatchLoading({ type: "activities", value: false });
    }
  };

  const updateExpectedEndDate = async (brickId, newExpectedEndDate) => {
    dispatchLoading({ type: "updateEndDate", value: true });
    try {
      await put(`${BRICKS}/${brickId}/expectedEndDate`, {
        expectedEndDate: newExpectedEndDate,
      });
    } catch (err) {
      console.error(err);
    } finally {
      miscValueRef.current = null;
      dispatchLoading({ type: "updateEndDate", value: false });
    }
  };

  const deleteActivity = async activityId => {
    dispatchLoading({ type: "activities", value: true });
    try {
      await del(`${ACTIVITIES}/${activityId}`);
      revalidateActivities();
    } catch (err) {
      console.error(err);
    }
  };

  const updateUserRating = async (brickId, rating) => {
    dispatchLoading({ type: "updateRating", value: true });
    try {
      await put(`${BRICKS}/${brickId}/requestValidation`, {
        rating,
      });
    } catch (err) {
      console.error(err);
    } finally {
      miscValueRef.current = null;
      dispatchLoading({ type: "updateRating", value: false });
    }
  };

  // Helpers
  const handleAllBricksExpanded = expanded => {
    const keepValues = !(typeof expanded === "boolean");
    if (!keepValues) bricksScroolValueRef.current = 0;
    setExpandedMap(
      Object.fromEntries(
        targetBricks.map(b => [
          b.id,
          keepValues ? !!expandedMap[b.id] : expanded,
        ])
      )
    );
  };

  const handleAccordionChange = (brickId, expanded) =>
    setExpandedMap({ ...expandedMap, [brickId]: expanded });

  const handleClearForm = () => {
    filterForm.reset();
    setActiveFilters({ ...activeFilters, ...defaultFilterFormValues });
  };

  const handleChangeSorting = sortingValue =>
    setActiveFilters({ ...activeFilters, OrderBy: sortingValue });

  const onFilterSubmit = data =>
    setActiveFilters({ ...activeFilters, ...data });

  const handleEditExpectedEndDate = brick => {
    setModalConfig({
      title: "Data di fine prevista",
      content: (
        <ExpectedEndDateModal
          currentValue={brick?.expectedEndDate}
          valueRef={miscValueRef}
        />
      ),
      secondaryAction: {
        text: "Annulla",
        onClick: () => setModalConfig(null),
      },
      primaryAction: {
        text: "Salva",
        onClick: async () => {
          setModalConfig(null);
          await updateExpectedEndDate(brick.id, miscValueRef.current);
          fetchUserBricks();
        },
      },
    });
  };

  const handleReport = data => {
    const payloadHasActivity = !!data.activity;
    const activityForCurrentBrickAndDate = currentActivities.find(
      a =>
        a.brick.id === data.brick.id &&
        dayjs(a.date).isSame(dayjs(currentDate), "day")
    );
    const nextActivityData = {
      ...data,
      ...(activityForCurrentBrickAndDate && {
        activity: activityForCurrentBrickAndDate,
      }),
    };

    reportDrawer.setIsDrawerOpen(true);
    setActivityData(nextActivityData);
  };

  const handleRequestClose = async (brick, error) => {
    if (isQualityActive) {
      setModalConfig({
        title: "Richiesta chiusura",
        content: (
          <RequestCloseModal
            owner={
              brick?.phaseManager?.name + " " + brick?.phaseManager?.surname
            }
            valueRef={miscValueRef}
            error={error}
          />
        ),
        secondaryAction: {
          text: "Annulla",
          onClick: () => setModalConfig(null),
        },
        primaryAction: {
          text: "Salva",
          onClick: async () => {
            if (!miscValueRef.current)
              return handleRequestClose(
                brick,
                "Inserisci un valore per l'autovalutazione"
              );
            setModalConfig(null);
            await updateUserRating(brick.id, miscValueRef.current);
            fetchUserBricks();
          },
        },
      });
    } else {
      await updateUserRating(brick.id, DEFAULT_QUALITY);
      fetchUserBricks();
    }
  };

  const revalidateActivities = () => {
    fetchUserBricks();
    fetchCalendarActivities();
    fetchActivitiesByDate();
    revalidateWidgets();
  };

  const revalidateWidgets = () => setRefreshSecondCol(true);

  // Renders
  const DashboardContent = () => (
    <Grid container mt={2} gap={8} wrap="nowrap">
      <Grid item xs>
        <UserDashboardHeader
          formControl={filterForm.control}
          globalExpanded={globalExpanded}
          onClearForm={handleClearForm}
          activeSorting={activeFilters?.OrderBy}
          onChangeSorting={handleChangeSorting}
          loading={loadings.userBricks}
          onGlobalExpandedClick={() => setGlobalExpanded(prev => !prev)}
        />
        {loadings.userBricks || loadings.activities ? (
          <Box>
            <Loading
              showWrapper={false}
              animationStyle={{ height: 80, width: 80, marginTop: 50 }}
            />
          </Box>
        ) : targetBricks.length ? (
          <Box height="calc(100vh - 234px)" overflow="auto">
            <PerfectScrollbar
              id="pf-active-bricks"
              onScrollY={el => (bricksScroolValueRef.current = el.scrollTop)}
              containerRef={ref => (bricksScrollbarRef.current = ref)}
              style={{ paddingBottom: 30 }}
            >
              {targetBricks.map((el, index) => {
                const totalBricks = el.bricks?.length
                  ? `${el.bricks?.length} brick`
                  : "";
                return (
                  <Accordion
                    title={{
                      name: el.name,
                      to: isMaker
                        ? `/user/${id}/project/${el.id}`
                        : `/project/${el.id}`,
                    }}
                    defaultExpanded={expandedMap[el.id]}
                    expanded={expandedMap[el.id]}
                    onChange={(_, expanded) =>
                      handleAccordionChange(el.id, expanded)
                    }
                    subtitle={totalBricks}
                    key={index}
                    details={
                      <BrickDetails
                        bricks={el.bricks}
                        onEstimationEdit={brick =>
                          openEstimationModal({
                            brick,
                            onCloseSuccess: fetchUserBricks,
                          })
                        }
                        onExpectedEndDateEdit={handleEditExpectedEndDate}
                        onCreateReport={handleReport}
                        onRequestClose={handleRequestClose}
                        isCurrentUserDashboard={isCurrentUserDashboard}
                      />
                    }
                    heightScroll={"calc(100vh - 215px)"}
                    style={{ marginBottom: theme.spacing(2) }}
                  />
                );
              })}
            </PerfectScrollbar>
          </Box>
        ) : (
          <Typography variant="h6" py={2}>
            {activeFilters.Query
              ? "Nessun brick trovato"
              : "Non ci sono brick in corso"}
          </Typography>
        )}
      </Grid>
      <Grid item sx={{ width: 320 }}>
        <Box mb={3}>
          <Typography variant="h6" fontWeight="bold" textTransform="uppercase">
            Calendario
          </Typography>
          <Typography
            variant="subtitle"
            fontWeight="bold"
            color={WILD_BLUE_YONDER}
            mt={0.2}
            mb={2}
            component="p"
          >
            Seleziona la data su cui vuoi rendicontare.
          </Typography>

          <StyledDateTitle
            dateString={currentDate.toISOString()}
            format={"dd, DD/MM"}
            variant="h4"
          />
          <StaticDatePicker
            value={currentDate}
            onChange={setCurrentDate}
            disableFuture
            disabled={loadings.calendar}
            markedDates={calendarActivities
              .filter(ca => ca.checked)
              .map(ca => dayjs(ca.day))}
          />
        </Box>

        <Box>
          <Typography variant="h6" fontWeight="bold" textTransform="uppercase">
            Ore rendicontate
          </Typography>
          <Box mt={1} height="calc(100vh - 626px)" overflow="auto">
            <PerfectScrollbar
              id="pf-current-activities"
              style={{ paddingBottom: 30 }}
            >
              {loadings.activities ? (
                <Loading
                  showWrapper={false}
                  animationStyle={{ height: 80, width: 80, marginTop: 50 }}
                />
              ) : currentActivities?.length ? (
                currentActivities.map(a => (
                  <Activity
                    key={a.id}
                    data={a}
                    {...(isCurrentUserDashboard && {
                      onEditActivity: handleReport,
                      onDeleteActivity: deleteActivity,
                    })}
                  />
                ))
              ) : (
                <Typography variant="subtitle" component="p">
                  Nessun report trovato
                </Typography>
              )}
            </PerfectScrollbar>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );

  if (!user) return null;

  return (
    <Layout headerIcons={headerIcons}>
      <form onSubmit={filterForm.handleSubmit(onFilterSubmit)}>
        <DashboardContent />
        <ReportDrawer
          date={currentDate}
          data={activityData}
          open={reportDrawer.isDrawerOpen}
          setOpen={reportDrawer.setIsDrawerOpen}
          onActivitySaved={revalidateActivities}
        />
      </form>
    </Layout>
  );
};

export default UserDashboard;
