import React from "react";
import PropTypes from "prop-types";

// Material UI
import { Box, Stack } from "@mui/material";
import { ReportProgress } from "./Progress";
import { getWeekdaysInMonth, timeToHours } from "utils";

const ReportWidget = ({ data }) => {
  const { day, week, month } = data;

  // Month
  const standardMonthValue = Math.round(month.value);
  const extraMonthValue = Math.round(month.extra);
  const workingDaysInMonth = getWeekdaysInMonth();
  const monthValuePercentage = (standardMonthValue / workingDaysInMonth) * 100;
  const extraMonthValuePercentage =
    (extraMonthValue / workingDaysInMonth) * 100;

  // Week
  const standardWeekValue = timeToHours(week.value);
  const extraWeekValue = timeToHours(week.extra);
  const weekValuePercentage = (standardWeekValue / 40) * 100;
  const extraWeekValuePercentage = (extraWeekValue / 40) * 100;

  // Today
  const standardDayValue = timeToHours(day.value);
  const extraDayValue = timeToHours(day.extra);
  const dayValuePercentage = (standardDayValue / 8) * 100;
  const extraDayValuePercentage = (extraDayValue / 8) * 100;

  return (
    <Box height="100%" width="100%" position={"relative"}>
      <Stack py={2} gap={1} alignItems="flex-end" flexWrap="wrap">
        <ReportProgress
          size={45}
          value={standardMonthValue}
          extraValue={extraMonthValue}
          valuePercentage={monthValuePercentage}
          extraValuePercentage={extraMonthValuePercentage}
          title="Mese"
          legendSuffix="gg"
        />
        <ReportProgress
          size={45}
          value={standardWeekValue}
          extraValue={extraWeekValue}
          valuePercentage={weekValuePercentage}
          extraValuePercentage={extraWeekValuePercentage}
          title="Settimana"
          legendSuffix="hh"
        />
        <ReportProgress
          size={45}
          value={standardDayValue}
          extraValue={extraDayValue}
          valuePercentage={dayValuePercentage}
          extraValuePercentage={extraDayValuePercentage}
          title="Oggi"
          legendSuffix="hh"
        />
      </Stack>
    </Box>
  );
};

ReportWidget.propTypes = {
  data: PropTypes.object,
};

export { ReportWidget };
