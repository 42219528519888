import PropTypes from "prop-types";

export const BrickShape = {
  avatarImageUrl: PropTypes.string,
  cost: PropTypes.number,
  displayName: PropTypes.string,
  endDate: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  quality: PropTypes.number,
  startDate: PropTypes.string,
  time: PropTypes.number,
  type: PropTypes.number,
};
