import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import theme from "themeV4";
// Amcharts
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// Set theme
am4core.useTheme(am4themes_animated);

const PieChart = ({
  chartId,
  canvasWidth,
  canvasHeight,
  data,
  disableInteraction,
  hideLbales,
  hideTooltip,
  labelColor,
  labelsInsideSlice,
  radius,
  sliceShiftOnClick,
}) => {
  // Hooks
  const chartRef = useRef(null);

  // Effects
  useEffect(() => {
    // Chart Config
    if (!chartRef.current) {
      // Add and configure Series
      chartRef.current = am4core.create(chartId, am4charts.PieChart);
      chartRef.current.radius = am4core.percent(radius);

      let pieSeries = chartRef?.current?.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "text";
      // pieSeries.slices.template.propertyFields.fill = "color"; // Option to set slice color from data
      pieSeries.ticks.template.disabled = true;
      pieSeries.colors.list = [
        am4core.color("#194FFF"),
        am4core.color(theme.palette.primary.main),
      ];
      // Tooltip
      pieSeries.slices.template.tooltipText =
        "{text}: {value.percent.formatNumber('#.#')}%";
      if (hideTooltip) pieSeries.tooltip.disabled = true;

      // Slices shift on click
      if (!sliceShiftOnClick)
        pieSeries.slices.template.states.getKey(
          "active"
        ).properties.shiftRadius = 0;

      if (hideLbales) pieSeries.labels.template.hide();

      pieSeries.alignLabels = false;
      pieSeries.labels.template.wrap = true;
      pieSeries.labels.template.fill = am4core.color(labelColor);
      pieSeries.labels.template.text = "{text}";

      if (labelsInsideSlice)
        pieSeries.labels.template.radius = am4core.percent(-50);

      // This creates initial animation
      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;
    }
  }, []);

  useEffect(() => {
    // Update chart data
    if (chartRef.current) {
      chartRef.current.data = data;
    }
  }, [data]);

  useEffect(() => {
    // Chart disposal on component unmount
    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  return (
    <div
      id={chartId}
      style={{
        width: canvasWidth,
        height: canvasHeight,
        pointerEvents: disableInteraction ? "none" : "auto",
      }}
    />
  );
};

PieChart.defaultProps = {
  chartId: "pie-chart",
  canvasWidth: "100%",
  canvasHeight: "100%",
  data: [],
  disableInteraction: false,
  hideLbales: true,
  hideTooltip: false,
  labelColor: "#FFF",
  labelsInsideSlice: true,
  radius: 70,
  sliceShiftOnClick: false,
};

PieChart.propTypes = {
  chartId: PropTypes.string,
  canvasWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  canvasHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.array.isRequired,
  disableInteraction: PropTypes.bool,
  hideLbales: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  labelColor: PropTypes.string,
  labelsInsideSlice: PropTypes.bool,
  radius: PropTypes.number,
  sliceShiftOnClick: PropTypes.bool,
};

export default PieChart;
