import React from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { RHFUpload } from "components/hook-form";
import { StyledButton, StyledWrapper } from "./AiGeneratorUpload.styles";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useState } from "react";
import useFetch from "hooks/useHTTP";
import { AI_GENERATOR } from "constants/api";
import { Storage } from "aws-amplify";
import { modalTypes } from "utils/enums/modals";
import { useHistory } from "react-router-dom";

const defaultValues = { files: [] };

const AiGeneratorUpload = ({ setModalConfig, portfolioId }) => {
  // hooks
  const form = useForm({ defaultValues });
  const { control, handleSubmit } = form;
  const { post } = useFetch();
  const history = useHistory();

  // state
  const [rejectedDrops, setRejectedDrops] = useState([]); // Used to regulate buttons top margin

  const wFiles = useWatch({ control, name: "files" });
  const hasFiles = !!wFiles.length;
  const hasRejectedDrops = !!rejectedDrops.length;

  // functions
  const handleAccept = () => setRejectedDrops([]);

  const handleReject = rejectedFiles => setRejectedDrops(rejectedFiles);

  const onSubmit = async data => {
    const { files } = data;
    const file = files[0];
    const bucket = process.env.REACT_APP_BUCKET_PROJECTS;

    try {
      if (!portfolioId) throw new Error("Portfolio id is missing!");
      if (!file) throw new Error("Pdf file to upload is missing!");

      setModalConfig({ type: modalTypes.LOADING });

      // Upload file to S3
      const uploadRes = await Storage.put(`ai/${file.name}`, file, {
        bucket,
      });

      // Save file key to database and generate skyline
      const input = {
        portfolioId,
        pdfFileKey: `public/${uploadRes.key}`,
        bucketName: process.env.REACT_APP_BUCKET_PROJECTS,
      };
      const { data } = await post(AI_GENERATOR, input);

      setModalConfig(null);
      history.push(`/planner/${data.projectId}/builder`);
    } catch (error) {
      console.error(error);
      setModalConfig({ type: modalTypes.ERROR, primaryAction: { text: "OK" } });
    }
  };

  return (
    <StyledWrapper>
      <Typography fontSize={14} mb={3}>
        Carica un documento in formato pdf. Il software analizzerà il file e
        restituirà una skyline di progetto coerente con i dati forniti.
      </Typography>{" "}
      <FormProvider {...form} onSubmit={handleSubmit(onSubmit)}>
        <RHFUpload
          name="files"
          accept={"application/pdf"}
          onDropRejected={handleReject}
          onDropAccepted={handleAccept}
        />{" "}
      </FormProvider>
      <Stack direction="row" gap={1} mt={hasFiles || hasRejectedDrops ? 3 : 10}>
        <StyledButton
          variant="contained"
          color="secondary"
          onClick={() => setModalConfig(null)}
        >
          ANNULA
        </StyledButton>
        <StyledButton
          variant="contained"
          color="primary"
          disabled={!hasFiles}
          onClick={handleSubmit(onSubmit)}
        >
          CONFERMA
        </StyledButton>
      </Stack>
    </StyledWrapper>
  );
};

AiGeneratorUpload.propTypes = {
  setModalConfig: PropTypes.func,
  portfolioId: PropTypes.string,
};

export { AiGeneratorUpload };
