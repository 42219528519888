import React, { useState } from "react";
import PropTypes from "prop-types";

import Loading from "components/Loading";

import { parseDate } from "utils";

import StackedRadiusColumn from ".";

const data1 = [
  {
    x: parseDate("05/05/2020"),
    total: "31H",
    y: 80,
    extraY: 40,
  },
  {
    x: parseDate("06/05/2020"),
    total: "32H",
    y: 50,
    extraY: 40,
    extraX: "test",
  },
  {
    x: parseDate("07/05/2020"),
    total: "33H",
    y: 8,
    extraY: 5,
    extraX: "test",
  },
  {
    x: parseDate("08/05/2020"),
    total: "34H",
    y: 20,
    extraY: 10,
    extraX: "test",
  },
  {
    x: parseDate("09/05/2020"),
    total: "35H",
    y: 30,
    extraY: null,
    extraX: "test",
  },
  {
    x: parseDate("10/05/2020"),
    total: "36H",
    y: 28,
    extraY: null,
    extraX: "test",
  },
  {
    x: parseDate("11/05/2020"),
    total: "28H",
    y: 48,
    extraY: 2,
    extraX: "test",
  },
  {
    x: parseDate("12/05/2020"),
    total: "37H",
    y: 42,
    extraY: 6,
    extraX: "test",
  },
  {
    x: parseDate("13/05/2020"),
    total: "48H",
    y: 8,
    extraY: 2,
    extraX: "test",
  },
  {
    x: parseDate("14/05/2020"),
    total: "39H",
    y: 30,
    extraY: 15,
    extraX: "test",
  },
  {
    x: parseDate("15/05/2020"),
    total: "45H",
    y: 10,
    extraY: 15,
    extraX: "test",
  },
  {
    x: parseDate("03/05/2021"),
    total: "35H",
    y: 30,
    extraY: 5,
    extraX: "test",
  },
  {
    x: parseDate("04/05/2021"),
    total: "33H",
    y: 35,
    extraY: 28,
    extraX: "test",
  },
  {
    x: parseDate("05/05/2021"),
    total: "39H",
    y: 80,
    extraY: 40,
    extraX: "test",
  },
  {
    x: parseDate("06/05/2021"),
    total: "31H",
    y: 50,
    extraY: 40,
    extraX: "test",
  },
  {
    x: parseDate("07/05/2021"),
    total: "36H",
    y: 8,
    extraY: 5,
    extraX: "test",
  },
  {
    x: parseDate("08/05/2021"),
    total: "32H",
    y: 20,
    extraY: 10,
    extraX: "test",
  },
  {
    x: parseDate("09/05/2021"),
    total: "38H",
    y: 30,
    extraY: null,
    extraX: "test",
  },
  {
    x: parseDate("10/05/2021"),
    total: "37H",
    y: 28,
    extraY: null,
    extraX: "test",
  },
  {
    x: parseDate("11/05/2021"),
    total: "28H",
    y: 48,
    extraY: 2,
    extraX: "test",
  },
  {
    x: parseDate("12/05/2021"),
    total: "25H",
    y: 42,
    extraY: 6,
    extraX: "test",
  },
  {
    x: parseDate("13/05/2021"),
    total: "48H",
    y: 8,
    extraY: 2,
    extraX: "test",
  },
  {
    x: parseDate("14/05/2021"),
    total: "25H",
    y: 30,
    extraY: 15,
    extraX: "test",
  },
  {
    x: parseDate("15/05/2021"),
    total: "40H",
    extraX: "test",
    y: 10,
    extraY: 15,
  },
  {
    x: parseDate("16/05/2021"),
    total: "42H",
    extraX: "test",

    y: 28,
    extraY: 10,
  },
  {
    x: "Oggi",
    total: "44H",
    extraX: "test",

    y: 8,
    extraY: 2,
  },
];

const StackedRadiusColumnManager = props => {
  const [data] = useState(props.data);

  if (Object.keys(data).length > 0) {
    return <StackedRadiusColumn data={data} {...props} />;
  }
  return <Loading />;
};

StackedRadiusColumnManager.propTypes = {
  data: PropTypes.array.isRequired,
};

StackedRadiusColumnManager.defaultProps = {
  data: data1,
};

export default StackedRadiusColumnManager;
