import React from "react";
import PropTypes from "prop-types";

// Material
import { Typography, makeStyles } from "@material-ui/core";

// Components
import BlueIcon from "components/BlueIcon";

// Constants
import { WILD_BLUE_YONDER } from "constants/colors";
import { Button, styled } from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { useEstimationModal } from "pages/App/modals/Estimation";
import { AppContext } from "context/AppContext";
import { modalTypes } from "utils/enums/modals";

const useStyles = makeStyles(theme => ({
  container: {
    flex: "0 0 457px",
    padding: "0 20px",
  },
  days: {
    fontSize: "15px",
    color: theme.palette.text.primary,
  },
  label: {
    marginLeft: 20,
    fontSize: "15px",
    color: theme.palette.text.primary,
  },
  leftColumn: {
    display: "flex",
    alignItems: "center",
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    color: WILD_BLUE_YONDER,
    "& >div:not(:last-child)": {
      padding: "0 0 15px 0",
      borderBottom: `1px solid rgba(255, 255, 255, .2)`,
    },
  },
  text: {
    fontSize: "20px",
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0 0 0",
  },
  rightCol: {
    display: "flex",
    gap: 16,
    alignItems: "center",
  },
}));

const StyledIconButton = styled(Button)({
  color: "white",
  borderColor: "white",
  width: 25,
  height: 25,
  minWidth: "unset",
  "& svg": {
    width: 12,
    height: 12,
  },
});

const Report = ({ brickData, isClassicMode, onRevalidate }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { setModalConfig } = useContext(AppContext);
  const { openEstimationModal } = useEstimationModal({
    onUpdateEstimationStart: () => setModalConfig({ type: modalTypes.LOADING }),
    onUpdateEstimationEnd: () => setModalConfig(null),
  });

  const loggedUserIsBrickOwner = user?.id === brickData?.userId;

  const renderText = (str, customClass) => {
    return (
      <Typography component="h3" className={customClass || classes.text}>
        {str}
      </Typography>
    );
  };

  const returnTime = str => {
    return Number(str) > 0 ? " " + str + "m)" : ")";
  };

  const plannedH = brickData?.plannedHours?.split(":");
  const reportedH = brickData?.reportedHours?.split(":");
  const extimationH = brickData?.estimationCompletion?.split(":");

  const data = [
    {
      label: isClassicMode ? "Ore pianificate" : "Giorni pianificati",
      value:
        Math.round(brickData?.plannedHoursInDays * 10) / 10?.toLocaleString() +
        "gg (" +
        plannedH?.[0] +
        "h" +
        returnTime(plannedH?.[1]),
    },
    {
      label: isClassicMode ? "Ore rendicontate" : "Giorni rendicontati",
      value:
        Math.round(brickData?.reportedHoursInDays * 10) / 10?.toLocaleString() +
        "gg (" +
        reportedH?.[0] +
        "h" +
        returnTime(reportedH?.[1]),
    },
    {
      label: "Stima a finire",
      value:
        Math.round(brickData?.estimationCompletionInDays * 10) /
          10?.toLocaleString() +
        "gg (" +
        extimationH?.[0] +
        "h" +
        returnTime(extimationH?.[1]),
    },
  ];

  const renderReport = () => (
    <div className={classes.container}>
      {renderText("Report")}
      <div className={classes.listContainer}>
        {data.map(({ label, value }, index) => {
          const isEstimationRow = label
            .toLowerCase()
            .includes("stima a finire");
          return (
            <div key={index} className={classes.rowContainer}>
              <div className={classes.leftColumn}>
                <BlueIcon width={"12.5px"} height={"12.5px"} />
                {renderText(label, classes.label)}
              </div>
              <div className={classes.rightCol}>
                {renderText(value, classes.days)}
                <div style={{ width: 25 }}>
                  {isEstimationRow && loggedUserIsBrickOwner && (
                    <StyledIconButton
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        openEstimationModal({
                          brick: brickData,
                          onCloseSuccess: onRevalidate,
                        })
                      }
                    >
                      <EditRoundedIcon />
                    </StyledIconButton>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  return renderReport();
};

Report.propTypes = {
  brickData: PropTypes.object,
  isClassicMode: PropTypes.bool,
  onRevalidate: PropTypes.func,
};

export default Report;
