export const workLoadFiltersTypes = {
  DAYS: 1,
  WEEKS: 2,
  MONTHS: 3,
};

export const effortGraphColor = {
  PAST: 1,
  TODAY: 2,
  FUTURE: 3,
};

export const workloadContext = {
  PROJECT: "project",
  DEPARTMENT: "department",
  USER: "user",
}