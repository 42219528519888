import numeral from "numeral";

/**
 * Formats a value as currency.
 * @param {string | number} n - The value to format as currency.
 * @returns {string} - The value formatted as currency.
 */
const fCurrency = n => {
  return numeral(n).format(Number.isInteger(n) ? "0.0" : "0.0,00");
};

/**
 * Formats a value as a percentage.
 * @param {number} n - The value to format as a percentage.
 * @returns {string} - The value formatted as a percentage.
 */
const fPercent = n => {
  return numeral(n / 100).format("0.0%");
};

/**
 * Formats a value as a generic number.
 * @param {string | number} n - The value to format as a number.
 * @returns {string} - The formatted number value.
 */
const fNumber = n => {
  return numeral(n).format();
};

/**
 * Formats a number to shorten it.
 * @param {string | number} n - The number to shorten.
 * @returns {string} - The shortened number.
 */
const fShortenNumber = n => {
  return numeral(n).format("0.00a").replace(".00", "");
};

/**
 * Formats data values.
 * @param {string | number} n - The value to format as data.
 * @returns {string} - The formatted data value.
 */
const fData = n => {
  return numeral(n).format("0.0 b");
};

/**
 * Returns a safe number. If the input value is not a number, it returns 0.
 * @param {*} value - The value to convert into a safe number.
 * @returns {number} - The safe number.
 */
const safeNum = value => (isNaN(Number(value)) ? 0 : Number(value));

/**
 * Checks if a given date falls within a specified range.
 * @param {Date | string | number} inputDate - The date to check.
 * @param {Date} startDate - The start date of the range.
 * @param {Date} endDate - The end date of the range.
 * @returns {boolean} True if the input date falls within the range; otherwise, false.
 */
const isBetween = (inputDate, startDate, endDate) => {
  const date = new Date(inputDate);

  const results =
    new Date(date.toDateString()) >= new Date(startDate.toDateString()) &&
    new Date(date.toDateString()) <= new Date(endDate.toDateString());

  return results;
};

/**
 * Checks if a start date is after an end date.
 * @param {Date | null} startDate - The start date.
 * @param {Date | null} endDate - The end date.
 * @returns {boolean} True if the start date is after the end date; otherwise, false.
 */
const isAfter = (startDate, endDate) => {
  const results =
    startDate && endDate
      ? new Date(startDate).getTime() > new Date(endDate).getTime()
      : false;

  return results;
};

export {
  fCurrency,
  fPercent,
  fNumber,
  fShortenNumber,
  fData,
  safeNum,
  isBetween,
  isAfter,
};
