import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

// Material
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { GHOST_WHITE } from "constants/colors";

//Enum
import { workLoadFiltersTypes } from "utils/enums/workLoad";

// Assests
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
//Hooks
import useFetch from "hooks/useHTTP";
import useFilters from "hooks/useFilters";

// Components
import ChartCard from "components/ChartCard";
import Loading from "components/Loading";
import CustomSelect from "components/CustomSelect";
import LabelList from "components/LabelList";
import SvgIcon from "components/SvgIcon";

import {
  PROJECT,
  WORKLOAD_BURNDOWN,
  WORKLOAD_BURNDOWN_DETAIL,
} from "constants/api";

// Charts and Themes
import StackedRadiusColumnManager from "components/Charts/StackedRadiusColumn/manager";

import {
  parseDate,
  parseMonth,
  parseWeek,
  timeToHours,
  timeToFormattedHoursMinutesString,
} from "utils";

// key
import { nanoid } from "nanoid";

const useStyles = makeStyles(() => ({
  chartContainer: {
    height: "380px",
    width: "100%",
  },
  select: {
    marginRight: "10px",
  },
  InfoContainer: {
    height: "500px",
    marginTop: 20,
  },
  closeIcon: {
    cursor: "pointer",
    width: 16,
    height: 16,
  },
  detailContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "100px",
  },
  detailHeader: {
    display: "flex",
    padding: "20px",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#1E2840",
    justifyContent: "space-between",
  },
  closeDetailIcon: {
    height: "100%",
    marginRight: "0px",
    marginTop: "auto",
  },
  detailRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "20px",
  },
  detailColumn: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  detailBody: {
    marginLeft: "10px",
    marginRight: "20px",
  },
  loadingWrapper: {
    height: "300px",
  },
}));

const BurndownWorkload = () => {
  const classes = useStyles();
  const { get } = useFetch();

  // State
  const [loadingBurnDown, setLoadingBurnDown] = useState(false);
  const [loadingBurnDownInfo, setLoadingBurnDownInfo] = useState(false);

  const [burnDownGraphData, setBurnDownGraphData] = useState(null);
  const [burnDownInfoData, setBurnDownInfoData] = useState(null);

  const [burnDownScrollBars, setBurnDownScrollBars] = useState(false);

  const { filters, setFilters } = useFilters({
    EffortFilter: workLoadFiltersTypes.DAYS,
    BurnDownFilter: workLoadFiltersTypes.DAYS,
  });
  const { projectId } = useParams();

  useEffect(() => {
    fetchBurnDownGraph(filters.BurnDownFilter);
    setBurnDownInfoData(null);
  }, [filters.BurnDownFilter]);

  // Renders
  const renderLoading = () => (
    <Loading
      showWrapper={false}
      animationStyle={{ height: 80, width: 80, marginTop: -100 }}
    />
  );

  // API GET BURNDOWN GRAPH
  const fetchBurnDownGraph = async (range = 1) => {
    try {
      setLoadingBurnDown(true);
      let filterFunction;

      switch (filters.BurnDownFilter) {
        case workLoadFiltersTypes.DAYS:
          filterFunction = (...args) => parseDate(...args);
          break;
        case workLoadFiltersTypes.WEEKS:
          filterFunction = (...args) => parseWeek(...args);
          break;
        case workLoadFiltersTypes.MONTHS:
          filterFunction = (...args) => parseMonth(...args);
          break;
        default:
          filterFunction = (...args) => parseDate(...args);
          break;
      }

      const res = await get(
        `${PROJECT}/${projectId}/${WORKLOAD_BURNDOWN}?type=${range}`
      );
      if (res.ok) {
        var newData = res.data.map(item => {
          let x = filterFunction(item.x);
          let extraX = item.x;
          let y = timeToHours(item.y) !== 0 ? timeToHours(item.y) : null;
          let extraY =
            timeToHours(item.extraY) !== 0 ? timeToHours(item.extraY) : null;

          return {
            x,
            extraX,
            y,
            extraY,
          };
        });
        if (newData?.length > 30) {
          setBurnDownScrollBars(true);
        }

        setBurnDownGraphData(newData);
      }
      setLoadingBurnDown(false);
    } catch (err) {
      console.log(err);
      setLoadingBurnDown(false);
    }
  };

  // API GET REPORT INFO
  const fetchBurnDownInfo = async time => {
    try {
      setLoadingBurnDownInfo(true);

      const res = await get(
        `${PROJECT}/${projectId}/${WORKLOAD_BURNDOWN_DETAIL}?type=${filters.BurnDownFilter}&timeValue=${time}`
      );
      if (res.ok) {
        let addedBricks = res.data.addedBricks.map(brick => {
          return {
            id: brick.id,
            name: brick.name,
            phaseName: brick.phaseName,
            rightText: timeToFormattedHoursMinutesString(brick.hours),
          };
        });

        let updatedBricks = res.data.updatedBricks.map(brick => {
          return {
            id: brick.id,
            name: brick.name,
            phaseName: brick.phaseName,
            rightText: "+ " + timeToFormattedHoursMinutesString(brick.hours),
          };
        });

        let newData = {
          addedBricks,
          updatedBricks,
        };

        setBurnDownInfoData(newData);
      }
      setLoadingBurnDownInfo(false);
    } catch (err) {
      console.log(err);
      setLoadingBurnDownInfo(false);
    }
  };

  const onChange = (e, filterKey) => {
    const value = e.target.value;
    setFilters({ ...filters, [filterKey]: value });
  };

  const filtersRange = [
    { value: workLoadFiltersTypes.DAYS, label: "DAYS" },
    { value: workLoadFiltersTypes.WEEKS, label: "WEEKS" },
    { value: workLoadFiltersTypes.MONTHS, label: "MONTHS" },
  ];

  const renderSelect = (disabled, filterKey, minWidth) => {
    return (
      <div key={nanoid()} className={classes.select}>
        <CustomSelect
          options={filtersRange}
          disabled={disabled}
          onChange={e => onChange(e, filterKey)}
          value={filters[filterKey]}
          minWidth={minWidth}
        />
      </div>
    );
  };

  const renderBurnDownGraph = () =>
    loadingBurnDown ? (
      <div className={classes.loadingWrapper}>{renderLoading()} </div>
    ) : (
      burnDownGraphData && (
        <div className={classes.chartContainer}>
          <ChartCard
            height={"380px"}
            marginLeft={"0px"}
            title="Burndown chart"
            filters={renderSelect(false, "BurnDownFilter", "194px")}
          >
            <StackedRadiusColumnManager
              id={"stackedColumn2"}
              onClick={fetchBurnDownInfo}
              scrollBars={burnDownScrollBars}
              columnWidth={20}
              tmp={burnDownInfoData}
              data={burnDownGraphData}
            />
          </ChartCard>
        </div>
      )
    );

  const reloadBurnDownGraph = () => {
    setBurnDownGraphData([...burnDownGraphData]);
    setBurnDownInfoData(null);
  };

  const renderBurnDownInfo = () =>
    loadingBurnDownInfo ? (
      <div className={classes.chartContainer}>{renderLoading()}</div>
    ) : (
      burnDownInfoData && (
        <div className={classes.InfoContainer}>
          <div className={classes.detailContainer}>
            <div className={classes.detailHeader}>
              <Typography variant="h6">
                Dettaglio {(burnDownInfoData && burnDownInfoData.Name) || ""}
              </Typography>
              <div className={classes.closeDetailIcon}>
                <SvgIcon
                  color={GHOST_WHITE}
                  className={classes.closeIcon}
                  icon={<CancelIcon />}
                  onClick={() => reloadBurnDownGraph()}
                  strokeWidth={2}
                />
              </div>
            </div>
            <div className={classes.detailRow}>
              <div className={classes.detailColumn}>
                <Typography variant="h6">
                  {(burnDownInfoData.addedBricks &&
                    burnDownInfoData.addedBricks?.length) ||
                    "0"}{" "}
                  Brick aggiunti
                </Typography>
                <div className={classes.detailBody}>
                  <LabelList disabled data={burnDownInfoData?.addedBricks} />
                </div>
              </div>
              <div className={classes.detailColumn}>
                <Typography variant="h6">
                  {(burnDownInfoData.updatedBricks &&
                    burnDownInfoData.updatedBricks?.length) ||
                    "0"}{" "}
                  Brick con stima a finire modificata
                </Typography>
                <div className={classes.detailBody}>
                  <LabelList disabled data={burnDownInfoData?.updatedBricks} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );

  return (
    <>
      {renderBurnDownGraph()}
      {renderBurnDownInfo()}
    </>
  );
};

export { BurndownWorkload };
