import { useLocation } from "react-router-dom";

//Hooks
import useStorage from "hooks/useStorage";

import { nanoid } from "nanoid";

const useBreadcrumbs = (defaultBreadcrumbs = []) => {
  // State
  const [breadcrumbs, setBreadcrumbs] = useStorage(
    "breadcrumbs",
    defaultBreadcrumbs,
    "session"
  );
  // Hooks
  const { pathname } = useLocation();

  const addBreadcrumb = newBreadcrumb => {
    const targetURL = newBreadcrumb?.url || pathname;
    const updatedBreadcrumbs = [...breadcrumbs];
    const lastBreadcrumb = updatedBreadcrumbs[updatedBreadcrumbs.length - 1];

    if (lastBreadcrumb.url !== targetURL) {
      updatedBreadcrumbs.push({
        id: nanoid(),
        url: targetURL,
        ...newBreadcrumb,
      });
      setBreadcrumbs(updatedBreadcrumbs);
    }
  };

  const resetBreadcrumbs = () => setBreadcrumbs(defaultBreadcrumbs);

  const sliceBreadcrumbs = breadcrumbId => {
    const targetIndex = breadcrumbs.findIndex(b => b.id === breadcrumbId);
    if (targetIndex === breadcrumbs.length - 1) return;
    const updatedBreadcrumbs = breadcrumbs.slice(0, targetIndex + 1);
    setBreadcrumbs(updatedBreadcrumbs);
  };

  return {
    addBreadcrumb,
    breadcrumbs,
    resetBreadcrumbs,
    setBreadcrumbs,
    sliceBreadcrumbs,
  };
};

export default useBreadcrumbs;
