import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import theme from "themeV4";
import NumberFormat from "./NumberFormat";

// Material UI components
import { TextField as TextFieldMUI, InputAdornment } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  adornment: ({ themeType }) => ({
    "& p": {
      color:
        themeType === "dark"
          ? theme.palette.componentsBackground.light
          : theme.palette.common.black,
    },
  }),
}));

export default function NumberTextField({
  className,
  themeType,
  label,
  icon,
  helperText,
  ...other
}) {
  const classes = useStyles({ themeType });

  return (
    <TextFieldMUI
      fullWidth
      label={label}
      variant="outlined"
      className={className}
      helperText={other.error && helperText}
      InputLabelProps={{
        style: {
          color:
            themeType === "dark"
              ? theme.palette.componentsBackground.light
              : theme.palette.componentsBackground.main,
        },
      }}
      InputProps={{
        startAdornment: icon ? (
          <InputAdornment className={classes.adornment} position="start">
            {icon}
          </InputAdornment>
        ) : undefined,
        style: {
          color:
            themeType === "dark"
              ? theme.palette.componentsBackground.light
              : theme.palette.common.black,
          backgroundColor:
            themeType === "dark"
              ? theme.palette.componentsBackground.main
              : theme.palette.componentsBackground.light,
        },
        inputComponent: NumberFormat,
      }}
      {...other}
    />
  );
}

NumberTextField.propTypes = {
  className: PropTypes.string,
  themeType: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.node,
  helperText: PropTypes.string,
};

NumberTextField.defaultProps = {
  themeType: "dark",
  helperText: "Campo obbligatorio",
};
