import React from "react";
import PropTypes from "prop-types";

// Material
import { Typography, makeStyles } from "@material-ui/core";
import { SPACE_CADET } from "constants/colors";

// Components
import PerfectScrollbar from "react-perfect-scrollbar";
import Date from "components/Date";

// Contants
import { WILD_BLUE_YONDER } from "constants/colors";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles(theme => ({
  container: {
    flex: "0 0 457px",
    padding: "0 20px",
    position: "relative",
    height: 178,
  },
  noteContainer: {
    display: "flex",
    gap: theme.spacing(3),
  },
  box: {
    minHeight: "128px",
    height: "128px",
    backgroundColor: SPACE_CADET,
    display: "flex",
    flexDirection: "column",
    margin: "10px 0",
    padding: theme.spacing(2),
    borderRadius: "4px",
    flex: "0 0 315px",
    position: "relative",
  },
  text: {
    fontSize: "20px",
    color: theme.palette.text.primary,
  },
  text2: {
    fontSize: "13px",
    color: theme.palette.text.primary,
    marginBottom: "10px",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  text3: {
    fontSize: "13px",
    color: WILD_BLUE_YONDER,
    marginBottom: 8,
  },
}));

const Note = ({ data }) => {
  const classes = useStyles();
  if (data?.notes?.length === 0) return null;

  return (
    <div className={classes.container}>
      <Typography component="h3" className={classes.text}>
        Note da report
      </Typography>

      <PerfectScrollbar
        id="pf-notes-brick"
        style={{ height: "calc(100% - 35px)" }}
        options={{ suppressScrollY: true }}
      >
        <div className={classes.noteContainer}>
          {data?.notes?.map(({ description, creationDateTime }, index) => (
            <div key={index} className={classes.box}>
              <Date dateString={creationDateTime} className={classes.text3} />
              <Tooltip title={description} followCursor>
                <Typography component="h3" className={classes.text2}>
                  {description}
                </Typography>
              </Tooltip>
            </div>
          ))}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

Note.propTypes = {
  data: PropTypes.object,
};

export default Note;
