import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const NumberFormatCustom = props => {
  const { inputRef, onChange, suffix, fixedDecimalScale, ...other } = props;
  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      suffix={suffix}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={2}
      type="text"
      isNumericString
      {...other}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.objectOf(PropTypes.func),
  onChange: PropTypes.func,
  suffix: PropTypes.string,
  fixedDecimalScale: PropTypes.bool,
};

NumberFormatCustom.defaultProps = {
  fixedDecimalScale: true,
};

export default NumberFormatCustom;
