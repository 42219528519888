import React from "react";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import { SPACE_CADET } from "constants/colors";
import PropTypes from "prop-types";
import BlueIcon from "components/BlueIcon";
import { timeToFormattedHoursMinutesString } from "utils";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const StyledWrapper = styled("div")(({ theme, hasClickAction }) => ({
  backgroundColor: SPACE_CADET,
  marginBottom: theme.spacing(1),
  padding: 10,
  borderRadius: 5,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),

  ...(hasClickAction && {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(30, 40, 64, 0.8)",
    },
  }),
}));

const StyledButton = styled(Button)({
  width: 40,
  height: 40,
  minWidth: "unset",

  "& svg": {
    width: 18,
    height: 18,
  },
});

const Activity = ({ data, onEditActivity, onDeleteActivity }) => {
  return (
    <StyledWrapper
      onClick={() => onEditActivity?.({ brick: data.brick, activity: data })}
      hasClickAction={!!onEditActivity}
    >
      <Stack
        flex={1}
        justifyContent="space-between"
        flexWrap="nowrap"
        gap={1}
        width={244}
      >
        <Typography
          component="div"
          variant="body2"
          fontWeight={600}
          lineHeight={1}
          noWrap
          title={data.brick.projectName}
        >
          {data.brick.projectName}
        </Typography>
        <Box display="flex" flex={1} gap={1}>
          <Box>
            <BlueIcon width={"12px"} height={"12px"} borderRadius={"3px"} />
          </Box>
          <Typography component="div" variant="caption" lineHeight={1}>
            {data.brick.name}
          </Typography>
        </Box>
        <Typography textTransform="uppercase" fontWeight="bold" lineHeight={1}>
          {timeToFormattedHoursMinutesString(data.time, true, true)}
        </Typography>
      </Stack>

      {onDeleteActivity && (
        <StyledButton
          color="secondary"
          variant="contained"
          onClick={e => {
            e.stopPropagation();
            onDeleteActivity(data.id);
          }}
        >
          <DeleteRoundedIcon />
        </StyledButton>
      )}
    </StyledWrapper>
  );
};

Activity.propTypes = {
  data: PropTypes.object.isRequired,
  onEditActivity: PropTypes.func,
  onDeleteActivity: PropTypes.func,
};

export default Activity;
