import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";

// Material UI
import { Fade, Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Components
import Layout from "components/Layout";
import Loading from "components/Loading";
import Skyline from "./components/Skyline";
import HorizonActions from "./components/Skyline/HorizonActions";
import HorizonLine from "./components/Skyline/HorizonLine";
import Percentage from "./components/Percentage";
import SvgIcon from "components/SvgIcon";

// Utils
import { getHorizonLevel } from "./utils";
//Hooks
import useWindowSize from "hooks/useWindowSize";
import useFetch from "hooks/useHTTP";
import useQueryString from "hooks/useQueryString";
// Context
import { AppContext } from "context/AppContext";
import ProjectContext from "./ProjectContext";
// Costants
import { OXFORD_BLUE, CORNFLOWER_BLUE, WHITE } from "constants/colors";
import { PROJECT } from "constants/api";
// Icons
import { ReactComponent as EditIcon } from "assets/edit.svg";
// Config
import {
  HEADER_HEIGHT,
  INDICATORS_WIDTH,
  INDICATORS_ANIMATION_DURATION,
} from "./components/Skyline/config";
import { metricTypes } from "utils/enums/grooveMetrics";
import { NOT_FOUND } from "constants/routes";
import { AuthContext } from "context/AuthContext";

// Styles
const useStyles = makeStyles(theme => ({
  wrapper: {
    height: "100%",
    display: "flex",
    position: "relative",
  },
  indicators: {
    width: INDICATORS_WIDTH,
    height: "100%",
    position: "relative",
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    paddingRight: 5,
  },
  fade: {
    backgroundImage: `linear-gradient(90deg, ${OXFORD_BLUE}, ${CORNFLOWER_BLUE}, transparent)`,
    position: "absolute",
    left: "98%",
    width: 20,
    height: "100%",
    zIndex: 1,
  },
  loadingWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconButton: {
    position: "absolute",
    bottom: theme.spacing(7),
    right: theme.spacing(7),
    width: 65,
    height: 65,
    padding: 20,
  },
}));

const Project = () => {
  // Context
  const { addBreadcrumb, setActiveMetric, setOwner, setPageTitle, settings } =
    useContext(AppContext);
  const { user } = useContext(AuthContext);
  // Hooks
  const classes = useStyles();
  const [windowWidth, windowHeight] = useWindowSize();
  const { get, loading } = useFetch();
  const { projectId } = useParams();
  const projectWrapperRef = useRef();
  // ! TODO: Remove -------------
  const { p } = useQueryString();
  // State
  const [horizonLevel, setHorizonLevel] = useState(0);
  const [projectData, setProjectData] = useState();
  const [activeView, setActiveView] = useState();
  const [percentageAnimated, setPercentageAnimated] = useState(false);
  const [fixedPercentage, setFixedPercentage] = useState(null);
  // ! TODO: Remove -------------
  const [manualCompletionPercentage] = useState(+p);
  const history = useHistory();

  const activePercentage = Number.isInteger(manualCompletionPercentage)
    ? manualCompletionPercentage
    : projectData?.project?.completionPercentage;

  const isBeActive = settings?.isBeActive;
  const readOnlyMode = !user?.canAccessWeb;

  // Effects
  useEffect(() => {
    fetchProject();
    setActiveMetric(metricTypes.DO);
  }, []);

  useEffect(() => {
    if (projectData) drawProject();
  }, [projectData, fixedPercentage, windowHeight, windowWidth]);

  useEffect(() => {
    if (projectData) {
      addBreadcrumb({
        name: projectData?.project?.name,
      });
      setPageTitle(projectData?.project?.name);
    }
  }, [projectData]);

  // Context
  const contextData = {
    completionPercentage: projectData?.project?.completionPercentage,
    onPercentageAnimation: setPercentageAnimated,
    percentageAnimated,
  };

  // API
  const fetchProject = async () => {
    try {
      const res = await get(`${PROJECT}/${projectId}/skyline`);
      if (res.ok) {
        setProjectData(res.data);
        const path = res?.data?.project?.user;
        //QUI CI VA L'OWNER, temporaneamente ci metto user
        setOwner({
          name: path?.name + " " + path?.surname,
          id: path?.id,
          image: path?.imageUrl,
          jobTitle: "owner di progetto",
          onClick: () => history.push(`/user/${path?.id}`),
        });
      }
    } catch (err) {
      // TODO: Handle error
      history.push(NOT_FOUND);
    }
  };

  // Helpers
  const drawProject = () => {
    const canvasHeight =
      projectWrapperRef?.current?.getBoundingClientRect().height;

    const updatedHorizonLevel = getHorizonLevel({
      activePercentage: fixedPercentage || activePercentage,
      canvasHeight,
    });

    // TODO: Remove setTimeout / alternative animation behavior
    setTimeout(() => {
      setHorizonLevel(updatedHorizonLevel);
    }, 100);
  };

  // Renders
  const renderLoading = () => (
    <Loading
      showWrapper={false}
      animationStyle={{ height: 80, width: 80, marginTop: -100 }}
    />
  );

  const renderProject = () => {
    const offsetTop = horizonLevel - HEADER_HEIGHT;
    return (
      <>
        <Fade in timeout={INDICATORS_ANIMATION_DURATION}>
          <div className={classes.indicators}>
            <HorizonActions
              activeView={activeView}
              offsetTop={offsetTop}
              onLevelChange={setFixedPercentage}
              setActiveView={setActiveView}
            />
            <HorizonLine offsetLeft={60} offsetTop={offsetTop} start />
            <Percentage offsetTop={horizonLevel} />
            <div className={classes.fade}></div>
          </div>
        </Fade>
        <Skyline
          horizonY={horizonLevel}
          phases={projectData.phases}
          readOnlyMode={readOnlyMode}
        />
      </>
    );
  };

  const renderEditButton = () => {
    if (loading || !projectData || readOnlyMode) return null;
    return (
      <>
        <Fab
          color="primary"
          aria-label="edit"
          aria-describedby={"transitions-edit"}
          className={classes.iconButton}
          onClick={() => history.push(`/planner/${projectId}/builder`)}
        >
          <SvgIcon
            icon={<EditIcon />}
            color={WHITE}
            width={"75%"}
            heigth={"75%"}
            strokeWidth={1}
          />
        </Fab>
      </>
    );
  };

  const headerIcons = [
    {
      icon: "do",
      action: () => {
        setActiveMetric(metricTypes.DO);
      },
    },
    ...(isBeActive
      ? [
          {
            icon: "be",
            action: () => {
              setActiveMetric(metricTypes.BE);
              history.push(`${history.location.pathname}/skyview`);
            },
          },
        ]
      : []),
  ];

  return (
    <Layout padRight={false} headerIcons={headerIcons}>
      <ProjectContext.Provider value={contextData}>
        <div ref={projectWrapperRef} className={classes.wrapper}>
          {(loading || !projectData) && renderLoading()}
          {!loading && projectData && (
            <>
              {renderProject()}
              {renderEditButton()}
            </>
          )}
        </div>
      </ProjectContext.Provider>
    </Layout>
  );
};

export default Project;
