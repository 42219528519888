import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import theme from "themeV4";
import { data } from "./data";

// Components
import { Typography, Button, Fade } from "@material-ui/core";
import Layout from "components/Layout";

// constants
import { ROOT } from "constants/routes";
import { OXFORD_BLUE } from "constants/colors";

// context
import { AppContext } from "context/AppContext";
import { AuthContext } from "context/AuthContext";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    backgroundImage: props => `url(${props.lighterBackgroundImage})`,
    [theme.breakpoints.up("xxl")]: {
      backgroundImage: props => `url(${props.heavierBackgroundImage})`,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > span": {
      fontSize: "1rem",
      color: theme.palette.common.white,
      fontWeight: "bold",
      top: "1.1rem",
      position: "relative",
      textTransform: "uppercase",
    },
  },
  contentBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    zIndex: 5,
    textShadow: `0px 3px 3px ${OXFORD_BLUE}`,
  },
  overlay: {
    position: "absolute",
    top: "40%",
    left: 0,
    width: "100%",
    height: "60%",
    backgroundImage: `linear-gradient(transparent 0%, ${OXFORD_BLUE} 100%)`,
    zIndex: 0,
  },
  img: {
    height: "18.5rem",
    maxHeight: "28vh",
    marginBottom: "7vh",
  },
  title: {
    marginTop: "2vh",
    marginBottom: theme.spacing(1),
    alignSelf: "center",
    fontSize: "1.7rem",
  },
  subtitle: {
    textAlign: "center",
    marginBottom: "8vh",
    whiteSpace: "pre-line",
  },
  skipButton: {
    color: theme.palette.common.white,
  },
  button: {
    height: "3.3rem",
    width: "9rem",
    marginBottom: "1rem",
  },
  navigation: {
    display: "flex",
    height: "0.9rem",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "4vh",
    marginBottom: "0.4rem",
  },
  navigationDot: {
    background: theme.palette.common.white,
    opacity: "0.4",
    width: "0.6rem",
    height: "0.6rem",
    borderRadius: "0.6rem",
    margin: "0.5rem",
    cursor: "pointer",
    "&:hover": {
      opacity: "1",
      width: "0.9rem",
      height: "0.9rem",
      borderRadius: "0.9rem",
    },
  },
  activeDot: {
    background: theme.palette.common.white,
    opacity: "1",
    width: "0.9rem",
    height: "0.9rem",
    borderRadius: "0.9rem",
    margin: "0.5rem",
    cursor: "pointer",
  },
}));

const Walkthrough = () => {
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);
  const { usersWalkThrough, setUsersWalkThrough } = useContext(AppContext);
  const { user } = useContext(AuthContext);

  const classes = useStyles({
    lighterBackgroundImage: data[activeIndex].lighterBackgroundImage,
    heavierBackgroundImage: data[activeIndex].heavierBackgroundImage,
  });

  const showNextSlide = () => {
    if (activeIndex !== data.length - 1) setActiveIndex(activeIndex + 1);
    else saveUsertoStorage();
  };

  const changeSlide = id => {
    setActiveIndex(id);
  };

  const saveUsertoStorage = () => {
    const updatedUsersWalkthrough = usersWalkThrough.filter(
      userId => userId !== user.id
    );
    updatedUsersWalkthrough.push(user.id);
    setUsersWalkThrough(updatedUsersWalkthrough);
    history.push(ROOT);
  };

  const renderDots = () => (
    <div className={classes.navigation}>
      {data?.map((item, index) => {
        return (
          <div
            key={index}
            className={`${
              activeIndex === index ? classes.activeDot : classes.navigationDot
            }`}
            onClick={() => {
              changeSlide(index);
            }}
          ></div>
        );
      })}
    </div>
  );

  const renderSlide = index => (
    <Fade in timeout={1500} key={index}>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.overlay}></div>
          <img src={data[index].image} className={classes.img} />

          <div className={classes.contentBody}>
            <Typography variant="h5" className={classes.title}>
              {data[index].title}
            </Typography>
            <Typography variant="h6" className={classes.subtitle}>
              {data[index].subtitle}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={() => showNextSlide()}
            >
              AVANTI
            </Button>
            {renderDots()}
            <Button
              className={classes.skipButton}
              onClick={() => saveUsertoStorage()}
            >
              SKIP
            </Button>
          </div>
        </div>
      </div>
    </Fade>
  );

  return (
    <Layout showHeader={false} showFirstCol={false} showSecondCol={false}>
      {renderSlide(activeIndex)}
    </Layout>
  );
};

export default Walkthrough;
