const initialValues = {
  dashboard: false,
  userBricks: false,
  calendar: false,
  activities: false,
};

const loadingReducer = (state, action) => {
  switch (action.type) {
    case "updateEstimation":
    case "updateEndDate":
    case "updateRating":
      return { ...state, userBricks: action.value };
    default:
      return {
        ...state,
        [action.type]: action.value,
      };
  }
};

export { loadingReducer, initialValues as loadingInitialValues };
