export const beNotificationTypes = {
  ENGAGEMENT: 1,
  COMMITMENT: 2,
  REPUTATION: 3,
  MONITORING_SURVEY_POSSIBILITY: 4,
  MONITORING_PERFORMANCE_SURVEY: 5,
  OVERVIEW_PROJECT_SURVEY: 6,
};

export const beNotificationMap = {
  [beNotificationTypes.COMMITMENT]: "commitment",
  [beNotificationTypes.ENGAGEMENT]: "engagement",
  [beNotificationTypes.REPUTATION]: "reputation",
  [beNotificationTypes.MONITORING_SURVEY_POSSIBILITY]: "survey",
  [beNotificationTypes.MONITORING_PERFORMANCE_SURVEY]: "survey",
  [beNotificationTypes.OVERVIEW_PROJECT_SURVEY]: "survey",
};

export const seniorityTypes = {
  JUNIOR: 0,
  INTERMEDIATE: 1,
  SENIOR: 2,
};
