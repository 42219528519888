import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Fade } from "@material-ui/core";
// Components
import HorizonLine from "./HorizonLine";
import HorizonPhase from "./HorizonPhase";
import Brick from "pages/Project/components/Brick";
// Skiline config
import {
  PHASE_ANIMATION_DURATION,
  HEADER_HEIGHT,
  HORIZON_HEIGHT,
  INDICATORS_WIDTH,
  BackgroundImages,
} from "./config";

// Styles
const useStyles = makeStyles({
  canvas: {
    position: "absolute",
    left: INDICATORS_WIDTH - 4,
    top: 0,
    width: `calc(100% - ${INDICATORS_WIDTH - 4}px)`,
    height: "100%",
    overflowX: "auto",
    overflowY: "visible",
  },
  common: {
    position: "absolute",
    transition: "all 0.3s ease-out",
  },
  horizon: {
    height: HORIZON_HEIGHT,
  },
  sky: {
    top: 0,
    display: "flex",
    paddingBottom: 20,
  },
  water: {
    display: "flex",
    paddingTop: 5,
  },
  horizonPhases: {
    width: "100%",
    display: "flex",
    height: "100%",
  },
  phase: {
    width: 300,
    padding: "0 15px",
    overflow: "auto",
    "&.water-phase": {
      marginTop: 10,
    },
    "& .sky-brick": {
      margin: "8px 0",
    },
    "& .water-brick": {
      margin: "14px 0 28px",
    },
  },
  phaseScroll: {
    "& > div": {
      display: "flex",
      flexDirection: "column",
    },
    "&.sky-scroll": {
      display: "flex",
      minHeight: "100%",
      flexDirection: "column-reverse",
    },
    "&.water-scroll": {
      paddingTop: 15,
      minHeight: "100%",
    },
  },
});

const Skyline = ({ horizonY, phases, readOnlyMode }) => {
  // Hooks
  const canvas = useRef(null);
  const history = useHistory();
  const classes = useStyles();

  const skylineHeight = canvas?.current?.getBoundingClientRect().bottom;

  // Helpers
  const goToBrick = id => history.push(`/brick/${id}`);

  const goToUser = id => history.push(`/user/${id}`);

  const buildBrickData = (
    {
      expectedEndDate,
      time,
      cost,
      plannedEndDate,
      quality,
      realEndDate,
      status,
      name,
      realStartDate,
      plannedStartDate,
      user,
      reportedHoursInDays,
      plannedHoursInDays,
      color,
      tag,
    },
    background
  ) => ({
    avatarImageUrl: user?.imageUrl,
    cost,
    displayName: `${user?.name} ${user?.surname}`,
    endDate: realEndDate || expectedEndDate || plannedEndDate,
    name,
    position: user?.jobTitle?.name,
    quality,
    startDate: realStartDate || plannedStartDate,
    time,
    type: status,
    imageUrl: BackgroundImages[background],
    reported: reportedHoursInDays,
    planned: plannedHoursInDays,
    color,
    tag,
  });

  // Renders
  const renderHorizon = () => (
    <div
      className={clsx(classes.common, classes.horizon)}
      style={{
        top: horizonY - HEADER_HEIGHT,
        opacity: horizonY > 0 ? 1 : 0,
      }}
    >
      <HorizonLine />
      <div className={classes.horizonPhases}>
        {phases?.map(
          ({
            cost,
            name,
            plannedEndDate,
            quality,
            realEndDate,
            reportedHoursInDays,
            plannedHoursInDays,
            time,
            user,
            status,
          }) => {
            const kpis = {
              time,
              cost,
              quality,
              status,
            };
            return (
              <HorizonPhase
                key={name}
                endDate={realEndDate || plannedEndDate}
                kpis={kpis}
                owner={`${user?.name} ${user?.surname}`}
                title={name}
                reported={reportedHoursInDays}
                planned={plannedHoursInDays}
              />
            );
          }
        )}
      </div>
    </div>
  );

  const renderSky = () => (
    <div
      className={clsx(classes.common, classes.sky)}
      style={{
        opacity: horizonY > 0 ? 1 : 0,
        height: `${horizonY - HEADER_HEIGHT}px`,
      }}
    >
      {phases?.map((skyPhase, phasesIdx) => (
        <Fade
          in
          key={phasesIdx}
          timeout={PHASE_ANIMATION_DURATION * (phasesIdx + 1)}
        >
          <div className={classes.phase}>
            <PerfectScrollbar>
              <div className={clsx(classes.phaseScroll, "sky-scroll")}>
                {skyPhase?.completedBricks?.map((brick, brickIdx) => (
                  <div key={brickIdx} className="sky-brick">
                    <Brick
                      data={buildBrickData(brick, skyPhase?.background)}
                      {...(!readOnlyMode && {
                        onClick: () => goToBrick(brick?.id),
                        onOwnerClick: e => {
                          goToUser(brick?.user?.id);
                          e.stopPropagation();
                        },
                      })}
                    />
                  </div>
                ))}
              </div>
            </PerfectScrollbar>
          </div>
        </Fade>
      ))}
    </div>
  );

  const renderWater = () => (
    <div
      className={clsx(classes.common, classes.water)}
      style={{
        top: horizonY + HORIZON_HEIGHT - HEADER_HEIGHT,
        height: `${skylineHeight - horizonY - HEADER_HEIGHT + 30}px`,
        opacity: horizonY > 0 ? 1 : 0,
      }}
    >
      {phases?.map((waterPhase, phasesIdx) => (
        <Fade
          in
          key={phasesIdx}
          timeout={PHASE_ANIMATION_DURATION * (phasesIdx + 1)}
        >
          <div className={clsx(classes.phase, "water-phase")}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              <div className={clsx(classes.phaseScroll, "water-scroll")}>
                {waterPhase?.currentBricks?.map((brick, brickIdx) => (
                  <div key={brickIdx} className="water-brick">
                    <Brick
                      data={buildBrickData(brick)}
                      {...(!readOnlyMode && {
                        onClick: () => goToBrick(brick.id),
                        onOwnerClick: e => {
                          goToUser(brick?.user?.id);
                          e.stopPropagation();
                        },
                      })}
                    />
                  </div>
                ))}
              </div>
            </PerfectScrollbar>
          </div>
        </Fade>
      ))}
    </div>
  );

  return (
    <div ref={canvas} className={classes.canvas}>
      <PerfectScrollbar options={{ suppressScrollY: true }}>
        {renderSky()}
        {renderHorizon()}
        {renderWater()}
      </PerfectScrollbar>
    </div>
  );
};

Skyline.defaultProps = {
  horizonY: 0,
};

Skyline.propTypes = {
  horizonY: PropTypes.number,
  phases: PropTypes.array,
  readOnlyMode: PropTypes.bool,
};

export default Skyline;
