import React from "react";
import PropTypes from "prop-types";

import { makeStyles, Typography } from "@material-ui/core";

// Components
import { Reputation } from "components/KpiBe/Reputation";
import Date from "components/Date";

//Utils
import { brickTypes } from "utils/enums/bricks";

// Colors
import { WILD_BLUE_YONDER } from "constants/colors";
import { useContext } from "react";
import { AppContext } from "context/AppContext";
import { AuthContext } from "context/AuthContext";

const useStyles = makeStyles(() => ({
  container: {
    flex: "0 0 457px",
    display: "flex",
    marginTop: 10,
    marginBottom: 40,
    flexDirection: "column",
    "& >div:not(:last-child)": {
      borderBottom: `1px solid rgba(255, 255, 255, .2)`,
    },
  },
  evaluationContainer: {
    display: "flex",
  },
  evaluationContainerBody: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 0",
    maxWidth: 285,
    margin: "0 45px",
    "&:first-child": {
      marginLeft: 0,
      maxWidth: 320,
    },
  },
  evalutationSpacer: {
    border: `0.25px solid ${WILD_BLUE_YONDER}`,
    display: "block",
    width: 0.5,
  },
  reputationContainer: {
    textAlign: "right",
    marginLeft: "auto",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  text: {
    fontSize: "20px",
  },
  text2: {
    fontSize: "15px",
    textAlign: "left",
  },
  text3: {
    fontSize: "12px",
    color: WILD_BLUE_YONDER,
  },
  reputationManager: {
    justifyContent: "flex-end",
    display: "flex",
    marginTop: "10px",
  },
}));

const Evaluation = ({ data, ratingManager, setRatingManager }) => {
  const classes = useStyles();
  const { settings } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const status = data?.status;
  const isQualityActive = settings?.isQualityActive;
  const isMaker = !user?.canAccessWeb

  const renderText = (str, customClass) => {
    return (
      <Typography component="h3" className={customClass || classes.text}>
        {str}
      </Typography>
    );
  };
  const evaluationData = [
    {
      label: "Richiesta di chiusura ricevuta il",
      value: data?.requestValidationDateTime,
      reputationLabel: "Autovalutazione",
      reputationValue: data?.ownerRating,
    },
    {
      label: "Chiusura fatta il",
      value: data?.validationDateTime,
      reputationLabel: "Valutazione manager",
      reputationValue: data?.managerRating,
    },
  ];

  const renderClosure = (
    label,
    value,
    reputationLabel,
    reputationValue,
    index
  ) => {
    if (index === 0 || (index === 1 && status === brickTypes?.CLOSED))
      return (
        <>
          <div key={index} className={classes.evaluationContainerBody}>
            <div>
              {renderText(label, classes.text3)}
              <Date dateString={value} className={classes.text2} />
            </div>
            {isQualityActive && (
              <div className={classes.reputationContainer}>
                {renderText(reputationLabel, classes.text3)}
                <Reputation
                  reputation={reputationValue}
                  widthIcon={15}
                  starsHeight={19}
                />
              </div>
            )}
          </div>
          {index === 0 && status === brickTypes?.CLOSED && (
            <div className={classes.evalutationSpacer}></div>
          )}
        </>
      );

    return (
      isQualityActive && !isMaker && (
        <div className={classes.reputationContainer}>
          {renderText(
            "Assegna la tua valutazione per chiudere il Brick",
            classes.text2
          )}
          <div className={classes.reputationManager}>
            <Reputation
              reputation={Number(ratingManager)}
              widthIcon={30}
              readOnly={false}
              onChange={(e, newValue) => {
                if (!newValue) {
                  newValue = 0;
                }
                setRatingManager(newValue);
              }}
            />
          </div>
        </div>
      )
    );
  };
  if (status < brickTypes?.CLOSED) return null;
  return (
    <div className={classes.container}>
      {renderText(isQualityActive ? "Quality" : "Chiusura brick", classes.title)}
      <div className={classes.evaluationContainer}>
        {evaluationData?.map(
          ({ label, value, reputationLabel, reputationValue }, index) =>
            renderClosure(label, value, reputationLabel, reputationValue, index)
        )}
      </div>
    </div>
  );
};

Evaluation.propTypes = {
  data: PropTypes.object,
  ratingManager: PropTypes.number,
  setRatingManager: PropTypes.func,
};

export default Evaluation;
