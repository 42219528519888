import React from "react";
import PropTypes from "prop-types";

import NumberFormatCustom from "components/NumberFormat";
import NumericWidget from "../NumericWidget";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { HARVARD_CRIMSON, MALACHITE, OXFORD_BLUE } from "constants/colors";

//Utils
import { truncateDecimals } from "utils/index";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "100%",
    display: "flex",
    float: "right",
    flexDirection: "row",
    backgroundColor: OXFORD_BLUE,
  },
  column: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  number: ({ color }) => ({
    width: "100%",
    textAlign: "right",
    color: color,
    fontSize: 32,
    marginTop: 10,
    fontWeight: "bold",
  }),
}));

const SavingExtraEffort = ({ value }) => {
  /* 
    Le fasce di colore sono assegnate secondo i segueti criteri:
    Se postivo → ROSSO
    Se zero o negativo → VERDE
  */
  const customValue = truncateDecimals(value, 0) || 0;
  const color = value > 0 ? HARVARD_CRIMSON : MALACHITE;

  const classes = useStyles({ color });

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.row}>
            <NumericWidget
              number={
                <div className={classes.number}>
                  <NumberFormatCustom value={customValue} decimalScale={0} />
                </div>
              }
              suffix={"%"}
              color={color}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SavingExtraEffort.propTypes = {
  value: PropTypes.number,
};

export default SavingExtraEffort;
