import React from "react";
import PropTypes from "prop-types";

import NumberFormatCustom from "components/NumberFormat";
import NumericWidget from "../NumericWidget";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { HARVARD_CRIMSON, MALACHITE, OXFORD_BLUE } from "constants/colors";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "100%",
    display: "flex",
    float: "right",
    flexDirection: "row",
    backgroundColor: OXFORD_BLUE,
  },
  column: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  number: ({ color }) => ({
    width: "100%",
    textAlign: "right",
    color: color,
    fontSize: 32,
    marginTop: 10,
    fontWeight: "bold",
  }),
}));

const BudgetErosion = ({ value }) => {
  /* 
    Le fasce di colore sono assegnate secondo i segueti criteri:
    - 0% - 100% -> Verde
    - > 100 -> Rosso
  */

  const color = value > 100 ? HARVARD_CRIMSON : MALACHITE;

  const classes = useStyles({ color });

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.row}>
            <NumericWidget
              number={
                <div className={classes.number}>
                  <NumberFormatCustom value={value} decimalScale={0} />
                </div>
              }
              suffix={"%"}
              color={color}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BudgetErosion.propTypes = {
  value: PropTypes.number,
};

export default BudgetErosion;
