import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { KeyboardDatePicker } from "@material-ui/pickers";
import dayjs from "dayjs";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

// Icon
import { ReactComponent as CalendarIcon } from "assets/calendar-light.svg";
import { ERROR } from "constants/colors";

// Dayjs plugins
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const useStyles = makeStyles(theme => ({
  datePicker: ({ themeType }) => ({
    width: "100%",
    "& .MuiInputBase-root": {
      color:
        themeType === "dark"
          ? theme.palette.componentsBackground.light
          : theme.palette.common.black,
      backgroundColor:
        themeType === "dark"
          ? theme.palette.componentsBackground.main
          : theme.palette.componentsBackground.light,
    },
    ...(themeType === "dark" && {
      "& .MuiFormLabel-root:not(.Mui-focused)": {
        color: theme.palette.componentsBackground.light,
      },
    }),
  }),
  sunday: {
    color: ERROR,
  },
}));

const DatePicker = forwardRef(({ themeType, helperText, ...other }, ref) => {
  const classes = useStyles({ themeType });
  return (
    <KeyboardDatePicker
      className={classes.datePicker}
      autoOk
      placeholder="DD/MM/YYYY"
      keyboardIcon={<CalendarIcon style={{ width: "auto", height: 16 }} />}
      format="DD/MM/YYYY"
      variant="inline"
      inputVariant="outlined"
      disableToolbar
      helperText={other.error && helperText}
      InputAdornmentProps={{ position: "start" }}
      inputRef={ref}
      {...other}
      renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
        const currentDate = dayjs(day);
        const isSelected = currentDate.isSame(selectedDate);
        const isSunday = currentDate.day() === 0;
        const { minDate, maxDate } = other;
        // if rendered day is sunday, display it red
        if (isSunday && dayInCurrentMonth) {
          if (
            (!minDate && !maxDate) ||
            (!minDate && currentDate.isSameOrBefore(maxDate, "day")) ||
            (!maxDate && currentDate.isSameOrAfter(minDate, "day"))
          ) {
            return React.cloneElement(dayComponent, {
              className: clsx(
                !isSelected && classes.sunday,
                isSelected && "MuiPickersDay-daySelected",
                "MuiPickersDay-day"
              ),
            });
          }
        }

        return dayComponent;
      }}
    />
  );
});

DatePicker.displayName = "DatePicker";

DatePicker.propTypes = {
  themeType: PropTypes.string,
  minDate: PropTypes.object,
  helperText: PropTypes.string,
};

DatePicker.defaultProps = {
  themeType: "dark",
  helperText: "Campo obbligatorio",
};

export default DatePicker;
