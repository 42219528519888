import { useState, useCallback, useRef } from "react";

export default function usePlayer(media) {
  const [videoIndex, setVideoIndex] = useState(0);
  const [durationSeconds, setDurationSeconds] = useState();
  const [progressSeconds, setProgressSeconds] = useState();
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(true);
  const [playing, setPlaying] = useState(false);

  const player = useRef();

  const onProgress = useCallback(({ playedSeconds }) => {
    const s = playedSeconds;
    setProgressSeconds(s);
  }, []);

  const onDuration = useCallback(s => {
    // console.log(s);
    setDurationSeconds(s);
  }, []);

  const onVolumeChange = useCallback((event, value) => {
    setMuted(false);
    setVolume(value);
  }, []);

  const onMuted = useCallback(() => {
    muted ? setVolume(100) : setVolume(0);
    setMuted(!muted);
  }, [muted]);

  const onStart = useCallback(() => {
    // Triggered only on first "start" when looping
  }, []);

  const onPlay = useCallback(() => {
    setPlaying(true);
  }, []);

  const onPause = useCallback(() => {
    setPlaying(false);
  }, []);

  const onNext = useCallback(() => {
    setVideoIndex((videoIndex + 1) % media.length);
  }, [videoIndex, media]);

  const onPrev = useCallback(() => {
    setVideoIndex(videoIndex === 0 ? media.length - 1 : videoIndex - 1);
  }, [videoIndex, media]);

  const onSeek = useCallback((event, value) => {
    player.current.seekTo(value / 100);
  }, []);

  const onEnded = useCallback(() => {
    setVideoIndex((videoIndex + 1) % media.length);
    player.current.seekTo(0);
    setPlaying(false);
  }, [videoIndex]);

  return {
    player,
    url: media[videoIndex],
    onNext,
    onPrev,
    durationSeconds,
    progressSeconds,
    onProgress,
    onDuration,
    volume,
    onVolumeChange,
    muted,
    onMuted,
    playing,
    onStart,
    onPlay,
    onPause,
    onEnded,
    onSeek,
    videoIndex,
    setVideoIndex,
  };
}
