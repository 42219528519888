import React from "react";
import { useHistory } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Components
import Button from "components/Button";

// Constants
import { GHOST_WHITE, WHITE, WILD_BLUE_YONDER } from "constants/colors";

// Assets
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
import { ReactComponent as ArrowIcon } from "assets/back.svg";
import { ROOT } from "constants/routes";
import SvgIcon from "components/SvgIcon";

// Styles
const useStyles = makeStyles(() => ({
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "50px 0px",
  },
  backButton: {
    display: "flex",
    alignItems: "center",
  },
  cancelIcon: {
    cursor: "pointer",
  },
}));

const Navigation = () => {
  const classes = useStyles({});
  const history = useHistory();
  return (
    <div className={classes.navigation}>
      <div className={classes.backButton}>
        <Button
          color={GHOST_WHITE}
          icon={<ArrowIcon />}
          onClick={() => history.goBack()}
          strokeWidth={2}
        />
        <Typography variant="h5" className={classes.title}>
          Settings
        </Typography>
      </div>
      <SvgIcon
        className={classes.cancelIcon}
        icon={<CancelIcon />}
        color={WILD_BLUE_YONDER}
        hoverColor={WHITE}
        strokeWidth={3}
        onClick={() => history.push(ROOT)}
      />
    </div>
  );
};

export default Navigation;
