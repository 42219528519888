import React from "react";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { useState } from "react";
import { SketchPicker } from "react-color";
import { WILD_BLUE_YONDER } from "constants/colors";
import { IconButton, makeStyles } from "@material-ui/core";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const useStyles = makeStyles(() => ({
  wrapper: props => ({
    width: props.fullWidth ? "100%" : 50,
  }),
  color: props => ({
    height: "14px",
    borderRadius: "2px",
    backgroundColor: props.color,
    position: "relative",
  }),
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "block",
    cursor: "pointer",
  },
  popover: props => ({
    position: "absolute",
    transform: "translateY(4px)",
    zIndex: "2",
    ...(props.alwaysOpen && {
      position: "static",
      transform: "none",
      width: "max-content",
    }),
  }),
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  resetIconButton: {
    height: 24,
    width: 24,
    marginLeft: 8,
  },
}));

const ColorPicker = props => {
  const { color, className, fullWidth, alwaysOpen } = props;
  // hooks
  const classes = useStyles({ color, fullWidth, alwaysOpen });
  // state
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const staticVersion = (
    <div className={classes.popover}>
      <SketchPicker disableAlpha {...props} />
    </div>
  );

  if (alwaysOpen) return staticVersion;

  return (
    <div className={clsx(classes.wrapper, className)}>
      {displayColorPicker && (
        <div
          className={classes.cover}
          onClick={() => setDisplayColorPicker(false)}
        />
      )}

      <div
        className={classes.swatch}
        onClick={() => setDisplayColorPicker(prev => !prev)}
      >
        <div className={classes.color} />
      </div>
      {displayColorPicker ? staticVersion : null}
    </div>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  alwaysOpen: PropTypes.bool,
};

const ColorPickerControl = ({
  control,
  name,
  rules,
  defaultValue,
  ...sketchColorPickerProps
}) => {
  // hooks
  const classes = useStyles();

  // state
  const [localColor, setLocalColor] = useState(defaultValue);

  // functions
  const handleColorChange = values => setLocalColor(values.hex);

  const handleColorPickerClose = (values, onChange) => onChange(values.hex);

  const handleReset = onChange => {
    onChange("");
    setLocalColor("");
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ColorPicker
            color={field.value || localColor}
            onChange={handleColorChange}
            onChangeComplete={values =>
              handleColorPickerClose(values, field.onChange)
            }
            {...sketchColorPickerProps}
          />
          {field.value && (
            <IconButton size="small" className={classes.resetIconButton}>
              <CloseRoundedIcon onClick={() => handleReset(field.onChange)} />
            </IconButton>
          )}
        </div>
      )}
    />
  );
};

ColorPickerControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
};

export { ColorPickerControl };

export default ColorPicker;
