import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";

// icons
import likeIcon from "assets/like-icon-filled-rounded.svg";
import loveIcon from "assets/love-icon-filled-rounded.svg";
import hintIcon from "assets/hint-icon-filled-rounded.svg";

// Components
import AudioPlayer from "components/AudioPlayer";
import VideoPlayer from "components/VideoPlayer";
import Date from "components/Date";
import { Divider, Typography, Grid } from "@material-ui/core";
import Avatar from "components/Avatar";
import { Truncate } from "components/TextFormat/Truncate";

// Contexts
import { AppContext } from "context/AppContext";

// Hooks
import theme from "themeV4";
import { WILD_BLUE_YONDER } from "constants/colors";
import { POST_REACTION } from "../enums";
import dayjs from "dayjs";

// Dayjs plugins
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const Post = ({
  avatar,
  name,
  text,
  date,
  type,
  style,
  role,
  reactions,
  content,
  taggedUsers,
  taggedProject,
}) => {
  const useStyles = makeStyles(() => ({
    container: {
      marginBottom: "1rem",
      overflow: "hidden",
      width: "100%",
      background: `${
        style == "light"
          ? theme.palette.common.white
          : theme.palette.componentsBackground.main
      }`,
      color:
        style === "dark"
          ? theme.palette.componentsBackground.light
          : theme.palette.common.black,
      borderRadius: "0.5rem",
    },
    content: {
      padding: theme.spacing(1.5),
    },
    profile: {
      display: "flex",
      flex: "1",
      alignItems: "center",
    },
    role: {
      color: WILD_BLUE_YONDER,
    },
    date: {
      fontSize: "0.62rem",
      color: WILD_BLUE_YONDER,
    },
    text: {
      paddingLeft: 38,
    },
    containerInfo: {
      paddingLeft: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      flex: "1",
    },
    info: {
      display: "flex",
      justifyContent: "space-between",
    },
    bottomBar: {
      display: "flex",
      padding: `0px ${theme.spacing(1.5)}px 0px ${theme.spacing(1.5)}px`,
      "& figcaption": {
        color: WILD_BLUE_YONDER,
        fontSize: "0.65rem",
      },
    },
    icon: {
      margin: `4px 8px 8px 0px`,
      display: "flex",
      alignItems: "center",
      "& > img": {
        width: 20,
        height: 20,
        marginRight: 4,
      },
    },
    showMoreOrLess: {
      marginTop: 5,
      cursor: "pointer",
    },
    mediaContainer: {
      cursor: "pointer",
    },
  }));
  const classes = useStyles();
  const { setLightbox } = useContext(AppContext);

  const renderDate = date => {
    if (dayjs().diff(date, "minute") < 1) {
      return (
        <Typography className={classes.date}>{`${dayjs().diff(
          date,
          "second"
        )} sec fa`}</Typography>
      );
    } else {
      const diffInHours = dayjs().diff(date, "hour");
      return diffInHours < 1 ? (
        <Typography className={classes.date}>{`${dayjs().diff(
          date,
          "minute"
        )} min fa`}</Typography>
      ) : diffInHours < 24 ? (
        <Typography
          className={classes.date}
        >{`${diffInHours} h fa`}</Typography>
      ) : (
        <Date dateString={date} className={classes.date} />
      );
    }
  };

  const renderOtherImages = () => {
    const imagesArray = content.split(",");
    const otherImages = imagesArray.slice(1, 5);
    let otherImagesCount;
    if (imagesArray.length > 5) {
      otherImagesCount = imagesArray.length - 5;
    }
    return otherImages?.map((image, index) => {
      return (
        <Grid item key={index} style={{ width: "25%", height: "75px" }}>
          <div
            style={{ position: "relative", height: "100%" }}
            onClick={() =>
              setLightbox({
                isOpen: true,
                type: type,
                content,
                index: index + 1,
              })
            }
          >
            <img
              src={image}
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 5,
              }}
            />
            {otherImagesCount && index === 3 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  backgroundColor: "#A5A9C799",
                }}
              >
                <Typography
                  variant="h5"
                  style={{ color: theme.palette.common.white }}
                >{`+${otherImagesCount}`}</Typography>
              </div>
            )}
          </div>
        </Grid>
      );
    });
  };

  const handleText = () => {
    const tagArray = text.match(/\[user:(\S+)]/g);
    const projectTagsArray = text.match(/\[projectId:(\S+)]/g);
    let updatedText = text;
    if (tagArray) {
      tagArray.forEach(tag => {
        const tagId = tag.substring(
          tag.lastIndexOf(":") + 1,
          tag.lastIndexOf("]")
        );
        const user = taggedUsers?.find(user => user?.id === tagId);
        updatedText = text.replace(
          tag,
          `@${user?.name || "Utente"} ${user?.surname || "Sconosciuto"}`
        );
      });
    }

    if (projectTagsArray && taggedProject) {
      projectTagsArray.forEach(tag => {
        updatedText = updatedText.replace(tag, `@${taggedProject?.name}`);
      });
    }

    return updatedText;
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.profile}>
          <Avatar image={avatar} size={30} onClick={() => {}} />
          <div className={classes.containerInfo}>
            <div className={classes.info}>
              <Typography style={{ fontWeight: "bold" }}>{name}</Typography>
              {renderDate(dayjs(date).utc(true))}
            </div>
            <Typography variant="subtitle1" className={classes.role}>
              {role}
            </Typography>
          </div>
        </div>
        <Typography className={classes.text}>
          <Truncate width={245}>{handleText()}</Truncate>
        </Typography>
        <div className={classes.mediaContainer}>
          {type === "audio" && <AudioPlayer content={content} />}
          {type === "video" && (
            <div
              onClick={() =>
                setLightbox({ isOpen: true, type: type, content, index: 0 })
              }
            >
              <VideoPlayer content={content} hideControls />
            </div>
          )}
          {type === "image" && (
            <>
              <div
                onClick={() =>
                  setLightbox({ isOpen: true, type: type, content, index: 0 })
                }
              >
                <img
                  style={{
                    width: "100%",
                    height: 130,
                    borderRadius: 5,
                    marginTop: 16,
                    objectFit: "cover",
                  }}
                  src={content.split(",")[0] || content}
                />
              </div>
              <Grid container spacing={1} style={{ width: "100%" }}>
                {renderOtherImages()}
              </Grid>
            </>
          )}
        </div>
      </div>
      <Divider style={{ marginTop: 11 }} />
      <div className={classes.bottomBar}>
        {reactions &&
          reactions?.map(reaction => (
            <figure key={nanoid()} className={classes.icon}>
              <img
                src={
                  reaction.type === POST_REACTION.LIKE
                    ? likeIcon
                    : reaction.type === POST_REACTION.LOVE
                    ? loveIcon
                    : hintIcon
                }
              />
              <figcaption>{reaction.count}</figcaption>
            </figure>
          ))}
      </div>
    </div>
  );
};

Post.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.string,
  reactions: PropTypes.array,
  reaction: PropTypes.number,
  content: PropTypes.string,
  taggedUsers: PropTypes.array,
  taggedProject: PropTypes.object,
};

export default Post;
