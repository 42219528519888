import { MAX_ANGLE_OFFSET, PEOPLE_SAFE_OFFSET } from "./config";

export const getThirdPoint = ({
  firstPointX,
  firstPointY,
  secondPointX,
  secondPointY,
  distanceRatio,
  offset = 0,
}) => {
  const thirdPoint = {
    x: (firstPointX + offset - secondPointX) * distanceRatio + secondPointX,
    y: (firstPointY + offset - secondPointY) * distanceRatio + secondPointY,
  };

  return thirdPoint;
};

const assignRandomSign = (abs, negativeRotation) =>
  negativeRotation ? -abs : abs;

const rotate = (cx, cy, x, y, angle) => {
  var radians = (angle * Math.PI) / 180,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cx + (x - cx) * cos - (y - cy) * sin,
    ny = cy + (y - cy) * cos + (x - cx) * sin;
  return { x: nx, y: ny };
};

const getAdaptedPercentage = (basePercentage, prevPercentage) => {
  if (
    basePercentage - prevPercentage < PEOPLE_SAFE_OFFSET &&
    basePercentage + PEOPLE_SAFE_OFFSET < 100
  )
    return getAdaptedPercentage(basePercentage + 1, prevPercentage);
  return basePercentage;
};

const generateAngle = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const getIntermediatePoint = ({
  startPoint,
  endPoint,
  percentage,
  prevPersonPercentage,
  pivotPoint,
  negativeRotation = false,
  phasesCount,
  offsetX = 0,
  offsetY = 0,
}) => {
  const adaptedPercentage = getAdaptedPercentage(
    percentage,
    prevPersonPercentage
  );

  const intermediatePoint = {
    x:
      (endPoint.x - startPoint.x) / (100 / adaptedPercentage || 1) +
      startPoint.x +
      offsetX,
    y:
      (endPoint.y - startPoint.y) / (100 / adaptedPercentage || 1) +
      startPoint.y +
      offsetY,
  };

  const phasesDependantAngle = MAX_ANGLE_OFFSET / phasesCount;
  const maxAngleOffsetDegrees =
    (phasesDependantAngle * adaptedPercentage) / 100;

  const angleOffsetDegrees = generateAngle(0, maxAngleOffsetDegrees);

  const angleOffsetDegreesWithSign = assignRandomSign(
    angleOffsetDegrees,
    negativeRotation
  );

  const rotatedIntermediatePoint = rotate(
    pivotPoint.x,
    pivotPoint.y,
    intermediatePoint.x,
    intermediatePoint.y,
    angleOffsetDegreesWithSign
  );

  return {
    coords: rotatedIntermediatePoint,
    angle: angleOffsetDegreesWithSign,
    adaptedPercentage,
  };
};

export const getCircumferencePoint = ({
  centerX,
  centerY,
  radius,
  index,
  pointsCount,
  offset = 0,
}) => {
  const pointCoords = {
    x:
      centerX + radius * Math.cos((2 * Math.PI * index) / pointsCount) - offset,
    y:
      centerY + radius * Math.sin((2 * Math.PI * index) / pointsCount) - offset,
  };

  return pointCoords;
};
