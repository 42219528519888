// NOTA: Se si cambiano le rotte controllare il file
// widgets -> configuration -> index.js
// Groove / Gallery
export const ROOT = "/";
export const PORTFOLIOS = "/portfolios/:portfolioId?";
export const DEPARTMENTS = "/departments/:departmentId?";

// Authentication
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgotPassword";
export const RESET_PASSWORD = "/resetPassword";
export const PASSWORD_REQUESTED = "/passwordRequested";
export const PASSWWORD_CHANGED = "/passwordChanged";

// Walkthrough
export const WALKTHROUGH = "/walkthrough";

// Project
export const PROJECT_DETAIL = "/project/:projectId";
export const PROJECT_SKYVIEW = "/project/:projectId/skyview";

// Planner
export const PLANNER_DETAIL = "/planner/:id";
export const PLANNER = "/planner";
export const PLANNER_BUILDER = "/planner/:projectId/builder";

// Brick
export const BRICK_DETAIL = "/brick/:id";

//User
export const USER = "/user/:id";
export const USER_PROJECT = "/user/:id/project/:projectId";

//Settings
export const SETTINGS = "/settings";
export const SETTINGS_USERS = "/settings/users";
export const SETTINGS_DEPARTMENTS = "/settings/departments";
export const SETTINGS_PORTFOLIOS = "/settings/portfolios";
export const SETTINGS_CUSTOMERS = "/settings/customers";
export const SETTINGS_SKILLS = "/settings/skills";
export const SETTINGS_JOB_TITLE = "/settings/jobtitles";
export const SETTINGS_GENERAL = "/settings/general";

//workLoad
export const WORKLOAD = "/workload/:id";

//Page not found
export const NOT_FOUND = "/notFound";

//Page error
export const GENERIC_ERROR = "/error";

//Search
export const SEARCH_ROOT = "/search";
export const SEARCH_CONTEXT = "/search/:searchContext";
