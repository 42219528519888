import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";

// Styles
const useStyles = makeStyles(() => ({
  showFilterRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "14px 0",
  },
  showIconContainer: {
    cursor: "pointer",

    "& :hover": {
      textDecoration: "underline",
    },
  },
  showFilterText: { padding: "5px 0" },
}));

const Collapsable = ({
  content,
  buttonsContainer,
  customIsOpen,
  callBackIsOpen,
}) => {
  const classes = useStyles();
  const [filtersOpen, setFiltersOpen] = useState(customIsOpen);

  const handleClick = () => {
    if (callBackIsOpen) {
      callBackIsOpen(!customIsOpen);
      setFiltersOpen(!filtersOpen);
    } else {
      setFiltersOpen(!filtersOpen);
    }
  };

  useEffect(() => {
    setFiltersOpen(customIsOpen);
  }, [customIsOpen]);

  return (
    <div>
      <div className={classes.showFilterRow}>
        <div
          className={classes.showIconContainer}
          onClick={() => handleClick()}
        >
          <p className={classes.buttonsContainer}>
            {!filtersOpen ? "+ Mostra Filtri" : "- Nascondi Filtri"}
          </p>
        </div>
        {filtersOpen && buttonsContainer}
      </div>
      <Collapse in={filtersOpen} timeout="auto">
        {content}
      </Collapse>
    </div>
  );
};

Collapsable.defaultProps = {
  customIsOpen: false,
};

Collapsable.propTypes = {
  content: PropTypes.node.isRequired,
  buttonsContainer: PropTypes.node.isRequired,
  customIsOpen: PropTypes.bool,
  callBackIsOpen: PropTypes.func,
};

export default Collapsable;
