import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import theme from "themeV4";

// Components
import TextField from "components/TextField";
import Autocomplete from "components/Autocomplete";
import Loading from "components/Loading";
import ImageUploader from "components/ImageUploader";
import NumberTextField from "components/NumberFormatTextField";
import DatePicker from "components/DatePicker";

// Icons
import WhiteCheckedIcon from "assets/check.svg";

// Material UI
import {
  Grid,
  Typography,
  Fade,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import dayjs from "dayjs";

// Constants
import { JOB_TITLE, SKILLS, SETTINGS_DEPARTMENTS } from "constants/api";

// Styles
const useStyles = makeStyles(() => ({
  formWrapper: {
    width: "100%",
  },
  formRow: {
    marginBottom: 15,
  },
  name: { flex: 1, marginBottom: "15px" },
  paragraphRow: {
    marginBottom: theme.spacing(0.5),
  },
  switchWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  dragIcon: {
    marginRight: 10,
  },
  loading: {
    height: "40vh",
  },
  title: {
    fontWeight: "bold",
    fontSize: 13,
  },
  paragraph: {
    fontWeight: "bold",
    color: theme.palette.common.black,
  },
  checkbox: {
    "& span": {
      paddingLeft: 0,
    },
  },
  uncheckedIcon: {
    borderRadius: 3,
    border: `1px solid ${theme.palette.componentsBackground.main}`,
    width: 24,
    height: 24,
    backgroundColor: theme.palette.common.white,
  },
  checkedIcon: {
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    "&:before": {
      display: "block",
      width: 24,
      height: 24,
      backgroundImage: `url(${WhiteCheckedIcon})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "60%",
      content: '""',
    },
  },
}));

const THEME_TYPE = "light";

const UserForm = ({
  userData,
  departmentTitle,
  loading,
  files,
  setFiles,
  fromSettings,
}) => {
  // Hooks
  const classes = useStyles();
  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext();

  // Effects
  useEffect(() => {
    if (userData) {
      setValue("name", userData.name);
      setValue("surname", userData.surname);
      setValue("email", userData.email);
      setValue("roleId", userData.roleId);
      setValue("imageUrl", userData.imageUrl);
      setValue("departmentId", userData.departmentName || departmentTitle);
      setValue("department", userData.department);
      setValue("jobTitleId", userData.jobTitle);
      setValue("hourlyCost", userData.hourlyCost);
      setValue("userContractFrom", "");
      setValue("seniorSkills", userData.seniorSkills);
      setValue("intermediateSkills", userData.intermediateSkills);
      setValue("juniorSkills", userData.juniorSkills);
      setValue("canAccessWeb", userData.canAccessWeb);
      setValue("canManageUsers", userData.canManageUsers);
      setValue("isAdmin", userData.isAdmin);
    } else {
      setValue("seniorSkills", []);
      setValue("intermediateSkills", []);
      setValue("juniorSkills", []);
      setValue("userContractFrom", dayjs());
    }
  }, [userData]);

  useEffect(() => {
    setValue("departmentId", departmentTitle);
  }, [departmentTitle]);

  if (loading)
    return (
      <div className={classes.loading}>
        <Loading showWrapper={false} />
      </div>
    );

  const StyledCheckbox = props => {
    const classes = useStyles();

    return (
      <Checkbox
        disableRipple
        color="primary"
        checkedIcon={<span className={classes.checkedIcon} />}
        icon={<span className={classes.uncheckedIcon} />}
        {...props}
      />
    );
  };

  return (
    <Fade in timeout={400}>
      <form>
        <Grid container spacing={1} style={{ width: "100%" }}>
          <Grid item xs={12} className={classes.formRow}>
            <Typography color="primary" className={classes.title}>
              {departmentTitle && `DIPARTIMENTO: ${departmentTitle}`}
            </Typography>
          </Grid>
          <Grid item xs>
            <div style={{ display: "flex" }}>
              <ImageUploader
                selectedFiles={files}
                onSelect={setFiles}
                imageUrl={watch("imageUrl")}
                themeType={THEME_TYPE}
                dimensions={{ width: 225, height: 175 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  //justifyContent: "space-between",
                  width: "100%",
                  marginLeft: theme.spacing(2),
                }}
              >
                <Grid item xs={12} className={classes.name}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        label="Nome"
                        error={!!errors.name}
                        required
                        themeType={THEME_TYPE}
                        shrink={!!field.value}
                        {...field}
                      />
                    )}
                    name="name"
                    control={control}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        label="Cognome"
                        error={!!errors.surname}
                        required
                        themeType={THEME_TYPE}
                        shrink={!!field.value}
                        {...field}
                      />
                    )}
                    name="surname"
                    control={control}
                    rules={{ required: true }}
                  />
                </Grid>
                {/*<Grid item>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        label="Ruolo"
                        error={!!errors.roleId}
                        required
                        themeType={THEME_TYPE}
                        shrink={!!field.value}
                        {...field}
                      />
                    )}
                    name="roleId"
                    control={control}
                    rules={{ required: true }}
                  />
                    </Grid>*/}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            <Controller
              render={({ field }) => (
                <TextField
                  label="E-mail"
                  error={!!errors.email}
                  required
                  themeType={THEME_TYPE}
                  shrink={!!field.value}
                  type="email"
                  disabled={userData?.email}
                  {...field}
                />
              )}
              name="email"
              control={control}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            {!fromSettings && (
              <Controller
                render={({ field }) => (
                  <TextField
                    label="Nome dipartimento"
                    required
                    themeType={THEME_TYPE}
                    shrink={!!field.value}
                    disabled
                    {...field}
                  />
                )}
                name="departmentId"
                control={control}
              />
            )}
            {fromSettings && (
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    label="Dipartimento"
                    endpoint={`${SETTINGS_DEPARTMENTS}?OnlyPotentialUserContainer=true&q`}
                    error={!!errors.department}
                    getOptionLabel={option => option?.name}
                    themeType={THEME_TYPE}
                    required
                    {...field}
                    onChange={(_, data) => field.onChange(data)}
                  />
                )}
                name="department"
                defaultValue={""}
                control={control}
                rules={{ required: true }}
              />
            )}
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  label="Job title"
                  endpoint={`${JOB_TITLE}?q`}
                  error={!!errors.jobTitleId}
                  getOptionLabel={option => option?.name}
                  themeType={THEME_TYPE}
                  required
                  {...field}
                  onChange={(_, data) => field.onChange(data)}
                />
              )}
              name="jobTitleId"
              defaultValue={""}
              control={control}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={userData ? 12 : 6} className={classes.formRow}>
            <Controller
              render={({ field }) => (
                <NumberTextField
                  label="Costo orario"
                  icon="€"
                  themeType={THEME_TYPE}
                  required
                  disabled={userData || false}
                  error={!!errors.hourlyCost}
                  {...field}
                />
              )}
              name="hourlyCost"
              control={control}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={userData ? 12 : 6} className={classes.formRow}>
            {!userData && (
              <Controller
                render={({ field }) => (
                  <DatePicker
                    themeType={THEME_TYPE}
                    label="Data inizio costo"
                    error={!!errors.userContractFrom}
                    helperText={
                      errors.userContractFrom?.type === "validate"
                        ? "La data di inizio costo non può essere superiore alla data odierna"
                        : undefined
                    }
                    required
                    maxDate={dayjs()}
                    {...field}
                  />
                )}
                name="userContractFrom"
                control={control}
                rules={{
                  required: true,
                  validate: selectedDate =>
                    selectedDate.isSameOrBefore(dayjs()),
                }}
              />
            )}
          </Grid>
          <Grid item className={classes.paragraphRow}>
            <Typography className={classes.paragraph}>Skills</Typography>
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            {watch("seniorSkills") && (
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    label="Senior"
                    endpoint={`${SKILLS}?q`}
                    error={!!errors.seniorSkills}
                    getOptionLabel={option => option?.name}
                    multiple
                    themeType={THEME_TYPE}
                    {...field}
                    onChange={(_, data) => field.onChange(data)}
                    minSearchLength={2}
                  />
                )}
                name="seniorSkills"
                defaultValue={[]}
                control={control}
              />
            )}
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            {watch("intermediateSkills") && (
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    label="Intermediate"
                    endpoint={`${SKILLS}?q`}
                    error={!!errors.intermediateSkills}
                    getOptionLabel={option => option?.name}
                    multiple
                    themeType={THEME_TYPE}
                    {...field}
                    onChange={(_, data) => field.onChange(data)}
                    minSearchLength={2}
                  />
                )}
                name="intermediateSkills"
                defaultValue={[]}
                control={control}
              />
            )}
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            {watch("juniorSkills") && (
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    label="Junior"
                    endpoint={`${SKILLS}?q`}
                    error={!!errors.juniorSkills}
                    getOptionLabel={option => option?.name}
                    multiple
                    themeType={THEME_TYPE}
                    {...field}
                    onChange={(_, data) => field.onChange(data)}
                    minSearchLength={2}
                  />
                )}
                name="juniorSkills"
                defaultValue={[]}
                control={control}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  className={classes.checkbox}
                  control={<StyledCheckbox {...field} checked={field.value} />}
                  label="Project leader"
                />
              )}
              control={control}
              name="canAccessWeb"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  className={classes.checkbox}
                  control={<StyledCheckbox {...field} checked={field.value} />}
                  label="Abilita gestione utenti"
                />
              )}
              control={control}
              name="canManageUsers"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  className={classes.checkbox}
                  control={<StyledCheckbox {...field} checked={field.value} />}
                  label="Admin"
                />
              )}
              control={control}
              name="isAdmin"
            />
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};

UserForm.propTypes = {
  userData: PropTypes.object,
  departmentTitle: PropTypes.string,
  loading: PropTypes.bool,
  files: PropTypes.array,
  setFiles: PropTypes.func,
  fromSettings: PropTypes.bool,
};

export default UserForm;
