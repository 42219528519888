import React from "react";
import PropTypes from "prop-types";

import NumberFormatCustom from "components/NumberFormat";
import NumericWidget from "../NumericWidget";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// Constants
import {
  DARK_ORANGE,
  HARVARD_CRIMSON,
  MALACHITE,
  OXFORD_BLUE,
  WILD_BLUE_YONDER,
} from "constants/colors";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "100%",
    display: "flex",
    float: "right",
    flexDirection: "row",
    backgroundColor: OXFORD_BLUE,
  },
  column: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  number: ({ color }) => ({
    width: "100%",
    textAlign: "right",
    color: color,
    fontSize: 32,
    marginTop: 10,
    fontWeight: "bold",
  }),
}));

const Delay = ({ value }) => {
  /* 
    Le fasce di colore sono assegnate secondo i segueti criteri:
    - se (data fine prevista - data fine pianificata) ≤ -3gg → VERDE
    - se -3 < (data fine prevista - data fine pianificata) > 3gg → GRIGIO
    - se 3 ≤ (data fine prevista - data fine pianificata) < 5gg → ARANCIONE
    - se (data fine prevista - data fine pianificata) ≥ 5gg → ROSSO
  */

  const color =
    value <= -3
      ? MALACHITE
      : value > -3 && value < 3
      ? WILD_BLUE_YONDER
      : value >= 3 && value < 5
      ? DARK_ORANGE
      : HARVARD_CRIMSON;

  const classes = useStyles({ color });

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.row}>
            <NumericWidget
              number={
                <div className={classes.number}>
                  <NumberFormatCustom
                    value={Math.floor(value)}
                    decimalScale={0}
                    prefix={value === 0 || value < 0 ? "" : "+"}
                  />
                </div>
              }
              suffix={"gg"}
              color={color}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Delay.propTypes = {
  value: PropTypes.number,
};

export default Delay;
