import React from "react";
// Material UI
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { WILD_BLUE_YONDER } from "constants/colors";

const StyledSwitch = withStyles(theme => ({
  root: {
    width: 46,
    height: 23,
    padding: 0,
    margin: theme.spacing(1),
    marginBottom: 0,
  },
  switchBase: {
    marginLeft: 2,
    padding: 1,
    "&$disabled": {
      color: WILD_BLUE_YONDER,
      "& + $track": {
        backgroundColor: WILD_BLUE_YONDER,
        opacity: 1,
        border: "none",
      },
    },
    "&$checked": {
      transform: "translateX(22px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: "none",
      },
    },
    "&$checked$disabled": {
      transform: "initial",
      color: theme.palette.common.white,
      cursor: "default",
      opacity: 0.2,
      "& + $track": {
        backgroundColor: WILD_BLUE_YONDER,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: theme.palette.primary.main,
    },
  },
  thumb: {
    marginTop: 1.5,
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: "#a5a9c7",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  disabled: {},
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      {...props}
    />
  );
});

const CustomSwitch = props => {
  return <StyledSwitch {...props} />;
};

export default CustomSwitch;
