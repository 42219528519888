import React from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { WHITE } from "constants/colors";

// Components
import NumberFormatCustom from "components/NumberFormat";
import NumericWidget from "../NumericWidget";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "100%",
    display: "flex",
    float: "right",
    flexDirection: "row",
  },
  column: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  number: {
    width: "100%",
    textAlign: "right",
    color: WHITE,
    fontSize: 32,
    marginTop: 10,
    fontWeight: "bold",
    marginRight: "7px",
  },
}));

const Pricing = ({ value }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.row}>
            <NumericWidget
              number={
                <div className={classes.number}>
                  <NumberFormatCustom
                    value={value >= 1000000 ? value / 1000 : value}
                    decimalScale={Math.round(value * 10) / 10 < 100000 ? 2 : 0}
                  />
                  {value >= 1000000 ? " K " : ""}
                </div>
              }
              suffix={"€"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Pricing.propTypes = {
  value: PropTypes.number,
};

export default Pricing;
