/* eslint-disable max-len */
import React from "react";
import { PropTypes } from "prop-types";

const SentimentNegative = ({ fill = "#DB0015", width, height, style }) => {
  return (
    <svg
      width={width || 50}
      height={width || 50}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M32.368 0C23.9921 0.0664823 15.9819 3.44044 10.0826 9.38675C4.18325 15.3331 0.872986 23.3698 0.872986 31.746C0.872986 40.1222 4.18325 48.1589 10.0826 54.1053C15.9819 60.0516 23.9921 63.4255 32.368 63.492C40.7439 63.4255 48.7542 60.0516 54.6535 54.1053C60.5528 48.1589 63.863 40.1222 63.863 31.746C63.863 23.3698 60.5528 15.3331 54.6535 9.38675C48.7542 3.44044 40.7439 0.0664823 32.368 0ZM32.368 60.731C24.7317 60.6533 17.4345 57.5653 12.062 52.1379C6.68954 46.7105 3.6759 39.3823 3.6759 31.7455C3.6759 24.1088 6.68954 16.7805 12.062 11.3531C17.4345 5.92573 24.7317 2.83769 32.368 2.76C40.0044 2.83769 47.3016 5.92573 52.674 11.3531C58.0465 16.7805 61.0601 24.1088 61.0601 31.7455C61.0601 39.3823 58.0465 46.7105 52.674 52.1379C47.3016 57.5653 40.0044 60.6533 32.368 60.731Z"
        fill={fill}
      />
      <path
        d="M25.534 26.225C25.5259 25.4159 25.2786 24.6273 24.8232 23.9585C24.3678 23.2896 23.7247 22.7705 22.9748 22.4665C22.225 22.1624 21.4019 22.0871 20.6093 22.2499C19.8167 22.4127 19.09 22.8063 18.5207 23.3813C17.9514 23.9563 17.565 24.6869 17.4101 25.4811C17.2552 26.2753 17.3387 27.0976 17.6502 27.8443C17.9617 28.5911 18.4872 29.2291 19.1605 29.6778C19.8338 30.1265 20.6249 30.366 21.434 30.366C22.5264 30.3591 23.5715 29.9193 24.34 29.1431C25.1086 28.3668 25.538 27.3174 25.534 26.225ZM20.067 26.225C20.0696 25.9552 20.1519 25.6922 20.3037 25.4691C20.4555 25.246 20.6698 25.0728 20.9198 24.9714C21.1698 24.8699 21.4443 24.8447 21.7086 24.8989C21.9729 24.9531 22.2153 25.0843 22.4051 25.276C22.595 25.4677 22.7239 25.7113 22.7756 25.9761C22.8273 26.2409 22.7995 26.5151 22.6957 26.7641C22.5918 27.0131 22.4166 27.2259 22.1921 27.3755C21.9676 27.5251 21.7038 27.605 21.434 27.605C21.07 27.6026 20.7217 27.4561 20.4654 27.1974C20.2092 26.9387 20.066 26.5891 20.067 26.225Z"
        fill={fill}
      />
      <path
        d="M43.301 22.0842C42.4803 22.0761 41.6757 22.312 40.9893 22.7621C40.303 23.2121 39.7658 23.856 39.4461 24.6119C39.1264 25.3678 39.0385 26.2017 39.1937 27.0076C39.3488 27.8136 39.7399 28.5553 40.3174 29.1385C40.8948 29.7217 41.6326 30.1202 42.437 30.2834C43.2413 30.4465 44.076 30.3669 44.8351 30.0547C45.5942 29.7426 46.2433 29.2118 46.7002 28.53C47.157 27.8482 47.401 27.0459 47.401 26.2252C47.405 25.1328 46.9756 24.0834 46.2071 23.3071C45.4385 22.5309 44.3934 22.0911 43.301 22.0842ZM43.301 27.6052C43.0276 27.6078 42.7595 27.529 42.5308 27.379C42.3022 27.2289 42.1233 27.0143 42.0168 26.7624C41.9104 26.5104 41.8812 26.2326 41.933 25.964C41.9847 25.6955 42.1151 25.4484 42.3076 25.2541C42.5001 25.0598 42.7459 24.927 43.014 24.8727C43.282 24.8184 43.5602 24.845 43.8131 24.949C44.066 25.0531 44.2823 25.23 44.4345 25.4572C44.5867 25.6844 44.668 25.9517 44.668 26.2252C44.6693 26.5894 44.5262 26.9392 44.2699 27.1979C44.0136 27.4566 43.6652 27.6031 43.301 27.6052Z"
        fill={fill}
      />
      <path
        d="M32.368 38.6475C29.8514 38.6463 27.3633 39.1788 25.0676 40.2098C22.7719 41.2407 20.7209 42.7467 19.05 44.6285C18.8084 44.9023 18.6842 45.2602 18.7042 45.6249C18.7243 45.9895 18.8869 46.3317 19.157 46.5775C19.2902 46.6983 19.4461 46.7915 19.6157 46.8516C19.7852 46.9116 19.965 46.9374 20.1446 46.9273C20.3242 46.9173 20.5 46.8716 20.6618 46.793C20.8235 46.7144 20.9681 46.6044 21.087 46.4695C22.5036 44.877 24.2413 43.6025 26.1858 42.7297C28.1304 41.8569 30.2376 41.4057 32.369 41.4057C34.5004 41.4057 36.6076 41.8569 38.5521 42.7297C40.4966 43.6025 42.2344 44.877 43.651 46.4695C43.7699 46.6044 43.9144 46.7144 44.0762 46.793C44.238 46.8716 44.4138 46.9173 44.5934 46.9273C44.773 46.9374 44.9528 46.9116 45.1223 46.8516C45.2919 46.7915 45.4478 46.6983 45.581 46.5775C45.8511 46.3317 46.0137 45.9895 46.0337 45.6249C46.0538 45.2602 45.9296 44.9023 45.688 44.6285C44.0168 42.7465 41.9655 41.2403 39.6695 40.2093C37.3734 39.1784 34.8849 38.646 32.368 38.6475Z"
        fill={fill}
      />
    </svg>
  );
};

SentimentNegative.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
};

export { SentimentNegative };
