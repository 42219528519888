import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

// Icons
import { ReactComponent as ThumbUpIcon } from "assets/thumb-up-icon.svg";
import { ReactComponent as ThumbDownIcon } from "assets/thumb-down-icon.svg";
import { WHITE } from "constants/colors";
import { useLayoutEffect } from "react";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    height: "100%",
  },
  bar: {
    width: "100%",
    height: 12,
    borderRadius: 10,
    background: "linear-gradient(to left, #0000FF, #FF0000)",
    position: "relative",
    marginBlock: 20,
  },
  icons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 12,
  },
  marker: ({ displayValue }) => ({
    position: "absolute",
    left: `${displayValue}%`,
    top: "50%",
    transform: "translateY(-50%)",
    width: 2,
    height: 30,
    borderRadius: 20,
    backgroundColor: WHITE,
    transition: "0.7s ease-in-out",
    zIndex: 5,
  }),
  ticks: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  tick: {
    display: "block",
    borderRadius: "100%",
    backgroundColor: WHITE,
    opacity: 0.35,
    width: 8,
    height: 8,

    "&:first-child": {
      marginLeft: 2,
    },

    "&:last-child": {
      marginRight: 2,
    },
  },
}));

const HeatBar = ({ value, tickPercentage, max }) => {
  const [displayValue, setDisplaValue] = useState(0);
  const classes = useStyles({ displayValue });

  const percentageValue = Math.round((value / max) * 100);

  useLayoutEffect(() => {
    setDisplaValue(percentageValue > 100 ? 100 : percentageValue);
  }, [value]);

  const ticks = Math.round(100 / tickPercentage);

  return (
    <div className={classes.warpper}>
      <div className={classes.bar}>
        <div className={classes.marker} />
        {ticks && (
          <div className={classes.ticks}>
            {Array.from({ length: ticks }, (_, i) => (
              <div className={classes.tick} key={i}></div>
            ))}
          </div>
        )}
      </div>
      <div className={classes.icons}>
        <ThumbDownIcon />
        <ThumbUpIcon />
      </div>
    </div>
  );
};

HeatBar.propTypes = {
  value: PropTypes.array,
  tickPercentage: PropTypes.number,
  max: PropTypes.number,
};

HeatBar.defaultProps = {
  value: 0,
  max: 100,
  tickPercentage: 20,
};

export default HeatBar;
