/* eslint-disable max-len */
import React from "react";
import { PropTypes } from "prop-types";

const CollapseBricksIcon = ({ fill = "white", style, onClick }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    onClick={onClick}
  >
    <path
      d="M0.374523 1.99951V8.00049C0.37426 8.13124 0.387423 8.26081 0.413261 8.3818C0.4391 8.5028 0.477112 8.61284 0.52512 8.70566C0.573128 8.79847 0.630194 8.87224 0.693062 8.92275C0.75593 8.97325 0.823366 8.9995 0.891521 9L18.4715 9C18.5397 8.9995 18.6071 8.97325 18.67 8.92275C18.7329 8.87224 18.7899 8.79847 18.8379 8.70566C18.8859 8.61284 18.9239 8.5028 18.9498 8.3818C18.9756 8.26081 18.9888 8.13124 18.9885 8.00049V1.99951C18.9888 1.86876 18.9756 1.73919 18.9498 1.6182C18.9239 1.4972 18.8859 1.38716 18.8379 1.29434C18.7899 1.20153 18.7329 1.12776 18.67 1.07725C18.6071 1.02675 18.5397 1.0005 18.4715 1L0.891521 1C0.823366 1.0005 0.75593 1.02675 0.693062 1.07725C0.630194 1.12776 0.573128 1.20153 0.52512 1.29434C0.477112 1.38716 0.4391 1.4972 0.413261 1.6182C0.387423 1.73919 0.37426 1.86876 0.374523 1.99951ZM17.9545 1.99951V8.00049L1.40852 8.00049V1.99951L17.9545 1.99951Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.1"
    />
    <path
      d="M0.374523 11.9995V18.0005C0.37426 18.1312 0.387423 18.2608 0.413261 18.3818C0.4391 18.5028 0.477112 18.6128 0.52512 18.7057C0.573128 18.7985 0.630194 18.8722 0.693062 18.9227C0.75593 18.9733 0.823366 18.9995 0.891521 19H18.4715C18.5397 18.9995 18.6071 18.9733 18.67 18.9227C18.7329 18.8722 18.7899 18.7985 18.8379 18.7057C18.8859 18.6128 18.9239 18.5028 18.9498 18.3818C18.9756 18.2608 18.9888 18.1312 18.9885 18.0005V11.9995C18.9888 11.8688 18.9756 11.7392 18.9498 11.6182C18.9239 11.4972 18.8859 11.3872 18.8379 11.2943C18.7899 11.2015 18.7329 11.1278 18.67 11.0773C18.6071 11.0267 18.5397 11.0005 18.4715 11L0.891521 11C0.823366 11.0005 0.75593 11.0267 0.693062 11.0773C0.630194 11.1278 0.573128 11.2015 0.52512 11.2943C0.477112 11.3872 0.4391 11.4972 0.413261 11.6182C0.387423 11.7392 0.37426 11.8688 0.374523 11.9995ZM17.9545 11.9995V18.0005H1.40852V11.9995L17.9545 11.9995Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.1"
    />
  </svg>
);

CollapseBricksIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export { CollapseBricksIcon };
