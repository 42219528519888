//COMPONENTS
import InfoWidget from "../components/Info";
import { ReportWidget } from "../components";
import MarginWidget from "../components/Margin";
import BudgetErosionWidget from "../components/BudgetErosion";
import QualityWidget from "../components/Quality";
import DelayWidget from "../components/Delay";
import SkillMatchingWidget from "../components/SkillMatching";
import SentimentWidget from "../components/SentimentWidget";
import CompletionWidget from "../components/Completion";
import SavingExtraEffortWidget from "../components/SavingExtraEffort";
import MostUsedSkillsWidget from "../components/MostUsedSkills";
import PricingWidget from "../components/Pricing";
import PieChart from "components/PieChart";

// SOSPESI
// import PeopleWidget from "../components/PeopleWidget";
// import LowerQualitySkillsWidget from "../components/LowerQualitySkills";

//ENUM
import { widgetHeightTypes } from "utils/enums/widgets";

import {
  WIDGETS_BRICKS,
  WIDGETS_COMPANY,
  WIDGETS_DEPARTMENTS,
  WIDGETS_PORTFOLIOS,
  WIDGETS_PROJECTS,
  WIDGETS_SEARCH_BRICKS,
  WIDGETS_SEARCH_PROJECTS,
  WIDGETS_SEARCH_USERS,
  WIDGETS_USERS,
} from "constants/api";

export const ownerDisplay = ["/user", "/project", "/departments"];

export const widgetsGroove = {
  1: {
    title: "INFO",
    size: widgetHeightTypes.NORMAL,
    label1: "PROGETTI ATTIVI",
    label2: "RISORSE",
    component: InfoWidget,
  },
  2: {
    title: "PRICING",
    size: widgetHeightTypes.NORMAL,
    component: PricingWidget,
  },
  3: {
    title: "MARGINE PREVISTO",
    size: widgetHeightTypes.NORMAL,
    component: MarginWidget,
  },
  4: {
    title: "STATUS PROGETTI",
    size: widgetHeightTypes.DOUBLE,
    component: PieChart,
  },
  5: {
    title: "QUALITà",
    size: widgetHeightTypes.NORMAL,
    component: QualityWidget,
  },
  // SOSPESO
  // {
  //   id: "5",
  //   title: "CARICO DI LAVORO SETTIMANALE MEDIO",
  //   size: widgetHeightTypes.NORMAL,
  // },
  7: {
    title: "SENTIMENT",
    size: widgetHeightTypes.NORMAL,
    component: SentimentWidget,
  },
};

export const widgetsGalleryDo = {
  1: {
    title: "INFO",
    size: widgetHeightTypes.NORMAL,
    component: InfoWidget,
    label1: "PROGETTI ATTIVI",
    label2: "RISORSE",
  },
  2: {
    title: "PRICING",
    size: widgetHeightTypes.NORMAL,
    component: PricingWidget,
  },
  3: {
    title: "MARGINE PREVISTO",
    size: widgetHeightTypes.NORMAL,
    component: MarginWidget,
  },
  8: {
    title: "RITARDO MEDIO PREVISTO",
    size: widgetHeightTypes.NORMAL,
    component: DelayWidget,
  },
  4: {
    title: "STATUS PROGETTI",
    size: widgetHeightTypes.DOUBLE,
    component: PieChart,
  },
  5: {
    title: "QUALITà",
    size: widgetHeightTypes.NORMAL,
    component: QualityWidget,
  },
  7: {
    title: "SENTIMENT",
    size: widgetHeightTypes.NORMAL,
    component: SentimentWidget,
  },
  //sospeso
  // 9: {
  //   title: "SKILLS CHE HANNO GENERATO QUALITà PIù BASSA",
  //   size: widgetHeightTypes.DOUBLE,
  //   component: LowerQualitySkillsWidget,
  // },
};

export const widgetsGalleryBe = {
  1: {
    title: "INFO",
    size: widgetHeightTypes.NORMAL,
    component: InfoWidget,
    label1: "PROGETTI ATTIVI",
    label2: "RISORSE",
    clickable: true,
  },
  12: {
    title: "SAVING / EXTRA EFFORT CONSUNTIVATO",
    size: widgetHeightTypes.NORMAL,
    component: SavingExtraEffortWidget,
  },
  8: {
    title: "RITARDO MEDIO DI FINE BRICK",
    size: widgetHeightTypes.NORMAL,
    component: DelayWidget,
  },
  5: {
    title: "QUALITà",
    size: widgetHeightTypes.NORMAL,
    component: QualityWidget,
  },
  7: {
    title: "SENTIMENT",
    size: widgetHeightTypes.NORMAL,
    component: SentimentWidget,
  },
  10: {
    title: "SKILL MAGGIORMENTE USATE",
    size: widgetHeightTypes.DOUBLE,
    component: MostUsedSkillsWidget,
  },
};

export const widgetsMainEntity = {
  1: {
    title: "INFO",
    size: widgetHeightTypes.NORMAL,
    component: InfoWidget,
    clickable: true,
    label1: "BRICK ATTIVI",
    label2: "RISORSE",
  },
  2: {
    title: "PRICING",
    size: widgetHeightTypes.NORMAL,
    component: PricingWidget,
  },
  3: {
    title: "MARGINE PREVISTO",
    size: widgetHeightTypes.NORMAL,
    component: MarginWidget,
    clickable: true,
  },
  8: {
    title: "RITARDO PREVISTO",
    size: widgetHeightTypes.NORMAL,
    component: DelayWidget,
    clickable: true,
  },
  5: {
    title: "QUALITà",
    size: widgetHeightTypes.NORMAL,
    component: QualityWidget,
  },
  7: {
    title: "SENTIMENT",
    size: widgetHeightTypes.NORMAL,
    component: SentimentWidget,
  },
  15: {
    title: "BUDGET",
    size: widgetHeightTypes.NORMAL,
    component: PricingWidget,
  },
  16: {
    title: "EROSIONE DEL BUDGET",
    size: widgetHeightTypes.NORMAL,
    component: BudgetErosionWidget,
    clickable: true,
  },
  //sospeso
  // 9: {
  //   id: "7",
  //   title: "SKILLS CHE HANNO GENERATO QUALITà PIù BASSA",
  //   size: widgetHeightTypes.DOUBLE,
  //   component: LowerQualitySkillsWidget
  // },
};

export const widgetsBaseEntityDo = {
  11: {
    title: "COMPLETAMENTO",
    size: widgetHeightTypes.NORMAL,
    component: CompletionWidget,
  },
  12: {
    title: "SAVING / EXTRA EFFORT PREVISTO",
    size: widgetHeightTypes.NORMAL,
    component: SavingExtraEffortWidget,
  },
  8: {
    title: "RITARDO PREVISTO AL COMPLETAMENTO",
    size: widgetHeightTypes.NORMAL,
    component: DelayWidget,
  },
  5: {
    title: "QUALITà",
    size: widgetHeightTypes.NORMAL,
    component: QualityWidget,
  },
  7: {
    title: "SENTIMENT",
    size: widgetHeightTypes.NORMAL,
    component: SentimentWidget,
  },
  //sospeso
  // 13:{
  //   title: "PEOPLE SUPPORT",
  //   size: widgetHeightTypes.NORMAL,
  //   component: PeopleWidget
  // },
  14: {
    title: "SKILL MATCHING",
    size: widgetHeightTypes.NORMAL,
    component: SkillMatchingWidget,
  },
};

export const widgetsBaseEntityBe = {
  17: {
    title: "REPORT",
    size: widgetHeightTypes.DOUBLE,
    component: ReportWidget,
  },
  1: {
    title: "INFO",
    size: widgetHeightTypes.NORMAL,
    label1: "BRICK ATTIVI",
    label2: "TEAM DI PROGETTO",
    component: InfoWidget,
    clickable: true,
  },
  12: {
    title: "SAVING / EXTRA EFFORT CONSUNTIVATO",
    size: widgetHeightTypes.NORMAL,
    component: SavingExtraEffortWidget,
    secondaryText: "NEGLI ULTIMI TRE MESI",
  },
  8: {
    title: "RITARDO PREVISTO AL COMPLETAMENTO",
    size: widgetHeightTypes.NORMAL,
    component: DelayWidget,
    secondaryText: "NEGLI ULTIMI TRE MESI",
  },
  5: {
    title: "QUALITà",
    size: widgetHeightTypes.NORMAL,
    component: QualityWidget,
    secondaryText: "NEGLI ULTIMI TRE MESI",
  },
  7: {
    title: "SENTIMENT",
    size: widgetHeightTypes.NORMAL,
    component: SentimentWidget,
    secondaryText: "NEGLI ULTIMI TRE MESI",
    clickable: true,
  },
  10: {
    title: "SKILL MAGGIORMENTE USATE",
    size: widgetHeightTypes.DOUBLE,
    component: MostUsedSkillsWidget,
    secondaryText: "NEGLI ULTIMI TRE MESI",
  },
};

export const widgetsSearchBricks = {
  1: {
    title: "INFO",
    size: widgetHeightTypes.NORMAL,
    component: InfoWidget,
    label1: "BRICK ATTIVI",
    label2: "RISORSE",
  },
  //sospeso
  // 3: {
  //   title: "MARGINE PREVISTO",
  //   size: widgetHeightTypes.NORMAL,
  //   component: MarginWidget,
  // },
  8: {
    title: "RITARDO PREVISTO",
    size: widgetHeightTypes.NORMAL,
    component: DelayWidget,
  },
  5: {
    title: "QUALITà",
    size: widgetHeightTypes.NORMAL,
    component: QualityWidget,
  },
  7: {
    title: "SENTIMENT",
    size: widgetHeightTypes.NORMAL,
    component: SentimentWidget,
  },
  //sospeso
  // 9: {
  //   title: "SKILLS CHE HANNO GENERATO QUALITà PIù BASSA",
  //   size: widgetHeightTypes.DOUBLE,
  //   component: LowerQualitySkillsWidget,
  // },
};
export const widgetsSearchProjects = {
  1: {
    title: "INFO",
    size: widgetHeightTypes.NORMAL,
    label1: "PROGETTI ATTIVI",
    label2: "RISORSE",
    component: InfoWidget,
  },
  2: {
    title: "PRICING",
    size: widgetHeightTypes.NORMAL,
    component: PricingWidget,
  },
  3: {
    title: "MARGINE PREVISTO",
    size: widgetHeightTypes.NORMAL,
    component: MarginWidget,
  },
  8: {
    title: "RITARDO PREVISTO",
    size: widgetHeightTypes.NORMAL,
    component: DelayWidget,
  },
  4: {
    title: "STATUS PROGETTI",
    size: widgetHeightTypes.DOUBLE,
    component: PieChart,
  },
  5: {
    title: "QUALITà",
    size: widgetHeightTypes.NORMAL,
    component: QualityWidget,
  },
  7: {
    title: "SENTIMENT",
    size: widgetHeightTypes.NORMAL,
    component: SentimentWidget,
  },
  //sospeso
  // 9: {
  //   title: "SKILLS CHE HANNO GENERATO QUALITà PIù BASSA",
  //   size: widgetHeightTypes.DOUBLE,
  //   component: LowerQualitySkillsWidget,
  // },
};
export const widgetsSearchUsers = {
  1: {
    title: "INFO",
    size: widgetHeightTypes.NORMAL,
    label1: "PROGETTI ATTIVI",
    label2: "RISORSE",
    component: InfoWidget,
  },
  //sospeso
  // 3: {
  //   title: "MARGINE MEDIO",
  //   size: widgetHeightTypes.NORMAL,
  //   component: MarginWidget,
  // },
  8: {
    title: "RITARDO MEDIO DI FINE BRICK",
    size: widgetHeightTypes.NORMAL,
    component: DelayWidget,
  },
  5: {
    title: "QUALITà",
    size: widgetHeightTypes.NORMAL,
    component: QualityWidget,
  },
  7: {
    title: "SENTIMENT",
    size: widgetHeightTypes.NORMAL,
    component: SentimentWidget,
  },
  //sospeso
  // 10: {
  //   title: "SKILL MAGGIORMENTE USATE",
  //   size: widgetHeightTypes.DOUBLE,
  //   component: MostUsedSkillsWidget,
  // },
};

export const widgetConfiguration = {
  "/": widgetsGroove,
  "/portfolios": widgetsGalleryDo,
  "/departments": widgetsGalleryBe,
  "/project": widgetsMainEntity,
  "/brick": widgetsBaseEntityDo,
  "/user": widgetsBaseEntityBe,
  "/search/bricks": widgetsSearchBricks,
  "/search/projects": widgetsSearchProjects,
  "/search/users": widgetsSearchUsers,
};

export const widgetsUrl = {
  "/": WIDGETS_COMPANY,
  "/portfolios": WIDGETS_PORTFOLIOS,
  "/departments": WIDGETS_DEPARTMENTS,
  "/project": WIDGETS_PROJECTS,
  "/brick": WIDGETS_BRICKS,
  "/user": WIDGETS_USERS,
  "/search/bricks": WIDGETS_SEARCH_BRICKS,
  "/search/projects": WIDGETS_SEARCH_PROJECTS,
  "/search/users": WIDGETS_SEARCH_USERS,
};
