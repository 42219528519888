import React from "react";
import PropTypes from "prop-types";

// Components
import Loading from "components/Loading";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";

// Styles
const useStyles = makeStyles(() => ({
  wrapper: {
    zIndex: 200,
  },
}));

const GlobalLoading = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop open={open} className={classes.wrapper}>
      <Loading />
    </Backdrop>
  );
};

GlobalLoading.defaultProps = {
  open: false,
};

GlobalLoading.propTypes = {
  open: PropTypes.bool,
};

export default GlobalLoading;
