import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

// icons
import { ReactComponent as CheckIcon } from "assets/check.svg";
import { MALACHITE } from "constants/colors";

const useStyles = makeStyles((theme) => ({
  check: {
    width: "3%",
    marginRight: theme.spacing(0.5),

    "& path": {
      fill: (props) =>
        props.isOk ? MALACHITE : theme.palette.componentsBackground.main,
    },
  },
}));

const ConditionalCheck = ({ isOk }) => {
  const classes = useStyles({ isOk });

  return <CheckIcon className={classes.check} alt="check" />;
};

ConditionalCheck.propTypes = {
  isOk: PropTypes.bool,
};

ConditionalCheck.defaultProps = {
  isOk: false,
};

export default ConditionalCheck;
