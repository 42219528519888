import React, { useState, useRef, useEffect, useCallback } from "react";

import PropTypes from "prop-types";
import Slider from "react-slick";

import { makeStyles } from "@material-ui/core";

import Backdrop from "components/Backdrop";
import VideoPlayer from "components/VideoPlayer";

import nextIcon from "assets/next.svg";
import prevIcon from "assets/previous.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
  },
  icon: {
    width: "35px",
    height: "35px",
  },
  sliderContainer: {
    "& .slick-slider": {
      "& .slick-list": {
        padding: "unset !important",
        "& .slick-track": {
          display: "flex",
          "& .slick-current": {
            "& div": {
              "& div": {
                border: "2px solid",
                borderRadius: 10,
              },
            },
          },
          "& .slick-slide": {
            "& div": {
              "& div": {
                "& img": {
                  height: 100,
                  cursor: "pointer",
                  objectFit: "cover",
                },
              },
            },
          },
        },
      },
    },
  },
  arrow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    zIndex: 1,
    top: 0,
    height: "100%",
    width: 30,
  },
  smallArrow: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    zIndex: 1,
    top: 0,
    height: 100,
    width: 30,
    boxShadow: "rgb(0 0 0 / 50%) 0px -650px 0px 0px inset",
  },
  imageContentContainer: {
    width: "70%",
    height: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  mainImageContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    marginBottom: 15,
  },
  mainImage: {
    maxWidth: "80%",
    height: 600,
  },
}));

const LightBox = ({ lightbox, handleLightBoxClose }) => {
  // Hooks
  const classes = useStyles();

  // Refs
  const slider = useRef(null);

  // State
  const [currentImg, setCurrentImg] = useState();
  const [images, setImages] = useState([]);

  // Callbacks
  const onKeyPressed = useCallback(
    e => {
      switch (e.key) {
        case "ArrowLeft":
          onClickPrev?.();
          break;
        case "ArrowRight":
          onClickNext?.();
          break;
        case "Escape":
          handleClose?.();
          break;
      }
    },
    [currentImg]
  );

  // Effects
  useEffect(() => {
    if (window) window.addEventListener("keydown", onKeyPressed);
    return () => {
      window.removeEventListener("keydown", onKeyPressed);
    };
  }, [window, onKeyPressed]);

  useEffect(() => {
    if (currentImg) {
      slider?.current?.slickGoTo(currentImg?.index);
    }
  }, [currentImg]);

  useEffect(() => {
    if (lightbox) {
      const nextImages = lightbox?.content?.split?.(",");
      setImages(nextImages);
      setCurrentImg({
        data: nextImages?.[lightbox?.index],
        index: lightbox?.index,
      });
    }
  }, [lightbox]);

  // Functions
  const handleClose = () => {
    setCurrentImg(null);
    handleLightBoxClose?.();
  };

  const onClickPrev = scrollOnly => {
    slider?.current?.slickPrev();

    if (!scrollOnly) {
      const nextCurrentImage = { ...currentImg };

      const curIdx =
        !currentImg?.index || currentImg?.index === 0
          ? images?.length
          : currentImg?.index;

      const imgIndex = (curIdx - 1) % images?.length;

      nextCurrentImage.index = imgIndex;
      const imgData = images?.[imgIndex];
      nextCurrentImage.data = imgData;

      slider?.current?.slickGoTo(imgIndex);
      setCurrentImg(nextCurrentImage);
    }
  };

  const onClickNext = scrollOnly => {
    slider?.current?.slickNext();

    if (!scrollOnly) {
      const nextCurrentImage = { ...currentImg };

      const imgIndex = ((currentImg?.index || 0) + 1) % images?.length;
      nextCurrentImage.index = imgIndex;
      const imgData = images?.[imgIndex];
      nextCurrentImage.data = imgData;

      setCurrentImg(nextCurrentImage);
    }
  };

  // Components
  const SampleNextArrow = ({ small }) => {
    return (
      <div
        className={small ? classes.smallArrow : classes.arrow}
        style={{
          right: 0,
        }}
        onClick={() => onClickNext(small)}
      >
        <img src={nextIcon} height={small ? 20 : 45} />
      </div>
    );
  };

  SampleNextArrow.propTypes = {
    small: PropTypes.bool,
  };

  const SamplePrevArrow = ({ small }) => {
    return (
      <div
        className={small ? classes.smallArrow : classes.arrow}
        style={{
          left: 0,
          borderBottomLeftRadius: 8,
          borderTopLeftRadius: 8,
        }}
        onClick={() => onClickPrev(small)}
      >
        <img src={prevIcon} height={small ? 20 : 45} />
      </div>
    );
  };

  SamplePrevArrow.propTypes = {
    small: PropTypes.bool,
  };

  const ImageContent = () => {
    return (
      <div className={classes.imageContentContainer}>
        <div className={classes.mainImageContainer}>
          <div>
            <SamplePrevArrow />
          </div>
          <img src={currentImg?.data} className={classes.mainImage} />
          <div>
            <SampleNextArrow />
          </div>
        </div>
        {images?.length > 1 ? (
          <div className={classes.sliderContainer}>
            <Slider {...settings} ref={slider}>
              {images?.map((item, idx) => {
                return (
                  <div key={idx}>
                    <img
                      src={item}
                      onClick={() => {
                        setCurrentImg({ data: item, index: idx });
                      }}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
        ) : null}
      </div>
    );
  };

  const VideoContent = () => {
    return (
      <VideoPlayer
        content={lightbox?.content}
        customVideoHeight={600}
        enableFullscreen
        autoStart
      />
    );
  };

  const RenderContent = () => {
    switch (lightbox?.type) {
      case "image":
        return <ImageContent />;
      case "video":
        return <VideoContent />;

      default:
        return <>-</>;
    }
  };

  // Constants
  const settings = {
    dots: true,
    infinite: images?.length > 6,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    centerMode: true,
    draggable: false,
    nextArrow: <SampleNextArrow small />,
    prevArrow: <SamplePrevArrow small />,
  };

  return (
    <>
      <Backdrop
        backgroundOpacity={0.8}
        onClose={handleClose}
        open={lightbox?.isOpen}
        showCancel
      >
        <RenderContent />
      </Backdrop>
    </>
  );
};

LightBox.propTypes = {
  lightbox: PropTypes.object,
  handleLightBoxClose: PropTypes.func,
};

export { LightBox };
