import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Slider as MuiSlider } from "@material-ui/core";
import { OXFORD_BLUE, WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(theme => ({
  root: ({ sliderWidth }) => ({
    width: sliderWidth,
    "& .MuiSlider-rail": {
      color: OXFORD_BLUE,
      backgroundColor: OXFORD_BLUE,
      opacity: 1,
      height: 3,
    },
    "& .MuiSlider-thumb": {
      width: 16,
      height: 16,
      background: "rgba(0,0,255,0.6)",
      border: "3px solid #0000FF",
      marginTop: -7,
    },
    "& .MuiSlider-valueLabel": {
      top: "-22px",
      background: "transparent",
      color: "transparent",
      width: "20px",
      height: "20px",
      fontSize: 9,
    },
    "& .MuiSlider-markLabel": {
      color: WILD_BLUE_YONDER,
      fontSize: 9,
      top: 24,
    },
    "& .MuiSlider-marked": {
      marginBottom: 10,
    },
  }),
  margin: {
    height: theme.spacing(3),
  },
}));

const valueLabelFormat = (value, suffix) => {
  return `${value?.toString()}${suffix}`;
};

const Slider = ({
  customValueFormat,
  defaultValue,
  min,
  max,
  marks,
  step,
  sliderWidth,
  suffix,
}) => {
  const classes = useStyles({ sliderWidth });

  return (
    <div className={classes.root}>
      <MuiSlider
        track={false}
        valueLabelFormat={value =>
          valueLabelFormat(customValueFormat?.(value) || value, suffix)
        }
        aria-labelledby="track-false-slider"
        getAriaValueText={value =>
          valueLabelFormat(customValueFormat?.(value) || value, suffix)
        }
        defaultValue={defaultValue}
        valueLabelDisplay="on"
        marks={marks}
        min={min}
        step={step}
        max={max}
      />
    </div>
  );
};
/**
 * @example <SliderRangeControl
 *            control={control}
              name={'pricing'}
              defaultValue={[100, 300]}
              suffix={"K"}
              marks={[
                {
                  value: 0,
                  label: "0",
                },
                {
                  value: 500,
                  label: ">500K",
                },
              ]}
              min={0}
              max={500}
              step={1}
            />
 * @param  control - [react-hook-form control Object](https://react-hook-form.com/api/useform/control)
 * @param  name - Slider name
 * @param  defaultValue - Slider default value (defaultValue: [20, 100])
 * @param  suffix - String suffix assignable to labels
 * @param  min - Min value of Slider
 * @param  max - Max value of Slider
 * @param  marks - Marks assignable to min and max labels
 * @param  step - Slider number of steps
 * @param  sliderWidth - Slider style width (defaultValue: 240)
 */
const SliderRangeControl = ({
  customValueFormat,
  control,
  name,
  defaultValue,
  suffix,
  min,
  max,
  marks,
  step,
  sliderWidth,
}) => {
  const classes = useStyles({ sliderWidth });
  return (
    <div className={classes.root}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <MuiSlider
            {...field}
            onChange={(_, value) => {
              field.onChange(value);
            }}
            valueLabelFormat={value =>
              valueLabelFormat(customValueFormat?.(value) || value, suffix)
            }
            getAriaValueText={value =>
              valueLabelFormat(customValueFormat?.(value) || value, suffix)
            }
            valueLabelDisplay="on"
            max={max}
            min={min}
            marks={marks}
            step={step}
          />
        )}
      />
    </div>
  );
};

Slider.defaultProps = {
  sliderWidth: 240,
  defaultValue: 0,
};

Slider.propTypes = {
  customValueFormat: PropTypes.func,
  defaultValue: PropTypes.string,
  suffix: PropTypes.string,
  sliderWidth: PropTypes.number,
  marks: PropTypes.array,
  min: PropTypes.number,
  step: PropTypes.number,
  max: PropTypes.number,
};

SliderRangeControl.defaultProps = {
  sliderWidth: 240,
  defaultValue: [20, 100],
};

SliderRangeControl.propTypes = {
  customValueFormat: PropTypes.func,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  suffix: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  step: PropTypes.number,
  sliderWidth: PropTypes.number,
};

export { Slider, SliderRangeControl };
