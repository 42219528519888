import PropTypes from "prop-types";
import { fileTypes } from "utils/enums/file";

export const attachmentType = {
  IMAGE: "image",
  VIDEO: "video",
  DOCUMENT: "document",
};

export const fileShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  entityId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(fileTypes)),
  fileType: PropTypes.oneOf(Object.values(attachmentType)),
  name: PropTypes.string,
  size: PropTypes.number,
  s3Key: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  userId: PropTypes.string,
});
