import React from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
// Skyline Config
import { HORIZON_RADIUS, HORIZON_WIDTH } from "../config";

const useStyles = makeStyles(() => ({
  HorizonSeparator: ({ offsetTop, right }) => ({
    position: "absolute",
    width: HORIZON_WIDTH,
    height: 60,
    top: offsetTop,
    right: right ? 0 : "unset",
    borderRadius: HORIZON_RADIUS,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #33475C00 100%) 0% 0% no-repeat padding-box",
  }),
}));

const HorizonSeparator = ({ offsetTop, right }) => {
  const classes = useStyles({ offsetTop, right });
  return <div className={classes.HorizonSeparator}></div>;
};

HorizonSeparator.defaultProps = {
  offsetTop: 0,
  right: false,
};

HorizonSeparator.propTypes = {
  offsetTop: PropTypes.number,
  right: PropTypes.bool,
};

export default HorizonSeparator;
