import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
// Material UI
import Typography from "@material-ui/core/Typography";
// Locales
import "dayjs/locale/it";

const Date = ({ className, dateString, format, locale, variant }) => {
  if (!dateString) return null;
  return (
    <Typography className={className} variant={variant} component="span">
      {dayjs(dateString).locale(locale).format(format)}
    </Typography>
  );
};

Date.defaultProps = {
  format: "DD MMM YYYY",
  locale: "it",
};

Date.propTypes = {
  className: PropTypes.string,
  dateString: PropTypes.string,
  format: PropTypes.string,
  locale: PropTypes.string,
  variant: PropTypes.string,
};

export default Date;
