import clsx from "clsx";

export function getQueryString(params) {
  const paramsArray = [];
  if (params) {
    const keys = Object.keys(params);
    keys.forEach(key => {
      if (
        params[key] !== undefined &&
        params[key] !== "" &&
        params[key] !== null
      ) {
        if (Array.isArray(params[key])) {
          for (let i = 0; i < params[key].length; i += 1) {
            paramsArray.push(`${key}=${params[key][i]}`);
          }
        } else {
          paramsArray.push(`${key}=${params[key]}`);
        }
      }
    });
  }

  return paramsArray.length > 0
    ? `?${clsx(paramsArray).split(" ").join("&")}`
    : "";
}
