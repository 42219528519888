import React, { memo } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import { Controller, useFormContext } from "react-hook-form";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Fade, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
// Components
import TextField from "components/TextField";
import AutoComplete from "components/Autocomplete";
import BuilderBrick from "../BuilderBrick";
import SvgIcon from "components/SvgIcon";
// Assets
import { ReactComponent as DragHandle } from "assets/drag-handle.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-outlined.svg";
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
// API
import { USERS_LIST } from "constants/api";
// Constants
import { WILD_BLUE_YONDER, WHITE, OXFORD_BLUE } from "constants/colors";

// Styles
const useStyles = makeStyles(theme => ({
  wrapper: {
    width: 290,
    padding: "0 6px",
    flexShrink: 0,
  },
  header: {
    display: "flex",
    marginBottom: 8,
    alignItems: "center",
  },
  dragHandle: {
    display: "flex",
    flexShrink: 0,
  },
  dragIcon: {
    marginRight: 10,
  },
  title: ({ id }) => ({
    color: id ? WHITE : WILD_BLUE_YONDER,
    fontWeight: "bold",
  }),
  headerActions: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    height: 50,
  },
  headerAction: {
    flexShrink: 0,
  },
  deleteButton: {
    padding: 18,
    "& path": {
      stroke: WILD_BLUE_YONDER,
      fill: WILD_BLUE_YONDER,
      color: WILD_BLUE_YONDER,
    },
    "&:hover": {
      "& path": {
        stroke: theme.palette.secondary.main,
        fill: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
      },
    },
  },
  bricksWrapper: {
    overflowY: "auto",
    height: "calc(100vh - 523px)",
  },
  missingBrick: {
    border: `2px dashed ${theme.palette.secondary.main}`,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "25px 20px",
    marginTop: 20,
    cursor: "pointer",
    "&:hover": {
      background: WILD_BLUE_YONDER + "10",
      cursor: "pointer",
    },
  },
  field: {
    marginBottom: 15,
  },
  primaryButton: ({ id }) => ({
    marginTop: 10,
    marginBottom: 5,
    backgroundColor: id && OXFORD_BLUE,
  }),
}));

const DEFAULT_PHASE_TITLE = "Nuova fase";
const PHASE_ANIMATION_DURATION = 250;

const BuilderPhase = ({
  bricks,
  dndProvided,
  draftId,
  id,
  index,
  missesBricks,
  onAddBrick,
  // onAddGenericBrick,
  onCopyBrick,
  onEditBrick,
  onEditPhase,
  onRestoreBrick,
  onDeleteBrick,
  onDeletePhase,
  onCreatePhase,
  title,
}) => {
  // Hooks
  const classes = useStyles({ id });
  const {
    control,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const phaseFormName = `phase-${id || draftId}`;

  const watchedPhaseName = watch(`${phaseFormName}.name`);
  const watchedPhaseOwner = watch(`${phaseFormName}.user`);

  // Helpers
  const handlePhaseAction = () => (id ? onAddBrick(id) : initPhaseCreation());

  const initPhaseCreation = () => {
    const newPhaseData = {
      draftId,
      name: getValues(`${phaseFormName}.name`),
      user: getValues(`${phaseFormName}.user`),
    };
    onCreatePhase(newPhaseData);
  };

  // Renders
  const renderHeader = () => (
    <div className={classes.header}>
      <div className={classes.dragHandle} {...dndProvided?.dragHandleProps}>
        {id && (
          <SvgIcon
            className={classes.dragIcon}
            icon={<DragHandle />}
            color={WILD_BLUE_YONDER}
            width={14}
          />
        )}
        <Typography component="h3" variant="h6" className={classes.title}>
          {id ? title : DEFAULT_PHASE_TITLE}
        </Typography>
      </div>
      <div className={classes.headerActions}>
        {onDeletePhase && (
          <IconButton
            onClick={() => onDeletePhase(id)}
            className={classes.deleteButton}
          >
            <SvgIcon
              icon={draftId ? <CancelIcon /> : <DeleteIcon />}
              width={14}
              height={14}
            />
          </IconButton>
        )}
      </div>
    </div>
  );

  const renderFields = () => (
    <>
      <Controller
        render={({ field }) => (
          <TextField
            required={!!id}
            label="Nome fase"
            className={classes.field}
            error={!!errors[phaseFormName]?.name}
            {...field}
            onBlur={onEditPhase && (() => onEditPhase(id))}
          />
        )}
        defaultValue=""
        name={`${phaseFormName}.name`}
        rules={{ required: !!id }}
        control={control}
      />

      <Controller
        render={({ field }) => (
          <AutoComplete
            required={!!id}
            label="Owner di fase"
            className={classes.field}
            endpoint={`${USERS_LIST}?me=true&q`}
            getOptionLabel={option => `${option.name} ${option.surname}` || ""}
            error={!!errors[phaseFormName]?.user}
            {...field}
            onChange={(_, data) => {
              field.onChange(data);
              if (onEditPhase) onEditPhase(id);
            }}
          />
        )}
        defaultValue={null}
        name={`${phaseFormName}.user`}
        control={control}
        rules={{ required: !!id }}
      />
    </>
  );

  const renderActions = () => (
    <>
      <Button
        disabled={!id && (!watchedPhaseName || !watchedPhaseOwner)}
        fullWidth
        className={classes.primaryButton}
        color="primary"
        onClick={handlePhaseAction}
        variant={id ? "outlined" : "contained"}
      >
        {id ? "+ BRICK" : "SALVA"}
      </Button>
      {/* {bricks?.length > 0 && (
        <Button
          fullWidth
          variant="text"
          disableRipple
          onClick={() => onAddGenericBrick(id)}
        >
          + BRICK GENERICO
        </Button>
      )} */}
    </>
  );

  const renderMissingBrick = () => (
    <div onClick={() => onAddBrick(id)} className={classes.missingBrick}>
      <Typography component="span" variant="subtitle1">
        + AGGIUNGI UN BRICK PER PUBBLICARE LA FASE
      </Typography>
    </div>
  );

  const renderBricks = () => (
    <div className={classes.bricksWrapper}>
      <PerfectScrollbar style={{ paddingBottom: 20 }}>
        <div className={classes.bricksContainer}>
          {!missesBricks &&
            bricks?.map(
              (
                {
                  id: brickId,
                  name,
                  plannedEndDate,
                  plannedStartDate,
                  realEndDate,
                  realStartDate,
                  user,
                  isDraft,
                  inactive,
                  type,
                  multipleDates,
                  color,
                },
                idx
              ) => (
                <BuilderBrick
                  key={idx}
                  endDate={realEndDate || plannedEndDate}
                  name={name}
                  onCopy={() => onCopyBrick(brickId, id)}
                  onEdit={() => onEditBrick(brickId, id)}
                  onDelete={() => onDeleteBrick(brickId, id)}
                  onRestore={() => onRestoreBrick(brickId, id)}
                  startDate={realStartDate || plannedStartDate}
                  color={color}
                  user={user}
                  isDraft={isDraft}
                  isInactive={!!inactive}
                  type={type}
                  multipleDates={multipleDates}
                />
              )
            )}
          {missesBricks && renderMissingBrick()}
        </div>
      </PerfectScrollbar>
    </div>
  );

  return (
    <Fade in timeout={PHASE_ANIMATION_DURATION * (index + 1)}>
      <div
        className={classes.wrapper}
        ref={dndProvided?.innerRef}
        {...dndProvided?.draggableProps}
      >
        {renderHeader()}
        {renderFields()}
        {renderActions()}
        {renderBricks()}
      </div>
    </Fade>
  );
};

BuilderPhase.propTypes = {
  bricks: PropTypes.array,
  dndProvided: PropTypes.object,
  draftId: PropTypes.string,
  id: PropTypes.number,
  index: PropTypes.number,
  onAddBrick: PropTypes.func,
  onAddGenericBrick: PropTypes.func,
  onCopyBrick: PropTypes.func,
  onEditBrick: PropTypes.func,
  onEditPhase: PropTypes.func,
  onDeletePhase: PropTypes.func,
  onDeleteBrick: PropTypes.func,
  onRestoreBrick: PropTypes.func,
  onCreatePhase: PropTypes.func,
  missesBricks: PropTypes.bool,
  title: PropTypes.string,
};

export default memo(BuilderPhase);
