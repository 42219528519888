/* eslint-disable max-len */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import {
  WILD_BLUE_YONDER,
  HARVARD_CRIMSON,
  FOUNTAIN_BLUE,
  MALACHITE,
} from "constants/colors";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    height: "100%",
  },
}));

const AreaWithDate = props => {
  const classes = useStyles();
  const { data, id } = props;

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return;

    // Create chart
    var chart = am4core.create(`div${id}`, am4charts.XYChart);

    chart.data = data;
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.baseInterval = {
      timeUnit: "day",
      count: 1,
    };
    dateAxis.renderer.labels.template.fill = am4core.color(props.color);
    dateAxis.renderer.labels.template.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.labels.template.fill = am4core.color(props.color);
    valueAxis.renderer.grid.template.stroke = am4core.color(props.color);

    var series = chart.series.push(new am4charts.LineSeries());
    series.tensionX = 0.8;
    series.tensionY = 1;
    series.dataFields.dateX = "x";
    series.dataFields.valueY = "y";
    series.fillOpacity = 0.3;
    series.stroke = am4core.color(FOUNTAIN_BLUE);
    series.strokeWidth = 3;
    series.tooltipHTML = `<div><span style="font-size: 20px">{valueY}</span><br><span style="font-size: 10px">&nbsp;giorni&nbsp;</span></div>`;
    series.tooltip.getFillFromObject = false;

    series.tooltip.getFillFromObject = false;
    series.adapter.add("tooltipText", tooltipText => {
      if (series.tooltipDataItem.valueY < 0)
        series.tooltip.background.fill = am4core.color(MALACHITE);
      else series.tooltip.background.fill = am4core.color(HARVARD_CRIMSON);
      return tooltipText;
    });

    series.tooltip.background.cornerRadius = 60;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.background.pointerLength = 0;
    series.tooltip.label.minWidth = 40;
    series.tooltip.label.minHeight = 40;
    series.tooltip.label.textAlign = "middle";

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;
    chart.cursor.lineX.opacity = 0;
  }, [data]);

  return <div className={classes.container} id={`div${id}`} />;
};

AreaWithDate.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  theme: PropTypes.array,
  legend: PropTypes.bool,
  color: PropTypes.string,
};

AreaWithDate.defaultProps = {
  legend: false,
  color: WILD_BLUE_YONDER,
};

export default AreaWithDate;
