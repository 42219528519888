import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Components
import Loading from "components/Loading";
import PerfectScrollbar from "react-perfect-scrollbar";

//Hooks
import useFetch from "hooks/useHTTP";

// Constants
import { USERS_LIST } from "constants/api";

import { Box, Fade, Grid, Typography } from "@mui/material";
import SentimentWithDateManager from "components/Charts/SentimentWithDate/manager";
import HeatBar from "components/Charts/HeatBar";

import { Skill } from "./Skill";

const Be = () => {
  const { id } = useParams();
  const { get } = useFetch();

  // state
  const [loading, setLoading] = useState();
  const [sentimentData, setSentimentData] = useState();
  const [skills, setSkills] = useState();
  const [surveyData, setSurveyData] = useState();

  // effects
  useEffect(() => {
    fetchData();
  }, []);

  // api
  const fetchData = async () => {
    setLoading(true);
    try {
      const fetchSentiment = get(`${USERS_LIST}/${id}/sentimentGraph`).then(
        res => setSentimentData(res.data)
      );
      const fetchSkills = get(`${USERS_LIST}/${id}/skillsGraph`).then(res =>
        setSkills(res.data)
      );
      const fetchSurvey = get(`${USERS_LIST}/${id}/surveyGraph`).then(res =>
        setSurveyData(res.data)
      );
      await Promise.all([fetchSentiment, fetchSkills, fetchSurvey]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // renders
  const Sentiment = () => (
    <>
      <Typography component="div" fontSize={24} fontWeight="bold">
        Sentiment
      </Typography>
      <Box mt={4} mb={4}>
        <SentimentWithDateManager
          id={"user-sentiment"}
          data={sentimentData?.map(s => ({ x: s.date, y: s.value }))}
        />
      </Box>
    </>
  );

  const HardSkills = () => (
    <>
      <Typography variant="subtitle1" fontSize={16} fontWeight="bold" mb="40px">
        Hard skills
      </Typography>
      {skills?.length ? (
        <Fade in timeout={1300} appear>
          <Box height="calc(100vh - 560px)">
            <PerfectScrollbar id="pf-hard-skills" style={{ paddingBottom: 30 }}>
              {skills.map((s, idx) => (
                <Skill key={idx} data={s} />
              ))}
            </PerfectScrollbar>
          </Box>
        </Fade>
      ) : (
        <Typography variant="h6">Non sono presenti dati</Typography>
      )}
    </>
  );

  const BeKpis = () => (
    <>
      <Box my={3}>
        <Typography variant="subtitle1" fontSize={16} fontWeight="bold">
          Reputation
        </Typography>
        <HeatBar value={surveyData?.reputation + 2} max={4} />
      </Box>
      <Box my={3}>
        <Typography variant="subtitle1" fontSize={16} fontWeight="bold">
          Engagement
        </Typography>
        <HeatBar value={surveyData?.engagement + 2} max={4} />
      </Box>
      <Box my={3}>
        <Typography variant="subtitle1" fontSize={16} fontWeight="bold">
          Commitment
        </Typography>
        <HeatBar value={surveyData?.commitment + 2} max={4} />
      </Box>
    </>
  );

  return loading ? (
    <Box height="calc(100% - 100px)">
      <Loading showWrapper={false} />
    </Box>
  ) : (
    <div>
      <Sentiment />
      <Grid container gap={8} wrap="nowrap">
        <Grid item xs md={6}>
          <HardSkills />
        </Grid>
        <Grid item xs md={6}>
          <BeKpis />
        </Grid>
      </Grid>
    </div>
  );
};

export default Be;
