import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
// Material UI
import Rating from "@material-ui/lab/Rating";
// Assets
import { ReactComponent as RatingIconFull } from "assets/star-full.svg";
import { ReactComponent as RatingIconEmpty } from "assets/star-empty.svg";
import { WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(() => ({
  rating: ({ widthIcon, starsHeight }) => ({
    height: starsHeight,
    display: "flex",
    alignItems: "center",
    "& .MuiRating-icon": {
      width: widthIcon,
      marginRight: 4,
    },
    "& .MuiRating-iconFilled": {
      fill: "#FFF",
    },
    "& .MuiRating-iconEmpty": {
      fill: WILD_BLUE_YONDER,
    },
  }),
}));

const Reputation = forwardRef(
  (
    {
      customClass,
      reputation,
      widthIcon,
      readOnly,
      onChange,
      precision,
      starsHeight,
    },
    ref
  ) => {
    const classes = useStyles({ widthIcon, starsHeight });
    return (
      <Rating
        className={clsx(classes.rating, customClass)}
        value={reputation}
        precision={precision}
        icon={<RatingIconFull />}
        emptyIcon={<RatingIconEmpty />}
        readOnly={readOnly}
        onChange={onChange}
        name={"rating"}
        ref={ref}
      />
    );
  }
);

Reputation.displayName = "Reputation";

/**
 * @param  control - [react-hook-form control Object](https://react-hook-form.com/api/useform/control)
 * @param  name - Reputation name
 * @param  defaultValue - Reputation default value
 * @param  ...rest - props expected by \<Reputation/\> component
 * @example <ReputationControl
              control={control}
              name={"quality"}
              defaultValue={4}
              widthIcon={20}
              starsHeight={19}
              readOnly={false}
              horizontalDirection={true}
            />
 */

const ReputationControl = ({ control, name, defaultValue, ...rest }) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || 0}
        render={({ field }) => (
          <Reputation
            {...field}
            {...rest}
            onChange={e => field.onChange(+e.target.value)}
            reputation={field.value}
          />
        )}
      />
    </>
  );
};

Reputation.defaultProps = {
  reputation: 0,
  widthIcon: 8,
  readOnly: true,
  precision: 1,
  starsHeight: 35,
};

Reputation.propTypes = {
  customClass: PropTypes.string,
  reputation: PropTypes.number,
  widthIcon: PropTypes.number,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  precision: PropTypes.number,
  starsHeight: PropTypes.number,
};

ReputationControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  rest: PropTypes.shape({
    customClass: PropTypes.string,
    reputation: PropTypes.number,
    widthIcon: PropTypes.number,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    precision: PropTypes.number,
    starsHeight: PropTypes.number,
  }),
};

export { Reputation, ReputationControl };
