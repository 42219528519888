/* eslint-disable max-len */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import {
  WILD_BLUE_YONDER,
  PACIFIC_BLU,
  HARVARD_CRIMSON,
} from "constants/colors";

import { COLORS1 } from "./themes";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    height: "100%",
  },
}));

const StackedRadiusColumn = ({
  data,
  id,
  columnWidth,
  theme,
  onClick,
  color,
  scrollBars,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return;

    var chart = am4core.create(`div${id}`, am4charts.XYChart);
    chart.data = data;
    chart.colors.list = theme;
    chart.paddingTop = 40;
    chart.paddingLeft = 0;

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "x";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.fill = am4core.color(color);
    categoryAxis.renderer.labels.template.maxWidth = 70;
    categoryAxis.renderer.minGridDistance = 30;

    categoryAxis.events.on("sizechanged", function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -45;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    var categoryAxis2 = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis2.dataFields.category = "total";
    categoryAxis2.renderer.grid.template.location = 0;
    categoryAxis2.renderer.labels.template.fill = am4core.color(color);
    categoryAxis2.renderer.opposite = true;
    categoryAxis2.renderer.minGridDistance = 10;
    categoryAxis2.renderer.labels.template.truncate = true;

    // Axis for save date for post request in workloadWidget/Page
    var categoryAxis3 = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis3.dataFields.category = "extraX";
    categoryAxis3.renderer.grid.template.location = 0;
    categoryAxis3.renderer.labels.template.fill = am4core.color(color);
    categoryAxis3.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;
    valueAxis.renderer.labels.template.fill = am4core.color(color);
    valueAxis.renderer.grid.template.stroke = am4core.color(color);
    valueAxis.renderer.grid.template.strokeWidth = 3;
    valueAxis.renderer.minGridDistance = 30;

    valueAxis.title.text = "Ore";
    valueAxis.title.fill = am4core.color(color);
    valueAxis.title.rotation = 0;
    valueAxis.title.align = "center";
    valueAxis.title.valign = "top";
    valueAxis.title.dy = -25;
    valueAxis.title.dx = 40;
    valueAxis.title.fontWeight = 1000;

    var series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.width = am4core.percent(columnWidth);
    series1.dataFields.categoryX = "x";
    series1.dataFields.categoryY = "extraX";

    series1.dataFields.valueY = "y";
    series1.stacked = true;
    series1.columns.template.fill = am4core.color(PACIFIC_BLU);
    series1.columns.template.column.cornerRadiusBottomLeft = 10;
    series1.columns.template.column.cornerRadiusBottomRight = 10;
    series1.columns.template.tooltipText = "{y}H";

    var series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.columns.template.width = am4core.percent(columnWidth);
    series2.dataFields.categoryX = "x";
    series2.dataFields.categoryY = "extraX";
    series2.dataFields.valueY = "extraY";
    series2.stacked = true;
    series2.columns.template.fill = am4core.color(HARVARD_CRIMSON);
    series2.columns.template.column.strokeOpacity = 0;

    series2.columns.template.column.cornerRadiusTopLeft = 10;
    series2.columns.template.column.cornerRadiusTopRight = 10;
    series2.columns.template.tooltipText = "{extraY}H";

    //EVENT
    series1.columns.template.events.on(
      "hit",
      ev => {
        series1.columns.each(function (column) {
          if (column !== event.target) {
            column.setState("default");
            column.isActive = false;
          } else {
            column.isActive = true;
          }
        });
        series2.columns.each(function (column) {
          if (column.dataItem.categoryX !== ev.target.dataItem.categoryX) {
            column.setState("default");
            column.isActive = false;
          } else {
            column.isActive = true;
          }
        });
        onClick(ev.target.dataItem.categoryY);
      },
      this
    );

    series2.columns.template.events.on(
      "hit",
      ev => {
        series1.columns.each(function (column) {
          if (column.dataItem.categoryX !== ev.target.dataItem.categoryX) {
            column.setState("default");
            column.isActive = false;
          } else {
            column.isActive = true;
          }
        });
        series2.columns.each(function (column) {
          if (column !== event.target) {
            column.setState("default");
            column.isActive = false;
          } else {
            column.isActive = true;
          }
        });
        onClick(ev.target.dataItem.categoryY);
      },
      this
    );

    function customizeGrip(grip) {
      grip.icon.disabled = true;
    }

    if (scrollBars) {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      chart.scrollbarX.thumb.minWidth = 50;
      chart.scrollbarX.maxHeight = 10;

      chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarY.maxHeight = 10;

      customizeGrip(chart.scrollbarX.startGrip);
      customizeGrip(chart.scrollbarX.endGrip);
      customizeGrip(chart.scrollbarY.startGrip);
      customizeGrip(chart.scrollbarY.endGrip);
    }

    // ACTIVE COLUMN
    var columnTemplate = series2.columns.template;
    columnTemplate.togglable = true;

    var columnTemplate1 = series1.columns.template;
    columnTemplate1.togglable = true;

    var activeState1 = columnTemplate1.states.create("active");
    activeState1.properties.fill = am4core.color(WILD_BLUE_YONDER);
    activeState1.properties.fillOpacity = 0.5;

    var activeState = columnTemplate.states.create("active");
    activeState.properties.fill = am4core.color(WILD_BLUE_YONDER);
    activeState.properties.fillOpacity = 0.5;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;
    chart.cursor.lineX.opacity = 0;
  }, [data]);

  return <div className={classes.container} id={`div${id}`} />;
};

StackedRadiusColumn.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  theme: PropTypes.array,
  legend: PropTypes.bool,
  color: PropTypes.string,
  columnWidth: PropTypes.number,
  onClick: PropTypes.func,
  scrollBars: PropTypes.bool,
};

StackedRadiusColumn.defaultProps = {
  legend: false,
  color: WILD_BLUE_YONDER,
  columnWidth: 60,
  yMax: 60,
  theme: COLORS1,
  scrollBars: false,
};

export default StackedRadiusColumn;
