import React, { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";

// Hooks
import useFetch from "hooks/useHTTP";
import { useForm, Controller } from "react-hook-form";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Fade } from "@material-ui/core";

// Components
import Layout from "components/Layout";
import Button from "components/Button";
import Loading from "components/Loading";
import TextField from "components/TextField";
import Navigation from "../components/Navigation";
import DatePicker from "components/DatePicker";
import Select from "components/Select";
import Autocomplete from "components/Autocomplete";
import { RadioControl } from "components/Radio";

// Assets
import { ReactComponent as ExcelIcon } from "assets/excel-icon.svg";

// Constants
import {
  GENERAL_SETTINGS,
  USERS_LIST,
  DEPARTMENTS,
  EXPORT_REPORT,
} from "constants/api";

// Context
import { AppContext } from "context/AppContext";
import { Storage } from "aws-amplify";
import { downloadBlob, getFileKey } from "utils/file";
import { planningMode } from "utils/enums/globals";
import { CheckBoxControl } from "components/Checkbox";
import { FormControlLabel } from "@mui/material";
import { WILD_BLUE_YONDER } from "constants/colors";

const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);

// Styles
const useStyles = makeStyles(theme => ({
  paragraph: {
    width: "100%",
    borderBottom: "1px solid white",
    marginTop: `${theme.spacing(6)}px`,
  },
  subParagraph: {
    textTransform: "uppercase",
    marginTop: 30,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  gridContainer: {
    margin: `${theme.spacing(2)}px 0`,
  },
  reportRow: {
    marginTop: theme.spacing(2),
  },
  loading: {
    height: "60vh",
  },
  excelButton: {
    minWidth: "unset",
    padding: 10,
    "& svg": {
      height: 18,
    },
  },
  checkboxLabel: {
    color: "white",
    "& .Mui-disabled": {
      color: `${WILD_BLUE_YONDER} !Important`,
    },
  },
}));

const exportDefaultValues = {
  type: "bu",
  startDate: null,
  endDate: null,
};

const planningModeOptions = [
  {
    label: `Classica`,
    value: planningMode.CLASSIC,
  },
  {
    label: "Con date multiple",
    value: planningMode.MULTIPLE,
  },
];

const THEME_TYPE = "dark";

const SettingsGeneral = () => {
  // Hooks
  const classes = useStyles();
  const { handleSubmit, control, reset } = useForm();
  const {
    control: exportControl,
    watch: exportWatch,
    getValues: exportGetValues,
    formState: { errors: exportErrors },
    handleSubmit: exportHandleSubmit,
    trigger: exportTrigger,
    reset: exportReset,
  } = useForm({
    defaultValues: exportDefaultValues,
    shouldUnregister: true,
  });
  const { get, post } = useFetch();
  const { setModalConfig, setGlobalLoading, settings, setSettings } =
    useContext(AppContext);

  // State
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  // Effects
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const startDate = exportGetValues("startDate");
    const endDate = exportGetValues("endDate");
    startDate && endDate && exportTrigger(["startDate", "endDate"]);
  }, [exportWatch("startDate"), exportWatch("endDate")]);

  // API
  const fetchData = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchSettings(), fetchDepartments()]);
    } finally {
      setLoading(false);
    }
  };

  const fetchSettings = async () => {
    try {
      const res = await get(GENERAL_SETTINGS);
      if (!res.ok) throw new Error("Error occured while fetching settings");
      const settingsData = res.data;
      const {
        tenantName,
        doGrooveTitle,
        beGrooveTitle,
        tenantTargetMargin,
        isBeActive,
        isQualityActive,
        isAIActive,
        planningMode: savedPlanningMode,
      } = settingsData;
      setSettings(settingsData); // Refresh global settings (loaded at app mount)
      reset({
        tenantName,
        doGrooveTitle,
        beGrooveTitle,
        tenantTargetMargin,
        isBeActive,
        isQualityActive,
        isAIActive,
        planningMode: savedPlanningMode || planningMode.CLASSIC,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDepartments = async () => {
    try {
      const { data } = await get(DEPARTMENTS);
      setDepartments(data);
    } catch (err) {
      console.log("e", err);
    }
  };

  const saveSettings = async data => {
    setModalConfig({
      type: "loading",
    });
    const body = { ...data };
    if (settings?.id) body.id = settings.id;
    body.tenantTargetMargin = parseInt(data.tenantTargetMargin, 10);

    await post(GENERAL_SETTINGS, body)
      .then(response => {
        if (response.ok) {
          setSettings(response.data);
          setModalConfig({
            type: "success",
            primaryAction: {
              text: "OK",
              onClick: () => {
                setModalConfig(null);
              },
            },
          });
        }
      })
      .catch(() => {
        setModalConfig({
          type: "error",
          primaryAction: {
            text: "OK",
            onClick: () => {
              setModalConfig(null);
            },
          },
        });
      });
  };

  const getDepartmentsUsers = departmentId => {
    const targetUsers = departmentId
      ? departments.find(d => d.id === departmentId).peopleIds
      : departments.flatMap(d => d.peopleIds);

    return targetUsers;
  };

  const onExportSubmit = async data => {
    setGlobalLoading(true);
    const { startDate, endDate, type, users, group } = data;

    const targetUserIds =
      type === "user" ? users.map(u => u.id) : getDepartmentsUsers(group);

    const exportInput = {
      usersIds: targetUserIds,
      fromDate: dayjs(startDate).startOf("day").toISOString(),
      toDate: dayjs(endDate).endOf("day").toISOString(),
    };

    try {
      const { data: exportUrl } = await post(EXPORT_REPORT, exportInput);

      const fileKey = getFileKey(exportUrl);

      const fileName = exportUrl.split("/").at(-1);

      const fileData = await Storage.get(fileKey, {
        bucket: process.env.REACT_APP_BUCKET_PROJECTS,
        download: true,
      });
      downloadBlob(fileData.Body, fileName);
      exportReset();
    } catch (err) {
      console.log("e", err);
    } finally {
      setGlobalLoading(false);
    }
  };

  const groupLabel = settings?.beGrooveTitle || "Business unit";
  const exportOptions = [
    {
      label: `Report per ${groupLabel}`,
      value: "bu",
    },
    {
      label: "Report per singoli utenti",
      value: "user",
    },
  ];

  const availableGroups = departments.map(d => ({ name: d.name, value: d.id }));

  return (
    <Layout showSecondCol={false} showHeader={false} showPosts={false}>
      <Navigation />
      {loading ? (
        <div className={classes.loading}>
          <Loading showWrapper={false} />
        </div>
      ) : (
        <>
          <Fade in appear timeout={400}>
            <form>
              <div className={classes.header}>
                <Typography variant="h5">Generale</Typography>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(saveSettings)}
                >
                  SALVA
                </Button>
              </div>
              <div className={classes.paragraph}>
                <Typography variant="h5">Groove</Typography>
              </div>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={3} xl={3}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        label="Nome azienda"
                        shrink={!!field.value}
                        {...field}
                      />
                    )}
                    name="tenantName"
                    control={control}
                  />
                </Grid>
                <Grid item xs={3} xl={3}>
                  <Controller
                    render={({ field }) => (
                      <TextField label="Do" shrink={!!field.value} {...field} />
                    )}
                    name="doGrooveTitle"
                    control={control}
                  />
                </Grid>
                <Grid item xs={3} xl={3}>
                  <Controller
                    render={({ field }) => (
                      <TextField label="Be" shrink={!!field.value} {...field} />
                    )}
                    name="beGrooveTitle"
                    control={control}
                  />
                </Grid>
              </Grid>
              <div className={classes.paragraph}>
                <Typography variant="h5">Licenze acquistate</Typography>
              </div>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={3} xl={3}>
                  <TextField
                    label="Maker"
                    shrink={true}
                    value={settings?.maxMobileUsers}
                    disabled
                  />
                </Grid>
                <Grid item xs={3} xl={3}>
                  <TextField
                    label="Backoffice"
                    shrink={true}
                    value={settings?.maxWebUsers}
                    disabled
                  />
                </Grid>
              </Grid>
              <div className={classes.paragraph}>
                <Typography variant="h5">Modalità di Pianificazione</Typography>
              </div>
              <Grid container spacing={2}>
                <Grid item>
                  <RadioControl
                    control={control}
                    name="planningMode"
                    defaultValue={null}
                    options={planningModeOptions}
                    disabled={!!settings?.planningMode}
                  />
                </Grid>
              </Grid>
              <div className={classes.paragraph}>
                <Typography variant="h5">Attivazione parametri</Typography>
              </div>
              <Grid container spacing={2} style={{ padding: 8 }}>
                <Grid item>
                  <FormControlLabel
                    className={classes.checkboxLabel}
                    control={
                      <CheckBoxControl
                        control={control}
                        name={`isQualityActive`}
                        defaultValue={true}
                        pxSize={20}
                        disabled={!!settings?.planningMode}
                      />
                    }
                    label="Quality"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    className={classes.checkboxLabel}
                    control={
                      <CheckBoxControl
                        control={control}
                        name={`isBeActive`}
                        defaultValue={true}
                        disabled={!!settings?.planningMode}
                        pxSize={20}
                      />
                    }
                    label="Be"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    className={classes.checkboxLabel}
                    control={
                      <CheckBoxControl
                        control={control}
                        name={`isAIActive`}
                        defaultValue={false}
                        disabled={!!settings?.planningMode}
                        pxSize={20}
                      />
                    }
                    label="AI"
                  />
                </Grid>
              </Grid>
              <div className={classes.paragraph}>
                <Typography variant="h5">Project</Typography>
              </div>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={3} xl={3}>
                  <Controller
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Margine target"
                        icon="%"
                        type="number"
                        error={fieldState.error}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                        inputProps={{ min: 0, max: 100 }}
                        {...field}
                      />
                    )}
                    name="tenantTargetMargin"
                    control={control}
                    rules={{
                      min: {
                        value: 0,
                        message: "Inserire un valore tra 0 e 100",
                      },
                      max: {
                        value: 100,
                        message: "Inserire un valore tra 0 e 100",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </Fade>
          {/* Export form */}
          {settings && (
            <Fade in={!!settings} appear timeout={400}>
              <form noValidate onSubmit={exportHandleSubmit(onExportSubmit)}>
                <div className={classes.paragraph}>
                  <Typography variant="h5">Report</Typography>
                </div>
                <Typography variant="body2" className={classes.subParagraph}>
                  Scarica report
                </Typography>
                <Grid container spacing={2}>
                  <RadioControl
                    control={exportControl}
                    name="type"
                    options={exportOptions}
                  />
                  <Grid item xs={3} xl={3}>
                    <Controller
                      render={({ field }) => (
                        <DatePicker
                          themeType={THEME_TYPE}
                          label="Data di inizio"
                          error={!!exportErrors.startDate}
                          required
                          maxDate={exportWatch("endDate") || undefined}
                          {...field}
                          helperText={exportErrors.startDate?.message}
                        />
                      )}
                      defaultValue={null}
                      name="startDate"
                      control={exportControl}
                      rules={{
                        required: "Campo obbligatorio",
                        validate: startDate =>
                          startDate?.isSameOrBefore(
                            exportWatch("endDate"),
                            "day"
                          ) || "Range di date non valido",
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} xl={3}>
                    <Controller
                      render={({ field }) => (
                        <DatePicker
                          themeType={THEME_TYPE}
                          label="Data di fine"
                          error={!!exportErrors.endDate}
                          required
                          minDate={exportWatch("startDate") || undefined}
                          {...field}
                          helperText={exportErrors.endDate?.message}
                        />
                      )}
                      defaultValue={null}
                      name="endDate"
                      control={exportControl}
                      rules={{
                        required: "Campo obbligatorio",
                        validate: endDate =>
                          exportWatch("startDate")?.isSameOrBefore(
                            endDate,
                            "day"
                          ) || "Range di date non valido",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.reportRow} spacing={2}>
                  {exportWatch("type") !== "user" ? (
                    <>
                      <Grid item xs={3}>
                        <Controller
                          render={({ field }) => (
                            <Select
                              label={groupLabel}
                              data={availableGroups}
                              themeType={THEME_TYPE}
                              error={!!exportErrors.brickTypology}
                              {...field}
                              showEmpty
                              showEmptyText="Tutto"
                            />
                          )}
                          defaultValue=""
                          name="group"
                          control={exportControl}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={6}>
                      <Controller
                        render={({ field }) => (
                          <Autocomplete
                            label="Utenti"
                            endpoint={`${USERS_LIST}?me=true&q`}
                            error={!!exportErrors.users}
                            getOptionLabel={option =>
                              option?.name && option?.surname
                                ? `${option?.name} ${option?.surname}`
                                : ""
                            }
                            multiple
                            required
                            {...field}
                            onChange={(_, data) => field.onChange(data)}
                          />
                        )}
                        name="users"
                        control={exportControl}
                        rules={{ required: true }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={1}>
                    <Button
                      type="submit"
                      className={classes.excelButton}
                      variant="outlined"
                      color="primary"
                      style={{ height: "100%" }}
                      icon={<ExcelIcon />}
                    />
                  </Grid>
                </Grid>
              </form>
            </Fade>
          )}
        </>
      )}
    </Layout>
  );
};

export default SettingsGeneral;
