import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";
import { Slider } from "@material-ui/core";
import usePlayer from "hooks/usePlayer";

// Icons
import playIcon from "assets/play.svg";
import pauseIcon from "assets/pause.svg";
import audioSpectrum from "assets/audio-spectrum.svg";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
  },
  audioPlayer: {
    display: "none",
  },
  playIcon: {
    width: 30,
    height: 30,
    cursor: "pointer",
  },
  sliderWrapper: {
    flexGrow: 1,
    position: "relative",
    marginLeft: 16,
    "& > div > span > span:nth-last-child(2)": {
      left: "93% !important",
    },
  },
  spectrum: {
    width: "100%",
  },
  mark: {
    fontSize: "0.78rem",
  },
  marked: {
    marginBottom: 0,
  },
}));

const toMMSS = amount => {
  var sec_num = parseInt(amount, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return minutes + ":" + seconds;
};

const AudioPlayer = ({ content }) => {
  const [media] = useState(content);
  const mediaPlayer = usePlayer([media]);
  const classes = useStyles({ isPlaying: mediaPlayer.playing });
  return (
    <div className={classes.container}>
      <img
        src={mediaPlayer.playing ? pauseIcon : playIcon}
        className={classes.playIcon}
        onClick={() =>
          mediaPlayer.playing ? mediaPlayer.onPause() : mediaPlayer.onPlay()
        }
      />
      <div className={classes.sliderWrapper}>
        <img src={audioSpectrum} className={classes.spectrum} />
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <ReactPlayer
            className={classes.audioPlayer}
            ref={mediaPlayer.player}
            url={content}
            playing={mediaPlayer.playing}
            onStart={mediaPlayer.onStart}
            onPause={mediaPlayer.onPause}
            onProgress={mediaPlayer.onProgress}
            onDuration={mediaPlayer.onDuration}
            onEnded={mediaPlayer.onEnded}
          />
          <Slider
            classes={{ markLabel: classes.mark }}
            value={
              typeof mediaPlayer.progressSeconds === "number" &&
              typeof mediaPlayer.durationSeconds === "number"
                ? (mediaPlayer.progressSeconds / mediaPlayer.durationSeconds) *
                  100
                : 0
            }
            onChange={(event, value) => {
              mediaPlayer.onSeek(event, value);
            }}
            aria-labelledby="continuous-slider"
            marks={[
              { value: 0.1, label: "0:00" },
              {
                value: 100,
                label: toMMSS(mediaPlayer.durationSeconds),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

AudioPlayer.propTypes = {
  content: PropTypes.string,
};

export default AudioPlayer;
