import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Components
import SkyviewAction from "./SkyviewAction";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
// Assets
import ExpandIcon from "assets/expand.png";
import ZoomInIcon from "assets/zoom-in-icon.svg";
import ZoomOutIcon from "assets/zoom-out-icon.svg";
import ResetZoomIcon from "assets/reset-zoom-icon.svg";
// Shapes
import { PointShape, ScaleShape } from "pages/Skyview/shapes";
// Config
import { ZOOM_SCALE, SCALE_DEFAULTS } from "../../config";

// Styles
const useStyles = makeStyles(() => ({
  wrapper: {
    width: 30,
    position: "absolute",
    zIndex: 100,
    left: 0,
    top: 0,
  },
}));

const STAGE_RESET_KEY = "reset";

const SkyviewActions = ({ canvasCenter, onStageChange, stage, stageData }) => {
  // Hooks
  const classes = useStyles();

  // Effects
  useEffect(() => {
    if (stageData === STAGE_RESET_KEY) onStageChange(SCALE_DEFAULTS);
  }, [stageData]);

  const handleReset = () => onStageChange(STAGE_RESET_KEY);

  const handleScaleChange = scaleDirection => {
    const oldScale = stageData.scale;
    const newScale =
      scaleDirection === scaleDirectionTypes.IN
        ? oldScale * ZOOM_SCALE
        : oldScale / ZOOM_SCALE;

    const mousePointTo = {
      x: canvasCenter.x / oldScale - stage.x() / oldScale,
      y: canvasCenter.y / oldScale - stage.y() / oldScale,
    };

    onStageChange({
      scale: newScale,
      x: (canvasCenter.x / newScale - mousePointTo.x) * newScale,
      y: (canvasCenter.y / newScale - mousePointTo.y) * newScale,
    });
  };

  return (
    <div className={classes.wrapper}>
      <SkyviewAction icon={ExpandIcon} isActive />
      <SkyviewAction
        icon={ZoomInIcon}
        onClick={() => handleScaleChange(scaleDirectionTypes.IN)}
        tooltipText="Zoom +"
      />
      <SkyviewAction
        icon={ResetZoomIcon}
        onClick={handleReset}
        tooltipText="Reset Zoom"
      />
      <SkyviewAction
        icon={ZoomOutIcon}
        onClick={() => handleScaleChange(scaleDirectionTypes.OUT)}
        tooltipText="Zoom -"
      />
    </div>
  );
};

const scaleDirectionTypes = {
  IN: 0,
  OUT: 1,
};

SkyviewActions.defaultProps = {};

SkyviewActions.propTypes = {
  canvasCenter: PropTypes.shape(PointShape),
  onStageChange: PropTypes.func,
  stage: PropTypes.object, // Instance of Konva Stage Class: https://konvajs.org/api/Konva.Stage.html
  stageData: PropTypes.oneOfType([
    PropTypes.shape(ScaleShape),
    PropTypes.string,
  ]),
};

export default SkyviewActions;
