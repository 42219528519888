import React from "react";
import PropTypes from "prop-types";

//Components
import NumberFormatCustom from "components/NumberFormat";
import NumericWidget from "../NumericWidget";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { OXFORD_BLUE, WHITE } from "constants/colors";
import ProgressBar from "components/ProgressBar";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "100%",
    display: "flex",
    float: "right",
    flexDirection: "row",
    backgroundColor: OXFORD_BLUE,
  },
  column: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  number: {
    width: "100%",
    textAlign: "right",
    color: WHITE,
    fontSize: 32,
    fontWeight: "bold",
  },
  widget: {
    marginTop: 0,
  },
}));

const Completion = ({ value }) => {
  const classes = useStyles({});

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.row}>
            <NumericWidget
              number={
                <div className={classes.number}>
                  <NumberFormatCustom value={value} decimalScale={0} />
                </div>
              }
              suffix={"%"}
            />
            <ProgressBar value={value} />
          </div>
        </div>
      </div>
    </div>
  );
};

Completion.propTypes = {
  value: PropTypes.number,
};

export default Completion;
