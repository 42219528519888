import React, { useEffect, useState, useContext } from "react";

// Hooks
import useFetch from "hooks/useHTTP";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { departmentsConfig } from "components/Table/config";

// Icons
import { ReactComponent as EditIcon } from "assets/edit.svg";
import plusIcon from "assets/plus-white.png";
import { ReactComponent as DeleteIcon } from "assets/delete-outlined.svg";

// Components
import Button from "components/Button";
import Table from "components/Table";
import Layout from "components/Layout";
import Loading from "components/Loading";
import Navigation from "../components/Navigation";

// Constants
import { DEPARTMENTS, SETTINGS_DEPARTMENTS } from "constants/api";

// Context
import { ManagingDrawersContext } from "context/ManagingDrawersContext";
import { AppContext } from "context/AppContext";

import { TextFieldControl } from "components/TextField";
import { ReactComponent as SearchIcon } from "assets/search-image.svg";
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
import { useForm } from "react-hook-form";
import { Grid, Stack } from "@mui/material";

// Styles
const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  buttonIcon: {
    width: 16,
    height: 16,
  },
  actionButton: {
    minWidth: "unset",
    width: 44,
    flex: "0 0 auto",
    padding: 10,
    "& svg": {
      height: 18,
    },
  },
}));

const SettingsDepartments = () => {
  // Context
  const {
    setDepartmentDrawerOpen,
    setErrorMessage,
    setLoadingDrawer,
    setSelectedItem,
    retrigger,
    setRetrigger,
  } = useContext(ManagingDrawersContext);
  const { setModalConfig, setGlobalLoading } = useContext(AppContext);

  // Hooks
  const classes = useStyles({});
  const { get, del } = useFetch();
  const { control, getValues, reset, handleSubmit } = useForm({
    defaultValues: { searchText: "" },
  });


  // State
  const [departments, setDepartments] = useState([]);
  const [loadingDepartments, setLoadingDepartments] = useState(false);

  // Effects
  useEffect(() => {
    fetchDepartments();
  }, []);

  useEffect(() => {
    if (retrigger) {
      fetchDepartments();
      setRetrigger();
    }
  }, [retrigger]);

  // Helpers
  const handleDepartmentDelete = department => {
    setModalConfig({
      title: "Eliminazione Dipartimento",
      content: "Sei sicuro di voler eliminare il Dipartimento?",
      primaryAction: {
        text: "OK",
        onClick: () => deleteDepartment(department),
      },
      secondaryAction: {
        text: "ANNULLA",
      },
    });
  };

  // API
  const fetchDepartments = async () => {
    setLoadingDepartments(true);
    try {
      const searchText = getValues("searchText");
      const qs = searchText ? `?q=${searchText}` : "";
      const res = await get(SETTINGS_DEPARTMENTS + qs);
      setLoadingDepartments(false);
      setDepartments(res.data);
    } catch (err) {
      setLoadingDepartments(false);
      setErrorMessage("Elaborazione non riuscita");
    }
  };

  const fetchDepartment = async id => {
    setLoadingDrawer(true);
    await get(`${DEPARTMENTS}/${id}`)
      .then(res => {
        if (res.ok) {
          setSelectedItem(res?.data);
          setLoadingDrawer(false);
        }
      })
      .catch(() => {
        setLoadingDrawer(false);
      });
  };

  const deleteDepartment = async department => {
    setGlobalLoading(true);
    setModalConfig(null);
    try {
      await del(`${DEPARTMENTS}/${department.id}`);
      setRetrigger("department");
      setGlobalLoading(false);
    } catch (err) {
      setGlobalLoading(false);
      console.error(err);

      if (err?.response?.status === 403) {
        setModalConfig({
          title: "Impossibile eliminare il Dipartimento",
          content: `Il dipartimento "${department.name}" contiene degli elementi al suo interno`,
          primaryAction: {
            text: "OK",
          },
        });
      }
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    return handleSubmit(() => fetchDepartments())();
  };

  const clearForm = () => {
    reset();
    fetchDepartments();
  };

  return (
    <Layout showSecondCol={false} showHeader={false} showPosts={false}>
      <Navigation />
      <div className={classes.header}>
        <Typography variant="h5">Dipartimenti</Typography>
        <Button
          color="primary"
          variant="contained"
          startIcon={<img src={plusIcon} className={classes.buttonIcon} />}
          onClick={() => setDepartmentDrawerOpen(true)}
        >
          Nuovo dipartimento
        </Button>
      </div>

      <Grid container marginBottom={2}>
        <Grid item xs={12} sm={3}>
          <form onSubmit={handleFilter}>
            <Stack direction="row" gap={1}>
              <TextFieldControl
                name="searchText"
                control={control}
                label="Filtra per dipartimento"
                placeholder="Digita il nome del dipartimento che desideri ricercare"
              />
              <Stack direction="row" gap={1}>
                <Button
                  className={classes.actionButton}
                  variant="outlined"
                  color="secondary"
                  onClick={clearForm}
                >
                  <CancelIcon />
                </Button>
                <Button
                  type="submit"
                  className={classes.actionButton}
                  variant="outlined"
                  color="primary"
                >
                  <SearchIcon />
                </Button>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>

      {loadingDepartments ? (
        <div className={classes.loading}>
          <Loading showWrapper={false} />
        </div>
      ) : (
        <Table
          data={departments}
          config={departmentsConfig}
          rowActions={[
            {
              onClick: item => {
                fetchDepartment(item?.id);
                setDepartmentDrawerOpen(true);
              },
              icon: <EditIcon />,
            },
            {
              onClick: handleDepartmentDelete,
              icon: <DeleteIcon />,
            },
          ]}
        />
      )}
    </Layout>
  );
};

export default SettingsDepartments;
