import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Slider from "react-slick";

import { makeStyles } from "@material-ui/core/styles";

// css imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick.css";

// icons
import nextIcon from "assets/next.svg";
import prevIcon from "assets/previous.svg";
import nextIconHover from "assets/next-hover.svg";
import prevIconHover from "assets/previous-hover.svg";

// components
import Card from "./Card";
import Department from "components/Department";

// Enums
import { carouselTypes } from "utils/enums/carousels";
import { metricTypes } from "utils/enums/grooveMetrics";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
  },
  icon: {
    width: "35px",
    height: "35px",
  },
}));

const Arrow = props => {
  const classes = useStyles();
  const { className, style, onClick, type } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img
        onMouseOver={e =>
          (e.currentTarget.src =
            type === "next" ? nextIconHover : prevIconHover)
        }
        onMouseLeave={e =>
          (e.currentTarget.src = type === "next" ? nextIcon : prevIcon)
        }
        className={classes.icon}
        src={type === "next" ? nextIcon : prevIcon}
      />
    </div>
  );
};

Arrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

const Carousel = ({
  isLeaf,
  items,
  metric,
  onEdit,
  slidesToScroll,
  type,
  deletePortfolio,
  deleteProject,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const settings = {
    initialSlide: 0,
    dots: false,
    swipe: false,
    draggable: false,
    infinite: false,
    speed: 1500,
    variableWidth: true,
    slidesToScroll: slidesToScroll, // l'ultimo rimane sempre by default
    nextArrow: <Arrow type={"next"} />,
    prevArrow: <Arrow />,
  };

  const handleDepartmentClick = department => {
    const { id } = department;
    history.push(`/departments/${id}`);
  };

  return (
    <div className={classes.container}>
      <Slider {...settings}>
        {items?.map(item => {
          switch (type) {
            case carouselTypes.PORTFOLIO:
              return (
                <Card
                  key={item.id}
                  portfolio={item}
                  metric={metric}
                  isLeaf={isLeaf}
                  onEdit={() => onEdit(item)}
                  itemId={item?.id}
                  deletePortfolio={deletePortfolio}
                  deleteProject={deleteProject}
                  canBeRemoved={item?.deletable}
                />
              );
            case carouselTypes.DEPARTMENT:
              return (
                <Department
                  key={item.id}
                  membersCount={item.peopleCount}
                  metric={metric}
                  name={item.name || item.hashtag}
                  onClick={e => {
                    e.stopPropagation();
                    handleDepartmentClick(item);
                  }}
                  owner={item.owner}
                  onEdit={() => onEdit(item)}
                  image={item?.imageUrl}
                  blur={item?.blurredImage}
                  sentiment={item?.sentiment}
                  {...item}
                />
              );
          }
        })}
      </Slider>
    </div>
  );
};

Carousel.propTypes = {
  items: PropTypes.array,
  metric: PropTypes.oneOf(Object.values(metricTypes)),
  type: PropTypes.oneOf(Object.values(carouselTypes)).isRequired,
  isLeaf: PropTypes.bool,
  onEdit: PropTypes.func,
  slidesToScroll: PropTypes.number,
  deletePortfolio: PropTypes.func,
  deleteProject: PropTypes.func,
};

Carousel.defaultProps = {
  items: [],
  slidesToScroll: 3,
};

export default Carousel;
