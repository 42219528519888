import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ReactComponent as MailIcon } from "assets/email.svg";

import Logo from "components/Logo";
import Layout from "components/Layout";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    flexDirection: "column",
  },
  margin: {
    margin: theme.spacing(1),
    width: "100%",
    "&:focus": {
      outline: "none",
    },
  },
  logoWrapper: {
    maxWidth: "20%",
  },
  title: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(2.5),
  },
  icon: {
    width: "2.5rem",
    marginTop: theme.spacing(11),
    marginBottom: theme.spacing(3),
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();

  return (
    <Layout showHeader={false} showFirstCol={false} showSecondCol={false}>
      <div className={classes.wrapper}>
        <div className={classes.logoWrapper}>
          <Logo isBlue />
        </div>
        <Typography variant="h5" className={classes.title}>
          Grazie
        </Typography>
        <Typography variant="subtitle1">
          Abbiamo inviato al tuo indirizzo di posta le infomazioni per procedere
          con il reset della password
        </Typography>
        <MailIcon className={classes.icon} alt="mail_icon" />
        <Typography variant="subtitle1">
          Se non ricevi la email, controlla anche all&apos; interno della posta
          indesiderata
        </Typography>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
