import React from "react";
import PropTypes from "prop-types";

//Components
import NumberFormatCustom from "components/NumberFormat";
import NumericWidget from "../NumericWidget";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { OXFORD_BLUE, WHITE } from "constants/colors";

//Utils
import { truncateDecimals } from "utils/index";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "100%",
    display: "flex",
    float: "right",
    flexDirection: "row",
    backgroundColor: OXFORD_BLUE,
  },
  column: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  number: () => ({
    width: "100%",
    textAlign: "right",
    color: WHITE,
    fontSize: 32,
    marginTop: 10,
    fontWeight: "bold",
  }),
}));

const SkillMatching = ({ value }) => {
  const classes = useStyles();
  const customValue = truncateDecimals(value, 0) || 0;
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.row}>
            <NumericWidget
              number={
                <div className={classes.number}>
                  <NumberFormatCustom value={customValue} decimalScale={0} />
                </div>
              }
              suffix={"%"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SkillMatching.propTypes = {
  value: PropTypes.number,
};

export default SkillMatching;
