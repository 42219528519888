import React from "react";
import { BurndownWorkload } from "./BurndownWorkload";
import { EffortWorkload } from "./EffortWorkload";
import PropTypes from "prop-types";
import { workloadContext } from "utils/enums/workLoad";

const Workload = props => {
  const { context = workloadContext.PROJECT } = props;
  const isProject = context === workloadContext.PROJECT;

  return (
    <>
      <EffortWorkload {...props} />
      {isProject && <BurndownWorkload {...props} />}
    </>
  );
};

Workload.propTypes = {
  context: PropTypes.oneOf(Object.values(workloadContext)),
};

Workload.defaultProps = {
  context: workloadContext.PROJECT,
};

export default Workload;
