import React, { useState } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import { Popper as MUIPopper, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(theme => ({
  popper: ({ darkMode }) => ({
    cursor: "pointer",
    zIndex: "100",
    backgroundColor: darkMode
      ? theme.palette.componentsBackground.main
      : theme.palette.common.white,
    borderRadius: 5,
    minWidth: 150,
    padding: theme.spacing(2),
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "0 100%",
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "100% 0",
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "100% 100%",
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "0 0",
      },
    },
  }),
  arrow: ({ darkMode }) => ({
    overflow: "hidden",
    position: "absolute",
    width: "1em",
    height: "0.71em",
    boxSizing: "border-box",
    color: darkMode
      ? theme.palette.componentsBackground.main
      : theme.palette.common.white,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      boxShadow: theme.shadows[1],
      backgroundColor: "currentColor",
      transform: "rotate(45deg)",
    },
  }),
  item: ({ darkMode }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    color: darkMode ? WILD_BLUE_YONDER : theme.palette.common.black,
    filter: "grayscale(1)",
    cursor: "pointer",
    padding: `${theme.spacing(1)}px`,
    borderRadius: 0,
    "&:hover": {
      borderRadius: 20,
      fontWeight: "bold",
      color: darkMode ? theme.palette.common.white : theme.palette.primary.main,
      filter: "none",
    },
    fontSize: "13px",
  }),

  icon: {
    width: "10px",
    height: "auto",
    marginLeft: "10px",
  },

  divider: ({ darkMode }) => ({
    background: darkMode ? WILD_BLUE_YONDER : "default",
  }),
}));

const Popper = ({
  modifiers,
  placement,
  children,
  darkMode,
  divider,
  title,
  ...other
}) => {
  const classes = useStyles({ darkMode });
  const [arrowRef, setArrowRef] = useState(null);
  return (
    <MUIPopper
      className={classes.popper}
      modifiers={
        modifiers || {
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }
      }
      placement={placement}
      {...other}
      transition
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        {title && (
          <Typography
            variant="h6"
            align="center"
            style={{ fontWeight: "bold" }}
          >
            {title}
          </Typography>
        )}
        {children?.map((child, index) => {
          return (
            <div key={nanoid()}>
              <div className={classes.item} onClick={child.onClick}>
                {child.text}
                {child.img && <img className={classes.icon} src={child.img} />}
              </div>
              {divider && index !== children.length - 1 && (
                <Divider classes={{ root: classes.divider }} />
              )}
            </div>
          );
        })}
      </div>
      <span className={classes.arrow} ref={setArrowRef} />
    </MUIPopper>
  );
};
Popper.defaultProps = {
  divider: true,
};

Popper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.array,
  className: PropTypes.object,
  placement: PropTypes.string,
  modifiers: PropTypes.object,
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  arrow: PropTypes.bool,
  darkMode: PropTypes.bool,
  divider: PropTypes.bool,
};

export default Popper;
