import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { SPACE_CADET } from "constants/colors";
import Typography from "@material-ui/core/Typography";

import SvgIcon from "components/SvgIcon";

// Styles
const useStyles = makeStyles(() => ({
  head: {
    display: "flex",
    flexDirection: "column",
  },
  image: {
    width: 32,
    height: 25,
  },
  number: {
    fontSize: 25,
    textAlign: "right",
    fontWeight: "bold",
  },
  title: {
    fontSize: 20,
  },
  wrapper: {
    width: "308.5px",
    height: "155px",
    backgroundColor: SPACE_CADET,
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "10px",
    marginRight: "9px",
    cursor: "pointer",
  },
}));

const Card = ({ className, icon, number, onClick, title }) => {
  const classes = useStyles({});
  return (
    <div className={clsx(classes.wrapper, className)} onClick={onClick}>
      <div className={classes.head}>
        <SvgIcon icon={icon} strokeWidth={0.5} width={32} height={25} />
        {title && <Typography className={classes.title}>{title}</Typography>}
      </div>
      {number && <Typography className={classes.number}>{number}</Typography>}
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  number: PropTypes.number,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default Card;
