import { InputBase, Typography, styled } from "@mui/material";
import { firstColConfig, secondColConfig } from "components/Layout/config";
import { OXFORD_BLUE } from "constants/colors";

export const Wrapper = styled("div")(({ isFirstColOpen }) => {
  const leftMargin =
    firstColConfig.width[isFirstColOpen ? "expanded" : "collapsed"] +
    secondColConfig.width.collapsed;

  return {
    position: "fixed",
    backgroundColor: OXFORD_BLUE,
    width: `calc(100% - ${leftMargin}px)`,
    height: "100%",
    bottom: 0,
    right: 0,
    left: leftMargin,
    zIndex: 10,

    ":before": {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundImage: `url("/assets/images/looper.png")`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom right",
      backgroundSize: "125% 110%",
      content: '""',
    },
  };
});

export const Content = styled("div")({
  margin: "auto",
  marginTop: 210,
  width: "calc(100% - 170px)",
  position: "relative",
});

export const GradientTypography = styled(Typography)({
  background: "-webkit-linear-gradient(320deg, #5050FF 10%, #A2DCFF 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  overflow: "visible",
  lineHeight: "0.8",
});

export const StyledInputBase = styled(InputBase)({
  backgroundColor: "white",
  borderRadius: 50,
  color: "#212B36",
  padding: 20,
  paddingTop: 24,
  fontSize: 22,
  fontWeight: 300,
});
