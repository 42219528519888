import { useCallback } from "react";

const useUpload = ({ formContext, name, multiple }) => {
  const { setValue, getValues } = formContext;

  const formatFile = useCallback(
    file =>
      file instanceof File
        ? Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        : file,
    []
  );

  const onDrop = useCallback(
    acceptedFiles => {
      const prevValue = getValues(name);
      const nextValue = [
        ...(multiple ? prevValue || [] : []),
        ...acceptedFiles.map(formatFile),
      ];

      setValue(name, nextValue);
    },
    [formatFile, getValues, multiple, name, setValue]
  );

  const onRemove = useCallback(
    file => {
      const prevValue = getValues(name);
      const isFileMetadata =
        !(file instanceof File) && typeof file !== "string";
      const nextValue = isFileMetadata // IFileDto
        ? prevValue?.filter(
            _file => !("key" in _file) || _file.key !== file.key
          )
        : prevValue?.filter(_file => _file !== file) || []; // File | CustomFile | string

      setValue(name, nextValue);
    },
    [getValues, name, setValue]
  );

  const onRemoveAll = useCallback(() => setValue(name, []), [name, setValue]);

  return { onDrop, onRemove, onRemoveAll };
};

export { useUpload };
