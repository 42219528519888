import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox as MuiCheckbox } from "@material-ui/core";

// Images
import WhiteCheckedIcon from "assets/check.svg";
import { WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(theme => ({
  uncheckedIcon: ({ customColorUnchecked, pxSize }) => ({
    borderRadius: 3,
    width: pxSize || 24,
    height: pxSize || 24,
    backgroundColor:
      customColorUnchecked || theme.palette.componentsBackground.main,
  }),
  checkedIcon: ({ customColorChecked, disabled, pxSize }) => ({
    borderRadius: 3,
    backgroundColor: disabled
      ? WILD_BLUE_YONDER
      : customColorChecked || theme.palette.primary.main,
    "&:before": {
      display: "block",
      width: pxSize || 24,
      height: pxSize || 24,
      backgroundImage: `url(${WhiteCheckedIcon})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "60%",
      content: '""',
    },
  }),
}));

const CheckBox = forwardRef(
  (
    {
      checked,
      disabled,
      label,
      customColorChecked,
      customColorUnchecked,
      pxSize,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles({
      customColorChecked,
      customColorUnchecked,
      pxSize,
      disabled
    });
    return (
      <MuiCheckbox
        checked={checked}
        disabled={disabled}
        name={label}
        disableRipple
        color="primary"
        checkedIcon={<span className={classes.checkedIcon} />}
        icon={<span className={classes.uncheckedIcon} />}
        {...rest}
        inputRef={ref}
      />
    );
  }
);

CheckBox.displayName = "CheckBox";

/**
 * @example <CheckBoxControl
 *            control={control}
              name={'isFormatted'}
              defaultValue={false}
              disabled={checkIsDisabled()}
              label={'Format TimeZone'}
            />
 * @param  control - [react-hook-form control Object](https://react-hook-form.com/api/useform/control)
 * @param  name - Checkbox name
 * @param  defaultValue - Checkbox default value
 * @param  disabled - Condition hanling checkbox disabled prop
 * @param  label - Checkbox label
 * @param  customColorChecked - Color desired for checked Checkbox
 * @param  customColorUnchecked - Color desired for unchecked Checkbox
 */
const CheckBoxControl = ({
  control,
  name,
  defaultValue,
  disabled,
  label,
  customColorChecked,
  customColorUnchecked,
  pxSize,
}) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || false}
        render={({ field }) => (
          <CheckBox
            label={label}
            onChange={e => field.onChange(e.target.checked)}
            checked={field.value}
            disabled={disabled}
            customColorChecked={customColorChecked}
            customColorUnchecked={customColorUnchecked}
            pxSize={pxSize}
            {...field}
          />
        )}
      />
    </>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  customColorChecked: PropTypes.string,
  customColorUnchecked: PropTypes.string,
  pxSize: PropTypes.number,
};

CheckBoxControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  customColorChecked: PropTypes.string,
  customColorUnchecked: PropTypes.string,
  pxSize: PropTypes.number,
};

export { CheckBox, CheckBoxControl };
