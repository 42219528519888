export const doKpiTypes = {
  COST: 1,
  TIME: 2,
  QUALITY: 3,
};

export const beKpiTypes = {
  SENTIMENT: 1,
  REPUTATION: 2,
  SKILLS: 3,
};
