import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import theme from "themeV4";

// Material UI
import { Divider, Typography, Grid } from "@material-ui/core";

// Constants
import { WILD_BLUE_YONDER } from "constants/colors";

// Components
import NumberFormatCustom from "components/NumberFormat";
import SvgIcon from "components/SvgIcon";
import Date from "components/Date";

// Icons
import { ReactComponent as CalendarIcon } from "assets/calendar.svg";

const useStyles = makeStyles(() => ({
  gridContainer: {
    justifyContent: "space-between",
  },
  item: {
    display: "flex",
    flexDirection: "column",
  },
  itemHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  label: {
    color: WILD_BLUE_YONDER,
    marginRight: theme.spacing(1),
  },
}));

const ProjectItem = ({ inline, icon, label, value }) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.item}>
      <div className={classes.itemHeader}>
        {icon && (
          <SvgIcon
            className={classes.icon}
            icon={icon}
            color={WILD_BLUE_YONDER}
            width={15}
            height={16}
          />
        )}
        <Typography className={classes.label}>{label}</Typography>
        {inline && <Typography>{value}</Typography>}
      </div>
      {!inline && <Typography>{value}</Typography>}
    </Grid>
  );
};

ProjectItem.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  value: PropTypes.any,
  inline: PropTypes.bool,
};

const ProjectDetails = ({ details }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container classes={{ container: classes.gridContainer }}>
        <ProjectItem
          icon={<CalendarIcon />}
          label="Data inizio"
          value={<Date dateString={details?.plannedStartDate} />}
        />
        <ProjectItem
          icon={<CalendarIcon />}
          label="Data fine"
          value={<Date dateString={details?.plannedEndDate} />}
        />
        <ProjectItem
          label="Owner di progetto"
          value={`${details?.user?.name} ${details?.user?.surname}`}
        />
        <ProjectItem
          label="Supervisori"
          value={`${details?.supervisorsUsers[0]?.name || ""} ${
            details?.supervisorsUsers[0]?.surname || ""
          } ${
            details?.supervisorsUsers.length > 1
              ? `+ ${details?.supervisorsUsers.length - 1}`
              : ""
          }`}
        />
        <ProjectItem
          label="Referente interno"
          value={`${details?.internalReferenceUser?.name || ""} ${
            details?.internalReferenceUser?.surname || ""
          }`}
        />
        <ProjectItem label="Nome cliente" value={details?.customer?.name} />
        <ProjectItem
          label="Referente cliente"
          value={details?.customerReference?.name}
        />
      </Grid>
      <Divider
        style={{
          backgroundColor: WILD_BLUE_YONDER,
          width: "100%",
          margin: `${theme.spacing(2)}px 0px`,
        }}
      />
      <Grid container spacing={4}>
        <ProjectItem
          inline
          label="Pricing"
          value={<NumberFormatCustom value={details?.pricing} suffix="€" />}
        />
        <ProjectItem
          inline
          label="Margine atteso"
          value={
            <NumberFormatCustom
              value={details?.expectedMargin}
              suffix="%"
              fixedDecimalScale={false}
            />
          }
        />
        <ProjectItem
          inline
          label="Anno competenza"
          value={details?.competenceYear}
        />
        <ProjectItem
          inline
          label="Altri costi"
          value={
            <NumberFormatCustom
              value={
                details?.costs.length > 0
                  ? details?.costs.reduce(
                      (accumulator, item) => accumulator + item.value,
                      0
                    )
                  : 0
              }
              suffix="€"
            />
          }
        />
      </Grid>
    </>
  );
};

ProjectDetails.propTypes = {
  details: PropTypes.object,
};

export default ProjectDetails;
