import { genericBrickTypes } from "utils/enums/bricks";

export const genericBrickTypologies = [
  {
    name: "Trasferte cliente",
    value: genericBrickTypes.BUSINESS_TRIP,
  },
  {
    name: "Riunioni cliente",
    value: genericBrickTypes.CUSTOMER_MEETINGS,
  },
  {
    name: "Formazione",
    value: genericBrickTypes.TRAINING,
  },
  {
    name: "Fiere / Eventi",
    value: genericBrickTypes.FAIRS_EVENTS,
  },
  {
    name: "Ricerca",
    value: genericBrickTypes.RESEARCH,
  },
  {
    name: "Benchmark",
    value: genericBrickTypes.BENCHMARK,
  },
  {
    name: "Supporto altri uffici",
    value: genericBrickTypes.OTHER_OFFICES_SUPPORT,
  },
];
