import React from "react";
// Components
import Sentiment from "components/KpiBe/Sentiment";
// Constants
import { OXFORD_BLUE } from "constants/colors";
import {
  searchCardTypes,
  searchFilterContext,
  searchTimeIntervalValues,
  searchTimeValues,
} from "utils/enums/search";
import { SliderRangeControl } from "components/Slider";
import CheckList from "components/CheckList";
import { ReputationControl } from "components/KpiBe/Reputation";
import { RadioControl } from "components/Radio";
import dayjs from "dayjs";

export const defaultValues = {
  high: false,
  medium: false,
  low: false,
  planned: false,
  stillRolling: false,
  closed: false,
  quality: 0,
  pricing: [0, 500000],
  marginality: [0, 0],
  period: "all",
  interval: searchTimeIntervalValues.LAST_SIX_MONTHS,
  PLANNED: false,
  ACTIVE: false,
  CLOSED: false,
  TO_VALIDATE: false,
};

const checkboxSentiment = [
  {
    key: "high",
    icon: <Sentiment sentiment={0.5} width={25} height={25} />,
    defaultValue: false,
    customColorUnchecked: OXFORD_BLUE,
  },
  {
    key: "medium",
    icon: <Sentiment sentiment={0} width={25} height={25} />,
    defaultValue: false,
    customColorUnchecked: OXFORD_BLUE,
  },
  {
    key: "low",
    icon: <Sentiment sentiment={-0.5} width={25} height={25} />,
    defaultValue: false,
    customColorUnchecked: OXFORD_BLUE,
  },
];

const checkboxStatus = [
  {
    key: "planned",
    label: "Pianificato",
    defaultValue: false,
    customColorUnchecked: OXFORD_BLUE,
  },
  {
    key: "stillRolling",
    label: "In corso",
    defaultValue: false,
    customColorUnchecked: OXFORD_BLUE,
  },
  {
    key: "closed",
    label: "chiuso",
    defaultValue: false,
    customColorUnchecked: OXFORD_BLUE,
  },
];

const checkboxBrickStatus = [
  {
    key: "PLANNED",
    label: "Pianificato",
    defaultValue: false,
    customColorUnchecked: OXFORD_BLUE,
  },
  {
    key: "ACTIVE",
    label: "In corso",
    defaultValue: false,
    customColorUnchecked: OXFORD_BLUE,
  },
  {
    key: "CLOSED",
    label: "chiuso",
    defaultValue: false,
    customColorUnchecked: OXFORD_BLUE,
  },
  {
    key: "TO_VALIDATE",
    label: "Da validare",
    defaultValue: false,
    customColorUnchecked: OXFORD_BLUE,
  },
];

const checkboxRole = [
  {
    key: "departmentOwner",
    label: "Owner di dipartimento",
    defaultValue: false,
    customColorUnchecked: OXFORD_BLUE,
  },
  {
    key: "phaseOwner",
    label: "Owner di fase",
    defaultValue: false,
    customColorUnchecked: OXFORD_BLUE,
  },
  {
    key: "projectOwner",
    label: "Owner di progetto",
    defaultValue: false,
    customColorUnchecked: OXFORD_BLUE,
  },
];

const radioTime = [
  { label: "Nessun Filtro", value: searchTimeValues.NO_FILTERS },
  { label: "Anno Fiscale", value: searchTimeValues.FISCAL_YEAR },
  { label: "Intervallo", value: searchTimeValues.INTERVAL },
];

const radioInterval = [
  { label: "Ultimi 6 mesi", value: searchTimeIntervalValues.LAST_SIX_MONTHS },
  { label: "Ultimi 3 mesi", value: searchTimeIntervalValues.LAST_THREE_MONTHS },
  { label: "Da inizio anno", value: searchTimeIntervalValues.YEAR_TO_DATE },
  {
    label: "Da inizio anno a inizio mese",
    value: searchTimeIntervalValues.YEAR_TO_MONTH,
  },
  { label: "Da inizio mese", value: searchTimeIntervalValues.MONTH_TO_DATE },
];

const radioFiscalYears = () => {
  let years = [];
  for (let i = dayjs().year() - 10; i <= dayjs().year() + 10; i++) {
    years.push({
      key: `year-${i}`,
      label: `${i}`,
      defaultValue: false,
      customColorUnchecked: OXFORD_BLUE,
    });
    defaultValues[`year-${i}`] = false;
  }
  return years;
};

const qualityAndSentiment = [
  {
    id: "quality",
    title: "Qualità",
    horizontal: true,
    type: searchCardTypes.STAR,
    child: ReputationControl,
    childProps: {
      name: "quality",
      widthIcon: 20,
      starsHeight: 19,
      readOnly: false,
      horizontalDirection: true,
    },
    // getHorizontal: ({ isBeActive }) => isBeActive,
    getHideAtMount: ({ isQualityActive }) => !isQualityActive,
    getColumnOffset: ({ isBeActive, context }) =>
      !isBeActive && context === searchFilterContext.PROJECTS ? -1 : 0,
  },
  {
    title: "Sentiment",
    type: searchCardTypes.CHECKBOX,
    child: CheckList,
    childProps: {
      checkBoxArray: checkboxSentiment,
      horizontalDirection: true,
    },
    horizontal: true,
    // getHorizontal: ({ isQualityActive }) => isQualityActive,
    getColumnOffset: ({ isQualityActive, context }) =>
      !isQualityActive && context === searchFilterContext.PROJECTS ? -1 : 0,
    getHideAtMount: ({ isBeActive }) => !isBeActive,
  },
];

const pricingAndMarginality = [
  {
    title: "Pricing",
    id: "pricing",
    horizontal: true,
    type: searchCardTypes.RANGE,
    child: SliderRangeControl,
    childProps: {
      name: "pricing",
      suffix: "K",
      min: 0,
      step: 1000,
      customValueFormat: value => value / 1000,
    },
  },
  {
    title: "Marginalità",
    id: "marginality",
    horizontal: true,
    type: searchCardTypes.RANGE,
    child: SliderRangeControl,
    childProps: {
      name: "marginality",
      suffix: "%",
      step: 1,
    },
  },
];

const periodIntervalFiscalYear = [
  {
    title: "Tempo",
    horizontal: false,
    type: searchCardTypes.RADIO,
    child: RadioControl,
    childProps: {
      name: "period",
      defaultValue: searchTimeValues.NO_FILTERS,
      options: radioTime,
      formControlStyle: { margin: "auto 0" },
      customColorUnchecked: OXFORD_BLUE,
      horizontalDirection: false,
    },
  },
  {
    title: "Anno Fiscale",
    horizontal: false,
    type: searchCardTypes.CHECKBOX,
    child: CheckList,
    childProps: {
      checkBoxArray: radioFiscalYears(),
      horizontalDirection: false,
    },
    showValue: searchTimeValues.FISCAL_YEAR,
    hideAtMount: true,
  },
  {
    title: "Intervallo",
    horizontal: false,
    type: searchCardTypes.RADIO,
    child: RadioControl,
    showValue: searchTimeValues.INTERVAL,
    childProps: {
      name: "interval",
      defaultValue: searchTimeIntervalValues.SIX_MONTHS,
      options: radioInterval,
      formControlStyle: { margin: "auto 0" },
      customColorUnchecked: OXFORD_BLUE,
      horizontalDirection: false,
    },
    hideAtMount: true,
  },
];

export const projectsTabCards = [
  ...periodIntervalFiscalYear,
  {
    title: "Clienti",
    id: "customers",
    horizontal: false,
    type: searchCardTypes.CHECKBOX,
    child: CheckList,
    childProps: {
      horizontalDirection: false,
    },
  },
  {
    title: "Stato",
    horizontal: false,
    type: searchCardTypes.CHECKBOX,
    child: CheckList,
    childProps: {
      checkBoxArray: checkboxStatus,
      horizontalDirection: false,
    },
  },
  ...pricingAndMarginality,
  ...qualityAndSentiment,
];

export const brickTabCards = [
  ...periodIntervalFiscalYear,
  {
    title: "Skill",
    id: "skills",
    horizontal: false,
    type: searchCardTypes.CHECKBOX,
    child: CheckList,
    childProps: {
      horizontalDirection: false,
    },
  },
  {
    title: "Stato",
    horizontal: false,
    type: searchCardTypes.CHECKBOX,
    child: CheckList,
    childProps: {
      checkBoxArray: checkboxBrickStatus,
      horizontalDirection: false,
    },
  },
  ...qualityAndSentiment,
];

export const usersTabCards = [
  {
    id: "skills",
    title: "Skill",
    horizontal: false,
    type: searchCardTypes.CHECKBOX,
    child: CheckList,
    childProps: {
      horizontalDirection: false,
    },
  },
  {
    id: "jobTitles",
    title: "Job title",
    horizontal: false,
    type: searchCardTypes.CHECKBOX,
    child: CheckList,
    childProps: {
      horizontalDirection: false,
    },
  },
  {
    title: "Ruolo",
    horizontal: false,
    type: searchCardTypes.CHECKBOX,
    child: CheckList,
    childProps: {
      checkBoxArray: checkboxRole,
      horizontalDirection: false,
    },
  },
  ...qualityAndSentiment,
];
