import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const SidebarKPI = ({ number, legend, legendColor }) => {
  const useStyles = makeStyles(() => ({
    container: {
      width: "4rem",
      height: "4rem",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      userSelect: "none",
      "& strong": {
        fontSize: "1.35rem",
        color: `${legendColor}`,
      },
      "& span": {
        fontSize: "0.7rem",
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <strong> {number} </strong>
      <span>{legend}</span>
    </div>
  );
};

SidebarKPI.defaultProps = {
  legendColor: "#ffffff",
};

SidebarKPI.propTypes = {
  legendColor: PropTypes.string,
  number: PropTypes.string,
  legend: PropTypes.string,
};

export default SidebarKPI;
