import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { SettingsGuard } from "guards";

// HOC
import withManagingDrawers from "./components/withManagingDrawers";

// Pages
import Groove from "pages/Groove";
import Planner from "pages/Planner";
import Project from "pages/Project";
import Skyview from "pages/Skyview";
import Walkthrough from "pages/Walkthrough";
import Builder from "pages/Planner/Builder";
import BrickDashboard from "pages/BrickDashboard";
import UserDashboard from "pages/UserDashboard";
import Settings from "pages/Settings";
import Search from "pages/Search";
import SettingsUsers from "pages/Settings/pages/users";
import SettingsSkills from "pages/Settings/pages/skills";
import SettingsJobTitles from "pages/Settings/pages/jobTitles";
import SettingsPortfolios from "pages/Settings/pages/portfolios";
import SettingsDepartments from "pages/Settings/pages/departments";
import SettingsCustomers from "pages/Settings/pages/customers";
import SettingsGeneral from "pages/Settings/pages/general";
import NotFound from "pages/NotFound";
import GenericError from "pages/GenericError";

// Context
import { AuthContext } from "context/AuthContext";

import {
  LOGIN,
  PLANNER,
  PLANNER_DETAIL,
  PLANNER_BUILDER,
  PROJECT_DETAIL,
  PROJECT_SKYVIEW,
  WALKTHROUGH,
  BRICK_DETAIL,
  USER,
  ROOT,
  PORTFOLIOS,
  DEPARTMENTS,
  SETTINGS,
  SETTINGS_USERS,
  SETTINGS_SKILLS,
  SETTINGS_CUSTOMERS,
  SETTINGS_JOB_TITLE,
  GENERIC_ERROR,
  SETTINGS_DEPARTMENTS,
  SETTINGS_PORTFOLIOS,
  SETTINGS_GENERAL,
  SEARCH_CONTEXT,
  USER_PROJECT,
} from "constants/routes";
import { useBeNotification } from "hooks/useBeNotification";
import { AppContext } from "context/AppContext";

const BaseRoutes = () => (
  <>
    <Route path={USER} component={UserDashboard} />
    <Route path={BRICK_DETAIL} component={BrickDashboard} />
    <Route path={WALKTHROUGH} component={Walkthrough} />
    <Route path={GENERIC_ERROR} component={GenericError} />
    <Route component={NotFound} />
  </>
);

const Authenticated = () => {
  useBeNotification();
  const { settings } = useContext(AppContext);
  const { user } = useContext(AuthContext);

  const isBeActive = settings?.isBeActive;

  // if there is not logged user object saved on local or session storage, redirect to login
  if (!user) return <Redirect to={LOGIN} />;

  if (!user.canAccessWeb)
    return (
      <SettingsGuard>
        <Switch>
          <Route path={USER_PROJECT} component={Project} />
          <Route
            path={ROOT}
            exact
            render={() => <Redirect to={`user/${user.id}`} />}
          />
          <BaseRoutes />
        </Switch>
      </SettingsGuard>
    );

  return (
    <SettingsGuard>
      <Switch>
        <Route path={PLANNER_BUILDER} component={Builder} />
        <Route path={PLANNER_DETAIL} component={Planner} />
        <Route path={PLANNER} component={Planner} />
        {isBeActive && <Route path={PROJECT_SKYVIEW} component={Skyview} />}
        <Route path={PROJECT_DETAIL} component={Project} />
        <Route path={SETTINGS_GENERAL} component={SettingsGeneral} />
        <Route
          path={SETTINGS_USERS}
          render={() => withManagingDrawers(SettingsUsers)}
        />
        <Route
          path={SETTINGS_DEPARTMENTS}
          render={() => withManagingDrawers(SettingsDepartments)}
        />
        <Route
          path={SETTINGS_PORTFOLIOS}
          render={() => withManagingDrawers(SettingsPortfolios)}
        />
        <Route
          path={SETTINGS_CUSTOMERS}
          render={() => withManagingDrawers(SettingsCustomers)}
        />
        <Route path={SETTINGS_SKILLS} component={SettingsSkills} />
        <Route path={SETTINGS_JOB_TITLE} component={SettingsJobTitles} />
        <Route path={SETTINGS} component={Settings} />
        <Route path={SEARCH_CONTEXT} component={Search} />
        <Route
          path={[DEPARTMENTS, PORTFOLIOS, ROOT]}
          exact
          render={() => withManagingDrawers(Groove)}
        />

        <BaseRoutes />
      </Switch>
    </SettingsGuard>
  );
};

export default Authenticated;
