import React, { useRef, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";

import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  truncateContainer: {
    display: "-webkit-box",
    WebkitLineClamp: props => props.lines,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    width: props => props.width || "unset",
  },
  toggleClamp: {
    cursor: "pointer",
    fontWeight: 600,
    textAlign: "end",
  },
}));

const Truncate = ({
  children,
  lines = 3,
  moreLabel = "Vedi di più",
  lessLabel = "Vedi meno",
  width,
}) => {
  // Hooks
  const classes = useStyles({ lines, width });

  // Refs
  const containerRef = useRef();

  // State
  const [isTextClamped, setIsTextClamped] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // Effects
  useEffect(() => {
    const nextIsTextClamped =
      containerRef?.current?.scrollHeight > containerRef?.current?.clientHeight;
    setIsTextClamped(nextIsTextClamped);
  }, [containerRef]);

  // Functions
  const toggleClamp = () => {
    const nextIsExpanded = !isExpanded;
    setIsExpanded(nextIsExpanded);
  };

  return (
    <>
      <div
        className={isExpanded ? null : classes.truncateContainer}
        ref={containerRef}
      >
        {children}
      </div>
      {isTextClamped ? (
        <div className={classes.toggleClamp} onClick={toggleClamp}>
          {isExpanded ? lessLabel : moreLabel}
        </div>
      ) : null}
    </>
  );
};

Truncate.propTypes = {
  children: PropTypes.node,
  lines: PropTypes.number,
  moreLabel: PropTypes.string,
  lessLabel: PropTypes.string,
  width: PropTypes.number,
};

export { Truncate };
