import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropType from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Components
import NumberFormatCustom from "components/NumberFormat";
import Loading from "components/Loading";
import SolidGaugeCost from "components/Charts/SolidGauge/cost";
import Accordion from "components/Accordion";

//Hooks
import useFetch from "hooks/useHTTP";

// Constants
import { SPACE_CADET, WHITE, WILD_BLUE_YONDER } from "constants/colors";
import { PROJECT } from "constants/api";

// hooks
import { nanoid } from "nanoid";

//constants
import { COLORS2, COLORS4, COLORS5 } from "components/Charts/SolidGauge/themes";
import Truncate from "react-truncate";
import { useContext } from "react";
import { AppContext } from "context/AppContext";
import { AuthContext } from "context/AuthContext";

import { planningMode } from "utils/enums/globals";

// Styles
const useStyles = makeStyles(() => ({
  children: {
    borderTop: `0.5px solid rgba(255, 255, 255, .2)`,
    borderBottom: `0.5ppx solid rgba(255, 255, 255, .2)`,
    width: "100%",
    height: "55px",
    display: "flex",
    alignItems: "center",
    "&:first-of-type": {
      borderTop: `0`,
    },
    "&:last-of-type": {
      borderBottom: `1px solid rgba(255, 255, 255, .2)`,
    },
  },
  column: {
    marginBottom: 20,
  },
  containerGraph: {
    height: "280px",
    width: "234px",
  },

  containerMargin: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "280px",
    width: "234px",
  },

  graph: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  firstCol: {
    width: "234px",
  },

  rowNumber: {
    width: "234px",
    fontSize: 15,
    textAlign: "end",
    color: WHITE,
  },
  rowText: {
    fontSize: 12.5,
    width: "234px",
    color: WILD_BLUE_YONDER,
  },

  totRow: {
    display: "flex",
    marginLeft: 35,
  },
  title: {
    fontSize: "30px",
    fontWeight: "bold",
  },
  totNumber: {
    fontSize: 27,
    width: "234px",
    textAlign: "end",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: "25px",
    paddingLeft: "10px",
  },
  totText: {
    textTransform: "uppercase",
    fontSize: 15,
    width: "234px",
    textAlign: "end",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  loadingWrapper: {
    height: "100%",
  },
  boxMargin: {
    width: "180px",
    height: "180px",
    backgroundColor: SPACE_CADET,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  marginText: {
    fontSize: 35,
  },
  subMarginText: {
    textTransform: "uppercase",
    fontSize: 10,
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeft: "50px solid",
    borderLeftColor: SPACE_CADET,
    borderTop: "90px solid transparent",
    borderBottom: "90px solid transparent",
  },
  economicsInDays: ({ isAdmin }) => ({
    color: isAdmin ? WILD_BLUE_YONDER : "#FFF",
  }),
}));

const marginLabels = {
  PLANNED: "Margine pianificato",
  WORKED: "Margine previsto",
  REPORTED: "Margine consuntivo",
};

const erosionLabels = {
  PLANNED: "Budget pianificato",
  WORKED: "Budget previsto",
  REPORTED: "Budget consuntivo",
};

const erosionPercentages = {
  PLANNED: "plannedErosionPercentage",
  WORKED: "expectedErosionPercentage",
  REPORTED: "currentErosionPercentagea",
};

const marginPercentages = {
  PLANNED: "plannedMarginPercentage",
  WORKED: "expectedMarginPercentage",
  REPORTED: "currentMarginPercentage",
};

const Economics = ({ isErosion }) => {
  const { user, refreshUserData } = useContext(AuthContext);
  const isAdmin = user.isAdmin;

  const classes = useStyles({ isAdmin });

  const { projectId } = useParams();
  const { get, loading } = useFetch();

  const { settings } = useContext(AppContext);

  const isMultipleDatesMode = settings?.planningMode === planningMode.MULTIPLE;

  const [data, setData] = useState();

  // DATI
  // costs = altri costi
  // worked costs = costi ricorrenti
  // estimated cost = stima a finire
  // costi pianificati = costi piantificati
  // margine previsto = estimated margin
  // margine atteso = planned margin
  // margine attuale = current margin
  // Attenzione all'utente

  // API
  const getEconomics = async () => {
    await get(`${PROJECT}/${projectId}/${isErosion ? "erosion" : "margins"}`)
      .then(res => {
        if (res.ok) {
          setData(res?.data);
        }
      })
      .catch(e => console.log("e", e));
  };

  const getData = async () => Promise.all([getEconomics(), refreshUserData()]);

  useEffect(() => {
    getData();
  }, []);

  const targetLabels = isErosion ? erosionLabels : marginLabels;
  const targetPercentages = isErosion ? erosionPercentages : marginPercentages;

  const dataPlanned = {
    percentage: Math.round(data?.[targetPercentages.PLANNED] * 1) / 1,
    labelPercentage: targetLabels.PLANNED,
    series: [
      {
        category: targetLabels.PLANNED,
        value:
          Math.round(data?.[targetPercentages.PLANNED] * 1) / 1 < 0
            ? 100
            : Math.round(data?.[targetPercentages.PLANNED] * 1) / 1,
        full: 100,
      },
    ],
  };
  const dataWorked = {
    percentage: Math.round(data?.[targetPercentages.WORKED] * 1) / 1,
    labelPercentage: targetLabels.WORKED,
    series: [
      {
        category: targetLabels.WORKED,
        value:
          Math.round(data?.[targetPercentages.WORKED] * 1) / 1 < 0
            ? 100
            : Math.round(data?.[targetPercentages.WORKED] * 1) / 1,
        full: 100,
      },
    ],
  };

  const dataReported = {
    percentage: Math.round(data?.[targetPercentages.REPORTED] * 1) / 1,
    labelPercentage: targetLabels.REPORTED,
    series: [
      {
        category: targetLabels.REPORTED,
        value:
          Math.round(data?.[targetPercentages.REPORTED] * 1) / 1 < 0
            ? 100
            : Math.round(data?.[targetPercentages.REPORTED] * 1) / 1,
        full: 100,
      },
    ],
  };
  const renderColor = (value, expectedMargin) => {
    const plannedValue = Math.round(value * 1) / 1;
    let color = COLORS2;
    if (plannedValue < 0) {
      color = COLORS4;
    } else if (value >= 0 && value < expectedMargin) {
      color = COLORS5;
    }
    return color;
  };

  const renderGraph = () => {
    const expectedMargin = data?.expectedMargin;
    return (
      <div className={classes.graph}>
        <div className={classes.containerMargin}>
          {!isErosion && (
            <>
              <div className={classes.boxMargin}>
                <Typography className={classes.marginText}>
                  {data?.expectedMargin}%
                </Typography>
                <Typography className={classes.subMarginText}>
                  {"margine target"}
                </Typography>
              </div>
              <div className={classes.triangle}></div>
            </>
          )}
        </div>

        <div className={classes.containerGraph}>
          <SolidGaugeCost
            type={1}
            id={"solidGauge1"}
            data={dataPlanned}
            theme={renderColor(data?.plannedMarginPercentage, expectedMargin)}
          />
        </div>
        <div className={classes.containerGraph}>
          <SolidGaugeCost
            type={1}
            id={"solidGauge2"}
            data={dataWorked}
            theme={renderColor(data?.expectedMarginPercentage, expectedMargin)}
          />
        </div>
        <div className={classes.containerGraph}>
          <SolidGaugeCost
            type={1}
            id={"solidGauge3"}
            data={dataReported}
            theme={renderColor(data?.currentMarginPercentage, expectedMargin)}
          />
        </div>
        <div className={classes.containerGraph} />
      </div>
    );
  };

  const renderLabel = () => {
    return (
      <div className={classes?.totRow}>
        <div className={classes.firstCol} />
        <Typography className={classes.totText}>
          {"Costi pianificati"}
        </Typography>
        <Typography className={classes.totText}>{"Costi Previsti"}</Typography>
        <Typography className={classes.totText}>
          {"Costi consuntivati"}
        </Typography>
        <Typography className={classes.totText}>{"Stima a finire"}</Typography>
      </div>
    );
  };

  const renderNumber = () => {
    return (
      <div className={classes?.totRow}>
        <div className={classes.firstCol} />
        <div className={classes.totNumber}>
          <NumberFormatCustom
            value={data?.plannedCost + data?.costsSum}
            suffix="€"
          />
        </div>
        <div className={classes.totNumber}>
          <NumberFormatCustom
            value={data?.estimatedCost + data?.workedCost + data?.costsSum}
            suffix="€"
          />
        </div>
        <div className={classes.totNumber}>
          <NumberFormatCustom
            value={data?.workedCost + data?.costsSum}
            suffix="€"
          />
        </div>
        <div className={classes.totNumber}>
          <NumberFormatCustom value={data?.estimatedCost} suffix="€" />
        </div>
      </div>
    );
  };

  const renderChildren = () => {
    const costCommonProps = {
      suffix: " €",
      fixedDecimalScale: true,
    };

    const daysCommonProps = {
      suffix: isAdmin ? " gg)" : " gg",
      fixedDecimalScale: false,
      ...(isAdmin && { prefix: " (" }),
    };

    return data?.userProjectCosts?.map(el => {
      const name = el?.user?.surname + " " + el?.user?.name;

      const plannedCost = el?.plannedCost;
      const reportedCost = el?.workedCost;
      const estimatedCost = el?.estimatedCost;
      const providedCost = estimatedCost + reportedCost;

      const plannedDays = el?.plannedHoursInDays;
      const reportedDays = el?.reportedHoursInDays;
      const estimatedDays = el?.estimationCompletionTotalInDays;
      const providedDays = estimatedDays + reportedDays;

      return (
        <div className={classes?.children} key={nanoid()}>
          <div style={{ width: 234 }}>
            <Typography className={classes.rowText}>
              <Truncate lines={2}>{name.toUpperCase()}</Truncate>
            </Typography>
          </div>

          <div className={classes.rowNumber}>
            {isAdmin && (
              <NumberFormatCustom
                decimalScale={plannedCost && 2}
                value={plannedCost}
                {...costCommonProps}
              />
            )}
            <span className={classes.economicsInDays}>
              <NumberFormatCustom
                decimalScale={plannedDays && 2}
                value={plannedDays}
                {...daysCommonProps}
              />
            </span>
          </div>
          <div className={classes.rowNumber}>
            {isAdmin && (
              <NumberFormatCustom
                decimalScale={providedCost && 2}
                value={providedCost}
                {...costCommonProps}
              />
            )}
            <span className={classes.economicsInDays}>
              <NumberFormatCustom
                decimalScale={providedDays && 2}
                value={providedDays}
                {...daysCommonProps}
              />
            </span>
          </div>
          <div className={classes.rowNumber}>
            {isAdmin && (
              <NumberFormatCustom
                decimalScale={reportedCost && 2}
                value={reportedCost}
                {...costCommonProps}
              />
            )}
            <span className={classes.economicsInDays}>
              <NumberFormatCustom
                decimalScale={reportedDays && 2}
                value={reportedDays}
                {...daysCommonProps}
              />
            </span>
          </div>
          <div className={classes.rowNumber}>
            {isAdmin && (
              <NumberFormatCustom
                decimalScale={estimatedCost && 2}
                value={estimatedCost}
                {...costCommonProps}
              />
            )}
            <span className={classes.economicsInDays}>
              <NumberFormatCustom
                decimalScale={estimatedDays && 2}
                value={estimatedDays}
                {...daysCommonProps}
              />
            </span>
          </div>
        </div>
      );
    });
  };

  const renderCosts = () => {
    return data?.costs?.map(el => {
      return (
        <div className={classes?.children} key={nanoid()}>
          <Typography className={classes.rowText}>{el?.description}</Typography>
          <div className={classes.rowNumber}>
            <NumberFormatCustom
              decimalScale={el?.value === 0 ? 0 : 2}
              value={el?.value}
              suffix="€"
            />
          </div>
          <div className={classes.rowNumber}>
            <NumberFormatCustom
              decimalScale={el?.value === 0 ? 0 : 2}
              value={el?.value}
              suffix="€"
            />
          </div>
          <div className={classes.rowNumber}>
            <NumberFormatCustom
              decimalScale={el?.value === 0 ? 0 : 2}
              value={el?.value}
              suffix="€"
            />
          </div>
          <div className={classes.rowNumber}></div>
        </div>
      );
    });
  };

  return loading ? (
    <div className={classes.loadingWrapper}>
      <Loading showWrapper={false} />
    </div>
  ) : (
    <div className={classes.wrapper}>
      {renderGraph()}
      {renderLabel()}
      {renderNumber()}
      {data?.userProjectCosts?.length > 0 && !isMultipleDatesMode && (
        <div className={classes.column}>
          <Accordion
            title={{
              name: "Risorse",
            }}
            key={nanoid()}
            details={renderChildren()}
            defaultExpanded
            heightScroll={"300px"}
          />
        </div>
      )}
      {data?.costs?.length > 0 && (
        <div className={classes.column}>
          <Accordion
            title={{
              name: "Altri costi",
            }}
            key={nanoid()}
            details={renderCosts()}
            defaultExpanded
            heightScroll={"300px"}
          />
        </div>
      )}
    </div>
  );
};

Economics.propTypes = {
  isErosion: PropType.bool,
};

export default Economics;
