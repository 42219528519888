import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Components
import Layout from "components/Layout";
import Card from "./components/Card";
import SvgIcon from "components/SvgIcon";

// Assests
import { ReactComponent as CancelIcon } from "assets/cancel.svg";

// Mock
import { settingsMock } from "./mock/settingsMock";
import { WHITE, WILD_BLUE_YONDER } from "constants/colors";
import { ROOT } from "constants/routes";

// Context
import { AuthContext } from "context/AuthContext";

// Styles
const useStyles = makeStyles(() => ({
  button: { width: "200px" },
  cardsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  card: {
    opacity: 0.5,
    cursor: "default",
  },
  closeIcon: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 50,
    cursor: "pointer",
  },
  headWrap: {
    display: "flex",
    marginTop: 70,
  },

  title: {
    fontSize: "25px",
  },
  wrapper: {
    display: "flex",
    padding: "10px 240px 0 160px",
    flexDirection: "column",
  },
  textfield: {
    margin: "23px 0",
  },
}));

const Settings = () => {
  const classes = useStyles({});
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const renderHead = () => {
    return (
      <>
        <div className={classes.closeIcon}>
          <SvgIcon
            color={WILD_BLUE_YONDER}
            hoverColor={WHITE}
            icon={<CancelIcon />}
            onClick={() => history.push(ROOT)}
            strokeWidth={2}
          />
        </div>
        <div className={classes.headWrap}>
          <Typography className={classes.title}>Settings</Typography>
        </div>
      </>
    );
  };

  const renderCards = () => {
    const cardList = [];
    let enabledSections = [
      "Job Title",
      "Skill",
      "Utenti",
      "Dipartimenti",
      "Portfolio",
      "Clienti",
      "Generale",
    ];

    settingsMock?.map(el => {
      if (!user.canManageUsers)
        enabledSections = enabledSections.filter(
          section => section !== "Utenti"
        );
      const opacity = enabledSections.indexOf(el?.title) < 0;
      cardList.push(
        <Card
          icon={el?.icon}
          title={el.title}
          number={el?.number}
          className={opacity && classes?.card}
          onClick={() => !opacity && history.push(`settings/${el?.key}`)}
        />
      );
    });

    return <div className={classes.cardsContainer}>{cardList}</div>;
  };

  return (
    <Layout showSecondCol={false} showHeader={false} showPosts={false}>
      <div className={classes.wrapper}>
        {renderHead()}
        {renderCards()}
      </div>
    </Layout>
  );
};

export default Settings;
