import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

// Material UI
import { Grid, Typography, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Components
import TextField from "components/TextField";
import Loading from "components/Loading";
import ImageUploader from "components/ImageUploader";
import Autocomplete from "components/Autocomplete";
// Constants
import { SETTINGS_PORTFOLIOS } from "constants/api";

// Styles
const useStyles = makeStyles(() => ({
  formWrapper: {
    width: "100%",
  },
  formRow: {
    marginBottom: 15,
  },
  switchWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  dragIcon: {
    marginRight: 10,
  },
  loading: {
    height: "40vh",
  },
  title: {
    fontWeight: "bold",
    fontSize: 13,
  },
  containerGrid: ({ fromSettings }) => ({
    width: "100%",
    paddingTop: fromSettings ? 0 : 5,
  }),
}));

const THEME_TYPE = "light";

const PortfolioForm = ({
  blurredImage,
  files,
  fromSettings,
  loading,
  portfolioData,
  setFiles,
  setBlurredImage,
}) => {
  // Hooks
  const classes = useStyles({ fromSettings });

  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext();

  const portfolioId = portfolioData?.id;
  const url =
    `${SETTINGS_PORTFOLIOS}?OnlyPotentialPortfoliosContainer=true` +
    (portfolioId ? `&ExcludeBranchFromRootId=${portfolioId}` : "") +
    "&q";

  // Effects
  useEffect(() => {
    if (portfolioData) {
      setValue("name", portfolioData?.name);
      setValue("imageUrl", portfolioData?.imageUrl);
      setValue("blurredImage", portfolioData?.blurredImage);
      setValue("area", {
        path: portfolioData?.path,
        name: "",
        id: portfolioData?.parentId || portfolioData?.parent?.id,
      });
      setBlurredImage(portfolioData?.blurredImage);
    }
  }, [portfolioData]);

  if (loading)
    return (
      <div className={classes.loading}>
        <Loading showWrapper={false} />
      </div>
    );
  return (
    <Fade in timeout={400}>
      <form>
        <Grid container spacing={1} className={classes.containerGrid}>
          {fromSettings && (
            <Grid item xs={12} className={classes.formRow}>
              <Typography
                color="primary"
                className={classes.title}
              >{`PERCORSO: ${
                typeof watch("area") === "string"
                  ? watch("area")
                  : watch("area")?.path || watch("area")?.name
                  ? watch("area")?.path +
                    (watch("area")?.path !== "/" &&
                    watch("area")?.path !== "undefined" &&
                    watch("area")?.name &&
                    watch("area")?.name !== "undefined"
                      ? "/"
                      : "") +
                    watch("area")?.name
                  : "/"
              }`}</Typography>
            </Grid>
          )}

          {fromSettings && (
            <Grid item xs={12} className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    label="Percorso"
                    endpoint={url}
                    error={!!errors.area}
                    getOptionLabel={option =>
                      option?.path || option?.name
                        ? option?.path +
                          (option?.path !== "/" &&
                          option?.path !== "undefined" &&
                          option?.name &&
                          option?.name !== "undefined"
                            ? "/"
                            : "") +
                          option?.name
                        : "/"
                    }
                    themeType={THEME_TYPE}
                    {...field}
                    onChange={(_, data) => field.onChange(data)}
                  />
                )}
                defaultValue={"/"}
                name="area"
                control={control}
              />
            </Grid>
          )}

          <Grid item xs={12} className={classes.formRow}>
            <Controller
              render={({ field }) => (
                <TextField
                  label="Nome Portfolio"
                  error={!!errors.name}
                  required
                  themeType={THEME_TYPE}
                  shrink={!!field.value}
                  {...field}
                />
              )}
              name="name"
              control={control}
              rules={{ required: true }}
            />
          </Grid>

          <Grid item xs>
            <ImageUploader
              selectedFiles={files}
              onSelect={setFiles}
              onBlurChange={setBlurredImage}
              blurrable
              blurActive={blurredImage}
              imageUrl={watch("imageUrl")}
              themeType={THEME_TYPE}
            />
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};

PortfolioForm.propTypes = {
  areaTitle: PropTypes.string,
  blurredImage: PropTypes.bool,
  files: PropTypes.array,
  fromSettings: PropTypes.bool,
  loading: PropTypes.bool,
  portfolioData: PropTypes.object,
  setFiles: PropTypes.func,
  setBlurredImage: PropTypes.func,
};

export default PortfolioForm;
