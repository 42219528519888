import React, { useEffect, useState, useContext } from "react";

// Hooks
import useFetch from "hooks/useHTTP";
import useDrawer from "hooks/useDrawer";
import { useForm, FormProvider } from "react-hook-form";

// Contexts
import { AppContext } from "context/AppContext";

// // Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

// Config
import { jobTitleConfig } from "components/Table/config";

// Assets
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-outlined.svg";
import plusIcon from "assets/plus-white.png";

// Components
import Button from "components/Button";
import Table from "components/Table";
import Layout from "components/Layout";
import Drawer from "components/Drawer";
import Loading from "components/Loading";
import JobTitleForm from "./components/JobTitleForm";
import Navigation from "../components/Navigation";

// Constants
import { JOB_TITLE } from "constants/api";

import { TextFieldControl } from "components/TextField";
import { ReactComponent as SearchIcon } from "assets/search-image.svg";
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
import { Grid, Stack } from "@mui/material";

// Styles
const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  buttonIcon: {
    width: 16,
    height: 16,
  },
  loading: {
    height: "40vh",
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "50px 0px",
  },
  backButton: {
    display: "flex",
    alignItems: "center",
  },
  actionButton: {
    minWidth: "unset",
    width: 44,
    flex: "0 0 auto",
    padding: 10,
    "& svg": {
      height: 18,
    },
  },
}));

const SettingsJobTitles = () => {
  const classes = useStyles({});
  const { get, put, post, del } = useFetch();
  const { setModalConfig, setGlobalLoading } = useContext(AppContext);
  const [jobTitles, setJobTitles] = useState([]);
  const [loadingJobTitles, setLoadingJobTitles] = useState(false);
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const jobTitleFormMethods = useForm({ defaultValues: { name: "" } });
  const { control, getValues, reset, handleSubmit } = useForm({
    defaultValues: { searchText: "" },
  });

  //Drawers state
  const {
    isDrawerOpen: isDrawerOpen,
    setIsDrawerOpen: setDrawerOpen,
    toggleDrawer: toggleDrawer,
  } = useDrawer();

  const fetchJobTitles = async () => {
    setLoadingJobTitles(true);
    const searchText = getValues("searchText");
    const qs = searchText ? `?q=${searchText}` : "";
    await get(JOB_TITLE + qs)
      .then(response => {
        setLoadingJobTitles(false);
        if (response.ok) setJobTitles(response.data);
      })
      .catch(() => {
        setLoadingJobTitles(false);
        setErrorMessage("Elaborazione non riuscita");
      });
  };

  const fetchJobTitle = async id => {
    setLoadingDrawer(true);
    await get(`${JOB_TITLE}/${id}`)
      .then(res => {
        if (res.ok) {
          setSelectedItem(res?.data);
          setLoadingDrawer(false);
        }
      })
      .catch(() => {
        setLoadingDrawer(false);
      });
  };

  const deleteJobTitle = async id => {
    setGlobalLoading(true);
    setModalConfig(null);
    await del(`${JOB_TITLE}/${id}`)
      .then(async res => {
        if (res.ok) {
          await fetchJobTitles();
          setGlobalLoading(false);
        }
      })
      .catch(() => {
        setGlobalLoading(false);
      });
  };

  const handleJobTitleDelete = item => {
    const deletable = item?.peopleCount === 0;

    setModalConfig({
      title: deletable
        ? "Eliminazione Job Title"
        : "Impossibile eliminare il Job Title",
      content: deletable
        ? "Sei sicuro di voler eliminare questo job title?"
        : "Esistono utenti associati al Job Title",
      primaryAction: {
        text: "OK",
        ...(deletable && { onClick: () => deleteJobTitle(item.id) }),
      },
      ...(deletable && {
        secondaryAction: {
          text: "ANNULLA",
        },
      }),
    });
  };

  const handleClosing = () => {
    setErrorMessage("");
    setSelectedItem();
    toggleDrawer();
    jobTitleFormMethods.reset();
    setLoadingDrawer(false);
  };

  const onSubmit = async data => {
    setLoadingDrawer(true);
    if (selectedItem) {
      await put(`${JOB_TITLE}/${selectedItem.id}`, data)
        .then(response => {
          if (response.ok) {
            handleClosing();
            fetchJobTitles();
          }
        })
        .catch(() => {
          setLoadingDrawer(false);
          setErrorMessage("Elaborazione non riuscita");
        });
    } else {
      await post(JOB_TITLE, data)
        .then(response => {
          if (response.ok) {
            handleClosing();
            fetchJobTitles();
          }
        })
        .catch(() => {
          setLoadingDrawer(false);
          setErrorMessage("Elaborazione non riuscita");
        });
    }
  };

  useEffect(() => {
    fetchJobTitles();
  }, []);

  const handleFilter = e => {
    e.preventDefault();
    return handleSubmit(() => fetchJobTitles())();
  };

  const clearForm = () => {
    reset();
    fetchJobTitles();
  };

  return (
    <Layout showSecondCol={false} showHeader={false} showPosts={false}>
      <Navigation />
      <div className={classes.header}>
        <Typography variant="h5">Job title</Typography>
        <Button
          color="primary"
          variant="contained"
          startIcon={<img src={plusIcon} className={classes.buttonIcon} />}
          onClick={() => setDrawerOpen(true)}
        >
          Nuovo job title
        </Button>
      </div>

      <Grid container marginBottom={2}>
        <Grid item xs={12} sm={3}>
          <form onSubmit={handleFilter}>
            <Stack direction="row" gap={1}>
              <TextFieldControl
                name="searchText"
                control={control}
                label="Filtra per job title"
                placeholder="Digita il job title che desideri ricercare"
              />
              <Stack direction="row" gap={1}>
                <Button
                  className={classes.actionButton}
                  variant="outlined"
                  color="secondary"
                  onClick={clearForm}
                >
                  <CancelIcon />
                </Button>
                <Button
                  type="submit"
                  className={classes.actionButton}
                  variant="outlined"
                  color="primary"
                >
                  <SearchIcon />
                </Button>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>

      {loadingJobTitles ? (
        <div className={classes.loading}>
          <Loading showWrapper={false} />
        </div>
      ) : (
        <Table
          data={jobTitles}
          config={jobTitleConfig}
          rowActions={[
            {
              onClick: item => {
                fetchJobTitle(item?.id);
                setDrawerOpen(true);
              },
              icon: <EditIcon />,
            },
            {
              onClick: item => handleJobTitleDelete(item),
              icon: <DeleteIcon />,
            },
          ]}
        />
      )}
      <FormProvider {...jobTitleFormMethods}>
        <Drawer
          open={isDrawerOpen}
          title={
            !loadingDrawer &&
            (selectedItem ? "Modifica Job Title" : "Nuovo Job Title")
          }
          primaryText={!loadingDrawer && "SALVA"}
          secondaryText={!loadingDrawer && "ANNULLA"}
          onPrimary={jobTitleFormMethods.handleSubmit(onSubmit)}
          onSecondary={() => handleClosing()}
          errorMessage={errorMessage}
        >
          <JobTitleForm data={selectedItem} loading={loadingDrawer} />
        </Drawer>
      </FormProvider>
    </Layout>
  );
};

export default SettingsJobTitles;
