import React, { useContext } from "react";
import PropTypes from "prop-types";

// Context
import { AppContext } from "context/AppContext";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
// Constants
import { BLACK } from "constants/colors";
// Enums
import { modalTypes } from "utils/enums/modals";
// Default Data
import { dialogDefaults } from "./defaultContent";
// Shapes
import { actionShape } from "./shapes";

// Styles
const useStyles = makeStyles(theme => ({
  modalBody: {
    maxWidth: 670,
    padding: 55,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      width: 670,
    },
  },
  bodyText: {
    color: BLACK,
    lineHeight: "30px",
    fontSize: 20,
  },
  title: {
    color: BLACK,
  },
  spacerTop: {
    marginTop: 50,
    whiteSpace: "pre-line",
  },
  actionsWrapper: {
    marginTop: 60,
    justifyContent: "center",
  },
  action: {
    flex: 1,
    flexGrow: 0,
  },
}));

const Dialog = ({
  content,
  closeOnBackdrop,
  primaryAction,
  secondaryAction,
  title,
  type,
}) => {
  // Hooks
  const classes = useStyles();
  // Context
  const { modalConfig, setModalConfig } = useContext(AppContext);

  // Renders
  const renderTitle = () => {
    const titleText = title || dialogDefaults[type]?.title;
    if (!titleText) return null;

    return (
      <DialogTitle className={classes.title}>
        <Typography variant="h5" component="span">
          {titleText}
        </Typography>
      </DialogTitle>
    );
  };

  const renderActions = () => {
    if (!primaryAction && !secondaryAction) return null;

    return (
      <DialogActions className={classes.actionsWrapper}>
        {secondaryAction && (
          <Button
            variant="contained"
            color="secondary"
            {...secondaryAction}
            onClick={secondaryAction.onClick || (() => setModalConfig(null))}
            className={classes.action}
          >
            {secondaryAction.text}
          </Button>
        )}
        {primaryAction && (
          <Button
            variant="contained"
            color="primary"
            {...primaryAction}
            onClick={primaryAction.onClick || (() => setModalConfig(null))}
            className={classes.action}
          >
            {primaryAction.text}
          </Button>
        )}
      </DialogActions>
    );
  };

  const renderContent = () => {
    if (type && dialogDefaults[type])
      return (
        <DialogContent>
          <div className={classes.spacerTop}>
            {dialogDefaults[type].content}
          </div>
        </DialogContent>
      );
    else
      return (
        <DialogContent>
          {content && (
            <div className={classes.spacerTop}>
              {typeof content === "string" ? (
                <Typography
                  variant="h6"
                  component="span"
                  className={classes.bodyText}
                >
                  {content}
                </Typography>
              ) : (
                content
              )}
            </div>
          )}
        </DialogContent>
      );
  };

  return (
    <MuiDialog
      classes={{ paper: classes.modalBody }}
      open={!!modalConfig}
      onBackdropClick={closeOnBackdrop && (() => setModalConfig(null))}
    >
      {renderTitle()}
      {renderContent()}
      {renderActions()}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  closeOnBackdrop: PropTypes.bool,
  primaryAction: PropTypes.shape(actionShape),
  secondaryAction: PropTypes.shape(actionShape),
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.values(modalTypes)),
};

export default Dialog;
