import React from "react";
import { BRICKS } from "constants/api";
import { AppContext } from "context/AppContext";
import useFetch from "hooks/useHTTP";
import { useContext } from "react";
import { useRef } from "react";
import { EstimationModal } from "./Estimation";

const useEstimationModal = ({
  onUpdateEstimationStart,
  onUpdateEstimationEnd,
} = {}) => {
  // hooks
  const { put } = useFetch();
  const { setModalConfig } = useContext(AppContext);

  // refs
  const estimationValueRef = useRef(null);

  // api
  const updateEstimation = async ({ brickId, newEstimationInDays }) => {
    onUpdateEstimationStart?.();
    try {
      await put(`${BRICKS}/${brickId}/estimationCompletion`, {
        estimationCompletionInDays: newEstimationInDays,
      });
    } catch (err) {
      console.error(err);
    } finally {
      estimationValueRef.current = null;
      onUpdateEstimationEnd?.();
    }
  };

  // helpers
  const openModal = ({ brick, title, onCloseSuccess }) =>
    setModalConfig({
      title: title || "Stima a finire (gg)",
      content: (
        <EstimationModal
          currentValue={brick.estimationCompletionInDays}
          valueRef={estimationValueRef}
        />
      ),
      secondaryAction: {
        text: "Annulla",
        onClick: () => setModalConfig(null),
      },
      primaryAction: {
        text: "Salva",
        onClick: async () => {
          setModalConfig(null);
          await updateEstimation({
            brickId: brick.id,
            newEstimationInDays: estimationValueRef.current,
          });
          onCloseSuccess?.();
        },
      },
    });

  return {
    updateEstimation,
    openEstimationModal: openModal,
    estimationValueRef,
  };
};

export { useEstimationModal };
