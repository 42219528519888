/* eslint-disable max-len */
import React from "react";
import { PropTypes } from "prop-types";

const SentimentPositive = ({ fill = "#28CD60", width, height, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 50}
      height={height || 50}
      viewBox="0 0 63 63"
      fill="none"
      style={style}
    >
      <path
        d="M31.572 1.59137e-08C25.3549 -0.000197767 19.2773 1.84321 14.1079 5.2971C8.93848 8.75099 4.90935 13.6602 2.53004 19.404C0.150717 25.1478 -0.471924 31.4682 0.740851 37.5659C1.95363 43.6635 4.94734 49.2646 9.34343 53.6608C13.7395 58.0571 19.3405 61.051 25.4381 62.2639C31.5358 63.4769 37.8561 62.8545 43.6 60.4753C49.3439 58.0962 54.2533 54.0672 57.7073 48.8979C61.1614 43.7286 63.005 37.6511 63.005 31.434C62.9954 23.1003 59.6807 15.1106 53.788 9.21768C47.8953 3.32476 39.9057 0.00979272 31.572 1.59137e-08ZM31.572 60.134C25.8957 60.134 20.3468 58.4508 15.6271 55.2972C10.9074 52.1436 7.22887 47.6613 5.05663 42.417C2.8844 37.1728 2.31604 31.4022 3.42344 25.8349C4.53084 20.2677 7.26425 15.1538 11.278 11.14C15.2918 7.12627 20.4056 4.39286 25.9729 3.28546C31.5401 2.17807 37.3108 2.74642 42.555 4.91866C47.7992 7.09089 52.2816 10.7694 55.4352 15.4891C58.5887 20.2088 60.272 25.7577 60.272 31.434C60.2635 39.0431 57.237 46.3382 51.8566 51.7186C46.4761 57.0991 39.1811 60.1255 31.572 60.134Z"
        fill={fill}
      />
      <path
        d="M24.738 25.9672C24.738 25.1563 24.4975 24.3636 24.047 23.6893C23.5965 23.0151 22.9561 22.4896 22.207 22.1793C21.4578 21.869 20.6334 21.7878 19.8381 21.946C19.0428 22.1042 18.3122 22.4947 17.7388 23.0681C17.1654 23.6415 16.7749 24.372 16.6167 25.1673C16.4585 25.9626 16.5397 26.787 16.8501 27.5362C17.1604 28.2854 17.6859 28.9257 18.3601 29.3762C19.0344 29.8267 19.8271 30.0672 20.638 30.0672C21.7249 30.0659 22.767 29.6335 23.5356 28.8649C24.3043 28.0963 24.7366 27.0542 24.738 25.9672ZM19.271 25.9672C19.271 25.6968 19.3511 25.4325 19.5013 25.2077C19.6516 24.9829 19.8651 24.8077 20.1148 24.7042C20.3646 24.6008 20.6395 24.5737 20.9047 24.6265C21.1698 24.6792 21.4134 24.8094 21.6046 25.0006C21.7958 25.1918 21.926 25.4353 21.9787 25.7005C22.0314 25.9657 22.0044 26.2405 21.9009 26.4903C21.7974 26.7401 21.6222 26.9536 21.3974 27.1038C21.1726 27.254 20.9083 27.3342 20.638 27.3342C20.2756 27.3337 19.9282 27.1895 19.6719 26.9332C19.4157 26.677 19.2715 26.3296 19.271 25.9672Z"
        fill={fill}
      />
      <path
        d="M42.505 21.8672C41.6941 21.8672 40.9014 22.1077 40.2271 22.5582C39.5529 23.0087 39.0274 23.649 38.7171 24.3982C38.4067 25.1474 38.3256 25.9717 38.4838 26.7671C38.642 27.5624 39.0324 28.2929 39.6058 28.8663C40.1792 29.4397 40.9098 29.8302 41.7051 29.9884C42.5004 30.1466 43.3248 30.0654 44.074 29.7551C44.8232 29.4448 45.4635 28.9193 45.914 28.245C46.3645 27.5708 46.605 26.7781 46.605 25.9672C46.6036 24.8802 46.1713 23.8381 45.4027 23.0695C44.634 22.3009 43.592 21.8685 42.505 21.8672ZM42.505 27.3342C42.2346 27.3342 41.9703 27.254 41.7455 27.1038C41.5207 26.9536 41.3455 26.7401 41.242 26.4903C41.1386 26.2405 41.1115 25.9657 41.1642 25.7005C41.217 25.4353 41.3472 25.1918 41.5384 25.0006C41.7295 24.8094 41.9731 24.6792 42.2383 24.6264C42.5035 24.5737 42.7783 24.6008 43.0281 24.7042C43.2779 24.8077 43.4914 24.9829 43.6416 25.2077C43.7918 25.4325 43.872 25.6968 43.872 25.9672C43.8714 26.3296 43.7272 26.677 43.471 26.9332C43.2147 27.1895 42.8674 27.3337 42.505 27.3342Z"
        fill={fill}
      />
      <path
        d="M44.783 38.6153C44.6493 38.4955 44.4933 38.4032 44.3239 38.3437C44.1545 38.2842 43.9751 38.2588 43.7958 38.2687C43.6166 38.2786 43.441 38.3238 43.2793 38.4016C43.1175 38.4795 42.9726 38.5884 42.853 38.7222C41.4279 40.3007 39.6874 41.5626 37.744 42.4262C35.8006 43.2899 33.6976 43.7361 31.571 43.7361C29.4444 43.7361 27.3414 43.2899 25.398 42.4262C23.4547 41.5626 21.7141 40.3007 20.289 38.7222C20.0442 38.4641 19.7084 38.3116 19.3529 38.297C18.9974 38.2825 18.6503 38.4072 18.3852 38.6445C18.1202 38.8818 17.9581 39.2131 17.9334 39.568C17.9087 39.923 18.0234 40.2735 18.253 40.5452C19.9348 42.4096 21.9893 43.9 24.2836 44.9202C26.5778 45.9404 29.0607 46.4675 31.5715 46.4675C34.0823 46.4675 36.5652 45.9404 38.8594 44.9202C41.1537 43.9 43.2082 42.4096 44.89 40.5452C45.1314 40.275 45.2558 39.92 45.2357 39.5581C45.2157 39.1963 45.0529 38.8572 44.783 38.6153Z"
        fill={fill}
      />
    </svg>
  );
};

SentimentPositive.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
};

export { SentimentPositive };
