import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

import {
  PRIMARY,
  ERROR,
  OXFORD_BLUE,
  WILD_BLUE_YONDER,
  SPACE_CADET,
  WHITE,
  BLACK,
} from "constants/colors";

const ALFA30 = "4D";
const ALFA91 = "CC";

export default responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1366,
        xl: 1920,
        xxl: 2560,
      },
    },
    palette: {
      action: {
        disabledBackground: WILD_BLUE_YONDER,
        disabled: WHITE,
      },
      primary: {
        main: PRIMARY,
        light: PRIMARY + ALFA30,
      },
      secondary: {
        main: ERROR,
      },
      error: {
        main: ERROR,
      },
      background: {
        default: OXFORD_BLUE,
        contrastText: WHITE,
      },
      text: {
        primary: WHITE,
      },
      componentsBackground: {
        main: SPACE_CADET,
        light: "#e5e8ef",
      },
      cardCarouselBackground: {
        main: SPACE_CADET,
        light: SPACE_CADET + ALFA91,
      },
    },
    typography: {
      fontFamily: "Nunito-Regular, Nunito-Light, Nunito-Bold, Arial",
      h5: {
        fontSize: "1.56rem",
        fontWeight: "bold",
      },
      h6: {
        fontSize: "1rem",
      },
      body1: {
        fontSize: "0.78rem",
      },
      button: {
        fontSize: "0.78rem",
        fontWeight: "bold",
      },
      subtitle1: {
        fontSize: "0.62rem",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 5,
          minWidth: 40,
        },
        contained: {
          minWidth: 275,
          padding: "8.5px 15px",
        },
        containedSecondary: {
          "&.iconButton": {
            padding: 14,
            minWidth: "unset",
          },
        },
        outlined: {
          padding: "8.5px 15px",
          minWidth: 275,

          "&.Mui-disabled": {
            borderColor: WILD_BLUE_YONDER,
            color: WILD_BLUE_YONDER,
          },
        },
        outlinedPrimary: {
          color: WHITE,
          border: `3px solid ${PRIMARY}`,
          "& svg": {
            width: "auto",
            height: 12,
          },
          "&:hover": {
            border: `3px solid ${PRIMARY}`,
          },
          "&.iconButton": {
            padding: 14,
            minWidth: "unset",
          },
        },
        outlinedSecondary: {
          border: `3px solid ${ERROR}`,
          "& svg": {
            width: "auto",
            height: 12,
          },
          "&:hover": {
            border: `3px solid ${ERROR}`,
          },
          "&.iconButton": {
            padding: 14,
            minWidth: "unset",
          },
          "&.Mui-disabled": {
            borderColor: WILD_BLUE_YONDER,
            color: WILD_BLUE_YONDER,
            border: `3px solid ${WILD_BLUE_YONDER}`,
          },
        },
        text: {
          fontWeight: 400,
          "&.Mui-disabled": {
            color: WILD_BLUE_YONDER,
          },
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
          },
        },
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: 0,
        },
      },
      MuiTextField: {
        root: {
          borderRadius: 5,
          "& .MuiOutlinedInput-input": {
            padding: 15,
          },
          "& .MuiOutlinedInput-multiline": {
            paddingLeft: 15,
            paddingRight: 15,
            "& textarea": {
              padding: 0,
            },
          },
          "& .MuiInputBase-input.Mui-disabled": {
            color: WILD_BLUE_YONDER,
          },
        },
      },
      MuiInputBase: {
        root: {
          "&.Mui-disabled": {
            color: WILD_BLUE_YONDER,
          },
        },
      },
      MuiSelect: {
        outlined: {
          padding: 15,
        },
      },
      MuiAutocomplete: {
        root: {
          borderRadius: 5,
          '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: "5.5px 10px",
          },
        },
      },
      MuiInputLabel: {
        outlined: {
          transform: `translate(14px, 17px) scale(1)`,
        },
      },
      MuiDrawer: {
        paper: {
          padding: "50px 70px  ",
          width: 760,
        },
      },
      MuiPopover: {
        paper: {
          color: BLACK,
        },
      },
      MuiPickersDay: {
        day: {
          color: "inherit",
        },
      },
      MuiAccordion: {
        root: {
          "&$expanded": {
            minHeight: 48,
            margin: 0,
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          "&$expanded": {
            minHeight: 48,
          },
        },
        content: {
          "&$expanded": {
            margin: 0,
          },
        },
      },
      MuiTable: {
        root: {
          "& .table-row-action.Mui-disabled :is(svg, path, rect)": {
            fill: OXFORD_BLUE,
          },
        },
      },
    },
  })
);
