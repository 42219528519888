import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import PerfectScrollbar from "react-perfect-scrollbar";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import {
  RadioGroup as MuiRadioGroup,
  FormControl as MuiFormControl,
  FormLabel as MuiFormLabel,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormLable .Mui-focused": {
      color: theme.palette.text.primary,
    },
  },
  uncheckedIcon: ({ customColorUnchecked, disabled }) => ({
    borderRadius: "50%",
    width: 24,
    height: 24,
    backgroundColor: disabled
      ? theme.palette.action.disabledBackground
      : customColorUnchecked || theme.palette.componentsBackground.main,
  }),
  checkedIcon: ({ customColorChecked, customColorUnchecked, disabled }) => ({
    width: 24,
    height: 24,
    backgroundColor: customColorUnchecked || theme.palette.background.default,
    border: `2px solid ${
      disabled
        ? theme.palette.action.disabledBackground
        : customColorChecked || theme.palette.primary.main
    }`,
    borderRadius: "50%",
    "&:before": {
      display: "block",
      width: 14,
      height: 14,
      borderRadius: "50%",
      backgroundColor: disabled
        ? theme.palette.action.disabledBackground
        : customColorChecked || theme.palette.primary.main,
      marginLeft: "50%",
      marginTop: "50%",
      transform: "translate(-50%, -50%)",
      content: '""',
    },
  }),
  formControl: ({ style }) => ({
    minWidth: 120,
    ...style,
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.text.primary,
    },
    "& .MuiFormControlLabel-root .Mui-disabled": {
      color: theme.palette.action.disabledBackground,
    },
  }),
  formControlLabel: { margin: "5px 0 " },
  formLabel: {
    marginBottom: 5,
    marginTop: 0,
    paddingBottom: 0,
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: 14,
  },
  coloredScrollbar: {
    width: "100%",
    "& .ps__thumb-y": {
      backgroundColor: theme.palette.primary.main,
    },
    "& :hover": {
      backgroundColor: "transparent",
    },
  },
}));

const Radio = forwardRef(
  (
    {
      label,
      options,
      defaultValue,
      formControlStyle,
      disabled,
      addAsteriks,
      customColorChecked,
      customColorUnchecked,
      horizontalDirection,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles({
      style: formControlStyle,
      customColorChecked,
      customColorUnchecked,
      disabled,
      horizontalDirection,
    });
    return (
      <PerfectScrollbar
        id="pf-checkboxes"
        className={classes.coloredScrollbar}
        options={{ suppressScrollY: horizontalDirection }}
      >
        <MuiFormControl className={classes.formControl}>
          {label && (
            <MuiFormLabel className={classes.formLabel}>
              {label}
              {addAsteriks && "*"}
            </MuiFormLabel>
          )}
          <MuiRadioGroup
            row={horizontalDirection}
            ref={ref}
            defaultValue={defaultValue}
            {...rest}
          >
            {options.map(({ label, value }) => (
              <MuiFormControlLabel
                className={classes.formControlLabel}
                key={value}
                value={value}
                control={
                  <MuiRadio
                    disableRipple
                    checkedIcon={<span className={classes.checkedIcon}></span>}
                    icon={<span className={classes.uncheckedIcon}></span>}
                    disabled={disabled}
                  />
                }
                label={label}
              />
            ))}
          </MuiRadioGroup>
        </MuiFormControl>
      </PerfectScrollbar>
    );
  }
);

Radio.displayName = "Radio";
/**
 * @param  control - [react-hook-form control Object](https://react-hook-form.com/api/useform/control)
 * @param  name - Radio group name
 * @param  defaultValue - Radio group default value
 * @param  ...rest - props expected by \<Radio/\> component
 * @example <RadioControl
              control={control}
              name={"timePeriod"}
              defaultValue={"all"}
              label={"Time"}
              options={[
                { label: "All time", value: "all" },
                { label: "One Month", value: "oneMonth" },
              ]}
              customColorUnchecked={OXFORD_BLUE}
            />
 */

const RadioControl = ({ control, name, rules, defaultValue, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <Radio
          {...rest}
          {...field}
          defaultValue={defaultValue}
          onChange={e => {
            field.onChange(e.target.value.toString());
          }}
        />
      )}
    />
  );
};

Radio.defaultProps = {
  defaultValue: "",
  addAsteriks: false,
  horizontalDirection: true,
};

RadioControl.defaultProps = {
  defaultValue: "",
};

Radio.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  defaultValue: PropTypes.string,
  formControlStyle: PropTypes.object,
  disabled: PropTypes.bool,
  addAsteriks: PropTypes.bool,
  customColorChecked: PropTypes.string,
  customColorUnchecked: PropTypes.string,
  horizontalDirection: PropTypes.bool,
};

RadioControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  rest: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ).isRequired,
    formControlStyle: PropTypes.object,
    disabled: PropTypes.bool,
    addAsteriks: PropTypes.bool,
    customColorChecked: PropTypes.string,
    customColorUnchecked: PropTypes.string,
    horizontalDirection: PropTypes.bool,
  }),
};

export { Radio, RadioControl };
