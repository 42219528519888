import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Context
import { AppContext } from "context/AppContext";
import { AuthContext } from "context/AuthContext";

// Material UI
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// Components
// import KpiDo from "components/KpiDo";
import KpiBe from "components/KpiBe";
import Avatar from "components/Avatar";
import SvgIcon from "components/SvgIcon";
import Popper from "components/Popper";

// Enums
import { collarTypes } from "utils/enums/users";
import { metricTypes } from "utils/enums/grooveMetrics";
import { beKpiTypes } from "utils/enums/grooveKpis";

// Icons
import { ReactComponent as BulletIcon } from "assets/bullets.svg";
import { ReactComponent as DisabledIcon } from "assets/forbidden.svg";

// Constants
import { WHITE, WILD_BLUE_YONDER } from "constants/colors";

// Style
const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  wrapper: ({ onClick }) => ({
    width: 230,
    cursor: onClick ? "pointer" : "default",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    textAlign: "center",
    position: "relative",
  }),
  header: ({ disabled }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...(disabled && { opacity: 0.5 }),
  }),
  disabledIcon: {
    position: "absolute",
    top: 102,
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
  },
  icon: {
    cursor: "pointer",
  },
  kpiWrapper: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    margin: "auto",
    width: "92%",
    marginTop: "auto",
    flex: 1,
  },
  kpiNumber: {
    fontSize: 25,
  },
  doLabel: {
    marginTop: 5,
  },
  name: {
    marginTop: 16,
    marginBottom: 16,
    fontSize: 20,
  },
}));

const REPUTATION_OFFSET = 3;

const Person = ({
  className,
  // cost,
  deletePerson,
  imageUrl,
  isOwner,
  // metric,
  name,
  onClick,
  onEdit,
  // quality,
  reputation,
  sentiment,
  skillCount,
  // time,
  userId,
  skillsCountDepartment,
  disabled,
}) => {
  const classes = useStyles({ imageUrl, onClick, disabled });
  const [anchorEl, setAnchorEl] = useState(null);
  // Context
  const { setModalConfig, settings } = useContext(AppContext);
  const { user } = useContext(AuthContext);

  const isBeActive = settings?.isBeActive;

  const popperItems = [
    {
      onClick: () => {
        setAnchorEl(null);
        onEdit();
      },
      text: "Gestisci utente",
    },
    {
      onClick: () => {
        setAnchorEl(null);
        handleDeletePerson();
      },
      text: "Elimina",
    },
  ];

  const handleDeletePerson = () =>
    setModalConfig({
      title: "Eliminazione Utente",
      content: "Sei sicuro di voler eliminare questo utente?",
      primaryAction: {
        text: "OK",
        onClick: () => deletePerson(userId),
      },
      secondaryAction: {
        text: "ANNULLA",
      },
    });

  const open = Boolean(anchorEl);
  const popperId = open ? "card-popper" : undefined;

  // Renders
  const renderHeader = () => (
    <>
      {disabled && (
        <SvgIcon
          color={WILD_BLUE_YONDER}
          className={classes.disabledIcon}
          icon={<DisabledIcon />}
          strokeWidth={0.5}
          width={30}
          height={30}
        />
      )}
      <div className={classes.header}>
        <Avatar
          collar={isOwner ? collarTypes.WHITE : collarTypes.BLUE}
          image={imageUrl}
          size={180}
          onClick={onClick}
        />
        <Typography className={classes.name} variant="h5">
          {name}
        </Typography>
      </div>
    </>
  );

  const renderKpis = () => (
    <div className={classes.kpiWrapper}>
      {/* {metric === metricTypes.DO && (
        <>
          <KpiDo
            number={time}
            label="time"
            orientation="column"
            numberClassname={classes.kpiNumber}
            labelClassname={classes.doLabel}
            alignItems="center"
          />
          <KpiDo
            number={cost}
            label="cost"
            orientation="column"
            numberClassname={classes.kpiNumber}
            labelClassname={classes.doLabel}
            alignItems="center"
          />
          <KpiDo
            number={quality}
            label="quality"
            orientation="column"
            numberClassname={classes.kpiNumber}
            labelClassname={classes.doLabel}
            alignItems="center"
          />
        </>
      )}
      {metric === metricTypes.BE && (
        <> */}
      {isBeActive && <KpiBe type={beKpiTypes.SENTIMENT} value={sentiment} />}
      <KpiBe
        type={beKpiTypes.REPUTATION}
        value={reputation + REPUTATION_OFFSET}
      />
      <KpiBe
        type={beKpiTypes.SKILLS}
        value={skillCount}
        maxSkillValue={skillsCountDepartment}
      />
      {/* </>
      )} */}
    </div>
  );

  return (
    <div className={classes.container}>
      {user.canManageUsers && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 30,
          }}
        >
          <SvgIcon
            color={anchorEl ? WHITE : WILD_BLUE_YONDER}
            onMouseEnter={e => setAnchorEl(anchorEl ? null : e.currentTarget)}
            onMouseLeave={() => setAnchorEl(null)}
            icon={<BulletIcon />}
            width={24}
            height="auto"
            className={classes.icon}
          >
            <Popper
              id={popperId}
              open={open}
              placement="bottom-end"
              anchorEl={anchorEl}
            >
              {popperItems}
            </Popper>
          </SvgIcon>
        </div>
      )}
      <div className={clsx(classes.wrapper, className)} onClick={onClick}>
        {renderHeader()}
        {renderKpis()}
      </div>
    </div>
  );
};

Person.defaultProps = {
  cost: 0,
  membersCount: 0,
  metric: metricTypes.BE,
  quality: 0,
  reputation: 0,
  sentiment: 0,
  skillCount: 0,
  time: 0,
  skillsCountDepartment: 0,
};

Person.propTypes = {
  className: PropTypes.string,
  cost: PropTypes.number,
  imageUrl: PropTypes.string,
  isOwner: PropTypes.bool,
  membersCount: PropTypes.number,
  metric: PropTypes.oneOf(Object.values(metricTypes)),
  name: PropTypes.string,
  onClick: PropTypes.func,
  quality: PropTypes.number,
  reputation: PropTypes.number,
  sentiment: PropTypes.number,
  skillCount: PropTypes.number,
  time: PropTypes.number,
  onEdit: PropTypes.func,
  userId: PropTypes.string,
  deletePerson: PropTypes.func,
  skillsCountDepartment: PropTypes.number,
  disabled: PropTypes.boolean,
};

export default Person;
