import { Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { PropTypes } from "prop-types";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { styled } from "@mui/system";
import { WILD_BLUE_YONDER } from "constants/colors";
import { useEffect } from "react";

const StyledIconButton = styled(Button)({
  width: 58,
  "& svg": {
    width: 36,
    height: 36,
  },
  "&.Mui-disabled svg": {
    fill: WILD_BLUE_YONDER,
  },
});

const EstimationModal = ({ currentValue = 0, valueRef }) => {
  const [value, setValue] = useState(currentValue);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return (
    <Stack direction="row" justifyContent="center" height={58} my={1}>
      <StyledIconButton
        variant="outlined"
        color="primary"
        disabled={value <= 0}
        onClick={() => setValue(prev => prev - 1)}
      >
        <RemoveRoundedIcon width={36} height={36} />
      </StyledIconButton>
      <Typography variant="h3" fontWeight="bold" color="black" mx={6}>
        {value}
      </Typography>
      <StyledIconButton
        variant="outlined"
        color="primary"
        onClick={() => setValue(prev => prev + 1)}
      >
        <AddRoundedIcon width={36} height={36} />
      </StyledIconButton>
    </Stack>
  );
};

EstimationModal.propTypes = {
  currentValue: PropTypes.number,
  valueRef: PropTypes.object,
};

export { EstimationModal };
