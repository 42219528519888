import { useContext, useState } from "react";
import axios from "axios";

// Context
import FetchContext from "./FetchContext";

let instance;
const cleanProps = obj =>
  Object.fromEntries(
    Object.entries(obj)
      .filter(([, val]) => val !== undefined)
      .filter(([, val]) => val !== null)
      .filter(([, val]) => val !== "")
  );

const useFetch = () => {
  const context = useContext(FetchContext);

  const [loading, setIsLoading] = useState();

  const createInstance = () => {
    const { url, options } = context;
    return axios.create({
      baseURL: url,
      headers: options.headers,
    });
  };

  if (!instance) instance = createInstance(context);

  instance.interceptors.request.handlers = [];
  if (context.options && context.options.interceptors) {
    instance.interceptors.request.use(...context.options.interceptors.request);
    instance.interceptors.response.use(
      ...context.options.interceptors.response
    );
  }

  const makeRequest = ({ method, url, userParams, data }) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      const params = userParams ? cleanProps(userParams) : userParams;
      instance({ method, url, params, data })
        .then(res => {
          resolve({ ...res, ok: res.status <= 299 });
        })
        .catch(reject)
        .finally(() => setIsLoading(false));
    });
  };

  const get = (url, userParams) =>
    makeRequest({ method: "get", url, userParams });
  const post = (url, data) => makeRequest({ method: "post", url, data });
  const put = (url, data) => makeRequest({ method: "put", url, data });
  const del = (url, userParams) =>
    makeRequest({ method: "delete", url, userParams });

  return { ...instance, get, post, put, del, loading };
};

export const useHttpOptions = (
  user,
  handleLogOut,
  autoLogin,
  getValidToken,
  sessionToken
) => {
  return {
    interceptors: {
      request: [
        async options => {
          if (user !== null) {
            let token;
            if (autoLogin) token = await getValidToken();
            else token = sessionToken;

            options.headers = { Authorization: `Bearer ${token}` };
          }
          if (options.body instanceof FormData) {
            delete options.headers["Content-Type"];
          }
          return options;
        },
        reqError => {
          return Promise.reject(reqError);
        },
      ],
      response: [
        response => response,
        async resError => {
          const errorCopy = { ...resError };
          const { response } = resError;
          // Unauthorized
          if (response?.status === 401) handleLogOut();
          return Promise.reject(errorCopy);
        },
      ],
    },
    headers: { "Content-Type": "application/json" },
  };
};

export * from "./FetchProvider";

export default useFetch;
