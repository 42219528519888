import React from "react";
import PropTypes from "prop-types";
// hooks
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

// Components
import { Typography } from "@material-ui/core";
import BlueIcon from "components/BlueIcon";

// Constants
import { WHITE, WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  row: ({ hasActions }) => ({
    borderBottom: "1px solid rgba(255, 255, 255, .2);",
    ...(hasActions && {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    }),
  }),
  icon: {
    margin: "5px 0",
  },
  phase: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "column",
  },
  subPhase: {
    fontSize: "12px",
    color: WILD_BLUE_YONDER,
  },
  title: ({ hasActions }) => ({
    fontSize: "15px",
    color: WHITE,
    ...(!hasActions && {
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    }),
  }),
  wrap: {
    width: "100%",
    height: 60,
    padding: 10,
    display: "flex",
    alignItems: "flex-start",
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    cursor: "pointer",
  },
  actionText: {
    fontSize: "0.7",
  },
}));

const BrickList = ({ bricks, actions }) => {
  const classes = useStyles({ hasActions: actions?.length });
  const history = useHistory();

  const renderBrick = el => {
    return (
      <div className={classes.wrap}>
        <div className={classes.icon}>
          <BlueIcon width={"12px"} height={"12px"} borderRadius={"3px"} />
        </div>
        <div className={classes.phase}>
          <Typography
            component="h2"
            className={classes.title}
            onClick={() =>
              !actions?.length ? history.push(`/brick/${el?.id}`) : {}
            }
          >
            {el?.name}
          </Typography>
          <Typography component="h2" className={classes.subPhase}>
            {el?.phaseName}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      {bricks?.map((el, index) => {
        return (
          <div key={"user-dash-brick" + index} className={classes.row}>
            {renderBrick(el)}
            {actions?.length && (
              <div className={classes.actionsContainer}>
                {actions.map((action, idx) => (
                  <div key={idx}>
                    <div
                      className={classes.actionContainer}
                      onClick={() => action.function(el.id)}
                    >
                      {action.text && (
                        <Typography className={classes.actionText}>
                          {action.text}
                        </Typography>
                      )}
                      <div>
                        <img
                          src={action.icon.img}
                          height={action.icon.height}
                          width={action.icon.width}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

BrickList.propTypes = {
  bricks: PropTypes.array,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.shape({
        img: PropTypes.node,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
      function: PropTypes.func,
    })
  ),
};

export default BrickList;
