/* eslint-disable max-len */
import React from "react";
import { PropTypes } from "prop-types";

const ExpandBricksIcon = ({ fill = "white", style, onClick }) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    onClick={onClick}
  >
    <path
      d="M0.374279 1.99951V8.00049C0.374016 8.13124 0.387178 8.26081 0.413017 8.3818C0.438856 8.5028 0.476868 8.61284 0.524876 8.70566C0.572884 8.79847 0.62995 8.87224 0.692818 8.92275C0.755686 8.97325 0.823122 8.9995 0.891277 9L18.4713 9C18.5394 8.9995 18.6069 8.97325 18.6697 8.92275C18.7326 8.87224 18.7897 8.79847 18.8377 8.70566C18.8857 8.61284 18.9237 8.5028 18.9495 8.3818C18.9754 8.26081 18.9885 8.13124 18.9883 8.00049V1.99951C18.9885 1.86876 18.9754 1.73919 18.9495 1.6182C18.9237 1.4972 18.8857 1.38716 18.8377 1.29434C18.7897 1.20153 18.7326 1.12776 18.6697 1.07725C18.6069 1.02675 18.5394 1.0005 18.4713 1L0.891277 1C0.823122 1.0005 0.755686 1.02675 0.692818 1.07725C0.62995 1.12776 0.572884 1.20153 0.524876 1.29434C0.476868 1.38716 0.438856 1.4972 0.413017 1.6182C0.387178 1.73919 0.374016 1.86876 0.374279 1.99951ZM17.9543 1.99951V8.00049L1.40828 8.00049V1.99951L17.9543 1.99951Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.1"
    />
    <path d="M1.18164 12L18.1816 12" stroke={fill} strokeLinecap="round" />
    <path d="M1.18164 15L18.1816 15" stroke={fill} strokeLinecap="round" />
    <path d="M1.18164 18L18.1816 18" stroke={fill} strokeLinecap="round" />
  </svg>
);

ExpandBricksIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export { ExpandBricksIcon };
