import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
// Components
import Posts from "sidebars/Posts";
import SvgIcon from "components/SvgIcon";
// Context
import { AppContext } from "context/AppContext";
import { AuthContext } from "context/AuthContext";
// Assets
import logoSmall from "assets/logo-small.svg";
import LogoFull from "assets/logoWhite.svg";
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
// Constants
import { ROOT } from "constants/routes"; // Constants
import { WHITE, WILD_BLUE_YONDER } from "constants/colors";
// Layout config
import { firstColConfig } from "../config";

// Styles
const useStyles = makeStyles({
  wrapper: {
    transition: "0.2s width linear",
    flexShrink: 0,
    backgroundColor: "rgba(0,0,0,0.25)",
  },
  header: {
    height: 60,
  },
  closeIcon: {
    cursor: "pointer",
    width: 18,
    height: 18,
  },
  logoContainer: {
    display: "block",
    textAlign: "center",
  },
  logo: {
    height: "auto",
  },
  content: {
    height: "calc(100% - 50px)",
  },
  user: {
    fontWeight: "bold",
  },
});

const FirstCol = ({ showPosts }) => {
  // Context
  const { user } = useContext(AuthContext);
  const { isFirstColOpen, setIsFirstColOpen } = useContext(AppContext);
  // Hooks
  const classes = useStyles({ isFirstColOpen });
  return (
    <div
      className={classes.wrapper}
      style={{
        width: isFirstColOpen
          ? firstColConfig.width.expanded
          : firstColConfig.width.collapsed,
        padding: isFirstColOpen ? 40 : "40px 8px",
      }}
    >
      <div
        className={classes.header}
        style={{ marginBottom: isFirstColOpen ? 30 : 6 }}
      >
        <Fade
          in={!isFirstColOpen}
          appear
          exit={false}
          unmountOnExit
          timeout={200}
        >
          <Link
            to={ROOT}
            className={classes.logoContainer}
            style={{ margin: "auto" }}
          >
            <img
              src={logoSmall}
              className={classes.logo}
              style={{ width: 48 }}
            />
          </Link>
        </Fade>
        <Fade
          in={isFirstColOpen}
          appear
          exit={false}
          unmountOnExit
          timeout={200}
        >
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Link to={ROOT} className={classes.logoContainer}>
                <img
                  src={LogoFull}
                  className={classes.logo}
                  style={{ width: 215 }}
                />
              </Link>
              <SvgIcon
                color={WILD_BLUE_YONDER}
                hoverColor={WHITE}
                className={classes.closeIcon}
                icon={<CancelIcon />}
                onClick={() => setIsFirstColOpen(false)}
                strokeWidth={3}
              />
            </div>

            <Typography variant="body1">
              <span>HELLO, </span>
              <span className={classes.user}>
                {user?.name} {user?.surname}
              </span>
            </Typography>
          </>
        </Fade>
      </div>
      <div className={classes.content}>
        <PerfectScrollbar id="posts-perfect-scrollbar">
          {showPosts && <Posts />}
        </PerfectScrollbar>
      </div>
    </div>
  );
};

FirstCol.defaultProps = {
  showPosts: true,
};

FirstCol.propTypes = {
  showPosts: PropTypes.bool,
};

export default FirstCol;
