import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import placeholderIcon from "assets/placeholder.png";
import { Typography } from "@material-ui/core";
import theme from "themeV4";
import calendarIcon from "assets/calendar.svg";

// Components
import Date from "components/Date";
import Avatar from "components/Avatar";
import KpiDo from "components/KpiDo";
// Utils
import { brickTypes } from "utils/enums/bricks";
// Shapes
import { BrickShape } from "./shapes";
// Constants
import { WILD_BLUE_YONDER } from "constants/colors";
import { Stack, Tooltip } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "context/AppContext";

const avatarSize = 30;

const useStyles = makeStyles({
  container: {
    cursor: props => (props.onClick ? "pointer" : "default"),
    borderBottom: props => (props.color ? `4px solid ${props.color}` : "none"),
    position: "relative",
    borderRadius: "0.4rem",
    width: props => props.width || "100%",
    minHeight: props => (props.isSkyBrick ? 216 : 98),
    whiteSpace: "normal",
    color: "#fff",
    backgroundImage: props =>
      props.isSkyBrick
        ? props.imageUrl
          ? `url(${props.imageUrl})`
          : `url(${placeholderIcon})`
        : undefined,
    backgroundColor: props =>
      props.isSkyBrick ? undefined : theme.palette.componentsBackground.main,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  imageWrapper: {
    backdropFilter: props =>
      props.isSkyBrick && !props.imageUrl ? "blur(20px)" : undefined,
    borderRadius: "0.4rem",
    padding: props => (props.isSkyBrick ? 14 : `0 16px 12px 22px`),
    display: "flex",
    flexDirection: props => (props.isSkyBrick ? "row" : "column"),
    height: "100%",
  },
  avatarWrapper: {
    position: "absolute",
    left: 18,
    top: -8,
  },
  waterBrickHeader: {
    paddingInline: "58px 12px",
    paddingBlock: 7,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  waterDisplayName: {
    fontSize: 10,
    textTransform: "uppercase",
    color: WILD_BLUE_YONDER,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  dateWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 2,
  },
  tagsWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
  },
  calendarIcon: {
    width: 10,
    aspectRatio: "1/1",
    marginRight: 4,
  },
  grey: {
    color: WILD_BLUE_YONDER,
    lineHeight: 0.5,
  },
  ownerWrapper: {
    display: "flex",
    alignItems: "flex-start",
  },
  infoOwner: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 12,
  },
  kpi: props => ({
    height: "100%",
    display: "flex",
    flexDirection: props.isSkyBrick ? "column" : "row",
    marginTop: props.isSkyBrick ? undefined : theme.spacing(1),
    alignItems: "flex-end",
    ...(props.isSkyBrick && !props.isQualityActive && { marginBottom: 50 }),
  }),
  leftWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  ellipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  date: {
    marginRight: 5,
    textTransform: "capitalize",
  },
  tag: {
    textTransform: "lowercase",
    backgroundColor: "#FFFFFF1F",
    borderRadius: 4,
    padding: 6,
  },
  brickTitle: ({ isSkyBrick }) => ({
    fontSize: 12,
    fontWeight: "bold",
    "&:first-letter": {
      textTransform: "capitalize",
    },
    ...(!isSkyBrick && {
      flexGrow: 1,
      height: 20,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }),
  }),
  reportedPlannedRatio: ({ isSkyBrick }) => ({
    flexGrow: 0,
    flexShrink: 0,
    fontWeight: "bold",
    textAlign: "right",
    ...(isSkyBrick
      ? { paddingBlock: 5, marginLeft: 4, fontSize: "15px" }
      : { maxWidth: 65 }),
  }),
  jobTitle: {
    marginTop: 3,
  },
});

const Brick = ({ data, width, onClick, onOwnerClick }) => {
  const {
    avatarImageUrl,
    endDate,
    imageUrl,
    startDate,
    type,
    displayName,
    planned,
    reported,
    position,
    color,
    tag,
  } = data;

  // Brick status helpers
  const isWaterBrick =
    type === brickTypes.PLANNED ||
    type === brickTypes.ACTIVE ||
    type === brickTypes.TO_VALIDATE;

  const isSkyBrick = type === brickTypes.CLOSED;

  const { settings } = useContext(AppContext);
  const classes = useStyles({
    imageUrl,
    width,
    type,
    isSkyBrick,
    onClick,
    isQualityActive: settings?.isQualityActive,
    color,
  });

  const renderReportedPlannedratio = ({ reported, planned }) => {
    if (!Number.isFinite(reported) || !Number.isFinite(planned)) return null;
    return (
      <Typography className={classes.reportedPlannedRatio}>
        {Math.round(reported)}/{Math.round(planned)}gg
      </Typography>
    );
  };

  return (
    <div className={classes.container} onClick={onClick}>
      {isWaterBrick && (
        <>
          <Tooltip title={position} arrow placement="top">
            <div className={classes.avatarWrapper}>
              <Avatar
                onClick={onOwnerClick}
                size={avatarSize}
                image={avatarImageUrl}
              />
            </div>
          </Tooltip>
          <div className={classes.waterBrickHeader}>
            <Typography
              className={classes.waterDisplayName}
              title={displayName}
            >
              {displayName}
            </Typography>
          </div>
        </>
      )}
      <div className={classes.imageWrapper}>
        <div className={classes.leftWrapper}>
          <div>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography
                variant="subtitle1"
                className={classes.brickTitle}
                title={data.name}
              >
                {data.name}
              </Typography>
              {!isSkyBrick &&
                renderReportedPlannedratio({
                  reported,
                  planned,
                })}
            </Stack>
            <div className={classes.dateWrapper}>
              <img src={calendarIcon} className={classes.calendarIcon} />
              <Date
                dateString={startDate}
                variant="subtitle1"
                className={clsx(classes.grey, classes.date)}
              />
              <Typography
                variant="subtitle1"
                className={clsx(classes.grey, classes.date)}
                component="span"
              >
                |
              </Typography>
              <Date
                dateString={endDate}
                variant="subtitle1"
                className={clsx(classes.grey, classes.date)}
              />
            </div>

            {tag && (
              <div className={classes.tagsWrapper}>
                <Typography
                  variant="subtitle1"
                  className={clsx(classes.grey, classes.tag)}
                  component="span"
                >
                  {`#${data.tag}`}
                </Typography>
              </div>
            )}
          </div>
          {/* In base al type del brick questo deve visualizzare i kpi in orizzontale o in verticale */}
          {isSkyBrick && (
            <div className={classes.ownerWrapper}>
              <Avatar
                onClick={onOwnerClick}
                size={avatarSize}
                image={avatarImageUrl}
              />
              <div className={classes.infoOwner}>
                <Typography variant="caption">{data.displayName}</Typography>
                <Typography
                  variant="subtitle1"
                  className={clsx(classes.grey, classes.jobTitle)}
                >
                  {data.position}
                </Typography>
              </div>
            </div>
          )}
        </div>
        {data.type !== brickTypes.PLANNED && (
          <div className={classes.kpi}>
            {isSkyBrick &&
              renderReportedPlannedratio({
                reported,
                planned,
              })}
            <KpiDo
              number={data?.time}
              label="time"
              orientation={isSkyBrick ? "column" : "row"}
              alignItems={isSkyBrick ? "flex-end" : "baseline"}
            />
            <KpiDo
              number={data?.cost}
              label="cost"
              orientation={isSkyBrick ? "column" : "row"}
              alignItems={isSkyBrick ? "flex-end" : "baseline"}
            />
            {settings?.isQualityActive && (
              <KpiDo
                number={data?.quality}
                label="quality"
                orientation={isSkyBrick ? "column" : "row"}
                alignItems={isSkyBrick ? "flex-end" : "baseline"}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Brick.propTypes = {
  data: PropTypes.shape(BrickShape),
  width: PropTypes.number,
  onClick: PropTypes.func,
  onOwnerClick: PropTypes.func,
};

Brick.defaultProps = {
  data: {},
};

export default Brick;
