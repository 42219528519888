export const userFormDefaults = {
  name: "",
  surname: "",
  email: "",
  imageUrl: "",
  roleId: "",
  departmentId: "",
  jobTitleId: "",
  hourlyCost: "",
  canAccessWeb: false,
  canManageUsers: false,
  isAdmin: false
};
