import PropTypes from "prop-types";

const JobTitleShape = {
  name: PropTypes.string,
  id: PropTypes.string,
};

export const PersonShape = {
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  // ATM Skyview APIs lack sentiment for Project and Phase owners -> required propr console warning
  sentiment: PropTypes.number.isRequired,
  jobTitle: PropTypes.shape(JobTitleShape),
  skillCount: PropTypes.number,
  skillsCountDepartment: PropTypes.number,
};

export const ScaleShape = {
  x: PropTypes.number,
  y: PropTypes.number,
  scale: PropTypes.number,
};

export const PointShape = {
  x: PropTypes.number,
  y: PropTypes.number,
};
