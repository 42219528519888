import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// Components
import Sentiment from "./Sentiment";
import { Reputation } from "./Reputation";
import Skills from "./Skills";
// Constants
import { WILD_BLUE_YONDER } from "constants/colors";
// Material UI
import { Typography } from "@material-ui/core";
// Enums
import { beKpiTypes } from "utils/enums/grooveKpis";

const useStyles = makeStyles(() => ({
  wrapper: ({ orientation }) => ({
    display: "flex",
    flexDirection: orientation,
    alignItems: "center",
    textAlign: "center",
    width: 70,
    height: 50,
  }),
  kpiValue: {
    height: 38,
  },
  label: ({ orientation }) => ({
    color: WILD_BLUE_YONDER,
    textTransform: "uppercase",
    lineHeight: orientation === "column" && 0.6,
    marginTop: 0,
    marginLeft: orientation === "row" && 10,
  }),
}));

const KpiBe = ({ orientation, type, value, maxSkillValue }) => {
  const classes = useStyles({ orientation });
  const kpiData = {
    [beKpiTypes.SENTIMENT]: {
      component: <Sentiment sentiment={value} />,
      label: "Sentiment",
    },
    [beKpiTypes.REPUTATION]: {
      component: <Reputation reputation={value} />,
      label: "Reputation",
    },
    [beKpiTypes.SKILLS]: {
      component: <Skills skillValue={value} maxSkillValue={maxSkillValue} />,
      label: "Skills",
    },
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.kpiValue}>{kpiData[type]?.component}</div>
      <Typography className={classes.label} variant="subtitle1">
        {kpiData[type]?.label}
      </Typography>
    </div>
  );
};

KpiBe.defaultProps = {
  orientation: "column",
};

KpiBe.propTypes = {
  orientation: PropTypes.string,
  type: PropTypes.oneOf(Object.values(beKpiTypes)).isRequired,
  value: PropTypes.number,
  maxSkillValue: PropTypes.number,
};

export default KpiBe;
