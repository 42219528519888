import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

// icons
import logoBlue from "../../assets/logoBlue.svg";
import logoWhite from "../../assets/logoWhite.svg";

const useStyles = makeStyles(() => ({
  logo: {
    height: props => (props.isBlue ? "100%" : "35px"),
    width: "100%",
  },
}));

const Logo = ({ isBlue }) => {
  const classes = useStyles({ isBlue });

  return (
    <img
      className={classes.logo}
      src={isBlue ? logoBlue : logoWhite}
      alt="logo"
    />
  );
};

Logo.propTypes = {
  isBlue: PropTypes.bool,
};

Logo.defaultProps = {
  isBlue: false,
};
export default Logo;
