import React from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Components
import Layout from "components/Layout";
import Button from "components/Button";

//Images
import NotFoundImg from "assets/not-found-404.svg";

// hooks
import { useHistory } from "react-router-dom";
import { ROOT } from "constants/routes";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";

// Styles
const useStyles = makeStyles(() => ({
  button: {
    marginTop: "50px",
    width: "340px",
  },
  sectionTitle: {
    fontSize: "4em",
    fontWeight: "bold",
  },
  subTitle: {
    fontSize: "20px",
    textAlign: "center",
    marginTop: 40,
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    width: "calc(100% - 100px)",
  },
  notFound: {
    height: "143.5px",
    width: "432px",
  },
}));

const Settings = () => {
  const classes = useStyles({});
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const isMaker = !user?.canAccessWeb;

  return (
    <Layout showSecondCol={false} showHeader={false} showPosts={false}>
      <div className={classes.wrapper}>
        <img
          src={NotFoundImg}
          className={classes.notFound}
          alt="not-found-image"
        />
        <Typography className={classes.subTitle}>
          {"La pagina che stai cercando non è stata trovata"}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => history.push(ROOT)}
        >
          {`TORNA ${isMaker ? "INDIETRO" : "AL GROOVE"}`}
        </Button>
      </div>
    </Layout>
  );
};

export default Settings;
