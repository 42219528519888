import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import InfiniteScroll from "react-infinite-scroll-component";

// Components
import Post from "./component/Post";
import Loading from "components/Loading";

// Constants
import { WILD_BLUE_YONDER } from "constants/colors";

const SidebarContent = ({ posts, moreData, total, hasMore }) => {
  const useStyles = makeStyles(() => ({
    container: {
      display: "flex",
      flexDirection: "column",
      paddingRight: 15,
    },
    logoContainer: {
      display: "flex",
      alignItems: "flex-end",
      height: "70px",
    },
    heading: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "1.5rem",
      paddingRight: "2.1rem",
      width: "100%",
      paddingBottom: "1rem",
      "& > p": {
        fontSize: "0.8rem",
        color: WILD_BLUE_YONDER,
      },
    },
  }));

  const classes = useStyles();

  const getRole = post => {
    let role = "";
    const area = post?.owner?.area;

    role += post?.owner?.jobTitle?.name || "";
    role += area ? ` #${area}` : "";

    return role;
  };

  return (
    <div className={classes.container}>
      <InfiniteScroll
        scrollableTarget="posts-perfect-scrollbar"
        dataLength={posts?.length}
        next={() => moreData()}
        hasMore={hasMore}
        loader={posts?.length === total ? null : <Loading />}
      >
        {posts &&
          posts?.map(post => (
            <Post
              key={nanoid()}
              avatar={post?.owner?.imageUrl}
              name={`${post?.owner?.name} ${post?.owner?.surname}`}
              role={getRole(post)}
              date={post?.creationDate}
              taggedUsers={post?.taggedUsers}
              taggedProject={post?.projectDetails}
              text={post?.text}
              type={
                post?.imageUrl
                  ? "image"
                  : post?.audioUrl
                  ? "audio"
                  : post?.videoUrl
                  ? "video"
                  : null
              }
              content={post?.imageUrl || post?.audioUrl || post?.videoUrl}
              style={post?.type === 1 ? "light" : "dark"}
              reactions={post?.reactions}
              reaction={post?.reaction}
            />
          ))}
      </InfiniteScroll>
    </div>
  );
};

SidebarContent.propTypes = {
  posts: PropTypes.array,
  moreData: PropTypes.func,
  total: PropTypes.number,
  hasMore: PropTypes.bool,
};

export default SidebarContent;
