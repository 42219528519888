import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import theme from "themeV4";

// Material UI components
import { TextField as TextFieldMUI, InputAdornment } from "@material-ui/core";
import { Controller } from "react-hook-form";

const useStyles = makeStyles(() => ({
  adornment: ({ themeType }) => ({
    width: 24,
    height: 24,
    "& p": {
      color:
        themeType === "dark"
          ? theme.palette.componentsBackground.light
          : theme.palette.common.black,
    },
  }),
}));

const TextField = React.forwardRef(
  (
    {
      autocomplete,
      className,
      themeType,
      label,
      icon,
      endIcon,
      inputProps,
      shrink,
      helperText,

      ...other
    },
    ref
  ) => {
    const classes = useStyles({ themeType });

    return (
      <TextFieldMUI
        fullWidth
        label={label}
        variant="outlined"
        helperText={other.error && helperText}
        autoComplete={autocomplete}
        className={className}
        InputLabelProps={{
          style: {
            color:
              themeType === "dark"
                ? theme.palette.componentsBackground.light
                : theme.palette.componentsBackground.main,
          },
          shrink: shrink,
        }}
        InputProps={{
          startAdornment: icon && (
            <InputAdornment className={classes.adornment} position="start">
              {icon}
            </InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
          inputProps: { ...inputProps, ref },
          style: {
            color:
              themeType === "dark"
                ? theme.palette.componentsBackground.light
                : theme.palette.common.black,
            backgroundColor:
              themeType === "dark"
                ? theme.palette.componentsBackground.main
                : theme.palette.componentsBackground.light,
          },
        }}
        {...other}
      />
    );
  }
);

TextField.displayName = "TextField";

TextField.propTypes = {
  autocomplete: PropTypes.string,
  className: PropTypes.string,
  themeType: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.node,
  endIcon: PropTypes.node,
  inputProps: PropTypes.object,
  shrink: PropTypes.bool,
  helperText: PropTypes.string,
};

TextField.defaultProps = {
  themeType: "dark",
  helperText: "Campo obbligatorio",
  autocomplete: "off",
};

const TextFieldControl = ({ control, name, rules, defaultValue, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TextField
          value={field.value}
          onChange={field.onChange}
          error={!!error}
          helperText={error?.message || TextField.defaultProps.helperText}
          {...field}
          {...rest}
        />
      )}
    />
  );
};

TextFieldControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
};

export { TextFieldControl };

export default TextField;
