import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

// Components
import TextField from "components/TextField";
import Loading from "components/Loading";
import Autocomplete from "components/Autocomplete";

// Material UI
import { Grid, Typography, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DEPARTMENTS } from "constants/api";

// Styles
const useStyles = makeStyles(() => ({
  formRow: {
    marginBottom: 15,
  },
  loading: {
    height: "40vh",
  },
  title: {
    fontWeight: "bold",
    fontSize: 13,
  },
}));

const THEME_TYPE = "light";

const SkillForm = ({ data, loading }) => {
  // Hooks
  const classes = useStyles();
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  // Effects
  useEffect(() => {
    if (data) {
      setValue("name", data.name);
      setValue("department", data.department);
    }
  }, [data]);

  if (loading)
    return (
      <div className={classes.loading}>
        <Loading showWrapper={false} />
      </div>
    );

  return (
    <Fade in timeout={400}>
      <form>
        <Grid container spacing={1} style={{ width: "100%" }}>
          <Grid item xs={12} className={classes.formRow}>
            <Typography color="primary" className={classes.title} />
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            <Controller
              render={({ field }) => (
                <TextField
                  label="Nome skill"
                  error={!!errors.name}
                  required
                  themeType={THEME_TYPE}
                  shrink={!!field.value}
                  {...field}
                />
              )}
              name="name"
              control={control}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  label="Dipartimento"
                  endpoint={`${DEPARTMENTS}?q`}
                  error={!!errors.department}
                  getOptionLabel={option => option?.name}
                  themeType={THEME_TYPE}
                  {...field}
                  onChange={(_, data) => field.onChange(data)}
                />
              )}
              name="department"
              control={control}
            />
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};

SkillForm.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
};

export default SkillForm;
