import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import clsx from "clsx";

import { Typography, makeStyles } from "@material-ui/core";
import Button from "components/Button";

import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import AttachmentItem from "./AttachmentItem";

// css imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "pages/Groove/Carousel/slick.css";

import nextIcon from "assets/next.svg";
import prevIcon from "assets/previous.svg";
import nextIconHover from "assets/next-hover.svg";
import prevIconHover from "assets/previous-hover.svg";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { downloadBlob } from "utils/file";
import { Storage } from "aws-amplify";
import ButtonFileUploader from "components/ButtonFileUploader";

import Loading from "components/Loading";
import { attachmentType, fileShape } from "pages/BrickDashboard/shapes";

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBlock: 25,
    "& .slick-slide": {
      marginRight: 8,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    flexGrow: 0,
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 0,
    gap: 8,
  },
  deleteActions: {
    display: "flex",
    alignItems: "center",
    height: 46,
  },
  deleteAction: {
    textTransform: "capitalize",
    fontWeight: 700,
  },
  doDeleteBtn: {
    color: theme.palette.secondary.main,
    marginRight: 15,
  },
  deleteSeparator: {
    background: "white",
    borderRadius: 10,
    width: 2,
    height: "50%",
    display: "block",
    marginInline: 4,
  },
  icon: {
    width: "35px",
    height: "35px",
  },
  noData: {
    margin: "30px auto",
  },
  content: {
    minHeight: 153,
  },
  loadingWrapper: {
    paddingTop: 55,
  },
}));

const Arrow = props => {
  const classes = useStyles();
  const { className, style, onClick, type } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img
        onMouseOver={e =>
          (e.currentTarget.src =
            type === "next" ? nextIconHover : prevIconHover)
        }
        onMouseLeave={e =>
          (e.currentTarget.src = type === "next" ? nextIcon : prevIcon)
        }
        className={classes.icon}
        src={type === "next" ? nextIcon : prevIcon}
      />
    </div>
  );
};

Arrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

const settings = {
  initialSlide: 0,
  dots: false,
  swipe: true,
  draggable: false,
  infinite: false,
  speed: 1500,
  variableWidth: true,
  arrows: true,
  slidesToScroll: 2,
  nextArrow: <Arrow type={"next"} />,
  prevArrow: <Arrow />,
};

const AttachmentCarousel = ({
  title,
  RHFKey,
  style,
  uploadText,
  accept,
  attachments,
  onItemClick,
  loading,
  setLoading,
  onFileSelectionError,
  maxUploadFileSize,
  onUpload,
  onDelete,
}) => {
  const classes = useStyles();
  const form = useForm({ shouldUnregister: true });
  const { control, reset, handleSubmit, watch } = form;
  const { fields } = useFieldArray({
    control,
    name: RHFKey,
  });

  const [deleteMode, setDeleteMode] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);

  useEffect(() => {
    if (!attachments) return;
    reset({
      [RHFKey]: attachments?.map(a => ({ ...a, delete: false })),
    });
  }, [attachments]);

  useEffect(() => {
    if (!filesToUpload?.length) return;
    // TODO: Upload logic here
    setLoading(true);
    onUpload(filesToUpload[0]).finally(() => {
      setLoading(false);
      setFilesToUpload([]);
    });
  }, [filesToUpload]);

  const toggleDeleteMode = () => setDeleteMode(prev => !prev);

  const handleAttachmentClick = async attachment => {
    if (deleteMode) return;

    if (
      attachment.fileType === attachmentType.IMAGE ||
      attachment.fileType === attachmentType.VIDEO
    )
      onItemClick(attachment);

    if (attachment.fileType === attachmentType.DOCUMENT) {
      const s3Key = attachment.s3Key.replace("public/", "");
      const fileData = await Storage.get(s3Key, {
        bucket: process.env.REACT_APP_BUCKET_FILE,
        download: true,
      });
      downloadBlob(fileData.Body, attachment.name);
    }
  };

  const handleDeleteAttachments = data => {
    setLoading(true);
    onDelete(data, RHFKey).then(() => {
      setDeleteMode(false);
      setLoading(false);
    });
  };

  const DeleteActions = () =>
    deleteMode ? (
      <div className={classes.deleteActions}>
        <Button
          variant="text"
          className={classes.deleteAction}
          onClick={toggleDeleteMode}
        >
          Annulla
        </Button>
        <span className={classes.deleteSeparator}></span>
        <Button
          variant="text"
          className={clsx(classes.deleteAction, classes.doDeleteBtn)}
          onClick={handleSubmit(handleDeleteAttachments)}
          disabled={!watch(RHFKey)?.some(f => f.delete)}
        >
          Elimina
        </Button>
      </div>
    ) : (
      <Button
        className="iconButton"
        variant="outlined"
        color="secondary"
        onClick={toggleDeleteMode}
        disabled={!attachments?.length || loading}
        icon={<DeleteIcon />}
      />
    );

  return (
    <FormProvider {...form}>
      <div className={classes.wrapper} style={style}>
        <div className={classes.header}>
          <Typography variant="body1" className={classes.title}>
            {title}
          </Typography>
          <div className={classes.actions}>
            <DeleteActions />
            <ButtonFileUploader
              text={uploadText || "Carica file"}
              variant="outlined"
              size="large"
              style={{ height: 46 }}
              disabled={deleteMode || loading}
              onFileSelection={setFilesToUpload}
              onFileSelectionError={onFileSelectionError}
              maxSizePerFile={maxUploadFileSize}
              accept={accept}
              maxFiles={1}
            />
          </div>
        </div>
        <div className={classes.content}>
          {loading ? (
            <div className={classes.loadingWrapper}>
              {<Loading showWrapper={false} />}
            </div>
          ) : fields.length ? (
            <Slider {...settings}>
              {fields?.map((a, idx) => (
                <AttachmentItem
                  key={idx}
                  onClick={() => handleAttachmentClick(a)}
                  name={a.name}
                  deleteMode={deleteMode}
                  date={a.date}
                  RHFKey={RHFKey}
                  type={a.fileType}
                  thumb={a.thumbnailUrl}
                  idx={idx}
                />
              ))}
            </Slider>
          ) : (
            <Typography variant="h6" className={classes.noData}>
              Nessun elemento caricato per questo brick
            </Typography>
          )}
        </div>
      </div>
    </FormProvider>
  );
};

AttachmentCarousel.propTypes = {
  title: PropTypes.string,
  RHFKey: PropTypes.string.isRequired,
  style: PropTypes.object,
  uploadText: PropTypes.string,
  accept: PropTypes.array,
  attachments: PropTypes.arrayOf(fileShape),
  onItemClick: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  onFileSelectionError: PropTypes.func,
  onUpload: PropTypes.func,
  onDelete: PropTypes.func,
  maxUploadFileSize: PropTypes.number,
};

export default AttachmentCarousel;
