import React from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import * as animateLoading from "assets/animations/loading.json";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: (props) =>
      props.showWrapper
        ? `linear-gradient(45deg, rgba(0, 0, 0, 0.2) 25%, transparent 100%)`
        : "transparent",
  },
}));

const Loading = ({ animationStyle, showWrapper }) => {
  const classes = useStyles({ showWrapper });
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animateLoading.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={classes.wrapper}>
      <Lottie options={defaultOptions} style={animationStyle} />
    </div>
  );
};

Loading.propTypes = {
  animationStyle: PropTypes.object,
  showWrapper: PropTypes.bool,
};

Loading.defaultProps = {
  showWrapper: true,
  animationStyle: { height: 50, width: 50 },
};

export default Loading;
