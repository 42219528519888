import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { InputBase, Typography } from "@material-ui/core";
// Constants
import { GHOST_WHITE, SPACE_CADET, WILD_BLUE_YONDER } from "constants/colors";
// Assests
import { ReactComponent as SearchIcon } from "assets/search-image.svg";
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
import SvgIcon from "components/SvgIcon";

const SEARCHBAR_RADIUS = 15;

// Styles
const useStyles = makeStyles(() => ({
  searchField: ({ hasResults, searchFieldAdditionalStyle }) => ({
    height: 65,
    backgroundColor: SPACE_CADET,
    borderRadius: SEARCHBAR_RADIUS,
    borderBottomLeftRadius: hasResults ? 0 : SEARCHBAR_RADIUS,
    borderBottomRightRadius: hasResults ? 0 : SEARCHBAR_RADIUS,
    display: "flex",
    alignItems: "center",
    padding: "25px 30px",
    cursor: "pointer",
    ...searchFieldAdditionalStyle,
  }),
  input: {
    margin: "0 20px",
    color: WILD_BLUE_YONDER,
    fontSize: 22,
    padding: "7px 0 7px",
  },
  suggestionsWrapper: {
    padding: "20px 76px",
    backgroundColor: SPACE_CADET,
    borderTop: `1px solid ${GHOST_WHITE}`,
    borderBottomRightRadius: SEARCHBAR_RADIUS,
    borderBottomLeftRadius: SEARCHBAR_RADIUS,
  },
  result: {
    display: "flex",
    fontSize: 18,
    margin: "12px auto",
    cursor: "pointer",
  },
  resultValue: {
    color: GHOST_WHITE,
  },
  resultCategory: {
    marginLeft: 4,
    color: WILD_BLUE_YONDER,
  },
}));
/**
 * @example <SearchBar
              searchFunction={text => get(`/SEARCH?q=${text}`)}
              fetchedResultsHandler={res => setResults(res)}
              resultPickedHandler={text => history.push(`${SEARCH}?q=${text}`)}
              placeholder="Cerca un Progetto, un Brick, un Utente..."
            />
 * @param  {} searchFunction - returns the text to search in order to make the effective searching call
 * @param  {} fetchedResultsHandler - returns the array of results
 * @param  {} resultPickedHandler - returns the text picked
 * @param  {} placeholder - Input placeholder
 * @param  {} isAutocomplete - Input autocomplete
 * @param  {} searchFieldAdditionalStyle - Input additional style
 * @param  {} emptyFieldAfterSubmit - Reset the input textfield after submit
 * @param  {} searchQuery - Input value (set from outside)
 */
const SearchBar = ({
  fetchedSuggestionsHandler,
  isAutocomplete,
  placeholder,
  resultPickedHandler,
  searchFieldAdditionalStyle,
  searchQuery,
  searchFunction,
}) => {
  //States
  const [results, setResults] = useState([]);
  const hasResults = !!results && results?.length > 0;

  // Hooks
  const classes = useStyles({ hasResults, searchFieldAdditionalStyle });
  const searchInputRef = useRef();
  const { register, setValue, watch } = useForm({
    defaultValues: {
      searchText: "",
    },
  });
  const searchText = watch("searchText");

  // Effects
  useEffect(() => {
    if (searchQuery) {
      setValue("searchText", searchQuery);
      searchInputRef.current.blur();
    }
  }, [searchQuery]);

  useEffect(() => {
    setResults([]);
    if (searchText?.length >= 3) search();
  }, [searchText]);

  //Functions
  const focusSearch = () => searchInputRef?.current.focus();
  const reset = () => {
    setResults([]);
    setValue("searchText", "");
  };
  const handleSuggestionClick = text => {
    reset();
    resultPickedHandler && resultPickedHandler(text);
  };
  const handleKeyPress = e => {
    if (e.charCode === 13 && !!searchText) {
      resultPickedHandler && resultPickedHandler(searchText);
    }
  };
  const search = async () => {
    try {
      const res = await searchFunction(searchText);
      setResults(res);
      fetchedSuggestionsHandler && fetchedSuggestionsHandler(res);
    } catch (err) {
      console.error(err);
      // setResults(dummyResults);
      // fetchedResultsHandler && fetchedResultsHandler(dummyResults);
    }
  };

  return (
    <>
      <div className={classes.searchField} onClick={focusSearch}>
        <SearchIcon width={28} height={28} />
        <InputBase
          autoFocus
          autoComplete={isAutocomplete ? "on" : "off"}
          onKeyPress={handleKeyPress}
          className={classes.input}
          inputRef={searchInputRef}
          fullWidth
          placeholder={placeholder}
          inputProps={{
            autoComplete: isAutocomplete ? "on" : "off",
            ...register("searchText"),
          }}
        />
        {!!searchText && (
          <SvgIcon
            icon={<CancelIcon />}
            width={23}
            height={23}
            strokeWidth={1.5}
            color={WILD_BLUE_YONDER}
            hoverColor={GHOST_WHITE}
            onClick={() => reset()}
          />
        )}
      </div>
      {hasResults && !!searchText && (
        <div className={classes.suggestionsWrapper}>
          {results.map((result, idx) => (
            <div
              key={idx}
              className={classes.result}
              onClick={() => handleSuggestionClick(result.value)}
            >
              <Typography variant="h6" className={classes.resultValue}>
                {result.value}
              </Typography>
              {result?.category && (
                <Typography variant="h6" className={classes.resultCategory}>
                  in {result.category}
                </Typography>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
SearchBar.defaultProps = {
  emptyFieldAfterSubmit: true,
  isAutocomplete: false,
  placeholder: "Cerca...",
};
SearchBar.propTypes = {
  emptyFieldAfterSubmit: PropTypes.bool,
  isAutocomplete: PropTypes.bool,
  fetchedSuggestionsHandler: PropTypes.func,
  placeholder: PropTypes.string,
  resultPickedHandler: PropTypes.func.isRequired,
  searchFieldAdditionalStyle: PropTypes.object,
  searchQuery: PropTypes.string,
  searchFunction: PropTypes.func,
};

export default SearchBar;
