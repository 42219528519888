import React, { useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import { nanoid } from "nanoid";

// Dayjs plugins
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

// Components
import TextField from "components/TextField";
import Autocomplete from "components/Autocomplete";
import Button from "components/Button";
// import Switch from "components/Switch";
import SvgIcon from "components/SvgIcon";
import Loading from "components/Loading";

// Material UI
import { Grid, Typography, Fade, InputAdornment } from "@material-ui/core";
import DatePicker from "components/DatePicker";
import { makeStyles } from "@material-ui/core/styles";
import TagIcon from "@mui/icons-material/Tag";

// Api
import { SKILLS, USERS_LIST } from "constants/api";

// Icons
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
import { ReactComponent as DragHandle } from "assets/drag-handle.svg";

// Constants
import { WILD_BLUE_YONDER } from "constants/colors";
import { ColorPickerControl } from "components/ColorPicker";
import { Box } from "@mui/material";

// Styles
const useStyles = makeStyles(() => ({
  formWrapper: {
    width: "100%",
  },
  formRow: {
    marginBottom: 15,
  },
  switchWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  dragIcon: {
    marginRight: 10,
  },
  dragIconRequired: {
    marginRight: 10,
    marginBottom: 20,
  },
  loading: {
    height: "40vh",
  },
  title: {
    fontWeight: "bold",
    fontSize: 13,
  },
}));

const THEME_TYPE = "light";

const BrickForm = ({ brickData, loading, phaseTitle }) => {
  // Hooks
  const classes = useStyles();
  const {
    formState: { errors },
    control,
    setValue,
    register,
    watch,
  } = useFormContext();

  const {
    fields,
    prepend,
    insert: setChecklists,
    remove,
    move,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "checklists", // unique name for your Field Array
  });

  const watchedStartDate = watch("plannedStartDate", null);
  const watchedEndDate = watch("plannedEndDate", null);

  // Effects
  useEffect(() => {
    setValue("checklists", []);

    if (brickData) {
      setValue("name", brickData.name);
      setValue("description", brickData.description);
      setValue("color", brickData.color);
      setValue("tag", brickData.tag);
      setValue("priority", brickData.priority);
      setValue("plannedStartDate", dayjs(brickData.plannedStartDate));
      setValue("plannedEndDate", dayjs(brickData.plannedEndDate));
      setValue("plannedHours", parseInt(brickData.plannedHours.split(":")[0]));
      setValue("user", brickData.user);
      setValue("message", brickData.message);
      setValue("skillsIds", brickData.skills || []);
      setChecklists(
        0,
        brickData.checklists.map(ck => ({ ...ck, originalId: ck.id }))
      );
    } else {
      setValue("skillsIds", []);
    }
  }, [brickData]);

  const addItem = () => {
    prepend({ id: nanoid(), description: "" });
  };

  const deleteItem = index => {
    remove(index);
  };

  const handleCheckListReorder = result => {
    const { destination, source } = result;
    // Dropped outside the list
    if (!destination) return;
    // Dropped in place
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    // Reorder items after drop
    move(source.index, destination.index);
  };

  if (loading)
    return (
      <div className={classes.loading}>
        <Loading showWrapper={false} />
      </div>
    );
  return (
    <DragDropContext onDragEnd={handleCheckListReorder}>
      <Fade in timeout={400}>
        <form>
          <Grid container spacing={1} style={{ width: "100%" }}>
            <Grid item xs={12} className={classes.formRow}>
              <Typography
                color="primary"
                variant="h6"
                className={classes.title}
              >{`FASE: ${phaseTitle}`}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <TextField
                    label="Nome Brick"
                    error={!!errors.name}
                    required
                    themeType={THEME_TYPE}
                    shrink={!!field.value}
                    {...field}
                  />
                )}
                name="name"
                control={control}
                rules={{ required: true }}
              />
            </Grid>

            <Grid item xs={12} className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <TextField
                    label="Descrizione"
                    multiline
                    rows={5}
                    themeType={THEME_TYPE}
                    shrink={!!field.value}
                    {...field}
                  />
                )}
                name="description"
                control={control}
              />
            </Grid>

            <Grid item xs={12} md={6} className={classes.formRow}>
              <Box
                sx={{
                  border: "1px solid #B0B2B8",
                  backgroundColor: "#E5E8EF",
                  borderRadius: 1,
                  padding: "10px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: -3,
                    height: 3,
                    width: 33,
                    backgroundColor: "#FFFFFF",
                  }}
                ></Box>
                <Box sx={{ position: "absolute", top: -8, left: 13 }}>
                  <Typography style={{ fontSize: 9, color: "#2C354C" }}>
                    {`Colore`}
                  </Typography>
                </Box>

                <ColorPickerControl
                  name="color"
                  fullWidth
                  control={control}
                  defaultValue={""}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <TextField
                    label="Tag"
                    themeType={THEME_TYPE}
                    shrink={true}
                    icon={<TagIcon sx={{ color: WILD_BLUE_YONDER }} />}
                    {...field}
                  />
                )}
                name="tag"
                control={control}
              />
            </Grid>
            <Grid item xs={12} className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <TextField
                    label="Priorità"
                    themeType={THEME_TYPE}
                    type="number"
                    inputProps={{
                      min: 1,
                      max: 10,
                      inputMode: "numeric",
                    }}
                    shrink={!!field.value}
                    error={!!errors.priority && !!field.value}
                    helperText={
                      "La priorità deve avere un valore compreso tra 1 e 10"
                    }
                    {...field}
                  />
                )}
                name="priority"
                control={control}
                rules={{
                  validate: value => !value || (value <= 10 && value >= 0),
                }}
              />
            </Grid>

            <Grid xs item className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <DatePicker
                    themeType={THEME_TYPE}
                    label="Data inizio pianificata"
                    error={!!errors.plannedStartDate}
                    helperText={
                      errors.plannedStartDate?.type === "validate"
                        ? "La data di inizio progetto non può essere superiore alla data di fine"
                        : undefined
                    }
                    required
                    // maxDate={watchedEndDate || dayjs()}
                    {...field}
                  />
                )}
                name="plannedStartDate"
                control={control}
                rules={{
                  required: true,
                  validate: selectedDate =>
                    watchedEndDate &&
                    selectedDate.isSameOrBefore(watchedEndDate),
                }}
              />
            </Grid>
            <Grid xs item className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <DatePicker
                    themeType={THEME_TYPE}
                    label="Data fine pianificata"
                    required
                    error={!!errors.plannedEndDate}
                    helperText={
                      errors.plannedEndDate?.type === "validate"
                        ? "La data di fine progetto non può essere inferiore alla data di inizio"
                        : undefined
                    }
                    minDate={watchedStartDate || dayjs()}
                    {...field}
                  />
                )}
                name="plannedEndDate"
                control={control}
                rules={{
                  required: true,
                  validate: selectedDate =>
                    watchedStartDate &&
                    selectedDate.isSameOrAfter(watchedStartDate),
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <TextField
                    label="Ore Pianificate"
                    themeType={THEME_TYPE}
                    required
                    type="number"
                    shrink={!!field.value}
                    error={!!errors.plannedHours}
                    {...field}
                  />
                )}
                name="plannedHours"
                control={control}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} className={classes.formRow}>
              {watch("skillsIds") && (
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      label="Associa skill"
                      endpoint={`${SKILLS}?q`}
                      error={!!errors.skillsIds}
                      getOptionLabel={option => option?.name}
                      multiple
                      themeType={THEME_TYPE}
                      {...field}
                      onChange={(_, data) => field.onChange(data)}
                      minSearchLength={2}
                    />
                  )}
                  name="skillsIds"
                  defaultValue={[]}
                  control={control}
                />
              )}
            </Grid>
            {/* <Grid item xs={12} className={classes.formRow}>
              <div className={classes.switchWrapper}>
                <Switch
                // checked={blurActive}
                // onChange={e => onBlurChange(e.target.checked)}
                />
                <Typography>Match owner con skills</Typography>
              </div>
            </Grid> */}
            <Grid item xs={12} className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    label="Owner"
                    endpoint={`${USERS_LIST}?me=true&q`}
                    error={!!errors.user}
                    getOptionLabel={option =>
                      `${option.name} ${option.surname}`
                    }
                    themeType={THEME_TYPE}
                    required
                    {...field}
                    onChange={(_, data) => field.onChange(data)}
                  />
                )}
                name="user"
                control={control}
                rules={{ required: true }}
              />
            </Grid>
            {/* <Grid item xs={12} className={classes.formRow}>
              <Controller
                render={({ field }) => (
                  <TextField
                    label="Messaggio per l'owner"
                    multiline
                    rows={5}
                    themeType={THEME_TYPE}
                    shrink={!!field.value}
                    {...field}
                  />
                )}
                name="message"
                control={control}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>Check list</Typography>
            </Grid>
            <Grid item xs={12} className={classes.formRow}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                themeType={THEME_TYPE}
                onClick={addItem}
              >
                + LIST ITEM
              </Button>
            </Grid>
          </Grid>
          <Droppable droppableId="droppable" direction="vertical">
            {provided => (
              <>
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {fields?.map((field, idx) => (
                    <Draggable
                      key={field.id}
                      draggableId={field.id?.toString()}
                      index={idx}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Grid container spacing={1} style={{ width: "100%" }}>
                            <Grid item xs={11} xl={11}>
                              <div
                                {...provided?.dragHandleProps}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <SvgIcon
                                  className={
                                    errors.checklists?.[idx]?.description
                                      ? classes.dragIconRequired
                                      : classes.dragIcon
                                  }
                                  icon={<DragHandle />}
                                  color={WILD_BLUE_YONDER}
                                  width={14}
                                />
                                <TextField
                                  label="Item"
                                  required
                                  themeType={THEME_TYPE}
                                  disabled={field.checked}
                                  error={
                                    !!errors.checklists?.[idx]?.description
                                  }
                                  {...register(
                                    `checklists.${idx}.description`,
                                    { required: true }
                                  )}
                                  defaultValue={field.description} // make sure to include defaultValue
                                />
                              </div>
                            </Grid>
                            <Grid item xs={1} xl={1}>
                              <Button
                                className="iconButton"
                                themeType={THEME_TYPE}
                                disabled={field.checked}
                                variant="outlined"
                                color="secondary"
                                onClick={() => deleteItem(idx)}
                                icon={<CancelIcon />}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
                {provided.placeholder}
              </>
            )}
          </Droppable>
        </form>
      </Fade>
    </DragDropContext>
  );
};

BrickForm.propTypes = {
  brickData: PropTypes.object,
  phaseTitle: PropTypes.string,
  loading: PropTypes.bool,
};

export default BrickForm;
